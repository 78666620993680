import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Box,
  Button,
  Flex,
  Heading,
  SkeletonCircle,
  SkeletonText,
  Tab,
  TabList,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { EmailIcon } from "@chakra-ui/icons";
import { SlCalender } from "react-icons/sl";
import CandidateNote from "./CandidateNote";
import Card from "components/card/Card";
import { candidateService } from "services/Candidate/CandidateServices";
import { ICandidate, IMasterData } from "types/interface";
import useOpenNotification from "hook/useOpenNotification";
import { formatDateTime, truncateString } from "common/utils";
import CandidateInfo from "./CandidateInfo";
import CandidateApplyInfo from "./CandidateApplyInfo";
import { HTTP_CODE, MASTER_DATA_TYPE, NULL_VALUE } from "common/constants";
import { PostIcon } from "components/icons/Icons";
import { masterDataService } from "services/MasterData/MasterDataServices";

const tabPanelTitle = ["Thông tin ứng viên", "Thông tin ứng tuyển"];

const TAB_PANEL_INDEX = {
  CANDIDATE_INFO: 0,
  CANDIDATE_APPLY_INFO: 1,
};

const CandidateInfoPage = () => {
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [candidateInfo, setCandidateInfo] = useState<ICandidate>({});
  const [loading, setLoading] = useState<boolean>(true);
  const [masterData, setMasterData] = useState<IMasterData[]>([]);
  const [isViewCV, setIsViewCV] = useState<boolean>(false);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const candidateId = searchParams.get("id");

  const openNotification = useOpenNotification();

  const infoStyle = {
    alignItems: "center",
    gap: "5px",
  };
  const handleTabChange = (selectedTabIndex: number) => {
    setSelectedTab(selectedTabIndex);
  };

  const getCandidateInfo = async () => {
    try {
      setLoading(true);
      const candidateInfoRes: any = await candidateService.getCandidateById(
        candidateId,
        {}
      );

      if (candidateInfoRes.status === HTTP_CODE.SUCCESS) {
        setCandidateInfo(candidateInfoRes.data);
      } else {
        openNotification(candidateInfoRes?.message || "Network error", "error");
        setCandidateInfo({});
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      openNotification((error as Error)?.message || "Network error", "error");
      setCandidateInfo({});
    }
  };

  const getMasterData = async () => {
    const headers = {
      page: 0,
      size: 1000,
    };

    const params = {
      type: [
        MASTER_DATA_TYPE.UNIVERSITY,
        MASTER_DATA_TYPE.CHARACTER,
        MASTER_DATA_TYPE.HIGH_SCHOOL,
        MASTER_DATA_TYPE.LEVEL,
        MASTER_DATA_TYPE.CRITERIA,
        MASTER_DATA_TYPE.REASON_FAIL,
      ].join(","),
    };
    try {
      const masterDataRes = await masterDataService.getMasterData(
        params,
        headers
      );

      if (masterDataRes?.status === HTTP_CODE.SUCCESS) {
        setMasterData(masterDataRes?.data?.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getCandidateInfo();
    getMasterData();
  }, [candidateId]);

  return (
    <>
      {loading ? (
        <Box padding="6" boxShadow="lg" bg="white">
          <SkeletonCircle size="70" />
          <SkeletonText mt="4" noOfLines={20} spacing="4" skeletonHeight="2" />
        </Box>
      ) : (
        <Card flexDirection="column" d="flex">
          <Flex>
            <Card flex={0.65}>
              <Flex gap="20px">
                <Flex flexDirection="column">
                  <Heading size="md">
                    {candidateInfo?.candidate_name || NULL_VALUE}
                  </Heading>

                  <Flex {...infoStyle} gap="5px">
                    <PostIcon />
                    <span>{candidateInfo?.title}</span>
                  </Flex>

                  <Flex {...infoStyle}>
                    <EmailIcon />
                    <span>
                      {candidateInfo?.email}{" "}
                      {candidateInfo?.phone_number &&
                        `- ${candidateInfo?.phone_number}`}
                    </span>
                  </Flex>

                  <Flex {...infoStyle}>
                    <SlCalender />
                    <span>
                      Ngày ứng tuyển:{" "}
                      {formatDateTime(candidateInfo?.created_at)}
                    </span>
                  </Flex>
                </Flex>
              </Flex>
              <Tabs
                align="start"
                mt="15px"
                onChange={handleTabChange}
                defaultIndex={selectedTab}
              >
                <TabList>
                  {tabPanelTitle.map((title, index) => (
                    <Tab key={index}>{title}</Tab>
                  ))}
                </TabList>
              </Tabs>

              {selectedTab === TAB_PANEL_INDEX.CANDIDATE_INFO && (
                <CandidateInfo
                  candidateInfo={candidateInfo}
                  getCandidateInfo={getCandidateInfo}
                  masterData={masterData}
                />
              )}
              {selectedTab === TAB_PANEL_INDEX.CANDIDATE_APPLY_INFO && (
                <CandidateApplyInfo
                  candidateInfo={candidateInfo}
                  masterData={masterData}
                />
              )}
            </Card>

            <Card flex={0.35} borderLeft="1px solid #a09c9c">
              <CandidateNote candidateId={candidateId} />
            </Card>
          </Flex>

          <Box>
            <Heading size="md" borderBottom="2px solid #E2E8F0" p="15px 0">
              Hồ sơ đính kèm
            </Heading>

            {candidateInfo?.cv_url?.length > 0 ? (
              <Button
                onClick={() => setIsViewCV(!isViewCV)}
                colorScheme="blue"
                m="15px 0"
              >
                {isViewCV ? "Đóng" : "Xem CV"}
              </Button>
            ) : (
              <Text>Chưa có CV</Text>
            )}

            {isViewCV && (
              <iframe
                src={candidateInfo?.cv_url?.[0]}
                width="100%"
                height="800px"
              ></iframe>
            )}
          </Box>
        </Card>
      )}
    </>
  );
};

export default CandidateInfoPage;
