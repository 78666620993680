import {
  Box,
  Button,
  Flex,
  Icon,
  Link,
  Progress,
  SimpleGrid,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Tooltip,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import * as React from "react";
import { MdFullscreen } from "react-icons/md";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CandidateStatus, cvManageServices } from "./CvManageServices";
import UpdateCvInfo from "./UpdateCvInfo";

export interface CandidateInfo {
  candidate_name: string;
  jobname: string;
  email: string;
  phone_number: string;
  note: string;
  status: string;
  cv_url: [string];
}
export default function CvDetail(data: any) {
  const id = data.location.search.split("=")[1];
  const [candidataData, setCandidataData] = React.useState<CandidateInfo>();
  const [isProgress, setIsProgress] = React.useState(true);
  const [showCv, setShowCv] = React.useState(false);

  const [showUpdateCvModal, setShowUpdateCvModal] = React.useState(false);
  const [dataUpdate, setDataUpdate] = React.useState({});

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const iconColor = useColorModeValue("brand.500", "white");

  const deleteCv = async () => {
    async function getData() {
      const header = {
        page: 0,
        size: 10,
      };
      const body = {
        status: CandidateStatus.DELETED,
      };
      const param = {
        id: id,
      };

      try {
        const response = await cvManageServices.changeCvStatus(
          body,
          param,
          header
        );
      } catch (error) {
        toast.error("Network error");
      } finally {
        setIsProgress(false);
      }
    }
    getData();
  };

  const convertStatus = (status: string) => {
    switch (status) {
      case CandidateStatus.CREATE:
        return "Nhận hồ sơ";
      case CandidateStatus.REVIEW:
        return "Review";
      case CandidateStatus.TEST:
        return "Làm bài test";
      case CandidateStatus.PV:
        return "Interview";
      case CandidateStatus.OFFER:
        return "Đề nghị nhận việc";
      case CandidateStatus.REJECT:
        return "Từ chối offer";
    }
  };

  const getCandidateInfo = () => {
    setIsProgress(true);
    async function getData() {
      const header = {
        page: 0,
        size: 1000,
      };
      const param = {
        id: id,
      };
      try {
        const response = await cvManageServices.getCvById(param, header);

        if (response?.data) {
          const { data } = response;

          setCandidataData(data);

          if (validateURL(data.cv_url)) {
            setShowCv(true);
          }
        } else {
          setCandidataData(null);
          setShowCv(false);
        }
      } catch (error) {
        toast.error("Network error");
      } finally {
        setIsProgress(false);
      }
    }
    getData();
  };

  const validateURL = (data: any) => {
    let isValid = true;
    if (data.length > 0) {
      data.forEach((item: any) => {
        if (!item || item === "") isValid = false;
      });
    }
    if (data.length === 0) isValid = false;
    return isValid;
  };

  const handleUpdateCandidateInfo = () => {
    setShowUpdateCvModal(true);
    setDataUpdate(candidataData);
  };
  const resetData = () => {
    setDataUpdate({});
  };

  React.useEffect(() => {
    getCandidateInfo();
  }, []);

  return (
    <Box overflow="hidden">
      <SimpleGrid>
        {!isProgress && (
          <Card flexDirection="column" w="full" pb={3}>
            {/* <Box height="700px" mb={0}>
            </Box> */}
            <Flex
              px="25px"
              mb="1rem "
              justifyContent="space-between"
              align="center">
              <Text
                color={textColor}
                fontSize="1.5rem"
                fontWeight="700"
                lineHeight="100%">
                Tên ứng cử viên: {candidataData.candidate_name}
              </Text>
            </Flex>
            <Flex
              px="25px"
              mb="1rem "
              justifyContent="space-between"
              align="center">
              <Text
                color={textColor}
                fontSize="1.5rem"
                fontWeight="700"
                lineHeight="100%">
                Vị trí ứng tuyển: {candidataData.jobname}
              </Text>
            </Flex>
            <Flex
              px="25px"
              mb="1rem "
              justifyContent="space-between"
              align="center">
              <Text
                color={textColor}
                fontSize="1rem"
                fontWeight="300"
                lineHeight="100%">
                Email: {candidataData.email}
              </Text>
            </Flex>
            <Flex
              px="25px"
              mb="1rem "
              justifyContent="space-between"
              align="center">
              <Text
                color={textColor}
                fontSize="1rem"
                fontWeight="300"
                lineHeight="100%">
                Số điện thoại: {candidataData.phone_number}
              </Text>
            </Flex>
            <Flex
              px="25px"
              mb="1rem "
              justifyContent="space-between"
              align="center">
              <Text
                color={textColor}
                fontSize="1rem"
                fontWeight="300"
                lineHeight="100%">
                Ghi chú: {candidataData.note}
              </Text>
            </Flex>
            <Flex
              px="25px"
              mb="1rem "
              justifyContent="space-between"
              align="center">
              <Text
                color={textColor}
                fontSize="1rem"
                fontWeight="300"
                lineHeight="100%">
                Trạng thái: {convertStatus(candidataData.status)}
              </Text>
            </Flex>
            {showCv && (
              <Tabs>
                {candidataData.cv_url.map((item: any, index: number) =>
                  (item && item.includes(".pdf")) || item.includes("topcv") ? (
                    <TabList>
                      <Tab key={index} w="10%">
                        <Text
                          overflow="hidden"
                          noOfLines={1}
                          color={textColor}
                          fontSize="1rem"
                          fontWeight="500"
                          lineHeight="100%">
                          {item &&
                          item.includes("/files/") &&
                          item.includes(".pdf")
                            ? item.split("/files/")[1].split(".")[0]
                            : candidataData.candidate_name}
                        </Text>
                      </Tab>
                    </TabList>
                  ) : (
                    ""
                  )
                )}

                <TabPanels>
                  {candidataData.cv_url.map((item: any, index: number) =>
                    (item && item.includes(".pdf")) ||
                    item.includes("topcv") ? (
                      <TabPanel key={index}>
                        <Flex px="25px">
                          <Tooltip label="Fullscreen">
                            <Link href={item}>
                              <Icon
                                as={MdFullscreen}
                                color={iconColor}
                                w="2rem"
                                h="2rem"
                              />
                            </Link>
                          </Tooltip>
                        </Flex>
                        <Flex
                          px="25px"
                          mb="1rem"
                          justifyContent="space-between"
                          align="center">
                          <object
                            data={item}
                            type="application/pdf"
                            width="100%"
                            height="400px"></object>
                        </Flex>
                      </TabPanel>
                    ) : (
                      <TabPanel
                        key={index}
                        color={textColor}
                        paddingLeft="25px">
                        Đường dẫn đến CV:
                        <Link
                          href={item}
                          target="_blank"
                          color="blue"
                          paddingLeft="5px">
                          Nhấn để tải file
                        </Link>
                      </TabPanel>
                    )
                  )}
                </TabPanels>
              </Tabs>
            )}
            <Flex>
              <Button
                margin="20px"
                padding="25px"
                w="10%"
                _hover={{ backgroundColor: "#C0C0C0" }}
                colorScheme="pink"
                variant="outline"
                onClick={() => handleUpdateCandidateInfo()}>
                Chỉnh sửa
              </Button>
              <Button
                margin="20px"
                padding="25px"
                w="10%"
                _hover={{ backgroundColor: "#C0C0C0" }}
                colorScheme="pink"
                variant="outline"
                onClick={() => deleteCv()}>
                Xóa CV
              </Button>
            </Flex>
          </Card>
        )}

        {isProgress && (
          <Flex height="710px" justify="center">
            <Progress size="xs" w="100%" mx={5} isIndeterminate />
          </Flex>
        )}
      </SimpleGrid>

      <UpdateCvInfo
        show={showUpdateCvModal}
        setShow={setShowUpdateCvModal}
        dataUpdate={dataUpdate}
        resetData={resetData}
        getCandidateInfo={getCandidateInfo}
      />
    </Box>
  );
}
