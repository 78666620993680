import { useMemo } from "react";
import {
  Box,
  Flex,
  Icon,
  Link,
  Text,
  Tooltip,
  useColorModeValue,
} from "@chakra-ui/react";
import { Draggable } from "react-beautiful-dnd";
import { MdOutlineCopyAll } from "react-icons/md";
import { useHistory } from "react-router-dom";
import { CandidateStatus } from "./CvManageServices";
import Card from "components/card/Card";
import useOpenNotification from "hook/useOpenNotification";
import {
  formatDateTime,
  formatDateWithFormatType,
  getUsernameFromEmail,
} from "common/utils";
import CvMenu from "views/admin/default/components/cvManage/CvMenu";

const STEP_STATUS_INDEX = {
  NEW: 1,
  REVIEW: 2,
  TEST: 3,
  INTERVIEW: 4,
  OFFER: 5,
  REJECT: 6,
};

const getStatusOrder = (status: CandidateStatus): number => {
  const keys = Object.keys(CandidateStatus);
  const index = keys.indexOf(status.toString().toUpperCase());

  return index === -1 ? -1 : index + 1;
};

export const CvInfo = (props: any) => {
  const { data, index } = props;

  const openNotification = useOpenNotification();

  const { status } = data;

  const currentStatusIndex = useMemo(() => getStatusOrder(status), [status]);

  const history = useHistory();
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const bgColor = useColorModeValue("#fff", "#023e8a");
  const draggingColor = useColorModeValue("#e0e8ec", "#aac0fe");

  const handleClick = (data: any) => {
    history.push({
      pathname: "/admin/candidate-info",
      search: `?id=${data.id}`,
    });
  };

  const handleCopyCvLink = () => {
    navigator.clipboard.writeText(data.cv_url?.[0]);
    data?.cv_url?.length > 0
      ? openNotification("Sao chép thành công")
      : openNotification("Đường dẫn trống", "error");
  };

  return (
    <Draggable
      key={data.id.toString()}
      draggableId={data.id.toString()}
      index={index}
    >
      {(provided, snapshot) => {
        return (
          <Card
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            {...provided.draggableProps.style}
            bgColor={!snapshot.isDragging ? bgColor : draggingColor}
            flexDirection="column"
            maxWidth="30rem"
            px="0px"
            transition="background-color 0.2s ease"
            py="15px !important"
            userSelect="none"
            m="0 0 8px 0"
            minH="230px"
            p={4}
            border={snapshot.isDragging ? "1px solid #3c454a" : "unset"}
            zIndex={10}
            color="white"
            position="relative"
            _hover={{ bg: "gray.200", cursor: "pointer" }}
          >
            <Flex flexDirection="column" justifyContent="space-between">
              <Flex
                px="25px"
                mb="8px"
                justifyContent="space-between"
                align="center"
                overflow="visible !important"
              >
                <Text
                  color={textColor}
                  fontSize="1rem"
                  fontWeight="700"
                  lineHeight="100%"
                >
                  <Tooltip label="Xem thông tin" placement="top">
                    <Link onClick={() => handleClick(data)}>
                      {data.candidate_name}
                    </Link>
                  </Tooltip>
                </Text>

                <Box>
                  <CvMenu data={data} currentStatusIndex={currentStatusIndex} />
                </Box>
              </Flex>

              {data?.jobname && (
                <Flex
                  px="25px"
                  mb="8px"
                  justifyContent="space-between"
                  align="center"
                >
                  <Text
                    color={textColor}
                    fontSize="1rem"
                    fontWeight="500"
                    lineHeight="100%"
                  >
                    {data?.jobname}
                  </Text>
                </Flex>
              )}

              <Flex
                px="25px"
                mb="8px"
                justifyContent="space-between"
                align="center"
              >
                <Box
                  color={textColor}
                  fontSize="1rem"
                  fontWeight="500"
                  lineHeight="100%"
                >
                  {data.hr ? (
                    <>HR: {getUsernameFromEmail(data?.hr)}</>
                  ) : (
                    <Text size="sm" color="red">
                      Chưa có người phụ trách
                    </Text>
                  )}
                </Box>
              </Flex>

              {[
                STEP_STATUS_INDEX.REVIEW,
                STEP_STATUS_INDEX.TEST,
                STEP_STATUS_INDEX.INTERVIEW,
              ].includes(currentStatusIndex) && (
                <Flex
                  px="25px"
                  mb="8px"
                  justifyContent="space-between"
                  align="center"
                >
                  <Box
                    color={textColor}
                    fontSize="1rem"
                    fontWeight="500"
                    lineHeight="100%"
                  >
                    {data.team_name ? (
                      <>Studio: {data.team_name}</>
                    ) : (
                      <Text size="sm" color="red">
                        Chưa chuyển studio
                      </Text>
                    )}
                  </Box>
                </Flex>
              )}

              {[STEP_STATUS_INDEX.INTERVIEW].includes(currentStatusIndex) && (
                <Flex
                  px="25px"
                  mb="8px"
                  justifyContent="space-between"
                  align="center"
                >
                  <Box
                    color={textColor}
                    fontSize="1rem"
                    fontWeight="500"
                    lineHeight="100%"
                  >
                    {data.user_assigned_name ? (
                      <>
                        Người phỏng vấn:{" "}
                        {getUsernameFromEmail(data?.user_assigned_name)}
                      </>
                    ) : (
                      <Text size="sm" color="red">
                        Chưa có người phỏng vấn
                      </Text>
                    )}
                  </Box>
                </Flex>
              )}

              <Flex
                px="25px"
                mb="8px"
                justifyContent="space-between"
                align="center"
              >
                <Text
                  color={textColor}
                  fontSize="1rem"
                  fontWeight="500"
                  lineHeight="100%"
                  cursor="pointer"
                  onClick={() => handleClick(data)}
                >
                  Ngày nhận: {formatDateTime(data.created_at)}
                </Text>
              </Flex>

              {data?.updated_by_user && (
                <Flex
                  px="25px"
                  mb="8px"
                  justifyContent="space-between"
                  align="center"
                >
                  <Text
                    color={textColor}
                    fontSize="1rem"
                    fontWeight="500"
                    lineHeight="100%"
                    cursor="pointer"
                    onClick={() => handleClick(data)}
                  >
                    Sửa bởi: {getUsernameFromEmail(data?.updated_by_user)}{" "}
                    {formatDateWithFormatType(
                      data.updated_at,
                      "HH:mm DD/MM/YYYY"
                    )}
                  </Text>
                </Flex>
              )}

              <Flex
                px="25px"
                mb="8px"
                justifyContent="space-between"
                align="center"
              >
                {data?.cv_url?.length > 0 ? (
                  <>
                    <Text
                      color={textColor}
                      fontSize="1rem"
                      fontWeight="300"
                      lineHeight="100%"
                      cursor="pointer"
                    >
                      CV link:
                      <Icon
                        cursor="pointer"
                        onClick={handleCopyCvLink}
                        as={MdOutlineCopyAll}
                        h="1.2rem"
                        w="1.2rem"
                        ml="8px"
                      />
                    </Text>
                  </>
                ) : (
                  <Text
                    color="red"
                    size="sm"
                    fontSize="1rem"
                    fontWeight="500"
                    lineHeight="100%"
                  >
                    Không có CV
                  </Text>
                )}
              </Flex>
            </Flex>
          </Card>
        );
      }}
    </Draggable>
  );
};
