import { Box, Flex, Spinner, Text } from "@chakra-ui/react";
import { SOURCE_NAME } from "common/constants";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { reportService } from "../../../../../../services/Report/ReportService";

type Props = {
  filterData: {
    fromDate: string;
    toDate: string;
  };
};

type data = {
  source: string;
  count: number;
};

const CountCVSourceComponent = ({ filterData }: Props) => {
  const sources = {
    [SOURCE_NAME.manual]: "Manual",
    [SOURCE_NAME.topcv]: "TopCV",
  };

  // const { loading, averageState } = props;
  const [loading, setLoading] = useState(false);
  const [countData, setCountData] = useState<data[]>([]);
  const [isEmpty, setIsEmpty] = useState(false);

  const getDatas = async () => {
    setLoading(true);
    try {
      const header = {};
      const param = {
        fromDate: "",
        toDate: "",
      };
      if (filterData.fromDate) param["fromDate"] = filterData.fromDate;
      if (filterData.toDate) param["toDate"] = filterData.toDate;

      const response = await reportService.getSourceCountReport(param, header);
      if (response.data.sourceCount) {
        setCountData(response.data.sourceCount);
      }
    } catch (error) {
      toast.error("Lỗi khi lấy dữ liệu Count CV Source");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (filterData.fromDate && filterData.toDate) {
      getDatas();
    }
  }, [filterData]);
  return (
    <Flex
      flexDirection={"column"}
      px={5}
      mt={{ sm: 0, md: 1 }}
      gap={1}
      minH="5vh"
      width={"40vw"}
      alignItems="center"
    >
      {countData.length > 0 &&
        !loading &&
        countData.map((item: data, index: any) => {
          return (
            <Flex key={index} width={"50%"}>
              <Flex
                width={"100%"}
                justifyContent="space-between"
                borderBottom={"1px solid orange"}
                pb={2}
                px={4}
                fontSize="sm"
              >
                <Text>{sources[item.source]}</Text>
                <Text>{item.count}</Text>
              </Flex>
            </Flex>
          );
        })}
      {!loading && countData.length === 0 && (
        <Box className="chart_empty" minH={"5vh"}>
          Không có dữ liệu
        </Box>
      )}
      {loading && <Spinner />}
    </Flex>
  );
};

export default CountCVSourceComponent;
