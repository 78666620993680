import { useRef, useState } from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Flex,
  Spinner,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import { useColorModeValue } from "@chakra-ui/system";
import { AiFillEdit } from "react-icons/ai";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { useDispatch } from "react-redux";
import {
  actSetEditMatrixData,
  actSetOpenMatrixModal,
} from "redux/features/superAdminSlice";
import { IMatrix, OptionType } from "types/interface";
import { HTTP_CODE, LIST_ROLE } from "common/constants";
import _ from "lodash";
import { matrixServices } from "services/Matrix/MatrixServices";
import useOpenNotification from "hook/useOpenNotification";
import CustomFilter from "components/common/CustomFilter";
import {
  methodOption,
  roleOption,
} from "views/SuperAdmin/ManageRoleMatrix/ManageMatrix/AddMatrixModal";
import { SearchQueryMatrix } from "views/SuperAdmin/ManageRoleMatrix/ManageMatrix";

interface ManageMatrixTableProps {
  listMatrix: IMatrix[];
  isLoading?: boolean;
  setComponentShouldBeRefresh: () => void;
  searchQueryMatrix?: SearchQueryMatrix;
  setSearchQueryMatrix?: (value: SearchQueryMatrix) => void;
}

const ManageMatrixTable = (props: ManageMatrixTableProps) => {
  const {
    listMatrix,
    setComponentShouldBeRefresh,
    searchQueryMatrix,
    setSearchQueryMatrix,
    isLoading,
  } = props;
  const [deleteId, setDeleteId] = useState<number | null>(null);

  const dispatch = useDispatch();
  const openNotification = useOpenNotification();

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const bgColor = useColorModeValue("white", "navy.800");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

  const cancelRef = useRef();
  const {
    isOpen: isOpenDialog,
    onOpen: onOpenDialog,
    onClose: onCloseDialog,
  } = useDisclosure();

  const cellStyles = {
    borderColor: borderColor,
    fontSize: { sm: "14px" },
    minW: { sm: "150px", md: "200px", lg: "auto" },
    textColor: textColor,
    padding: "16px",
  };

  const tableColumnHeaderStyles = {
    borderColor: borderColor,
    cursor: "pointer",
    padding: "16px",
    fontSize: { sm: "10px", lg: "12px" },
  };

  const handleEditMatrix = (matrix: IMatrix) => {
    dispatch(actSetOpenMatrixModal(true));
    dispatch(actSetEditMatrixData(matrix));
  };

  const renderRole = (roleIds: string) => {
    const listRoleId = roleIds?.split(",");

    const listRoleString = listRoleId?.map((roleId) => {
      const role = _.findKey(LIST_ROLE, (value) => value === Number(roleId));
      return role;
    });

    return listRoleString?.join(", ");
  };

  const handleDeleteMatrix = (id: number) => {
    setDeleteId(id);
    onOpenDialog();
  };

  const onCloseConfirmButton = () => {
    onCloseDialog();
    setDeleteId(null);
  };

  const onConfirmButton = async () => {
    try {
      const deleteRes = await matrixServices.deleteMatrix({ id: deleteId });

      if (deleteRes?.status === HTTP_CODE.SUCCESS) {
        openNotification("Xoá matrix thành công");
        setComponentShouldBeRefresh();
      }
    } catch (error) {
      throw error;
    } finally {
      setDeleteId(null);
      onCloseDialog();
    }
  };

  return (
    <>
      <Table variant="simple" color="gray.500" mb="24px" mt="12px">
        <Thead
          position="sticky"
          top={0}
          bgColor={bgColor}
          fontSize={{ sm: "10px", lg: "12px" }}
        >
          <Tr>
            <Th {...tableColumnHeaderStyles} width="25%">
              <Flex>Endpoint</Flex>
            </Th>

            <Th {...tableColumnHeaderStyles} width="10%">
              <Flex align="center">
                <span>Method</span>

                <CustomFilter
                  options={methodOption}
                  isMulti={true}
                  onChange={(value: OptionType[]) => {
                    setSearchQueryMatrix({
                      ...searchQueryMatrix,
                      queryMethod: value,
                    });
                  }}
                  value={searchQueryMatrix?.queryMethod}
                />
              </Flex>
            </Th>

            <Th {...tableColumnHeaderStyles} width="15%">
              <Flex align="center">Page</Flex>
            </Th>

            <Th {...tableColumnHeaderStyles} width="30%">
              <Flex align="center">
                <span>Role</span>
                <CustomFilter
                  options={roleOption}
                  isMulti={true}
                  onChange={(value: OptionType[]) => {
                    setSearchQueryMatrix({
                      ...searchQueryMatrix,
                      queryRole: value,
                    });
                  }}
                  value={searchQueryMatrix?.queryRole}
                />
              </Flex>
            </Th>

            <Th
              {...tableColumnHeaderStyles}
              width="20%"
            >
              <span>Action</span>
            </Th>
          </Tr>
        </Thead>

        {isLoading ? (
          <Flex
            mt="40px"
            alignItems="center"
            justifyContent="center"
            position="absolute"
            transform="translate(-50%, -50%)"
            top="50%"
            left="50%"
          >
            <Spinner size="lg" />
          </Flex>
        ) : (
          <Tbody bg={bgColor}>
            {listMatrix.map((matrix) => (
              <Tr key={matrix?.id} p="16px">
                <Td {...cellStyles}>{matrix?.endpoint}</Td>
                <Td {...cellStyles}>{matrix?.method}</Td>
                <Td {...cellStyles}>{matrix?.page}</Td>
                <Td {...cellStyles}>{renderRole(matrix?.roleIds)}</Td>

                <Td {...cellStyles}>
                  <Flex alignItems="center" gap="10px" justifyContent="center">
                    <Box
                      _hover={{ color: "orange.500" }}
                      fontSize="24px"
                      cursor="pointer"
                      color="orange.300"
                      onClick={() => handleEditMatrix(matrix)}
                    >
                      <AiFillEdit />
                    </Box>
                    <Box
                      _hover={{ color: "red.600", cursor: "pointer" }}
                      fontSize="28px"
                      cursor="pointer"
                      color="red.500"
                      onClick={() => handleDeleteMatrix(matrix?.id)}
                    >
                      <MdOutlineDeleteOutline />
                    </Box>
                  </Flex>
                </Td>
              </Tr>
            ))}
          </Tbody>
        )}
      </Table>

      <AlertDialog
        isOpen={isOpenDialog}
        leastDestructiveRef={cancelRef}
        onClose={onCloseDialog}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Xoá matrix
            </AlertDialogHeader>

            <AlertDialogBody>
              Bạn có muốn xoá matrix hiện tại? Hành động này không thể hoàn tác.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onCloseConfirmButton}>
                Huỷ
              </Button>
              <Button colorScheme="red" ml={3} onClick={onConfirmButton}>
                Xoá
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default ManageMatrixTable;
