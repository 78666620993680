import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import { FaSearch } from "react-icons/fa";
import { Box } from "@chakra-ui/react";
import { OptionType } from "types/interface";
import { FilterIcon } from "components/icons/Icons";
import _ from "lodash";

interface CustomSelectProps {
  options: OptionType[];
  defaultValue?: OptionType[];
  isMulti?: boolean;
  onChange?: (value: any) => void;
  isSearchable?: boolean;
  onFilterSearch?: () => void;
  value?: OptionType[];
  isCloseMenuAfterChange?: boolean;
  placeholder?: string;
  isClear?: boolean;
}

const CustomFilter: React.FC<CustomSelectProps> = ({
  options,
  defaultValue,
  isMulti = false,
  onChange,
  isSearchable,
  onFilterSearch,
  value,
  isCloseMenuAfterChange,
  placeholder,
  isClear,
}) => {
  const [selectedOption, setSelectedOption] = useState<OptionType[] | null>(
    defaultValue || null
  );

  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);

  const selectRef = useRef<HTMLDivElement>(null);

  const handleChange = (selectedOption: any) => {
    if (selectedOption === null) {
      setSelectedOption([]);
      onChange({ value: null });
      return;
    }

    setSelectedOption(selectedOption);
    onChange(selectedOption);
    setMenuIsOpen(!isCloseMenuAfterChange);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      selectRef.current &&
      !selectRef.current.contains(event.target as Node)
    ) {
      setMenuIsOpen(false);
    }
  };

  useEffect(() => {
    setSelectedOption(value);
  }, [value]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const components = !isSearchable
    ? undefined
    : {
        DropdownIndicator: (props: any) => (
          <div
            style={{
              width: "30px",
              marginLeft: "5px",
              display: "flex",
              justifyContent: "center",
            }}
            onClick={onFilterSearch}
          >
            <FaSearch fontSize="20px" style={{ cursor: "pointer" }} />
          </div>
        ),
      };

  const renderColorFilter = () => {
    return !_.isEmpty(selectedOption) ? "#2d23e2" : "#9094a0";
  };

  return (
    <Box
      onClick={() => setMenuIsOpen(true)}
      ref={selectRef}
      position="relative"
    >
      <FilterIcon fontSize="15px" color={renderColorFilter()} />

      {menuIsOpen && (
        <Box
          position="absolute"
          w="200px"
          transform="translate(-50%, 5%)"
          left="50%"
        >
          <Select
            menuIsOpen={menuIsOpen}
            value={selectedOption}
            defaultValue={defaultValue}
            options={options}
            isMulti={isMulti}
            onChange={handleChange}
            components={components}
            placeholder={placeholder}
            isClearable={isClear}
          />
        </Box>
      )}
    </Box>
  );
};

export default CustomFilter;
