import { IEmailTemplateVariable } from "types/interface";
import { Text, Tooltip, useColorModeValue } from "@chakra-ui/react";
import Card from "components/card/Card";
import useOpenNotification from "hook/useOpenNotification";

interface VariableItemProps {
  variable: IEmailTemplateVariable;
  bg: string;
}

const VariableItem = (props: VariableItemProps) => {
  const { variable, bg } = props;

  const openNotification = useOpenNotification();

  const labelColor = useColorModeValue("#8F9BBA", "white");

  const onCopyVariable = (variable: string) => {
    navigator.clipboard.writeText(`{{${variable}}}`);
    openNotification("Sao chép thành công");
  };

  return (
    <Card
      borderRadius="none"
      borderTop={`1px solid ${labelColor} !important`}
      bg={bg}
    >
      <Tooltip label="Click để sao chép variable" placement="top-start">
        <Text
          color="red"
          onClick={() => onCopyVariable(variable?.code)}
          _hover={{ color: "#04400a", cursor: "pointer" }}
        >{`{{${variable?.code}}}`}</Text>
      </Tooltip>
      <Text color={labelColor}>{variable?.description}</Text>
    </Card>
  );
};

export default VariableItem;
