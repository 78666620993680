import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Flex,
  Box,
  Avatar,
  Text,
  Textarea,
  Button,
} from "@chakra-ui/react";
import { HTTP_CODE } from "common/constants";
import useOpenNotification from "hook/useOpenNotification";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { candidateNoteServices } from "services/CandidateNote/CandidateNoteService";
import { ICandidateNote } from "types/interface";
import ResizeTextarea from "react-textarea-autosize";
import Card from "components/card/Card";
import { getUsernameFromEmail } from "common/utils";

const NoteItem = ({ candidateNote }: { candidateNote: ICandidateNote }) => {
  return (
    <Box>
      <Flex alignItems="center" bg="orange.100" p="10px">
        <Text fontWeight="bold" ml="5px">
          {candidateNote?.username}
        </Text>
      </Flex>

      <Textarea
        disabled={true}
        fontStyle="italic"
        fontWeight="bold"
        fontSize="15px"
        value={candidateNote?.note}
        h="fit-content"
        as={ResizeTextarea}
        opacity="1 !important"
      />
    </Box>
  );
};

interface CandidateNoteProps {
  candidateId?: string;
}

const CandidateNote = (props: CandidateNoteProps) => {
  const [candidateNote, setCandidateNote] = useState<ICandidateNote[]>([]);
  const [candidateNoteInput, setCandidateNoteInput] = useState<string>("");
  const [isLoadingCreateNote, setIsLoadingCreateNote] =
    useState<boolean>(false);

  const openNotification = useOpenNotification();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const candidateId = searchParams.get("id");

  // get list note of candidate
  const getListNote = async () => {
    const header = {
      page: 0,
      pageSize: 20,
    };

    const params = {
      candidate_id: candidateId,
    };

    const getListNoteRes = await candidateNoteServices.getCandidateNote(
      params,
      header
    );

    if (getListNoteRes?.status === HTTP_CODE.SUCCESS) {
      setCandidateNote(getListNoteRes?.data?.data);
    }
  };

  const onCreateCandidateNote = async () => {
    try {
      setIsLoadingCreateNote(true);
      const createCandidateNoteBody = {
        note: candidateNoteInput,
        candidate_id: parseInt(candidateId),
      };

      const createCandidateNoteRes =
        await candidateNoteServices.createCandidateNote(
          createCandidateNoteBody,
          {}
        );

      if (createCandidateNoteRes?.status === HTTP_CODE.CREATED) {
        getListNote();
        setCandidateNoteInput("");
        setIsLoadingCreateNote(false);
        openNotification("Thêm note thành công");
        return;
      }

      openNotification("Thêm note thất bại", "error");
      setIsLoadingCreateNote(false);
    } catch (error) {
      openNotification("Thêm note thất bại", "error");
      setIsLoadingCreateNote(false);
    }
  };

  const onChangeCandidateNote = (e: any) => {
    setCandidateNoteInput(e.target.value);
  };

  useEffect(() => {
    getListNote();
  }, [candidateId]);

  return (
    <Flex>
      <Accordion allowMultiple flex="1" defaultIndex={[0]}>
        <AccordionItem key="1">
          {({ isExpanded }) => (
            <>
              <h2>
                <AccordionButton bg={isExpanded ? "orange.100" : "unset"}>
                  <Box as="span" flex="1" textAlign="left">
                    Danh sách note
                  </Box>
                  <AccordionIcon maxWidth="100%" />
                </AccordionButton>
              </h2>

              <AccordionPanel
                pb={4}
                overflow="hidden"
                overflowY="auto"
                sx={{
                  "&::-webkit-scrollbar": {
                    width: "0px",
                  },
                }}
              >
                <Flex
                  flexDirection="column"
                  gap="35px"
                  overflowX="auto"
                  minHeight={candidateNote.length > 0 ? "150px" : "unset"}
                  maxHeight="350px"
                >
                  {candidateNote.map((note: ICandidateNote) => (
                    <NoteItem candidateNote={note} key={note?.id} />
                  ))}

                  {candidateNote.length === 0 && (
                    <Text fontStyle="italic" textAlign="center">
                      Chưa có note nào
                    </Text>
                  )}
                </Flex>
              </AccordionPanel>
            </>
          )}
        </AccordionItem>

        <AccordionItem mt={candidateNote.length > 0 && "20px"} key={2}>
          <h2>
            <AccordionButton>
              <Box as="span" flex="1" textAlign="left">
                Thêm note cho ứng viên
              </Box>
              <AccordionIcon maxWidth="100%" />
            </AccordionButton>
          </h2>

          <AccordionPanel pb={4}>
            <Box>
              <Textarea
                placeholder="Nhập note của bạn cho ứng viên"
                onChange={(e) => onChangeCandidateNote(e)}
                value={candidateNoteInput}
              />
            </Box>

            <Flex justifyContent="center" mt="10px">
              <Button
                colorScheme="blue"
                onClick={onCreateCandidateNote}
                isLoading={isLoadingCreateNote}
              >
                Thêm note
              </Button>
            </Flex>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Flex>
  );
};

export default CandidateNote;
