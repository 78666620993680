import ApiServices from "./ApiServices";
import { API_URL } from "config/environment";

const base_url = API_URL + "/master-data";

export class StudioServices extends ApiServices {
  public async getStudioDatas(params: any, headers: any) {
    return await this.getRequest(base_url, params, headers);
  }
  public async addStudioData(params: any, data: any, header: any) {
    return await this.postRequest(base_url, params, data, header);
  }
  public async updateStudioData(id: any, params: any, data: any, header: any) {
    return await this.putRequest(`${base_url}/${id}`, params, data, header);
  }
}

export const studioService = new StudioServices();
