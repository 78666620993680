import { combineReducers, configureStore } from "@reduxjs/toolkit";
import layoutSlice from "redux/features/layoutSlice";
import superAdmin from "./features/superAdminSlice";
import { persistReducer, persistStore } from "redux-persist";
import createWebStorage from "redux-persist/lib/storage/createWebStorage";

const storage = createWebStorage("local");
const persistConfig = {
  key: "sky_recruitment",
  storage,
  // Whitelist (Persist Specific Reducers)
  whitelist: ["Layout"],
};

const reducers = combineReducers({
  SuperAdmin: superAdmin,
  Layout: layoutSlice,
});

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
