import { ApiServices } from "services/ApiServices";
import { API_URL } from "config/environment";
import { API_URL_PATH } from "common/constants";

const base_url = API_URL + API_URL_PATH.CANDIDATE_INFO;
const sync_cv_url = API_URL + API_URL_PATH.SYNC_CV;

export class CandidateServices extends ApiServices {
  public async getCandidate(params: any, headers: any) {
    return await this.getRequest(base_url, params, headers);
  }

  public async getCandidateById(id: string, headers: any) {
    return await this.getRequest(base_url + `/${id}`, {}, headers);
  }

  public async updateCandidateInfo(body: any, params: any, headers: any) {
    return await this.putRequest(base_url + `/${params.id}`, {}, body, headers);
  }

  public async syncCvData() {
    return await this.postRequest(sync_cv_url, {}, {}, {});
  }
}

export const candidateService = new CandidateServices();
