import { useState } from "react";
import {
  Flex,
  FormControl,
  Input,
  Button,
  useColorModeValue,
  FormLabel,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { IoEyeOutline, IoEyeOffOutline } from "react-icons/io5";
import ErrorMessage from "components/common/ErrorFormMessage";
import { useHistory } from "react-router-dom";
import ApiServices from "services/ApiServices";
import useOpenNotification from "hook/useOpenNotification";
import { userServices } from "services/User/UserServices";
import { HTTP_CODE } from "common/constants";
import { sleep } from "common/utils";

const ChangePassword = () => {
  const apiServices = new ApiServices();

  const [showOldPassword, setShowOldPassword] = useState<boolean>(false);
  const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);

  const openNotification = useOpenNotification();
  const history = useHistory();

  const toggleOldPasswordVisibility = () => {
    setShowOldPassword(!showOldPassword);
  };

  const toggleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm();

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const labelColor = useColorModeValue("secondaryGray.500", "white");

  const onChangePassword = async () => {
    try {
      const res = await userServices.changeUserPassword(getValues());

      if (res?.status === HTTP_CODE.SUCCESS) {
        openNotification("Cập nhật mật khẩu thành công");
        await sleep(500);

        const logOutRes = await apiServices.signOutRequest();
        if (logOutRes) {
          apiServices.clearLocalStorage();
          window.location.reload();
        }
      }
    } catch (error) {
      throw error;
    }
  };

  const inputPasswordStyle = {
    size: "sm",
    _placeholder: { opacity: 1, color: labelColor },
    _hover: { borderColor: "none" },
    style: { cursor: "pointer" },
    color: textColor,
    h: "35px",
    borderRadius: "5px",
  };

  return (
    <Flex justifyContent="center">
      <form
        onSubmit={handleSubmit(onChangePassword)}
        style={{
          width: "50%",
          display: "flex",
          marginBottom: "5px",
        }}
      >
        <Flex
          p="0 5px"
          w="100%"
          justifyContent="space-between"
          flexDirection="column"
          gap="15px"
          alignItems="center"
        >
          <FormControl flex="0.6">
            <FormLabel>Nhập mật khẩu cũ*</FormLabel>
            <InputGroup size="sm">
              <Input
                type={showOldPassword ? "text" : "password"}
                placeholder="Vui lòng nhập mật khẩu cũ"
                {...inputPasswordStyle}
                {...register("currentPassword", {
                  required: "Vui lòng nhập mật khẩu cũ",
                  minLength: {
                    value: 8,
                    message: "Mật khẩu phải có ít nhất 8 ký tự",
                  },
                })}
                name="currentPassword"
              />

              <InputRightElement width="4.5rem">
                <Button
                  h="1.75rem"
                  size="sm"
                  onClick={toggleOldPasswordVisibility}
                >
                  {showOldPassword ? <IoEyeOutline /> : <IoEyeOffOutline />}
                </Button>
              </InputRightElement>
            </InputGroup>
            <ErrorMessage error={errors.currentPassword} />
          </FormControl>

          <FormControl flex="0.6">
            <FormLabel>Nhập mật khẩu mới*</FormLabel>
            <InputGroup size="sm">
              <Input
                type={showNewPassword ? "text" : "password"}
                placeholder="Vui lòng nhập mật khẩu mới"
                {...inputPasswordStyle}
                {...register("newPassword", {
                  required: "Vui lòng nhập mật khẩu mới",
                  minLength: {
                    value: 8,
                    message: "Mật khẩu phải có ít nhất 8 ký tự",
                  },
                })}
                name="newPassword"
              />
              <InputRightElement width="4.5rem">
                <Button
                  h="1.75rem"
                  size="sm"
                  onClick={toggleNewPasswordVisibility}
                >
                  {showNewPassword ? <IoEyeOutline /> : <IoEyeOffOutline />}
                </Button>
              </InputRightElement>
            </InputGroup>
            <ErrorMessage error={errors.newPassword} />
          </FormControl>

          <FormControl flex="0.6">
            <FormLabel>Nhập lại mật khẩu mới*</FormLabel>
            <InputGroup size="sm">
              <Input
                type={showConfirmPassword ? "text" : "password"}
                placeholder="Vui lòng nhập
                lại mật khẩu mới"
                {...inputPasswordStyle}
                {...register("confirmPassword", {
                  required: "Vui lòng nhập mật khẩu mới",
                  validate: (value) =>
                    value === getValues("newPassword") || "Mật khẩu không khớp",
                })}
                name="confirmPassword"
              />
              <InputRightElement width="4.5rem" bg="none">
                <Button
                  h="1.75rem"
                  size="sm"
                  onClick={toggleConfirmPasswordVisibility}
                >
                  {showConfirmPassword ? <IoEyeOutline /> : <IoEyeOffOutline />}
                </Button>
              </InputRightElement>
            </InputGroup>
            <ErrorMessage error={errors.confirmPassword} />
          </FormControl>

          <Button
            colorScheme="blue"
            flex="0.1"
            type="submit"
            minHeight="30px"
            w="120px"
          >
            Cập nhật
          </Button>
        </Flex>
      </form>
    </Flex>
  );
};

export default ChangePassword;
