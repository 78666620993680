import { useContext, useEffect, useMemo, useRef, useState } from "react";
import {
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalBody,
  Flex,
  FormControl,
  FormLabel,
  useColorModeValue,
  Input,
  ModalFooter,
  Button,
  Select as SelectChakra,
} from "@chakra-ui/react";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { ICandidate, IStudio, IUser, IUserAssigned } from "types/interface";
import {
  HTTP_CODE,
  INTERVIEW_STATUS,
  LIST_ROLE,
  UNLIMITED_PAGE_SIZE,
} from "common/constants";
import useOpenNotification from "hook/useOpenNotification";
import dayjs from "dayjs";
import { userServices } from "services/User/UserServices";
import { userAssignServices } from "services/UserAssign/UserAssignServices";
import { getColumnNameByStatus, formatDateWithFormatType } from "common/utils";
import ErrorMessage from "components/common/ErrorFormMessage";
import { ThemeContext } from "views/admin/default/components/cvManage/CvManage";
import _ from "lodash";
import { candidateService } from "services/Candidate/CandidateServices";
import { getListStudio, getListUser } from "services/utils";
import { removeItemUndefine } from "common/utils";

interface ChangeStudioModalProps {
  isOpen: boolean;
  onClose: () => void;
  candidateInfo: ICandidate;
  setComponentShouldBeRefresh?: () => void;
  columnStatus?: string;
}

const AssignInterviewModal = (props: ChangeStudioModalProps) => {
  const {
    isOpen,
    columnStatus,
    candidateInfo,
    setComponentShouldBeRefresh,
    onClose,
  } = props;

  const counterRef = useRef(1);
  const selectUserRef = useRef<any>(null);

  const {
    register,
    setValue: setFormValue,
    formState: { errors },
    getValues,
    handleSubmit,
    control,
    reset: resetFormValue,
  } = useForm();

  const textColor = useColorModeValue("secondaryGray.900", "white");

  const [listUser, setListUser] = useState<IUser[]>([]);
  const [listHr, setListHr] = useState<IUser[]>([]);
  const [isSelectUserLoading, setIsSelectUserLoading] =
    useState<boolean>(false);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [isEditAssignInterview, setIsEditAssignInterview] =
    useState<boolean>(false);
  const [previousAssignInterview, setPreviousAssignInterview] =
    useState<IUserAssigned>({});
  const [listStudio, setListStudio] = useState<IStudio[]>([]);

  const cvManageContext = useContext(ThemeContext);
  const setListStatusFetchData = cvManageContext?.setListStatusFetchData;

  const openNotification = useOpenNotification();

  // get list user is interviewer to select
  const getListUserApi = async () => {
    setIsSelectUserLoading(true);

    const header = {
      page: counterRef.current - 1,
      size: UNLIMITED_PAGE_SIZE,
    };

    const params = {
      team_id: getValues("teamId"),
    };

    try {
      const listUserRes = await userServices.getAllUser(params, header);
      if (listUserRes?.status === HTTP_CODE.SUCCESS) {
        setListUser(listUserRes?.data?.data);
      }
    } catch (err) {
      openNotification("Network error", "error");
    }

    setIsSelectUserLoading(false);
  };

  const getAssignUserById = async (userId: string) => {
    const params = { userId: userId };
    const userFoundRes = await userServices.getUserById(params, {});

    return userFoundRes;
  };

  const getAssignInterviewInfo = async () => {
    const params = {
      candidateId: candidateInfo?.id,
    };

    try {
      const assignInterviewRes =
        await userAssignServices.getUserInterviewByCandidateId(params, {});

      setFormValue("candidateName", candidateInfo?.candidate_name);
      setFormValue("jobName", candidateInfo?.jobname);
      setFormValue("teamId", candidateInfo?.team_id);

      if (assignInterviewRes?.status === HTTP_CODE.SUCCESS) {
        // is edit assign interview
        if (!_.isEmpty(assignInterviewRes?.data)) {
          setIsEditAssignInterview(true);
          setPreviousAssignInterview(assignInterviewRes?.data);
          const assignInterviewData = assignInterviewRes?.data;

          if (assignInterviewData?.user_id) {
            const assignedUser = await getAssignUserById(
              assignInterviewData?.user_id
            );
            const assignedUsername = assignedUser?.data?.username;

            setFormValue("selectedUser", {
              value: assignInterviewData?.user_id,
              label: assignedUsername,
            });
          }

          setFormValue("hr_id", assignInterviewData?.hr_id);
          setFormValue(
            "startInterviewTime",
            formatDateWithFormatType(assignInterviewData?.start_time)
          );
          setFormValue(
            "endInterviewTime",
            formatDateWithFormatType(assignInterviewData?.end_time)
          );
          setFormValue("status", {
            value: assignInterviewData?.status,
            label: INTERVIEW_STATUS.find(
              (status) => status.value === assignInterviewData?.status
            )?.label,
          });
          return;
        }
      }

      setPreviousAssignInterview({});
      setIsEditAssignInterview(false);
    } catch (err) {
      setPreviousAssignInterview({});
      setIsEditAssignInterview(false);
      openNotification("Network error", "error");
    }
  };

  const handleClickOutsideSelect = (event: any) => {
    if (
      selectUserRef.current &&
      !selectUserRef.current.contains(event.target)
    ) {
      setIsMenuOpen(false);
    } else {
      setIsMenuOpen(true);
    }
  };

  const listUserOption = useMemo(() => {
    return listUser?.map((user, index) => {
      return {
        value: user.id,
        label: user.username,
      };
    });
  }, [listUser]);

  const validateStartDateAndEndDate = () => {
    const selectedUser = getValues("selectedUser");

    if (!selectedUser) {
      return true;
    }

    const startInterviewTime = getValues("startInterviewTime");
    const endInterviewTime = getValues("endInterviewTime");

    const startInterviewTimeConverted = dayjs(startInterviewTime);
    const endInterviewTimeConverted = dayjs(endInterviewTime);

    if (!startInterviewTime || !endInterviewTime) {
      return "Thời gian bắt đầu và kết thúc phải được điền!";
    }

    if (endInterviewTimeConverted.isBefore(startInterviewTimeConverted)) {
      return "Thời gian kết thúc phải lớn hơn thời gian bắt đầu!";
    }

    return true;
  };

  const onCloseModal = () => {
    onClose();
    resetFormValue();
  };

  const onCreateAssignInterview = async () => {
    const {
      selectedUser,
      startInterviewTime,
      endInterviewTime,
      status,
      teamId,
      hr_id,
    } = getValues();

    // change team for candidate
    const params = {
      id: candidateInfo?.id,
    };

    let updateCandidateStudioRes;

    const isUpdateCandidateInfo = [
      LIST_ROLE.SUPER_ADMIN.toString(),
      LIST_ROLE.ADMIN.toString(),
    ].includes(localStorage.getItem("roleId"));

    // update candidate studio
    if (isUpdateCandidateInfo) {
      const updateCandidateData = {
        team_id: Number(teamId),
      };

      updateCandidateStudioRes = await candidateService.updateCandidateInfo(
        updateCandidateData,
        params,
        {}
      );
    }

    // create/update user assign interview
    const assignInterviewData = {
      candidate_id: candidateInfo?.id,
      user_id: selectedUser?.value,
      hr_id: Number(hr_id),
      start_time: startInterviewTime,
      end_time: endInterviewTime,
      status: status?.value,
    };

    removeItemUndefine(assignInterviewData);

    // create/update assign interview
    if (selectedUser || hr_id) {
      // edit user assign interview
      if (isEditAssignInterview) {
        try {
          const params = {
            id: previousAssignInterview?.id,
          };

          const updateUserAssignRes = await userAssignServices.updateUserAssign(
            assignInterviewData,
            params,
            {}
          );

          if (
            updateUserAssignRes?.status === HTTP_CODE.SUCCESS &&
            updateCandidateStudioRes?.status === HTTP_CODE.SUCCESS
          ) {
            openNotification("Cập nhật ứng viên thành công");
            setComponentShouldBeRefresh && setComponentShouldBeRefresh();

            // refresh data if update assign interview from candidate detail page
            if (columnStatus) {
              const columnStatusName = getColumnNameByStatus(columnStatus);
              setListStatusFetchData &&
                setListStatusFetchData([columnStatusName]);
            }
          } else {
            openNotification("Cập nhật ứng viên thất bại", "error");
          }

          onCloseModal();
        } catch (error) {
          onCloseModal();
          throw error;
        }
      } else {
        // add new assign interview
        try {
          const assignInterviewRes =
            await userAssignServices.assignInterviewerToCandidate(
              assignInterviewData,
              {},
              {}
            );

          if (assignInterviewRes?.status === HTTP_CODE.CREATED) {
            openNotification("Assign lịch phỏng vấn thành công");
            const columnStatusName = getColumnNameByStatus(columnStatus);
            setListStatusFetchData &&
              setListStatusFetchData([columnStatusName]);
          } else {
            openNotification("Assign lịch phỏng vấn thất bại", "error");
          }

          onCloseModal();
        } catch (err) {
          onCloseModal();
          openNotification("Network error", "error");
        }
      }
    }
  };

  const getListStudioApi = async () => {
    const listStudioRes = await getListStudio();

    setListStudio(listStudioRes);
  };

  const getListHr = async () => {
    const headers = {
      page: 0,
      size: UNLIMITED_PAGE_SIZE,
    };

    const params = {};

    const listUser = await getListUser(params, headers);

    const listHr = listUser?.data?.filter(
      (user: IUser) => user?.team_name === "HR"
    );
    setListHr(listHr);
  };

  const getInterviewData = async () => {
    await getListStudioApi();
    await getListHr();

    // is add assign interview
    getAssignInterviewInfo();
  };

  // can create/update assign interview in two pages: in list interview calendar or in candidate detail
  useEffect(() => {
    getInterviewData();
  }, [isOpen]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutsideSelect);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideSelect);
    };
  }, []);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {isEditAssignInterview
            ? "Sửa lịch phỏng vấn"
            : "Assign lịch phỏng vấn"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form
            style={{
              width: "100%",
              display: "flex",
              marginBottom: "5px",
            }}
          >
            <Flex flexDirection="column" gap="10px" w="100%">
              <FormControl>
                <FormLabel>Tên ứng viên</FormLabel>
                <Input
                  disabled={true}
                  color={textColor}
                  {...register("candidateName", {
                    required: "Hãy nhập tên của ứng viên",
                  })}
                  opacity="1 !important"
                  name="candidateName"
                />
                <ErrorMessage error={errors.campaignName} />
              </FormControl>

              <FormControl>
                <FormLabel>Vị trí ứng tuyển</FormLabel>
                <Input
                  color={textColor}
                  {...register("jobName", {
                    required: "Hãy nhập vị trí ứng tuyển",
                  })}
                  name="jobName"
                  opacity="1 !important"
                  disabled={true}
                />
                <ErrorMessage error={errors.jobName} />
              </FormControl>

              <FormControl>
                <FormLabel>HR phụ trách</FormLabel>
                <SelectChakra
                  {...register("hr_id", {
                    required: "Vui lòng chọn HR phụ trách",
                  })}
                  placeholder="Chọn người phụ trách"
                  name="hr_id"
                >
                  {listHr?.map((user: IUser) => (
                    <option key={user.id} value={user.id}>
                      {user.username}
                    </option>
                  ))}
                </SelectChakra>
                <ErrorMessage error={errors.hr_id} />
              </FormControl>

              <FormControl>
                <FormLabel>Studio</FormLabel>
                <SelectChakra
                  {...register("teamId")}
                  placeholder="Chọn studio"
                  name="teamId"
                >
                  {listStudio?.map((studio: IStudio) => (
                    <option key={studio.id} value={studio.id}>
                      {studio.name}
                    </option>
                  ))}
                </SelectChakra>
                <ErrorMessage error={errors.teamId} />
              </FormControl>

              <FormControl>
                <FormLabel>Người phỏng vấn</FormLabel>

                <div ref={selectUserRef}>
                  <Controller
                    name="selectedUser"
                    {...register("selectedUser")}
                    control={control}
                    defaultValue={[]}
                    render={({ field }) => (
                      <Select
                        isClearable={true}
                        options={listUserOption}
                        isLoading={isSelectUserLoading}
                        onMenuOpen={getListUserApi}
                        menuIsOpen={isMenuOpen}
                        onChange={(selectedOption) => {
                          field.onChange(selectedOption);
                          selectedOption?.value && setIsMenuOpen(false);
                        }}
                        value={field.value}
                        placeholder="Chọn người phỏng vấn"
                      />
                    )}
                  />
                </div>

                <ErrorMessage error={errors.selectedUser} />
              </FormControl>

              <FormControl>
                <FormLabel>Thời gian bắt đầu</FormLabel>
                <Input
                  width="250px"
                  type="datetime-local"
                  size="sm"
                  ml="10px"
                  {...register("startInterviewTime")}
                  name="startInterviewTime"
                  color={textColor}
                />
                <ErrorMessage error={errors.startInterviewTime} />
              </FormControl>

              <FormControl>
                <FormLabel>Thời gian kết thúc</FormLabel>
                <Input
                  width="250px"
                  type="datetime-local"
                  size="sm"
                  ml="10px"
                  {...register("endInterviewTime", {
                    validate: validateStartDateAndEndDate,
                  })}
                  name="endInterviewTime"
                  color={textColor}
                />
                <ErrorMessage error={errors.endInterviewTime} />
              </FormControl>

              {isEditAssignInterview && (
                <FormControl>
                  <FormLabel>Trạng thái</FormLabel>
                  <Controller
                    name="status"
                    {...register("status", {
                      required: "Hãy chọn trạng thái phỏng vấn",
                    })}
                    control={control}
                    render={({ field }) => (
                      <Select
                        options={INTERVIEW_STATUS}
                        placeholder="Chọn trạng thái phỏng vấn"
                        value={field.value}
                        onChange={(selectedOption) =>
                          field.onChange(selectedOption)
                        }
                      />
                    )}
                  />
                  <ErrorMessage error={errors.status} />
                </FormControl>
              )}
            </Flex>
          </form>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="red" mr={3} onClick={onClose}>
            Đóng
          </Button>
          <Button
            colorScheme="blue"
            onClick={handleSubmit(onCreateAssignInterview)}
          >
            {isEditAssignInterview ? "Cập nhật" : "Tạo mới"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AssignInterviewModal;
