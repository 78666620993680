import { useState } from "react";
import { Box, Button, Flex, Heading, Icon, Text } from "@chakra-ui/react";
import Card from "components/card/Card";
import { ICandidate, IMasterData } from "types/interface";
import EditInfomationForm from "./EditInfomationForm";
import { truncateString } from "common/utils";
import { MdOutlineCopyAll } from "react-icons/md";
import useOpenNotification from "hook/useOpenNotification";

const candidateInfomationMapping = {
  iq: "IQ",
  test_result: "Kết quả bài test",
  character_name: "Tính cách",
  level_name: "Trình độ",
  high_school_name: "Trường cấp 3",
  university_name: "Trường đại học",
  product_url: "Link sản phẩm",
  cv_url: "Link CV",
  other_contact: "Thông tin liên lạc khác",
};

interface CandidateInfoProps {
  candidateInfo: ICandidate;
  getCandidateInfo: () => void;
  masterData: IMasterData[];
}

const CandidateInfo = (props: CandidateInfoProps) => {
  const { candidateInfo, getCandidateInfo, masterData } = props;

  const [isShowEditForm, setIsShowEditForm] = useState<boolean>(false);

  const openNotification = useOpenNotification();

  const infoStyle = {
    alignItems: "center",
    gap: "5px",
  };

  const handleCopyCvLink = (urlLink: string) => {
    navigator.clipboard.writeText(urlLink);
    urlLink?.length > 0
      ? openNotification("Sao chép thành công")
      : openNotification("Đường dẫn trống", "error");
  };

  return (
    <>
      <Card d="flex" gap="10px" p="20px 0">
        {Object.entries(candidateInfomationMapping).map(([key, value]) => {
          if (key === "cv_url") {
            return (
              <Flex key={key} {...infoStyle}>
                <Text fontWeight="bold">{value}:</Text>
                <Text>{truncateString(candidateInfo?.cv_url?.[0], 60)}</Text>

                {candidateInfo?.cv_url[0]?.length > 0 && (
                  <Icon
                    cursor="pointer"
                    onClick={() => handleCopyCvLink(candidateInfo?.cv_url?.[0])}
                    as={MdOutlineCopyAll}
                    h="1.2rem"
                    w="1.2rem"
                    ml="8px"
                  />
                )}
              </Flex>
            );
          }
          return (
            <Flex key={key} {...infoStyle}>
              <Text fontWeight="bold">{value}:</Text>
              <Text>{(candidateInfo as any)[key] || ""}</Text>
            </Flex>
          );
        })}
      </Card>

      <Button onClick={() => setIsShowEditForm(true)} colorScheme="blue">
        Sửa thông tin
      </Button>

      {isShowEditForm && (
        <EditInfomationForm
          isOpen={isShowEditForm}
          setIsOpen={setIsShowEditForm}
          candidateInfo={candidateInfo}
          getCandidateInfo={getCandidateInfo}
          masterData={masterData}
        />
      )}
    </>
  );
};

export default CandidateInfo;
