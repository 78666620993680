import React, { useEffect } from "react";
import {
  Pagination,
  PaginationContainer,
  PaginationPageGroup,
  PaginationPrevious,
  PaginationNext,
  PaginationPage,
  PaginationSeparator,
} from "@ajna/pagination";
import { Flex, Select } from "@chakra-ui/react";

interface CustomPaginationProps {
  pages?: number[];
  pagesCount?: number;
  currentPage?: number;
  onPageChange?: (page: number) => void;
  pageSize?: number;
  onPageSizeChange?: (pageSize: number) => void;
  optionPageSize?: number[];
  setCurrentPage?: any;
}

const CustomPagination: React.FC<CustomPaginationProps> = ({
  pages,
  pagesCount,
  currentPage,
  onPageChange,
  pageSize,
  onPageSizeChange,
  optionPageSize = [10, 20, 50],
  setCurrentPage,
}) => {
  useEffect(() => {
    setCurrentPage && setCurrentPage(1);
  }, [pageSize]);

  return (
    <div>
      <Pagination
        pagesCount={pagesCount}
        currentPage={currentPage}
        onPageChange={onPageChange}
      >
        <PaginationContainer align="right" justify="center" w="full">
          <PaginationPrevious>{"<"}</PaginationPrevious>
          <PaginationPageGroup
            isInline
            align="center"
            separator={
              <PaginationSeparator fontSize="sm" w={7} jumpSize={11} />
            }
          >
            {pages.map((page) => (
              <PaginationPage
                w={7}
                key={`pagination_page_${page}`}
                page={page}
                fontSize="sm"
                _current={{
                  bg: "gray.300",
                  fontSize: "sm",
                  w: 10,
                }}
              />
            ))}
          </PaginationPageGroup>
          <PaginationNext>{">"}</PaginationNext>
        </PaginationContainer>
      </Pagination>
      <Flex justify="center" mt={2}>
        <Select
          ml={3}
          onChange={(event) => onPageSizeChange(Number(event.target.value))}
          value={pageSize}
          w="70px"
        >
          {optionPageSize.map((size) => (
            <option key={size} value={size}>
              {size}
            </option>
          ))}
        </Select>
      </Flex>
    </div>
  );
};

export default CustomPagination;
