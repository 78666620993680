import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useColorModeValue,
} from "@chakra-ui/react";
import _ from "lodash";

interface ModalDepartmentCommonProps {
  isOpen: boolean;
  onClose: () => void;
  modalData?: any;
  setModalData?: React.Dispatch<any>;
  onSubmit?: (e: any) => void;
  setOpenAddModal: (value: boolean) => void;
}

const ModalDepartmentCommon = ({
  isOpen,
  onClose,
  modalData,
  setModalData,
  onSubmit,
  setOpenAddModal,
}: ModalDepartmentCommonProps) => {
  const closeModal = () => {
    onClose();
    setModalData({});
    setOpenAddModal(false);
  };

  const textColor = useColorModeValue("secondaryGray.900", "white");

  const isCreate = _.isEmpty(modalData);

  return (
    <Modal isOpen={isOpen} onClose={closeModal}>
      <ModalOverlay onClick={closeModal} />
      <ModalContent>
        <ModalHeader>{isCreate ? "Thêm studio" : "Sửa studio"}</ModalHeader>
        <ModalCloseButton onClick={closeModal} />
        <form onSubmit={onSubmit}>
          <ModalBody>
            <label htmlFor="name">Tên studio:</label>
            <Input
              id="name"
              my={5}
              placeholder="Hãy nhập tên studio"
              onChange={(e) =>
                setModalData((prev: any) => ({
                  ...prev,
                  name: e.target.value,
                }))
              }
              defaultValue={modalData?.name}
              color={textColor}
            />
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="red" onClick={closeModal} mr={3}>
              Huỷ
            </Button>

            <Button colorScheme="blue" mr={3} type="submit">
              {isCreate ? "Tạo" : "Sửa"}
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default ModalDepartmentCommon;
