import { Box, Flex, Spinner } from "@chakra-ui/react";
import { ResponsivePie } from "@nivo/pie";
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { reportService } from "../../../../../../services/Report/ReportService";

ChartJS.register(ArcElement, Tooltip, Legend);

type Props = {
  filterData: {
    fromDate: string;
    toDate: string;
  };
};

const CountCVStatusComponent = ({ filterData }: Props) => {
  const [loading, setLoading] = useState(false);
  const [chartWidth, setChartWidth] = useState(0);
  const [chartData, setChartData] = useState<any>([]);

  const getData = async () => {
    setLoading(true);
    try {
      const header = {};
      const params = {
        fromDate: "",
        toDate: "",
      };

      if (filterData.fromDate) params["fromDate"] = filterData.fromDate;
      if (filterData.toDate) params["toDate"] = filterData.toDate;

      const res = await reportService.getStatusCountReport(params, header);
      const statusCount = res.data.candidateStatusCount;
      const transformedData = statusCount.map((item: any) => {
        return {
          id: item.status,
          label: item.status,
          value: item.count,
        };
      });
      setChartData(transformedData);
    } catch (error) {
      toast.error("Lỗi khi lấy dữ liệu Count CV Status");
    } finally {
      setLoading(false);
    }
  };

  const getWindowWidth = () => {
    return window.innerWidth;
  };

  useEffect(() => {
    if (filterData.fromDate && filterData.toDate) {
      getData();
    }

    setChartWidth(getWindowWidth() / 3);

    window.addEventListener("resize", () => {
      if (getWindowWidth() > 768) {
        setChartWidth(getWindowWidth() / 3);
      } else {
        setChartWidth(getWindowWidth() / 0.5);
      }
    });
    return () => {
      window.removeEventListener("resize", () => {});
    };
  }, [filterData]);
  return (
    <>
      {loading && <Spinner />}
      {!loading && chartData.length === 0 && (
        <Box className="chart_empty" minH={"25vh"}>
          Không có dữ liệu
        </Box>
      )}
      {!loading && chartData.length > 0 && (
        <Flex w={{ sm: "80vw", md: "40vw" }} h="40vh">
          {chartData && (
            <ResponsivePie
              data={chartData}
              margin={{ top: 30, right: 60, bottom: 50, left: 60 }}
              padAngle={0.7}
              innerRadius={0.5}
              cornerRadius={3}
              isInteractive={true}
              activeOuterRadiusOffset={8}
            />
          )}
        </Flex>
      )}
    </>
  );
};

export default CountCVStatusComponent;
