import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { UnFilterIcon } from "components/icons/Icons";
import { IoAddCircleOutline } from "react-icons/io5";
import Card from "components/card/Card";
import {
  Box,
  Button,
  Flex,
  FormControl,
  Input,
  Text,
  Tooltip,
  useColorModeValue,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import ManageMatrixTable from "views/SuperAdmin/ManageRoleMatrix/ManageMatrix/ManageMatrixTable";
import { matrixServices } from "services/Matrix/MatrixServices";
import { IMatrix, OptionType } from "types/interface";
import CustomPagination from "components/common/Pagination";
import { useToggle } from "hook/useToggle";
import { usePagination } from "@ajna/pagination";
import { DEFAULT_PAGE_SIZE, HTTP_CODE } from "common/constants";
import AddMatrixModal from "views/SuperAdmin/ManageRoleMatrix/ManageMatrix/AddMatrixModal";
import { actSetOpenMatrixModal } from "redux/features/superAdminSlice";

const EMPTY_QUERY: {
  queryMatrix: string;
  queryRole: OptionType[];
  queryMethod: OptionType[];
} = {
  queryMatrix: "",
  queryRole: [],
  queryMethod: [],
};

export type SearchQueryMatrix = {
  queryMatrix?: string;
  queryRole?: OptionType[];
  queryMethod?: OptionType[];
};

const ManageMatrix = () => {
  const [listMatrix, setListMatrix] = useState<IMatrix[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [searchQueryMatrix, setSearchQueryMatrix] =
    useState<SearchQueryMatrix>(EMPTY_QUERY);

  const { register, handleSubmit } = useForm();

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const focusBorderColor = useColorModeValue("secondaryGray.500", "white");

  const dispatch = useDispatch();

  // pagination table
  const [totalData, setTotalData] = useState<number | undefined>(undefined);
  const [componentShouldBeRefresh, setComponentShouldBeRefresh] =
    useToggle(false);
  const outerLimit = 2;
  const innerLimit = 2;

  const {
    pages,
    pagesCount,
    currentPage,
    setCurrentPage,
    pageSize,
    setPageSize,
  } = usePagination({
    total: totalData,
    initialState: {
      pageSize: DEFAULT_PAGE_SIZE,
      currentPage: 1,
    },
    limits: {
      outer: outerLimit,
      inner: innerLimit,
    },
  });

  const getMatrixData = async () => {
    setIsLoading(true);

    const header = {
      page: currentPage - 1,
      size: pageSize,
    };

    // get search query matrix
    let queryParamsMatrix: any = {};
    const { queryMatrix, queryRole, queryMethod } = searchQueryMatrix || {};

    if (queryMatrix) {
      queryParamsMatrix.matrix_name = queryMatrix;
    }

    if (queryRole?.length) {
      queryParamsMatrix.role_ids = queryRole
        .map((role: OptionType) => role?.value)
        ?.join(", ");
    }

    if (queryMethod?.length) {
      queryParamsMatrix.methods = queryMethod
        .map((method: OptionType) => method?.value)
        ?.join(", ");
    }

    try {
      const maxtrixRes = await matrixServices.getAllMatrix(
        queryParamsMatrix,
        header
      );

      if (maxtrixRes?.status === HTTP_CODE.SUCCESS) {
        setListMatrix(maxtrixRes?.data?.data);
        setTotalData(maxtrixRes?.data?.totalItems);
      }
    } catch (error) {
      setListMatrix([]);
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  const handlePageChange = (nextPage: number): void => {
    setCurrentPage(nextPage);
  };

  const onChangePageSize = (pageSize: number) => {
    setPageSize(pageSize);
    setCurrentPage(1);
  };

  const handleAddNewMatrix = () => {
    dispatch(actSetOpenMatrixModal(true));
  };

  const onSearchMatrix = () => {
    setCurrentPage(1);
    setComponentShouldBeRefresh();
  };

  const onClearFilter = () => {
    setSearchQueryMatrix(EMPTY_QUERY);
    setComponentShouldBeRefresh();
  };

  useEffect(() => {
    getMatrixData();
  }, [currentPage, pageSize, componentShouldBeRefresh]);

  return (
    <Box w="full">
      <Card
        flexDirection="column"
        w="100%"
        overflowX={{ sm: "scroll", lg: "hidden" }}
        minHeight={{ sm: "50vh", md: "85vh" }}
      >
        <Flex px="25px" mb="8px" justifyContent="space-between" align="center">
          <Flex gap="5px">
            <Text
              color={textColor}
              fontSize="22px"
              fontWeight="700"
              lineHeight="100%"
            >
              Manage matrix
            </Text>

            <Tooltip label="Thêm matrix" placement="top">
              <Box
                fontSize="24px"
                cursor="pointer"
                _hover={{ color: "#422AFB" }}
                onClick={handleAddNewMatrix}
              >
                <IoAddCircleOutline />
              </Box>
            </Tooltip>
          </Flex>

          <form>
            <Flex
              gap="10px"
              marginLeft="auto"
              maxHeight="32px"
              alignItems="center"
            >
              <FormControl>
                <Input
                  placeholder="Tên matrix..."
                  size="sm"
                  _placeholder={{ opacity: 1, color: textColor }}
                  color={textColor}
                  {...register("searchMatrix")}
                  _focus={{ borderColor: focusBorderColor }}
                  w="200px"
                  onChange={(e) =>
                    setSearchQueryMatrix({
                      ...searchQueryMatrix,
                      queryMatrix: e.target.value,
                    })
                  }
                  value={searchQueryMatrix?.queryMatrix}
                />
              </FormControl>

              <FormControl>
                <Button
                borderRadius={"50%"}
                  colorScheme="blue"
                  ml="auto"
                  p={0}
                  onClick={handleSubmit(onSearchMatrix)}
                  // size="sm"
                  type="submit"
                >
                  <SearchIcon />
                </Button>
              </FormControl>

              <FormControl>
                <Tooltip label="Xóa filter" placement="top">
                  <Box
                    cursor="pointer"
                    _hover={{ color: "#C53030" }}
                    onClick={onClearFilter}
                  >
                    <UnFilterIcon fontSize="24px" />
                  </Box>
                </Tooltip>
              </FormControl>
            </Flex>
          </form>
        </Flex>

        <Flex flexDirection="column">
          <ManageMatrixTable
            listMatrix={listMatrix}
            setComponentShouldBeRefresh={setComponentShouldBeRefresh}
            searchQueryMatrix={searchQueryMatrix}
            setSearchQueryMatrix={setSearchQueryMatrix}
            isLoading={isLoading}
          />

          {listMatrix?.length === 0 && !isLoading && (
            <Flex
              alignItems="center"
              justifyContent="center"
              minWidth="100%"
              mt="15px"
              position="absolute"
              transform="translate(-50%, -50%)"
              top="50%"
              left="50%"
            >
              <Text
                fontStyle="italic"
                textAlign="center"
                fontSize="24px"
                fontWeight="bold"
              >
                Không có dữ liệu
              </Text>
            </Flex>
          )}

          {listMatrix?.length > 0 && !isLoading && (
            <>
              {/* <Flex justifyContent="flex-end" p="0 10px">
                <Text fontSize="14px" fontWeight="500" fontStyle="italic">
                  Có {totalData} dữ liệu
                </Text>
              </Flex> */}

              <CustomPagination
                pages={pages}
                pagesCount={pagesCount}
                currentPage={currentPage}
                onPageChange={handlePageChange}
                pageSize={pageSize}
                onPageSizeChange={onChangePageSize}
                optionPageSize={[10, 20, 50]}
              />
            </>
          )}
        </Flex>

        <AddMatrixModal
          setComponentShouldBeRefresh={setComponentShouldBeRefresh}
        />
      </Card>
    </Box>
  );
};

export default ManageMatrix;
