import { useRef, useState } from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Flex,
  Spinner,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import { useColorModeValue } from "@chakra-ui/system";
import { AiFillEdit } from "react-icons/ai";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { useDispatch } from "react-redux";
import {
  actSetEditMasterData,
  actSetOpenMasterDataModal,
} from "redux/features/superAdminSlice";
import { IMasterData, OptionType } from "types/interface";
import { HTTP_CODE, TYPE_MASTER_DATA } from "common/constants";
import useOpenNotification from "hook/useOpenNotification";
import { masterDataService } from "services/MasterData/MasterDataServices";
import CustomFilter from "components/common/CustomFilter";

const masterDataTypeOption = Object.entries(TYPE_MASTER_DATA).map(
  ([value, label]) => ({
    label,
    value,
  })
);
interface ManageMasterDataTableProps {
  listMasterData: IMasterData[];
  isLoading?: boolean;
  setComponentShouldBeRefresh: () => void;
  searchQueryMasterData?: any;
  setSearchQueryMasterData?: (value: any) => void;
}

const ManageMasterDataTable = (props: ManageMasterDataTableProps) => {
  const {
    listMasterData,
    setComponentShouldBeRefresh,
    searchQueryMasterData,
    setSearchQueryMasterData,
    isLoading,
  } = props;
  const [deleteId, setDeleteId] = useState<number | null>(null);

  const dispatch = useDispatch();
  const openNotification = useOpenNotification();

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const bgColor = useColorModeValue("white", "navy.800");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

  const cancelRef = useRef();
  const {
    isOpen: isOpenDialog,
    onOpen: onOpenDialog,
    onClose: onCloseDialog,
  } = useDisclosure();

  const cellStyles = {
    borderColor: borderColor,
    fontSize: { sm: "14px" },
    textColor: textColor,
    padding: "16px",
  };

  const tableColumnHeaderStyles = {
    borderColor: borderColor,
    cursor: "pointer",
    padding: "16px",
    fontSize: { sm: "10px", lg: "12px" },
  };

  const handleEditMasterData = (masterData: IMasterData) => {
    dispatch(actSetOpenMasterDataModal(true));
    dispatch(actSetEditMasterData(masterData));
  };

  const handleDeleteMasterData = (id: number) => {
    setDeleteId(id);
    onOpenDialog();
  };

  const onCloseConfirmButton = () => {
    onCloseDialog();
    setDeleteId(null);
  };

  const onConfirmButton = async () => {
    try {
      const deleteRes = await masterDataService.deleteMasterData({
        id: deleteId,
      });

      if (deleteRes?.status === HTTP_CODE.SUCCESS) {
        openNotification("Xoá master data thành công");
        setComponentShouldBeRefresh();
      }
    } catch (error) {
      throw error;
    } finally {
      setDeleteId(null);
      onCloseDialog();
    }
  };

  return (
    <>
      <Table variant="simple" color="gray.500" mb="24px" mt="12px">
        <Thead
          position="sticky"
          top={0}
          bgColor={bgColor}
          fontSize={{ sm: "10px", lg: "12px" }}
        >
          <Tr>
            <Th {...tableColumnHeaderStyles}>
              <Flex>Code</Flex>
            </Th>

            <Th {...tableColumnHeaderStyles}>
              <Flex align="center">
                <span>Name</span>
              </Flex>
            </Th>

            <Th {...tableColumnHeaderStyles}>
              <Flex align="center">
                <span>Type</span>
                <CustomFilter
                  options={masterDataTypeOption}
                  onChange={(value: OptionType[]) => {
                    setSearchQueryMasterData({
                      ...searchQueryMasterData,
                      queryType: value,
                    });
                  }}
                  isCloseMenuAfterChange={true}
                  isMulti={true}
                  value={searchQueryMasterData?.queryType}
                  placeholder="Chọn type để filter"
                />
              </Flex>
            </Th>

            <Th
              {...tableColumnHeaderStyles}
              display="flex"
              justifyContent="center"
            >
              <span>Action</span>
            </Th>
          </Tr>
        </Thead>

        {isLoading ? (
          <Flex
            mt="40px"
            alignItems="center"
            justifyContent="center"
            position="absolute"
            transform="translate(-50%, -50%)"
            top="50%"
            left="50%"
          >
            <Spinner size="lg" />
          </Flex>
        ) : (
          <Tbody bg={bgColor}>
            {listMasterData.map((masterData) => (
              <Tr key={masterData?.id} p="16px">
                <Td {...cellStyles}>{masterData?.code}</Td>
                <Td {...cellStyles}>{masterData?.name}</Td>
                <Td {...cellStyles}>{masterData?.type}</Td>

                <Td {...cellStyles}>
                  <Flex alignItems="center" gap="10px" justifyContent="center">
                    <Box
                      _hover={{ color: "orange.500" }}
                      fontSize="24px"
                      cursor="pointer"
                      color="orange.300"
                      onClick={() => handleEditMasterData(masterData)}
                    >
                      <AiFillEdit />
                    </Box>
                    <Box
                      _hover={{ color: "red.600", cursor: "pointer" }}
                      fontSize="28px"
                      cursor="pointer"
                      color="red.500"
                      onClick={() => handleDeleteMasterData(masterData?.id)}
                    >
                      <MdOutlineDeleteOutline />
                    </Box>
                  </Flex>
                </Td>
              </Tr>
            ))}
          </Tbody>
        )}
      </Table>

      <AlertDialog
        isOpen={isOpenDialog}
        leastDestructiveRef={cancelRef}
        onClose={onCloseDialog}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Xoá master data
            </AlertDialogHeader>

            <AlertDialogBody>
              Bạn có muốn xoá master data hiện tại? Hành động này không thể hoàn
              tác.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onCloseConfirmButton}>
                Huỷ
              </Button>
              <Button colorScheme="red" ml={3} onClick={onConfirmButton}>
                Xoá
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default ManageMasterDataTable;
