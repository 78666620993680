import { API_URL } from "config/environment";
import ApiServices from "services/ApiServices";

const base_url = API_URL + "/candidate-info";
const upload_cv_url = API_URL + "/candidate-info/upload";
const change_cv_status_url = API_URL + "/candidate-info/status";
const delete_multiple_cv_url = API_URL + "/candidate-info/delete-multiple-cv";

export enum CandidateStatus {
  CREATE = "create",
  REVIEW = "review",
  TEST = "test",
  PV = "pv",
  OFFER = "offer",
  REJECT = "reject",
  FAIL = "fail",
  DRAFT = "draft",
  DELETED = "deleted",
}

export class CvManageServices extends ApiServices {
  public async getCvData(parasm: any, headers: any) {
    return await this.getRequest(base_url, parasm, headers);
  }

  public async getCvById(parasm: any, headers: any) {
    return await this.getRequest(base_url + `/${parasm.id}`, "", headers);
  }

  public async uploadCv(body: any, parasm: any, headers: any) {
    return await this.postFileRequest(upload_cv_url, parasm, body, headers);
  }

  public async createCv(body: any, parasm: any, headers: any) {
    return await this.postRequest(base_url, parasm, body, headers);
  }

  public async changeCvStatus(body: any, parasm: any, headers: any) {
    return await this.putRequest(
      change_cv_status_url + `/${parasm.id}`,
      parasm,
      body,
      headers
    );
  }

  public async updateCandidateInfo(body: any, parasm: any, headers: any) {
    return await this.putRequest(
      base_url + `/${parasm.id}`,
      parasm,
      body,
      headers
    );
  }

  public async deleteMultipleCv(params: { ids: string }) {
    return await this.putRequest(
      delete_multiple_cv_url + `/${params.ids}`,
      {},
      params,
      {}
    );
  }
}

export const cvManageServices = new CvManageServices();
