import CryptoJS from "crypto-js";

export enum ROLE {
  ADMIN = "admin",
  MANAGER = "manager",
  USER = "user",
}

export class TokenStorageService {
  private readonly _secret: string = "skyrecruiment-secret-key";
  private readonly key: string = "WrWjGWuNISFpegh3qgFHq1ildRTmk9yr";

  constructor() {}

  signOut(): void {
    window.sessionStorage.clear();
  }

  public encode(info: string) {
    const firstEncode = CryptoJS.AES.encrypt(
      info + this.key,
      this._secret
    ).toString();
    const secondEncode = CryptoJS.AES.encrypt(
      firstEncode + this.key,
      this._secret
    ).toString();
    return secondEncode;
  }

  public decode(encodeInfo: string) {
    if (!encodeInfo) return;
    let firstDecode = CryptoJS.AES.decrypt(encodeInfo, this._secret)
      .toString(CryptoJS.enc.Utf8)
      .replace(this.key, "");
    let secondDecode = CryptoJS.AES.decrypt(firstDecode, this._secret)
      .toString(CryptoJS.enc.Utf8)
      .replace(this.key, "");
    return secondDecode;
  }

  public decodeRoles() {
    return this.decode(localStorage.getItem("roles"));
  }
}

export const tokenStorageService = new TokenStorageService();
