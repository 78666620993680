import { ApiServices } from "services/ApiServices";
import { API_URL } from "config/environment";
import { API_URL_PATH } from "common/constants";

const base_url = API_URL + API_URL_PATH.EMAIL;
const preview_email_url = base_url + API_URL_PATH.PREVIEW_EMAIL;

export class EmailServices extends ApiServices {
  public async sendEmail(body: any, params: any, headers: any) {
    return await this.postFileRequestWithFile(base_url, params, body, headers);
  }

  public async previewEmail(params: any, headers: any) {
    return await this.getRequest(preview_email_url, params, headers);
  }
}

export const emailService = new EmailServices();
