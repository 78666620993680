import FullCalendar from "@fullcalendar/react";
import { FC, useState } from "react";
import { IInterviewSchedule } from "types/interface";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { Tooltip } from "@chakra-ui/react";
import "./style.css";

type Props = {
  listInterviewCalendar: IInterviewSchedule[];
};

const InterviewCalendar: FC<Props> = ({ listInterviewCalendar }) => {
  const [currentViewType, setCurrentViewType] =
    useState<string>("dayGridMonth");

  const handleDatesSet = (view: any) => {
    setCurrentViewType(view.view.type);
  };

  const renderEventContent1 = (eventInfo: any) => {
    return (
      <Tooltip
        label={
          currentViewType !== "timeGridDay" &&
          eventInfo.timeText + " - " + eventInfo.event.title
        }
        maxWidth="500px"
        placement="top"
      >
        <div className="custom-event-content">
          <div className="custom-event-time">{eventInfo.timeText}</div>
          <div className="custom-event-title">{eventInfo.event.title}</div>
        </div>
      </Tooltip>
    );
  };

  return (
    <FullCalendar
      plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
      initialView="dayGridMonth"
      headerToolbar={{
        left: "prev,next today",
        center: "title",
        right: "dayGridMonth,timeGridWeek,timeGridDay",
      }}
      weekends={false}
      events={listInterviewCalendar as any}
      nowIndicator={true}
      height="auto"
      eventContent={renderEventContent1}
      datesSet={handleDatesSet}
    />
  );
};

export default InterviewCalendar;
