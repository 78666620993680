import { Box, Flex, Spinner } from "@chakra-ui/react";
import { ResponsiveBar } from "@nivo/bar";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { reportService } from "../../../../../../services/Report/ReportService";


type Props = {
  filterData: {
    fromDate: string;
    toDate: string;
  };
};

const CountCVByTeam = ({ filterData }: Props) => {
  const [chartData, setChartData] = useState<any>([]);
  const [loading, setLoading] = useState(false);

  const getDatas = async () => {
    try {
      setLoading(true);
      const header = {};
      const params = {
        fromDate: "",
        toDate: "",
      };

      if (filterData.fromDate) params["fromDate"] = filterData.fromDate;
      if (filterData.toDate) params["toDate"] = filterData.toDate;

      const response = await reportService.getCountByTeamReport(params, header);
      const candidateCount = response.data.candidateCountByTeam;

      setChartData(candidateCount);
    } catch (error) {
      toast.error("Lỗi khi lấy dữ liệu Candidate By Team");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (filterData.fromDate && filterData.toDate) {
      getDatas();
    }
  }, [filterData]);

  return (
    <>
      {loading && <Spinner />}
      {!loading && chartData.length === 0 && (
        <Box className="chart_empty" minH={"25vh"}>
          Không có dữ liệu
        </Box>
      )}
      {!loading && chartData.length > 0 && (
        <Flex width={"45vw"} height="25vh">
          {!loading && chartData && (
            <ResponsiveBar
              data={chartData}
              keys={["count"]}
              indexBy="team_name"
              layout="horizontal"
              colors={{ scheme: "paired" }}
              margin={{ right: 60, bottom: 50, left: 60 }}
            />
          )}
        </Flex>
      )}
    </>
  );
};

export default CountCVByTeam;
