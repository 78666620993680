import { Text } from "@chakra-ui/react";

interface ErrorMessageProps {
  error?: any;
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({ error }) => (
  <Text color="red.500" fontSize="sm">
    {error?.message}
  </Text>
);

export default ErrorMessage;
