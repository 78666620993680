import { useState } from "react";
import { Button, Flex, Switch, Text } from "@chakra-ui/react";
import Card from "components/card/Card";
import { MdOutlineMailOutline } from "react-icons/md";
import { useHistory } from "react-router-dom";
import {
  getColumnNameByStatus,
  getUsernameFromEmail,
  TruncatedHtmlContent,
} from "common/utils";
import { emailTemplateServices } from "services/EmailTemplate/EmailTemplateServices";
import useOpenNotification from "hook/useOpenNotification";
import { HTTP_CODE } from "common/constants";

interface TemplateItemProps {
  emailTemplate: any;
  setShouldRefresh: () => void;
  setCurrentPage: (page: number) => void;
}

const TemplateItem = (props: TemplateItemProps) => {
  const { emailTemplate, setShouldRefresh, setCurrentPage } = props;

  const [isEnableEmail, setIsEnableEmail] = useState<boolean>(false);

  const openNotification = useOpenNotification();
  const history = useHistory();

  const onSwitchEnableEmail = async () => {
    setIsEnableEmail(!isEnableEmail);

    try {
      const updatedEmailTemplateRes =
        await emailTemplateServices.updateEmailTemplate(
          { active: emailTemplate?.active ? 0 : 1 },
          { id: emailTemplate?.id },
          {}
        );

      if (updatedEmailTemplateRes?.status === HTTP_CODE.SUCCESS) {
        setCurrentPage(1);
        setShouldRefresh();
        openNotification("Đổi trạng thái thành công");
      }
    } catch (error) {
      openNotification("Đổi trạng thái thất bại");
    }
  };

  return (
    <Flex borderBottom="1px solid #a09c9c">
      <Flex alignItems="center" justifyContent="center">
        <MdOutlineMailOutline fontSize="23px" color="#8F9BBA" />
      </Flex>

      <Card p="20px">
        <Flex justifyContent={"space-between"} alignItems={"center"}>
          <Flex flexDirection={"column"}>
            <Flex justifyContent="space-between">
              <Text colorScheme="blue" fontWeight="bold" fontSize="18px">
                {emailTemplate?.name}
              </Text>
            </Flex>

            <span>
              <div
                dangerouslySetInnerHTML={{
                  __html: TruncatedHtmlContent(emailTemplate?.content, 100),
                }}
              />
            </span>

            <Text>
              Tạo bởi @{getUsernameFromEmail(emailTemplate?.username)} lúc{" "}
              {emailTemplate?.created_at}
            </Text>
          </Flex>
          <Text colorScheme="blue" fontWeight="bold" fontSize="18px">
            {emailTemplate?.status &&
              "Mặc định: " + getColumnNameByStatus(emailTemplate?.status)}
          </Text>
        </Flex>
      </Card>

      <Flex alignItems="center" justifyContent="center">
        <Button
          colorScheme="orange"
          size="md"
          onClick={() =>
            history.push(`/admin/edit-template-email/${emailTemplate?.id}`)
          }
        >
          Sửa
        </Button>

        <Switch
          marginLeft="15px"
          size="md"
          isChecked={emailTemplate?.active}
          onChange={onSwitchEnableEmail}
        />
      </Flex>
    </Flex>
  );
};

export default TemplateItem;
