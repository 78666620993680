import {
  Box,
  Flex,
  Heading,
  Td,
  Text,
  Tooltip,
  useColorModeValue,
} from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { formatDateTime } from "common/utils";
import { ICandidate } from "types/interface";
import { EmailIcon, PhoneIcon } from "components/icons/Icons";

interface CandidateRowItemProps {
  candidate: ICandidate;
}
const CandidateRowItem = (props: CandidateRowItemProps) => {
  const { candidate } = props;
  const history = useHistory();

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textInfoColor = useColorModeValue("secondaryGray.900", "white");
  const hoverTextColor = useColorModeValue("secondaryGray.800", "brand.200");

  return (
    <>
      <Td>
        <Flex gap="10px">
          <Box w="100%" d="flex">
            <Tooltip label="Xem thông tin" mt={1} ml={2} placement="top">
              <Heading
                size="sm"
                onClick={() =>
                  history.push(`/admin/candidate-info?id=${candidate.id}`)
                }
                _hover={{ cursor: "pointer", color: hoverTextColor }}
              >
                {candidate?.candidate_name}
              </Heading>
            </Tooltip>
          </Box>
        </Flex>
      </Td>

      <Td>
        <Box>
          <Flex alignItems="center">
            {candidate?.email && (
              <>
                <EmailIcon />
                <Text fontSize="13px" ml={2} color={textInfoColor}>
                  {candidate?.email}
                </Text>
              </>
            )}
          </Flex>

          <Flex alignItems="center">
            {candidate?.phone_number && (
              <>
                <PhoneIcon />
                <Text fontSize="13px" ml={2} color={textInfoColor}>
                  {candidate?.phone_number}
                </Text>
              </>
            )}
          </Flex>
        </Box>
      </Td>

      <Td>
        <Text color={textColor} fontWeight="400">
          {candidate.jobname}
        </Text>
      </Td>

      <Td>
        <Text color={textColor}>{candidate?.source}</Text>
      </Td>

      <Td>
        <Text color={textColor}>{formatDateTime(candidate?.created_at)}</Text>
      </Td>
    </>
  );
};

export default CandidateRowItem;
