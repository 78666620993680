import { Icon } from "@chakra-ui/react";
import { HiDocumentReport, HiUsers } from "react-icons/hi";
import {
  MdHome,
  MdLock,
  MdManageAccounts,
  MdRecentActors,
  MdErrorOutline,
  MdOutlineMailOutline,
  MdOutlineCalendarMonth,
  MdOutlineSecurity,
  MdOutlineWorkHistory,
} from "react-icons/md";
import { IoAnalytics } from "react-icons/io5";
import { TbBrandCampaignmonitor } from "react-icons/tb";

// Admin Imports
import MainDashboard from "views/admin/default";
// Auth Imports
import SignInCentered from "views/auth/signIn";

import { tokenStorageService } from "services/TokenServices";
import CvCreate from "views/admin/default/components/cvManage/CvCreate";
import CvDetail from "views/admin/default/components/cvManage/CvDetail";
import JobDetail from "views/admin/default/components/jobManage/JobDetail";
import CandidateManage from "views/admin/default/components/CandidateManage";
import CandidateInfo from "views/admin/default/components/CandidateManage/CandidateInfo";
import CvManage from "views/admin/default/components/cvManage/CvManage";
import ListTemplateEmail from "views/admin/default/components/ManageEmail/TemplateVariable/ListTemplate";
import CreateEmailTemplate from "views/admin/default/components/ManageEmail";
import ReportPage from "views/admin/default/components/reportPage/ReportPage";
import ChangePassword from "views/auth/ChangePassword";
import CampaignManage from "views/admin/default/components/CampaignManage";
import InterviewScheduleCalendarPage from "views/admin/default/components/InterviewScheduleCalendar";
import EmailHistory from "views/admin/default/components/cvManage/Modal";
import EvaluationCriteria from "views/admin/default/components/CandidateManage/EvaluationCompetences";
import DraftCV from "views/admin/default/components/DraftCV";
import RoleAccessPage from "views/admin/default/components/roleAccessPage/RoleAccessPage";
import SuperAdminPage from "views/SuperAdmin";
import { IRoute } from "types/interface";

let routes: IRoute[] = [
  {
    name: "Trang chủ",
    layout: "/admin",
    apiEndPoint: "api/job-info_GET",
    path: "/dashboard",
    show: true,
    icon: (
      <Icon
        as={MdHome}
        display="block"
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    component: MainDashboard,
  },
  {
    name: "Quản lý hồ sơ",
    layout: "/admin",
    apiEndPoint: "api/candidate-info_POST",
    path: "/cvmanage",
    show: true,
    icon: (
      <Icon
        as={MdRecentActors}
        display="block"
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    component: CvManage,
  },
  {
    name: "Quản lý ứng viên",
    layout: "/admin",
    apiEndPoint: "api/candidate-info_GET",
    path: "/manage-candidate",
    show: true,
    icon: (
      <Icon as={MdManageAccounts} width="20px" height="20px" color="inherit" />
    ),
    component: CandidateManage,
  },
  {
    name: "Quản lý email",
    layout: "/admin",
    apiEndPoint: "api/email-template_GET",
    path: "/manage-email",
    show: true,
    icon: (
      <Icon
        as={MdOutlineMailOutline}
        width="20px"
        height="20px"
        color="inherit"
        display="block"
      />
    ),
    component: ListTemplateEmail,
  },

  {
    name: "Lịch sử gửi email",
    layout: "/admin",
    apiEndPoint: "api/email-history_GET",
    path: "/email-history",
    show: true,
    icon: (
      <Icon
        as={MdOutlineWorkHistory}
        width="20px"
        height="20px"
        color="inherit"
        display="block"
      />
    ),
    component: EmailHistory,
  },
  {
    name: "Xem lịch phỏng vấn",
    layout: "/admin",
    apiEndPoint: "api/user-assign_GET",
    path: "/view-interview-schedule",
    show: true,
    icon: (
      <Icon
        as={MdOutlineCalendarMonth}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    component: InterviewScheduleCalendarPage,
  },
  {
    name: "Báo cáo",
    layout: "/admin",
    apiEndPoint: "api/report_GET",
    path: "/reports",
    show: true,
    icon: (
      <Icon
        as={HiDocumentReport}
        display="block"
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    component: ReportPage,
  },

  {
    name: "Phân quyền",
    layout: "/admin",
    apiEndPoint: "api/role-access_GET",
    path: "/role-access",
    show: true,
    icon: (
      <Icon
        as={HiUsers}
        display="block"
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    component: RoleAccessPage,
  },

  {
    name: "Quản lý ứng viên",
    layout: "/admin",
    apiEndPoint: "api/candidate-info_GET",
    path: "/candidate-info",
    parentPath: "/manage-candidate",
    show: false,
    icon: (
      <Icon as={MdManageAccounts} width="20px" height="20px" color="inherit" />
    ),
    component: CandidateInfo,
  },

  {
    name: "Tiêu chí đánh giá",
    layout: "/admin",
    apiEndPoint: "api/evaluation-criteria_GET",
    path: "/evaluation-criteria",
    show: true,
    icon: (
      <Icon
        as={IoAnalytics}
        width="20px"
        height="20px"
        color="inherit"
        display="block"
      />
    ),
    component: EvaluationCriteria,
  },
  {
    name: "Tạo mẫu email",
    layout: "/admin",
    apiEndPoint: "api/email-template_POST",
    path: "/create-template-email",
    parentPath: "/manage-email",
    show: false,
    icon: (
      <Icon
        as={MdOutlineMailOutline}
        width="20px"
        height="20px"
        color="inherit"
        display="block"
      />
    ),
    component: CreateEmailTemplate,
  },
  {
    name: "Sửa mẫu email",
    layout: "/admin",
    apiEndPoint: "api/email-template/:id_PUT",
    path: "/edit-template-email/:id",
    parentPath: "/manage-email",
    show: false,
    icon: (
      <Icon
        as={MdOutlineMailOutline}
        width="20px"
        height="20px"
        color="inherit"
        display="block"
      />
    ),
    component: CreateEmailTemplate,
  },

  {
    name: "Quản lý campaign",
    layout: "/admin",
    apiEndPoint: "api/candidate-info_GET",
    path: "/manage-campaign",
    show: false,
    icon: (
      <Icon
        as={TbBrandCampaignmonitor}
        display="block"
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    component: CampaignManage,
  },
  {
    name: "Không xác định",
    layout: "/admin",
    apiEndPoint: "api/candidate-info_POST",
    path: "/cvdraft",
    show: true,
    icon: (
      <Icon
        as={MdErrorOutline}
        display="block"
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    component: DraftCV,
  },
  {
    name: "Super admin",
    layout: "/admin",
    apiEndPoint: "api/super-admin_GET",
    path: "/super-admin",
    show: true,
    icon: (
      <Icon
        as={MdOutlineSecurity}
        display="block"
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    component: SuperAdminPage,
  },
  {
    name: "Tạo mới CV",
    layout: "/admin",
    apiEndPoint: "api/candidate-info_POST",
    path: "/cvmanage_create",
    parentPath: "/cvmanage",
    show: false,
    icon: (
      <Icon
        as={MdRecentActors}
        display="block"
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    component: CvCreate,
  },
  {
    name: "Chi tiết ứng viên",
    layout: "/admin",
    apiEndPoint: "api/candidate-info/:id_GET",
    path: "/cvmanage_detail",
    parentPath: "/cvmanage",
    show: false,
    icon: (
      <Icon
        as={MdRecentActors}
        display="block"
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    component: CvDetail,
  },
  {
    name: "Thông tin chi tiết công việc",
    layout: "/admin",
    apiEndPoint: "api/job-info/:id_GET",
    path: "/jobmanageinfo_detail",
    show: false,
    icon: (
      <Icon
        as={MdRecentActors}
        display="block"
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    component: JobDetail,
  },

  {
    name: "Sign In",
    layout: "/auth",
    apiEndPoint: "",
    path: "/sign-in",
    show: false,
    icon: (
      <Icon
        as={MdLock}
        display="block"
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    component: SignInCentered,
  },
  {
    name: "Đổi mật khẩu",
    layout: "/admin",
    apiEndPoint: "api/user/change-password_PUT",
    path: "/user/change-password",
    show: false,
    icon: (
      <Icon
        as={MdLock}
        display="block"
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    component: ChangePassword,
  },
];

let returnRoute: any = [
  {
    name: "Sign In",
    layout: "/auth",
    path: "/sign-in",
    icon: (
      <Icon
        as={MdLock}
        display="block"
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    component: SignInCentered,
  },
];

if (tokenStorageService.decodeRoles()) {
  const role = JSON.parse(tokenStorageService.decodeRoles());
  routes.forEach((route: IRoute) => {
    role.find((rol: any) => {
      if (route.apiEndPoint === rol.endpoint + "_" + rol.method) {
        return returnRoute.push(route);
      }
    });
  });
}

export default returnRoute;
