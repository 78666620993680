import { useEffect, useState } from "react";
import {
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalBody,
  Flex,
  FormControl,
  FormLabel,
  useColorModeValue,
  Input,
  ModalFooter,
  Button,
} from "@chakra-ui/react";
import { Controller, useForm } from "react-hook-form";
import ErrorMessage from "components/common/ErrorFormMessage";
import useOpenNotification from "hook/useOpenNotification";
import { candidateService } from "services/Candidate/CandidateServices";
import { ICandidate, IMasterData, OptionType } from "types/interface";
import {
  HTTP_CODE,
  MASTER_DATA_TYPE,
  UNLIMITED_PAGE_SIZE,
} from "common/constants";
import { masterDataService } from "services/MasterData/MasterDataServices";
import Select from "react-select";
import { cvManageServices } from "views/admin/default/components/cvManage/CvManageServices";

interface ChangeReasonFailModalProps {
  isOpen: boolean;
  onClose: () => void;
  setIsOpenSendMailModal: (isOpen: boolean) => void;
  idCandidateChangeStatus?: number;
}

interface FormValues {
  reasonFailId: OptionType[];
  candidateName?: string;
  jobName?: string;
}

const ReasonCandidateFailModal = (props: ChangeReasonFailModalProps) => {
  const { isOpen, onClose, idCandidateChangeStatus, setIsOpenSendMailModal } =
    props;

  const [listReasonFailOption, setListReasonFailOption] = useState<any>([]);
  const [candidateInfo, setCandidateInfo] = useState<ICandidate | null>(null);

  const openNotification = useOpenNotification();

  const {
    register,
    formState: { errors },
    getValues,
    handleSubmit,
    reset: resetFormValue,
    control,
  } = useForm<FormValues>();

  const textColor = useColorModeValue("secondaryGray.900", "white");

  const getCandidateInfoById = async () => {
    if (!idCandidateChangeStatus) return;

    try {
      const header = {
        page: 0,
        size: UNLIMITED_PAGE_SIZE,
      };
      const param = {
        id: idCandidateChangeStatus,
      };

      const candidateInfoRes = await cvManageServices.getCvById(param, header);

      if (candidateInfoRes.status === HTTP_CODE.SUCCESS) {
        setCandidateInfo(candidateInfoRes?.data);
      }
    } catch (error) {
      throw error;
    }
  };

  const getListReasonFail = async () => {
    const headers = {
      page: 0,
      size: UNLIMITED_PAGE_SIZE,
    };

    const params = {
      type: MASTER_DATA_TYPE.REASON_FAIL,
    };

    try {
      const res = await masterDataService.getMasterData(params, headers);

      if (res.status === HTTP_CODE.SUCCESS) {
        const listReasonFail = res?.data?.data.map((item: IMasterData) => ({
          value: item.id,
          label: item.name,
        }));

        setListReasonFailOption(listReasonFail);
      }
    } catch (err) {
      openNotification("Network error", "error");
    }
  };

  const onCloseModal = () => {
    resetFormValue();
    onClose();
  };

  const onMoveCandidateToReasonFail = async () => {
    const { reasonFailId } = getValues();

    const params = {
      id: candidateInfo?.id,
    };
    const updateCandidateData = {
      reason_fail: reasonFailId
        .map((reason: OptionType) => reason.value)
        .join(","),
    };

    try {
      const updateCandidateReasonFailRes =
        await candidateService.updateCandidateInfo(
          updateCandidateData,
          params,
          {}
        );

      if (updateCandidateReasonFailRes.status === HTTP_CODE.SUCCESS) {
        openNotification("Cập nhật thông tin thành công", "success");

        // open modal send mail after update reason fail
        setIsOpenSendMailModal(true);
      } else {
        openNotification("Cập nhật thông tin thất bại", "error");
      }
      onCloseModal();
    } catch (err) {
      openNotification("Network error", "error");
      onCloseModal();
    }
  };

  useEffect(() => {
    getCandidateInfoById();
    getListReasonFail();
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Loại ứng viên</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form
            style={{
              width: "100%",
              display: "flex",
              marginBottom: "5px",
            }}
          >
            <Flex flexDirection="column" gap="10px" w="100%">
              <FormControl>
                <FormLabel>Tên ứng viên</FormLabel>
                <Input
                  opacity="1 !important"
                  color={textColor}
                  disabled={true}
                  value={candidateInfo?.candidate_name}
                  name="candidateName "
                />
                <ErrorMessage error={errors.candidateName} />
              </FormControl>

              <FormControl>
                <FormLabel>Tên job</FormLabel>
                <Input
                  color={textColor}
                  opacity="1 !important"
                  disabled={true}
                  value={candidateInfo?.jobname}
                  name="jobName"
                />
              </FormControl>

              <FormControl>
                <FormLabel>Lí do loại ứng viên</FormLabel>
                <Controller
                  name="reasonFailId"
                  {...register("reasonFailId", {
                    required: "Hãy chọn lí do bị loại",
                  })}
                  control={control}
                  render={({ field }) => (
                    <Select
                      value={field.value}
                      options={listReasonFailOption}
                      onChange={(selectedOption) =>
                        field.onChange(selectedOption)
                      }
                      isMulti
                    />
                  )}
                />
                <ErrorMessage error={errors.reasonFailId} />
              </FormControl>
            </Flex>
          </form>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="red" mr={3} onClick={onClose}>
            Đóng
          </Button>
          <Button
            colorScheme="blue"
            onClick={handleSubmit(onMoveCandidateToReasonFail)}
          >
            Sửa
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ReasonCandidateFailModal;
