import { Box, Divider, Flex, Image } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import RocketLogoLarge from "assets/img/rocket-logo-text.png";
import RocketLogoSmall from "assets/img/rocket.png";
import { layoutSelector } from "redux/features/layoutSlice";

export function SidebarBrand() {
  const layoutSelectorState = useSelector(layoutSelector);
  const { isCollapsedSidebar } = layoutSelectorState;

  const sizeImage = isCollapsedSidebar ? "60px" : "128px";

  return (
    <Box>
      <Flex
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        height={isCollapsedSidebar ? "78px" : "128px"}
      >
        <Image
          h={sizeImage}
          w={sizeImage}
          src={isCollapsedSidebar ? RocketLogoSmall : RocketLogoLarge}
          transition="all 0.3s"
        />
      </Flex>

      <Divider
        orientation="horizontal"
        borderColor="gray.600"
        minWidth={isCollapsedSidebar ? "80px" : "250px"}
        mt={!isCollapsedSidebar && "20px"}
      />
    </Box>
  );
}

export default SidebarBrand;
