import { ApiServices } from "services/ApiServices";
import { API_URL } from "config/environment";
import { API_URL_PATH } from "common/constants";

const base_url = API_URL + API_URL_PATH.EVALUATION_CRITERIA;
const base_url_criteria_group =
  API_URL + API_URL_PATH.MASTER_DATA + API_URL_PATH.EVALUATION_CRITERIA;

export class EvaluationCriteriaServices extends ApiServices {
  public async getEvaluationCriteria(params: any, headers: any) {
    return await this.getRequest(base_url, params, headers);
  }

  public async createEvaluationCriteria(body: any) {
    return await this.postRequest(base_url, {}, body, {});
  }

  public async updateEvaluationCriteria(body: any, params: any, headers: any) {
    return await this.putRequest(base_url + `/${params.id}`, {}, body, headers);
  }

  public async deleteEvaluationCriteria(params: any, headers: any) {
    return await this.deleteRequest(
      base_url + `/${params.id}`,
      params,
      headers
    );
  }

  public async getListEvaluationCriteriaGroup(headers: any) {
    return await this.getRequest(base_url_criteria_group, headers, {});
  }
}

export const evaluationCriteriaServices = new EvaluationCriteriaServices();
