import { statusColumnConstants } from "common/constants";
import { IStatusColumnConstants } from "types/interface";
import dayjs from "./dayjsConfig";
import _ from "lodash";

const truncateString = (str: string, maxLength: number): string => {
  if (!str) return "";

  if (str?.length <= maxLength) {
    return str;
  }
  return str?.substring(0, maxLength - 3) + "...";
};

const validateEndDate = (endDate: string, startDate: string) => {
  if (!endDate || !startDate) return true;
  return endDate >= startDate || "Ngày kết thúc phải lớn hơn ngày bắt đầu";
};

// convert to format dd/mm/yyyy hh:mm:ss
const formatDateTime = (datetimeString: string) => {
  const date = new Date(datetimeString);

  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();

  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const seconds = date.getSeconds().toString().padStart(2, "0");

  return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
};

const formatDateWithFormatType = (
  dateString: string,
  formatType: string = "YYYY-MM-DD HH:mm:ss"
) => {
  return dayjs(dateString).format(formatType);
};

const removeUndefinedKeyFromObject = (object: any) => {
  return Object.fromEntries(
    Object.entries(object).filter(([key, value]) => value)
  );
};

const getUsernameFromEmail = (email: string | undefined) => {
  return email?.split("@")?.[0] || "";
};

const getColumnNameByStatus = (candidateStatus: string) => {
  return statusColumnConstants.find(
    (statusColumn: IStatusColumnConstants) =>
      statusColumn.status === candidateStatus
  )?.columnName;
};

const getStatusCVByColumnName = (statusName: string) => {
  return statusColumnConstants.find(
    (statusColumn: IStatusColumnConstants) =>
      statusColumn.columnName === statusName
  )?.status;
};

const TruncatedHtmlContent = (content: string, maxLength: number) => {
  const truncateHtmlText = (htmlText: string, maxLength: number) => {
    let truncatedText = "";
    let currentLength = 0;
    let tagOpened = false;

    for (let i = 0; i < htmlText?.length; i++) {
      const char = htmlText[i];

      if (char === "<") {
        tagOpened = true;
      } else if (char === ">") {
        tagOpened = false;
      }

      if (!tagOpened) {
        if (currentLength < maxLength) {
          truncatedText += char;
          currentLength++;
        } else {
          // If maxLength is reached, close any open tags and break
          if (char === " " || char === ">") {
            truncatedText += "...";
            break;
          }
        }
      } else {
        truncatedText += char;
      }
    }

    return truncatedText;
  };

  const truncatedContent = truncateHtmlText(content, maxLength);

  return truncatedContent;
};

const sleep = (ms: number): Promise<void> => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

const debounce = <T extends any[]>(
  func: (...args: T) => void,
  delay: number
) => {
  let timeoutId: NodeJS.Timeout;
  return (...args: T) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => func(...args), delay);
  };
};

const removeItemUndefine = (data: { [key: string]: any }) => {
  Object.keys(data).forEach((key) => {
    if ((!data[key] || key === "id") && data[key] !== 0) {
      delete data[key];
    }
  });
  return data;
};

const convertReactQuillTextToSimpleHtml = (html: string) => {
  const classRegex = /class="ql-indent-(\d)"/g;

  // Replace class="ql-indent-X" with style="margin-left: Ypx"
  const replacedHTML = html.replace(classRegex, (match, indentValue) => {
    const marginValue = Number(indentValue) * 10;
    if (marginValue) {
      return `style="margin-left: ${marginValue}px"`;
    }
  });

  return replacedHTML;
};

const compareArrays = (arr1: number[], arr2: number[]) => {
  return _.isEqual(arr1.slice().sort(), arr2.slice().sort());
};

export {
  truncateString,
  validateEndDate,
  formatDateTime,
  removeUndefinedKeyFromObject,
  getUsernameFromEmail,
  getColumnNameByStatus,
  getStatusCVByColumnName,
  TruncatedHtmlContent,
  sleep,
  debounce,
  formatDateWithFormatType,
  removeItemUndefine,
  convertReactQuillTextToSimpleHtml,
  compareArrays,
};
