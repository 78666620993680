import { usePagination } from "@ajna/pagination";
import { SearchIcon } from "@chakra-ui/icons";
import {
  Button,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  Spinner,
  Tab,
  TabList,
  Tabs,
  Text,
  Tooltip,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  DEFAULT_PAGE_SIZE,
  EMAIL_TEMPLATES_ACTIVE_STATUS,
  HTTP_CODE,
} from "common/constants";
import CustomPagination from "components/common/Pagination";
import { useToggle } from "hook/useToggle";
import { KeyboardEvent, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { emailTemplateServices } from "services/EmailTemplate/EmailTemplateServices";
import { IEmailTemplate } from "types/interface";
import TemplateItem from "./TemplateItem";

import { HiPlus } from "react-icons/hi";
import DashboardLayout from "views/admin/default/layout";

const TAB_INDEX = {
  ALL: 0,
  CREATE_BY_ME: 1,
  LOCKED: 2,
};

const ListTemplateEmail = () => {
  const history = useHistory();
  const [listEmailTemplate, setListEmailTemplate] = useState<IEmailTemplate[]>(
    []
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [selectedTab, setSelectedTab] = useState(0);
  const [searchTemplateEmai, setSearchTemplateEmail] = useState("");

  const [componentShouldBeRefresh, setComponentShouldBeRefresh] =
    useToggle(false);

  const listTemplateRefValue = useRef<any>({});

  // pagination table
  const [totalData, setTotalData] = useState<number | undefined>(undefined);
  const outerLimit = 2;
  const innerLimit = 2;

  const {
    pages,
    pagesCount,
    currentPage,
    setCurrentPage,
    pageSize,
    setPageSize,
  } = usePagination({
    total: totalData,
    initialState: {
      pageSize: DEFAULT_PAGE_SIZE,
      currentPage: 1,
    },
    limits: {
      outer: outerLimit,
      inner: innerLimit,
    },
  });

  const handlePageChange = (nextPage: number): void => {
    setCurrentPage(nextPage);
  };

  const onChangePageSize = (pageSize: number) => {
    setPageSize(pageSize);
    setCurrentPage(1);
  };

  const handleTabChange = (index: number) => {
    setSelectedTab(index);
  };

  const onSearchTemplateEmail = () => {
    setCurrentPage(1);

    listTemplateRefValue.current = {
      name: searchTemplateEmai,
    };
    setComponentShouldBeRefresh();
  };

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const hoverIcon = useColorModeValue("secondaryGray.100", "whiteAlpha.100");

  const getListEmailTemplate = async (params: any) => {
    setIsLoading(true);

    const headers = {
      page: currentPage - 1,
      size: pageSize,
    };

    try {
      const listEmailTemplateRes = await emailTemplateServices.getEmailTemplate(
        params,
        headers
      );

      if (listEmailTemplateRes?.status === HTTP_CODE.SUCCESS) {
        setTotalData(listEmailTemplateRes?.data?.totalItems);
        setListEmailTemplate(listEmailTemplateRes?.data?.data);
        setIsLoading(false);
      }
    } catch (error) {
      setTotalData(0);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const onChangeSearchTemplate = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTemplateEmail(e.target.value);
  };

  const handleKeyDownSearch = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      onSearchTemplateEmail();
    }
  };

  const getQueryEmailTemplate = () => {
    const activeStatus =
      selectedTab === TAB_INDEX.CREATE_BY_ME
        ? EMAIL_TEMPLATES_ACTIVE_STATUS.ACTIVE
        : selectedTab === TAB_INDEX.LOCKED
        ? EMAIL_TEMPLATES_ACTIVE_STATUS.INACTIVE
        : "";

    const createdByOwnUser = selectedTab === TAB_INDEX.CREATE_BY_ME ? 1 : "";

    listTemplateRefValue.current = {
      ...listTemplateRefValue.current,
      created_by_own_user: createdByOwnUser,
      active: activeStatus,
    };
  };

  useEffect(() => {
    getQueryEmailTemplate();
    getListEmailTemplate(listTemplateRefValue.current);
  }, [selectedTab, pageSize, currentPage, componentShouldBeRefresh]);

  return (
    <>
      <DashboardLayout>
        <Flex flexDirection="column" gap={1} w="full" minHeight="80vh">
          <Flex
            alignItems="center"
            justifyContent={"space-between"}
            gap={3}
            px={4}
            pb={2}
            // borderBottom={"1px solid #f5932a"}
            w="full"
            h={"7%"}
          >
            <Flex justifyContent={"space-between"}>
              <Tabs
                width="100%"
                align="start"
                onChange={handleTabChange}
                defaultIndex={selectedTab}
              >
                <TabList>
                  <Tab>TẤT CẢ </Tab>
                  <Tab>TẠO BỞI TÔI </Tab>
                  <Tab>ĐANG TẠM KHOÁ </Tab>
                </TabList>
              </Tabs>
            </Flex>
            <Flex gap="5px">
              <InputGroup>
                <Input
                  placeholder="Tên mẫu email..."
                  size="md"
                  onChange={onChangeSearchTemplate}
                  value={searchTemplateEmai}
                  _placeholder={{ opacity: 1, color: textColor }}
                  color={textColor}
                  onKeyDown={handleKeyDownSearch}
                />
                <InputRightElement
                  maxHeight="100%"
                  onClick={onSearchTemplateEmail}
                  _hover={{ cursor: "pointer", bg: hoverIcon }}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <SearchIcon fontSize="20px" />
                </InputRightElement>
              </InputGroup>

              <Tooltip label="Tạo mẫu email mới" placement="top">
                <Button
                  colorScheme="orange"
                  px={0}
                  borderRadius={"50%"}
                  color="white"
                  onClick={() => {
                    history.push("/admin/create-template-email");
                  }}
                >
                  <HiPlus size={20} />
                </Button>
              </Tooltip>
            </Flex>
          </Flex>
          {isLoading ? (
            <Flex
              w={"100%"}
              h="400px"
              justifyContent="center"
              alignItems="center"
            >
              <Spinner />
            </Flex>
          ) : (
            listEmailTemplate?.length > 0 && (
              <>
                <Flex flexDirection={"column"} w={"100%"} h={"82%"}>
                  {listEmailTemplate.map((emailTemplate) => (
                    <TemplateItem
                      key={emailTemplate?.id}
                      emailTemplate={emailTemplate}
                      setShouldRefresh={setComponentShouldBeRefresh}
                      setCurrentPage={setCurrentPage}
                    />
                  ))}
                </Flex>

                {/* <Flex justifyContent="flex-end" p="0 10px" mt="15px">
                  <Text fontSize="14px" fontWeight="500" fontStyle="italic">
                    Có {totalData} dữ liệu
                  </Text>
                </Flex> */}
              </>
            )
          )}
          {listEmailTemplate?.length === 0 && !isLoading && (
            <Flex
              alignItems="center"
              justifyContent="center"
              minWidth="100%"
              mt="15px"
              position="absolute"
              transform="translate(-50%, -50%)"
              top="50%"
              left="50%"
            >
              <Text
                fontStyle="italic"
                textAlign="center"
                fontSize="22px"
                fontWeight="bold"
              >
                Không có dữ liệu
              </Text>
            </Flex>
          )}

          {!isLoading && listEmailTemplate?.length > 0 && (
            <CustomPagination
              pages={pages}
              pagesCount={pagesCount}
              currentPage={currentPage}
              onPageChange={handlePageChange}
              pageSize={pageSize}
              onPageSizeChange={onChangePageSize}
              optionPageSize={[10, 20, 50]}
            />
          )}
        </Flex>
      </DashboardLayout>
    </>
  );
};

export default ListTemplateEmail;
