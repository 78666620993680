import { Icon } from "@chakra-ui/react";
import { MdOutlineArrowDownward, MdOutlineArrowUpward } from "react-icons/md";

const CvIcon = ({
  clickNumber,
  isShow,
}: {
  clickNumber: number;
  isShow: boolean;
}) => {
  if (!isShow) {
    return <></>;
  } else if (clickNumber % 3 === 0) {
    return <></>;
  } else if (clickNumber % 3 === 1) {
    return (
      <Icon
        cursor="pointer"
        as={MdOutlineArrowUpward}
        h="1rem"
        w="1rem"
        me="8px"
      />
    );
  } else if (clickNumber % 3 === 2) {
    return (
      <Icon
        cursor="pointer"
        as={MdOutlineArrowDownward}
        h="1rem"
        w="1rem"
        me="8px"
      />
    );
  }
};

export default CvIcon;
