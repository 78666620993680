import { Flex } from "@chakra-ui/react";
import { createContext, useState } from "react";
import EmailTemplateForm from "./TemplateVariable/EmailTemplateForm";
import TemplateVariable from "./TemplateVariable/TemplateVariable";

export const EmailTemplateContext = createContext(null);

const CreateEmailTemplate = () => {
  const [textEditorValue, setTextEditorValue] = useState({
    value: "",
  });

  return (
    <EmailTemplateContext.Provider
      value={{ textEditorValue, setTextEditorValue }}
    >
      <Flex>
        <EmailTemplateForm />

        <TemplateVariable />
      </Flex>
    </EmailTemplateContext.Provider>
  );
};

export default CreateEmailTemplate;
