import { Flex, Tab, TabList, Tabs, useColorModeValue } from "@chakra-ui/react";
import { useState } from "react";
import ManageMasterData from "views/SuperAdmin/ManageRoleMatrix/ManageMasterData";
import ManageMatrix from "views/SuperAdmin/ManageRoleMatrix/ManageMatrix";
import DashboardLayout from "../admin/default/layout";

const TAB_INDEX = {
  MATRIX: 0,
  MASTER_DATA: 1,
};

const SuperAdminPage = () => {
  const [selectedTab, setSelectedTab] = useState(TAB_INDEX.MATRIX);

  const handleTabChange = (index: number) => {
    setSelectedTab(index);
  };

  return (
    <DashboardLayout>
      <Flex flexDirection="column" gap={1} w="full" minHeight="80vh">
        <Flex
          alignItems="center"
          justifyContent={"space-between"}
          gap={3}
          px={4}
          pb={2}
          w="full"
          h={"7%"}
        >
          <Flex justifyContent={"space-between"}>
            <Tabs
              width="100%"
              align="start"
              onChange={handleTabChange}
              defaultIndex={selectedTab}
            >
              <TabList>
                <Tab>Matrix</Tab>
                <Tab>Master data</Tab>
              </TabList>
            </Tabs>
          </Flex>
        </Flex>

        {selectedTab === TAB_INDEX.MATRIX && (
          <Flex>
            <ManageMatrix />
          </Flex>
        )}

        {selectedTab === TAB_INDEX.MASTER_DATA && (
          <Flex>
            <ManageMasterData />
          </Flex>
        )}
      </Flex>
    </DashboardLayout>
  );
};

export default SuperAdminPage;
