// Chakra imports
import { Portal, Box, useDisclosure, Flex } from "@chakra-ui/react";
// Layout components
import Navbar from "components/navbar/NavbarAdmin";
import Sidebar from "components/Sidebar/Sidebar";
import { SidebarContext } from "contexts/SidebarContext";
import { useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import returnRoute from "routes";

// Custom Chakra theme
export default function Dashboard(props: { [x: string]: any }) {
  let routes = [...returnRoute];
  const { ...rest } = props;
  // states and functions
  const [fixed] = useState(false);
  const [toggleSidebar, setToggleSidebar] = useState(false);

  // functions for changing the states from components
  const getRoute = () => {
    return window.location.pathname !== "/admin/full-screen-maps";
  };
  const getActiveRoute = (routes: RoutesType[]): string => {
    let activeRoute = "Default Brand Text";
    for (let i = 0; i < routes.length; i++) {
      const routePath = routes[i]?.path?.includes("/:id")
        ? routes[i]?.path?.split("/:id")?.[0]
        : routes[i]?.path;

      if (window.location.href.indexOf(routes[i].layout + routePath) !== -1) {
        return routes[i].name;
      }
    }
    return activeRoute;
  };

  const getActiveNavbar = (routes: RoutesType[]): boolean => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
      ) {
        return routes[i].secondary;
      }
    }
    return activeNavbar;
  };

  const getActiveNavbarText = (routes: RoutesType[]): string | boolean => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
      ) {
        return routes[i].name;
      }
    }
    return activeNavbar;
  };

  const getRoutes = (routes: RoutesType[]): any => {
    return routes.map((route: RoutesType, key: any) => {
      if (route.layout === "/admin") {
        return (
          <Route
            path={route.layout + route.path}
            component={route.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  document.documentElement.dir = "ltr";
  const { onOpen } = useDisclosure();

  return (
    <Box>
      <SidebarContext.Provider
        value={{
          toggleSidebar,
          setToggleSidebar,
        }}
      >
        <Flex>
          <Sidebar />

          <Box
            float="right"
            minHeight="100vh"
            height="100%"
            overflow="hidden"
            position="relative"
            maxHeight="100%"
            transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
            transitionDuration=".2s, .2s, .35s"
            transitionProperty="top, bottom, width"
            transitionTimingFunction="linear, linear, ease"
            flex="1"
            d="flex"
            flexDirection="column"
          >
            <Box px="25px" h="60px">
              <Navbar
                onOpen={onOpen}
                brandText={getActiveRoute(routes)}
                secondary={getActiveNavbar(routes)}
                message={getActiveNavbarText(routes)}
                {...rest}
              />
            </Box>

            {getRoute() ? (
              <Box
                mx="auto"
                p={{ base: "20px", md: "25px" }}
                pe="20px"
                pt="50px"
                w="100%"
              >
                <Switch>
                  {getRoutes(routes)}
                  <Redirect from="/" to="/admin/dashboard" />
                </Switch>
              </Box>
            ) : null}
          </Box>
        </Flex>
      </SidebarContext.Provider>
    </Box>
  );
}
