import { API_URL } from "config/environment";
import ApiServices from "services/ApiServices";

const base_url = API_URL + "/report";

class ReportService extends ApiServices {
  public async getOverViewChart(params: any, headers: any) {
    return await this.getRequest(base_url, params, headers);
  }

  public async getCandidateHistory(params: any, headers: any) {
    return await this.getRequest(base_url + "/history", params, headers);
  }

  public async getSourceCountReport(params: any, headers: any) {
    return await this.getRequest(base_url + "/sourses-count", params, headers);
  }

  public async getStatusCountReport(params: any, headers: any) {
    return await this.getRequest(base_url + "/status-count", params, headers);
  }

  public async getCountByTeamReport(params: any, headers: any) {
    return await this.getRequest(base_url + "/count-by-team", params, headers);
  }

  public async getConversationRatePerRound(params: any, headers: any) {
    return await this.getRequest(base_url + "/average-time-per-round", params, headers);
  }
}

export const reportService = new ReportService();
