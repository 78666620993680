import { ApiServices } from "services/ApiServices";
import { API_URL } from "config/environment";
import { API_URL_PATH } from "common/constants";

const base_url = API_URL + API_URL_PATH.USER_ASSIGN;

export class UserAssignServices extends ApiServices {
  public async getUserInterviewByCandidateId(params: any, headers: any) {
    return await this.getRequest(base_url, params, headers);
  }

  public async getInterviewSchedule(params: any, headers: any) {
    return await this.getRequest(
      base_url + API_URL_PATH.INTERVIEW_SCHEDULE,
      params,
      headers
    );
  }

  public async assignInterviewerToCandidate(
    body: any,
    params: any,
    headers: any
  ) {
    return await this.postRequest(base_url, params, body, headers);
  }

  public async updateUserAssign(body: any, params: any, headers: any) {
    return await this.putRequest(base_url + `/${params.id}`, {}, body, headers);
  }
}

export const userAssignServices = new UserAssignServices();
