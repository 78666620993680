import { ApiServices } from "services/ApiServices";
import { API_URL } from "config/environment";
import { API_URL_PATH } from "common/constants";

const base_url = API_URL + API_URL_PATH.EMAIL_TEMPLATE;
const base_url_variable = API_URL + API_URL_PATH.EMAIL_TEMPLATE_VARIABLE;

export class EmailTemplateServices extends ApiServices {
  public async getEmailTemplate(params: any, headers: any) {
    return await this.getRequest(base_url, params, headers);
  }

  public async getOneEmailTemplate(params: any) {
    return await this.getRequest(base_url + `/${params.id}`, {}, {});
  }

  public async getEmailTemplateVariables(params: any, headers: any) {
    return await this.getRequest(base_url_variable, params, headers);
  }

  public async createEmailTemplate(body: any, headers: any) {
    return await this.postRequest(base_url, {}, body, headers);
  }

  public async updateEmailTemplate(body: any, params: any, headers: any) {
    return await this.putRequest(base_url + `/${params.id}`, {}, body, headers);
  }
}

export const emailTemplateServices = new EmailTemplateServices();
