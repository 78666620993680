import {
  Button,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Icon,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Progress,
  Select,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Tooltip,
  useColorModeValue,
} from "@chakra-ui/react";
import _ from "lodash";
import { useEffect, useState } from "react";
import { MdFullscreen } from "react-icons/md";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { cvTableServices } from "../cvTable/CvTableService";
import { cvManageServices } from "./CvManageServices";
import { CandidateStatus } from "./CvManageServices";

export default function UpdateCvInfo(props: any) {
  const { show, setShow, dataUpdate } = props;
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const iconColor = useColorModeValue("brand.500", "white");
  const [id, setId] = useState(0);
  const [jobData, setJobData] = useState([]);
  const [title, setTitle] = useState("");
  const [jobId, setJobId] = useState(0);
  const [email, setEmail] = useState("");
  const [candidateName, setCandidateName] = useState("");
  const [status, setStatus] = useState("");
  const [otherContact, setOtherContact] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [cvUrl, setCVUrl] = useState([]);
  const [source, setSource] = useState("");
  const [note, setNote] = useState("");

  const [showAlertEmail, setShowAlertEmail] = useState(false);
  const [showAlertPhoneNumber, setShowAlertPhoneNumber] = useState(false);
  const [showCvLoading, setShowCvLoading] = useState(false);

  const convertStatus = (status: string) => {
    switch (status) {
      case CandidateStatus.CREATE:
        return "Nhận hồ sơ";
      case CandidateStatus.REVIEW:
        return "Review";
      case CandidateStatus.TEST:
        return "Làm bài test";
      case CandidateStatus.PV:
        return "Interview";
      case CandidateStatus.OFFER:
        return "Đề nghị nhận việc";
      case CandidateStatus.REJECT:
        return "Từ chối offer";
    }
  };

  let candidateStatus = Object.values(CandidateStatus).map((item: any) => {
    return {
      code: item,
      name: convertStatus(item),
    };
  });

  const handleClose = () => {
    setShow(false);
    setId(0);
    setStatus("");
    setJobData([]);
    setTitle("");
    setJobId(-1);
    setEmail("");
    setCandidateName("");
    setOtherContact("");
    setPhoneNumber("");
    setCVUrl([]);
    setSource("");
    setNote("");

    setShowAlertEmail(false);
    setShowAlertPhoneNumber(false);
    props.resetData();
  };

  useEffect(() => {
    if (!_.isEmpty(dataUpdate)) {
      setId(dataUpdate.id || "");
      setTitle(dataUpdate.title || "");
      setJobId(dataUpdate.job_id || 0);
      setStatus(dataUpdate.status || "");
      setEmail(dataUpdate.email || "");
      setCandidateName(dataUpdate.candidate_name || "");
      setOtherContact(dataUpdate.other_contact || "");
      setPhoneNumber(dataUpdate.phone_number || "");
      setCVUrl(dataUpdate.cv_url || []);
      setSource(dataUpdate.source || "");
      setNote(dataUpdate.note || "");
    }
  }, [dataUpdate]);

  const validate = () => {
    const boolenArray = [showAlertEmail, showAlertPhoneNumber];
    const isNotValid = boolenArray.find((item: boolean) => item === true);
    return isNotValid ? true : false;
  };

  useEffect(() => {
    setEmail(email);
    validateEmail();
  }, [email]);

  // useEffect(() => {
  //   setPhoneNumber(phoneNumber);
  //   validatePhoneNumber();
  // }, [phoneNumber]);

  const validateEmail = () => {
    if (
      !String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    )
      setShowAlertEmail(true);
    else setShowAlertEmail(false);
  };

  // const validatePhoneNumber = () => {
  //   const regexPhoneNumber = /(84|0[3|5|7|8|9])+([0-9]{8})\b/g;

  //   phoneNumber.match(regexPhoneNumber)
  //     ? setShowAlertPhoneNumber(false)
  //     : setShowAlertPhoneNumber(true);
  // };

  const handleSave = async () => {
    if (!validate()) {
      async function updateJobInfo() {
        const header = {
          page: 0,
          size: 1000,
        };
        const param = {
          id: Number(id),
        };
        const putData = {
          job_id: jobId,
          title: title,
          email: email,
          candidate_name: candidateName,
          other_contact: otherContact,
          phone_number: phoneNumber,
          status: status,
          cv_url: cvUrl,
          source: source,
          note: note,
        };

        const result = await cvManageServices.updateCandidateInfo(
          putData,
          param,
          header
        );
        if (result.status === 200) {
          props.getCandidateInfo();
          handleClose();
        }
      }
      updateJobInfo();
    }
  };

  const getJobInfo = () => {
    async function getData() {
      const header = {
        page: 0,
        size: 1000,
      };
      try {
        const response = await cvTableServices.getJobData("", header);
        if (response?.data?.data) {
          setJobData(response.data.data);
        } else {
          setJobData([]);
        }
      } catch (error) {
        toast.error("Network error");
      }
    }
    getData();
  };

  useEffect(() => {
    getJobInfo();
  }, [jobId]);

  const handleJobChange = (event: any) => {
    setJobId(Number(event?.target?.value));
  };

  const handleStatusChange = (event: any) => {
    setStatus(event?.target?.value);
  };

  const handleCVChange = async (event: any) => {
    setShowCvLoading(true);
    let CV = event.target.files;

    const header = {
      page: 0,
      size: 1000,
    };
    const { data } = await cvManageServices.uploadCv(CV, "", header);
    setShowCvLoading(false);
    if (data.urls) {
      setCVUrl(data.urls);
    }
  };

  return (
    <>
      <Modal isOpen={show} onClose={handleClose} size="6xl">
        <ModalOverlay />
        <ModalContent height="85%" overflowY="scroll">
          <ModalHeader fontSize="4xl" justifyContent="center">
            Chỉnh sửa thông tin ứng viên
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl style={{ display: "flex", flexWrap: "wrap" }}>
              <Flex flexDirection="column" pb={5} w="30%">
                <FormLabel
                  fontSize="1.2rem"
                  fontWeight="300"
                  lineHeight="100%"
                  margin="5px 0">
                  Tên ứng viên
                </FormLabel>
                <Input
                  color={textColor}
                  margin="5px 0"
                  placeholder="Nhập tên ứng viên"
                  onChange={(event) => setCandidateName(event?.target?.value)}
                  value={candidateName}
                  w="100%"
                />
              </Flex>
              <Flex flexDirection="column" pb={5} w="40%">
                <FormLabel
                  fontSize="1.2rem"
                  fontWeight="300"
                  lineHeight="100%"
                  margin="5px 22px">
                  Số điện thoại
                </FormLabel>
                <Input
                  color={textColor}
                  alignContent="center"
                  margin="5px 22px"
                  onChange={(event) => setPhoneNumber(event?.target?.value)}
                  value={phoneNumber}
                  type="text"
                  w="90%"
                />
                {showAlertPhoneNumber && (
                  <FormHelperText textColor="red">
                    Số điện thoại không hợp lệ
                  </FormHelperText>
                )}
              </Flex>
              <Flex flexDirection="column" pb={5} w="30%">
                <FormLabel
                  fontSize="1.2rem"
                  fontWeight="300"
                  lineHeight="100%"
                  margin="5px 0">
                  Địa chỉ email
                </FormLabel>
                <Input
                  color={textColor}
                  margin="5px 0"
                  onChange={(event) => setEmail(event?.target?.value)}
                  value={email}
                  type="email"
                  w="100%"
                />
                {showAlertEmail && (
                  <FormHelperText textColor="red">
                    Địa chỉ email không hợp lệ
                  </FormHelperText>
                )}
              </Flex>
              <Flex flexDirection="column" pb={5} w="50%">
                <FormLabel
                  fontSize="1.2rem"
                  fontWeight="300"
                  lineHeight="100%"
                  margin="5px 0">
                  Vị trí ứng tuyển
                </FormLabel>
                <Select
                  onChange={handleJobChange}
                  value={jobId}
                  placeholder="Chọn công việc"
                  w="90%">
                  {jobData.map((item: any, index: any) => (
                    <option key={item.id + index} value={item.id}>
                      {item.jobname}
                    </option>
                  ))}
                </Select>
              </Flex>

              <Flex flexDirection="column" pb={5} w="50%">
                <FormLabel
                  margin="5px 0"
                  fontSize="1.2rem"
                  fontWeight="300"
                  lineHeight="100%"
                  marginLeft="55px">
                  Thông tin liên lạc khác
                </FormLabel>
                <Input
                  color={textColor}
                  marginLeft="55px"
                  onChange={(event) => setOtherContact(event?.target?.value)}
                  value={otherContact}
                  type="text"
                  w="90%"
                />
              </Flex>

              <Flex flexDirection="column" pb={5} w="50%">
                <FormLabel
                  margin="5px 0"
                  fontSize="1.2rem"
                  fontWeight="300"
                  lineHeight="100%">
                  Ghi chú
                </FormLabel>
                <Input
                  color={textColor}
                  onChange={(event) => setNote(event?.target?.value)}
                  value={note}
                  type="text"
                  w="90%"
                />
              </Flex>

              <Flex flexDirection="column" pb={5} w="50%">
                <FormLabel
                  margin="5px 0"
                  marginLeft="55px"
                  fontSize="1.2rem"
                  fontWeight="300"
                  lineHeight="100%">
                  Tải lên CV
                </FormLabel>
                <Input
                  color={textColor}
                  multiple={true}
                  type="file"
                  w="90%"
                  marginLeft="55px"
                  onChange={handleCVChange}
                />
                {showCvLoading && (
                  <Progress size="xs" w="100%" mx={5} isIndeterminate />
                )}
              </Flex>

              <Flex flexDirection="column" pb={5} w="50%">
                <FormLabel
                  fontSize="1.2rem"
                  fontWeight="300"
                  lineHeight="100%"
                  margin="5px 0">
                  Trạng thái
                </FormLabel>
                <Select
                  onChange={handleStatusChange}
                  value={status}
                  placeholder="Chọn trạng thái"
                  w="90%">
                  {candidateStatus.map((item: any, index: any) => (
                    <option key={item.code + index} value={item.code}>
                      {item.name}
                    </option>
                  ))}
                </Select>
              </Flex>

              <Flex flexDirection="column" pb={5} w="100%">
                <FormLabel fontSize="1.2rem" fontWeight="300" lineHeight="200%">
                  Xem trước CV tải lên
                </FormLabel>
                <Tabs>
                  <TabList>
                    {cvUrl &&
                      cvUrl.length > 0 &&
                      cvUrl[0] !== "" &&
                      cvUrl.map((item: any, index: number) => (
                        <Tab key={index} w="10%">
                          <Text
                            overflow="hidden"
                            noOfLines={1}
                            color={textColor}
                            fontSize="1rem"
                            fontWeight="500"
                            lineHeight="100%">
                            {item &&
                            item.includes("/files/") &&
                            item.includes(".pdf")
                              ? item.split("/files/")[1].split(".")[0]
                              : candidateName}
                          </Text>
                        </Tab>
                      ))}
                  </TabList>

                  <TabPanels>
                    {cvUrl &&
                      cvUrl.length > 0 &&
                      cvUrl[0] !== "" &&
                      cvUrl.map((item: any, index: number) => (
                        <TabPanel key={index}>
                          <Flex px="25px">
                            <Tooltip label="Fullscreen">
                              <Link href={item}>
                                <Icon
                                  as={MdFullscreen}
                                  color={iconColor}
                                  w="2rem"
                                  h="2rem"
                                />
                              </Link>
                            </Tooltip>
                          </Flex>
                          <Flex
                            px="25px"
                            mb="1rem"
                            justifyContent="space-between"
                            align="center">
                            <object
                              data={item}
                              type="application/pdf"
                              width="100%"
                              height="450px"></object>
                          </Flex>
                        </TabPanel>
                      ))}
                  </TabPanels>
                </Tabs>
              </Flex>
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={() => handleSave()}>
              Lưu
            </Button>
            <Button variant="ghost" onClick={handleClose}>
              Đóng
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
