import { useEffect, useRef, useState } from "react";
import { usePagination } from "@ajna/pagination";
import {
  Box,
  Flex,
  GridItem,
  Progress,
  SimpleGrid,
  Spinner,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import { Droppable } from "react-beautiful-dnd";
import InfiniteScroll from "react-infinite-scroll-component";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./CvColumn.css";
import { CvInfo } from "./CvInfo";
import { cvManageServices } from "./CvManageServices";
import { DEFAULT_PAGE_SIZE } from "common/constants";

export const CvColumn = ({
  columnName,
  status,
  listStatusFetchData,
}: {
  columnName: string;
  status: string;
  listStatusFetchData: string[];
}) => {
  const [cvData, setCvData] = useState([]);
  const [pageArr, setPageArr] = useState([1]);
  const [isProgress, setIsProgress] = useState(true);
  const [isShowData, setIsShowData] = useState(false);
  const [isMore, setIsMore] = useState(false);
  const [totalPage, seTtotalPage] = useState(0);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const queryParamValue = searchParams.get("job_ids");
  const startDateQuery = searchParams.get("start_date");
  const endDateQuery = searchParams.get("end_date");
  const teamIdQuery = searchParams.get("team_id");
  const candidateNameQuery = searchParams.get("name");

  const [isLoadingColumn, setLoadingColumn] = useState<boolean>(true);
  const [total, setTotal] = useState<number | undefined>(undefined);

  const { currentPage, setCurrentPage, pageSize } = usePagination({
    total: total,
    initialState: {
      pageSize: DEFAULT_PAGE_SIZE,
      currentPage: 1,
    },
  });

  const headerColor = useColorModeValue("#fff", "#023e8a");
  const bgColor = useColorModeValue("#F4F5F7", "#728fea");
  const draggingBgColor = useColorModeValue("#E9EDF7", "#a3b9f8");
  const textColor = useColorModeValue("secondaryGray.900", "white");

  const getCvInfo = (currentPage = 1) => {
    setIsProgress(true);
    async function getData() {
      const header = {
        page: currentPage - 1,
        size: pageSize,
      };
      let query: any = { status };

      const optionalParams = [
        ["job_ids", queryParamValue],
        ["start_date", startDateQuery],
        ["end_date", endDateQuery],
        ["team_id", teamIdQuery],
        ["name", candidateNameQuery],
      ];

      optionalParams.forEach(([key, value]) => {
        if (value) {
          query[key] = value;
        }
      });

      try {
        const response = await cvManageServices.getCvData(query, header);
        if (response?.data?.data) {
          setIsShowData(true);
          setCvData((prev) => [...prev, ...response.data.data]);
          setTotal(response.data.totalItems);
          seTtotalPage(response.data.totalPages);
          setLoadingColumn(false);
        } else {
          setLoadingColumn(true);
          setIsShowData(false);
          setCvData([]);
          setTotal(0);
          seTtotalPage(0);
        }
      } catch (error) {
        toast.error("Network error");
        setIsProgress(false);
      }
    }
    getData();
  };

  useEffect(() => {
    setCvData([]);
    getCvInfo();
    setPageArr([1]);
    setCurrentPage(1);
  }, [
    queryParamValue,
    startDateQuery,
    endDateQuery,
    teamIdQuery,
    candidateNameQuery,
  ]);

  useEffect(() => {
    if (listStatusFetchData?.includes(columnName)) {
      setCvData([]);
      getCvInfo();
      setPageArr([1]);
      setCurrentPage(1);
      setLoadingColumn(true);
    }
  }, [listStatusFetchData]);

  const fetchData = () => {
    if (currentPage < totalPage) {
      setCurrentPage(currentPage + 1);
      if (!pageArr.some((currentPage) => currentPage === currentPage + 1)) {
        setPageArr((prev) => [...prev, currentPage + 1]);
        getCvInfo(currentPage + 1);
      }
    }

    setIsMore(true);

    if (currentPage === totalPage) {
      setIsMore(false);
    }
  };

  const handleScroll = (event: Event) => {
    const scrollElement = event.target as HTMLElement;

    if (
      scrollElement.scrollHeight - Math.ceil(scrollElement.scrollTop) ===
      scrollElement.clientHeight
    ) {
      fetchData();
    }
  };

  const headerRef = useRef(null);
  const [headerTopOffset, setHeaderTopOffset] = useState<number>(0);

  useEffect(() => {
    if (headerRef.current) {
      const { top } = headerRef.current.getBoundingClientRect();

      setHeaderTopOffset(
        top + window.scrollY - headerRef.current.clientHeight - 42
      );
    }
  }, []);

  return (
    <GridItem minWidth="20rem">
      <Card
        bgColor={headerColor}
        mb={10}
        position="sticky"
        zIndex={20}
        ref={headerRef}
        top={`${headerTopOffset}px`}
      >
        <Flex align="center" fontSize="1rem" justify="center" color={textColor}>
          {columnName} {`(${total || 0})`}
        </Flex>
      </Card>

      <Droppable droppableId={columnName}>
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={{
              backgroundColor: snapshot.isDraggingOver
                ? draggingBgColor
                : bgColor,
              padding: "1px 10px",
            }}
          >
            <InfiniteScroll
              className="container"
              height="710px"
              next={fetchData}
              dataLength={cvData.length}
              hasMore={isMore}
              onScroll={handleScroll}
              loader={
                <Flex justify="center">
                  <Progress
                    size="xs"
                    w="100%"
                    mt={2}
                    mx={5}
                    isIndeterminate={isProgress}
                  />
                </Flex>
              }
            >
              {isLoadingColumn ? (
                <Flex justifyContent="center" alignItems="center" m="20px 0">
                  <Spinner />
                </Flex>
              ) : (
                <SimpleGrid gap={6} zIndex={20}>
                  {isShowData &&
                    cvData.length > 0 &&
                    cvData.map((item: any, index: number) => (
                      <Box key={"data " + item.id} border="0px">
                        <CvInfo data={item} index={index}></CvInfo>
                      </Box>
                    ))}
                  {cvData.length === 0 && (
                    <Text fontSize="18px" fontWeight="700" textAlign="center">
                      Chưa có thông tin
                    </Text>
                  )}

                  {!isShowData && <></>}
                </SimpleGrid>
              )}
            </InfiniteScroll>

            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </GridItem>
  );
};
