import { IStatusColumnConstants } from "types/interface";
import { CandidateStatus } from "views/admin/default/components/cvManage/CvManageServices";

export const HTTP_CODE = {
  SUCCESS: 200,
  CREATED: 201,
  ERROR: 400,
  MISSING_QUERY: 301,
  MISSING_PARAMS: 302,
  MISSING_BODY: 303,
  INVALID_QUERY: 304,
  INVALID_PARAMS: 305,
  INVALID_BODY: 306,
  INVALID_TOKEN: 401,
  TOKEN_EXPIRED: 402,
  INVALID: 403,
  OBJECT_NOT_FOUND: 404,
  OBJECT_EXIST: 405,
  EXCEPTION_ERROR: 406,
  DATABASE_ERROR: 407,
  EXCEED: 408,
  EXPIRED: 409,
  SERVER_EXCEPTION: 500,
  PERMISSON_DENIED: 502,
  SEARCH_NOT_FOUND: 601,
  NOT_ENOUGH_BALANCE: 700,
};

export const STATUS_NAME = {
  CREATE: "Nhận hồ sơ",
  REVIEW: "Review",
  TEST: "Làm bài test",
  PV: "Interview",
  OFFER: "Đề nghị nhận việc",
  REJECT: "Từ chối offer",
  FAIL: "Từ chối ứng viên",
};

export const SOURCE_NAME = {
  manual: "manual",
  topcv: "topcv",
};

export const statusColumnConstants: IStatusColumnConstants[] = [
  { columnName: STATUS_NAME.CREATE, status: CandidateStatus.CREATE },
  { columnName: STATUS_NAME.REVIEW, status: CandidateStatus.REVIEW },
  { columnName: STATUS_NAME.TEST, status: CandidateStatus.TEST },
  { columnName: STATUS_NAME.PV, status: CandidateStatus.PV },
  { columnName: STATUS_NAME.OFFER, status: CandidateStatus.OFFER },
  { columnName: STATUS_NAME.REJECT, status: CandidateStatus.REJECT },
  { columnName: STATUS_NAME.FAIL, status: CandidateStatus.FAIL },
];

export const STATUS_EMAIL_TEMPLATE_NAME = [
  {
    label: "Làm bài test",
    value: CandidateStatus.TEST,
  },
  {
    label: "Phỏng vấn",
    value: CandidateStatus.PV,
  },
  {
    label: "Đề nghị nhận việc",
    value: CandidateStatus.OFFER,
  },
  {
    label: "Ứng viên từ chối offer",
    value: CandidateStatus.REJECT,
  },
  {
    label: "Từ chối ứng viên",
    value: CandidateStatus.FAIL,
  },
];
export const NULL_VALUE = "---";

export const DEFAULT_PAGE_SIZE = 20;

export const INTERVIEW_STATUS = [
  {
    value: 0,
    label: "Chưa phỏng vấn",
  },
  {
    value: 1,
    label: "Đã phỏng vấn",
  },
];
export enum CV_STATUS {
  CREATE = "create",
  REVIEW = "review",
  TEST = "test",
  PV = "pv",
  OFFER = "offer",
  REJECT = "reject",
  DRAFT = "draft",
  DELETED = "deleted",
  FAIL = "fail",
}

export const LIST_ROLE = {
  ADMIN: 1,
  MANAGER: 2,
  USER: 3,
  SUPER_ADMIN: 4,
};

export const API_URL_PATH = {
  CANDIDATE_INFO: "/candidate-info",
  CANDIDATE_NOTE: "/candidate-note",
  EMAIL: "/mail",
  EVALUATION_CRITERIA: "/evaluation-criteria",
  MAIL_HISTORY: "/email-history",
  MASTER_DATA: "/master-data",
  USER: "/user",
  USER_ASSIGN: "/user-assign",
  INTERVIEW_SCHEDULE: "/interview-schedule",
  EMAIL_TEMPLATE: "/email-template",
  EMAIL_TEMPLATE_VARIABLE: "/email-template-variable",
  PREVIEW_EMAIL: "/preview-mail",
  CHANGE_PASSWORD: "/change-password",
  ROLES: "/roles",
  MATRIX: "/matrix",
  SYNC_CV: "/sync-cv",
};

export const storage_name = {
  ROLE_ID: "roleId",
  ACCESS_TOKEN: "access_token",
  ROLES_MATRIX: "roles",
};

export const CRITERIA_TYPE = "criteria";

export enum MASTER_DATA_TYPE {
  SOURCE_CV = "source_cv",
  TEAM = "team",
  UNIVERSITY = "university",
  CHARACTER = "character",
  HIGH_SCHOOL = "high_school",
  LEVEL = "level",
  CRITERIA = "criteria",
  REASON_FAIL = "reason_fail",
}

export const HTML_CHARACTER_ENTITIES = {
  SPACE: "&nbsp;",
};

export enum EMAIL_TEMPLATES_ACTIVE_STATUS {
  ACTIVE = 1,
  INACTIVE = 0,
}

export const UNLIMITED_PAGE_SIZE = 10000000;

export enum METHOD {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
}

export const CONVERTED_MESSAGE: any = {
  RECORD_ALREADY_EXISTS: "Dữ liệu đã tồn tại",
  CRITERIA_GROUP_NOT_FOUND: "Không tìm thấy nhóm tiêu chí đánh giá",
  CRITERIA_NOT_FOUND: "Không tìm thấy tiêu chí đánh giá",
  CREATE_EVALUATION_CRITERIA_FAIL: "Tạo tiêu chí đánh giá thất bại",
  UPDATE_EVALUATION_CRITERIA_SUCCESS: "Cập nhật tiêu chí đánh giá thành công",
  UPDATE_EVALUATION_CRITERIA_FAIL: "Cập nhật tiêu chí đánh giá thất bại",
  DELETE_EVALUATION_CRITERIA_SUCCESS: "Xoá tiêu chí đánh giá thành công",
  DELETE_EVALUATION_CRITERIA_FAIL: "Xoá tiêu chí đánh giá thất bại",
  EMAIL_TEMPLATE_STATUS_EXIST: "Mẫu email đã tồn tại cho status này",
  CREATE_EMAIL_TEMPLATE_FAIL: "Tạo mẫu email thất bại",
  EMAIL_TEMPLATE_NOT_FOUND: "Không tìm thấy mẫu email",
  UPDATE_EMAIL_TEMPLATE_FAIL: "Cập nhật mẫu email thất bại",
};

export const TYPE_MASTER_DATA = {
  source_cv: "Nguồn CV",
  university: "Trường Đại Học",
  reason_fail: "Lí do từ chối ứng viên",
  team: "Studio",
  ignore_by_keyword_store_cv: "Từ khóa loại trừ",
  ignore_by_email_store_cv: "Email loại trừ",
};

export const PHONE_NUMBER_REGEX = /(84|0[3|5|7|8|9])+([0-9]{8})\b/;
export const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
export const ROCKET_EMAIL_PATTERN = /^[a-zA-Z0-9._%+-]+@rocketstudio\.com\.vn$/;

export const CV_URL_STATUS = {
  EMPTY: "empty",
  NOT_EMPTY: "not_empty",
};
