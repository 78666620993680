import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  EMAIL_REGEX,
  HTTP_CODE,
  MASTER_DATA_TYPE,
  PHONE_NUMBER_REGEX,
} from "common/constants";
import { candidateService } from "services/Candidate/CandidateServices";
import { ICandidate, IMasterData } from "types/interface";
import { cvManageServices } from "../../cvManage/CvManageServices";
import { toast } from "react-toastify";
import ErrorMessage from "components/common/ErrorFormMessage";
import { removeItemUndefine } from "common/utils";

interface EditInfomationFormProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  candidateInfo?: ICandidate;
  getCandidateInfo: () => void;
  masterData: IMasterData[];
}

const EditInfomationForm = (props: EditInfomationFormProps) => {
  const { isOpen, setIsOpen, candidateInfo, getCandidateInfo, masterData } =
    props;

  const textColor = useColorModeValue("secondaryGray.900", "white");

  const {
    register,
    getValues,
    setValue: setFormValue,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onClose = () => {
    setIsOpen(false);
  };

  const onEditCandidateInfomation = async () => {
    const {
      iq,
      character_id,
      level_id,
      high_school_id,
      university_id,
      product_url,
      cv_url,
      otherContact,
      email,
      phone_number,
      test_result,
    } = getValues();

    const updateBodyData = {
      iq: Number(iq),
      character_id: Number(character_id),
      level_id: Number(level_id),
      high_school_id: Number(high_school_id),
      university_id: Number(university_id),
      product_url,
      cv_url,
      otherContact,
      email,
      phone_number,
      test_result: Number(test_result),
    };

    const params = { id: candidateInfo?.id };

    try {
      const updateCandidateInfoRes = await candidateService.updateCandidateInfo(
        removeItemUndefine(updateBodyData),
        params,
        {}
      );

      if (updateCandidateInfoRes?.status === HTTP_CODE.SUCCESS) {
        onClose();
        getCandidateInfo();
      }
    } catch (error) {
      onClose();
    }
  };

  const RenderSelectCandidateInfomationOption = () => {
    const optionData = [
      {
        key: "character_id",
        label: "Tính cách",
        placeholder: "Chọn tính cách",
        type: MASTER_DATA_TYPE.CHARACTER,
      },

      {
        key: "level_id",
        label: "Trình độ",
        placeholder: "Chọn trình độ",
        type: MASTER_DATA_TYPE.LEVEL,
      },

      {
        key: "high_school_id",
        label: "Trường cấp 3",
        placeholder: "Chọn trường cấp 3",
        type: MASTER_DATA_TYPE.HIGH_SCHOOL,
      },

      {
        key: "university_id",
        label: "Trường đại học",
        placeholder: "Chọn trường đại học",
        type: MASTER_DATA_TYPE.UNIVERSITY,
      },
    ];

    return optionData.map((item, index) => {
      return (
        <FormControl key={item?.key}>
          <FormLabel>{item?.label}</FormLabel>

          <Select
            {...register(item?.key)}
            name={item?.key}
            placeholder={item?.placeholder}
          >
            {masterData
              ?.filter((data: IMasterData) => data.type === item?.type)
              ?.map((data: IMasterData) => (
                <option key={data?.id} value={data?.id}>
                  {data?.name}
                </option>
              ))}
          </Select>
        </FormControl>
      );
    });
  };

  const handleCVChange = async (e: any) => {
    let CV = e.target.files;
    const header = {
      page: 0,
      size: 1000,
    };
    try {
      const res = await cvManageServices.uploadCv(CV, "", header);
      if (res?.data) {
        setFormValue("cv_url", res?.data?.urls[0]);
      }
    } catch (error: any) {
      toast.error("Network error");
    }
  };

  const setEditFormValue = () => {
    const fieldsToUpdate: (keyof ICandidate)[] = [
      "iq",
      "product_url",
      "cv_url",
      "character_id",
      "level_id",
      "high_school_id",
      "university_id",
      "email",
      "phone_number",
      "test_result",
    ];

    fieldsToUpdate.forEach((field) => {
      setFormValue(field, candidateInfo?.[field]);
    });

    setFormValue("otherContact", candidateInfo?.other_contact);
  };

  const validatePhoneNumber = (value: string) => {
    if (!value?.trim()) return true; // Allow empty value

    if (PHONE_NUMBER_REGEX.test(value)) {
      return true;
    }

    return "Số điện thoại không hợp lệ";
  };

  const validateEmail = (value: string) => {
    if (!value?.trim()) return true; // Allow empty value
    if (EMAIL_REGEX.test(value)) {
      return true;
    }

    return "Email không hợp lệ";
  };

  useEffect(() => {
    setEditFormValue();
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Sửa thông tin</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form
            style={{
              width: "100%",
              display: "flex",
              marginBottom: "5px",
            }}
          >
            <Flex flexDirection="column" gap="10px" w="100%">
              <FormControl>
                <FormLabel>Email</FormLabel>
                <Input
                  color={textColor}
                  {...register("email", {
                    validate: (value) => validateEmail(value),
                  })}
                  name="email"
                />
                <ErrorMessage error={errors.email} />
              </FormControl>

              <FormControl>
                <FormLabel>Số điện thoại</FormLabel>
                <Input
                  color={textColor}
                  {...register("phone_number", {
                    validate: (value) => validatePhoneNumber(value),
                  })}
                  name="phone_number"
                />
                <ErrorMessage error={errors.phone_number} />
              </FormControl>

              <FormControl>
                <FormLabel>IQ</FormLabel>
                <Input color={textColor} {...register("iq")} name="iq" />
              </FormControl>

              <FormControl>
                <FormLabel>Kết quả bài test</FormLabel>
                <Input
                  color={textColor}
                  {...register("test_result")}
                  name="test_result"
                />
              </FormControl>

              {RenderSelectCandidateInfomationOption()}

              <FormControl>
                <FormLabel>Link sản phẩm</FormLabel>
                <Input
                  color={textColor}
                  {...register("product_url")}
                  name="product_url"
                />
              </FormControl>

              <FormControl>
                <Flex alignItems="center" justifyContent="space-between">
                  <FormLabel>Link CV</FormLabel>

                  {candidateInfo?.cv_url?.length > 0 && (
                    <Text
                      fontSize={"sm"}
                      alignSelf="flex-end"
                      textDecoration={"underline"}
                      color="blue.500"
                      _hover={{ color: "blue.600" }}
                      transition="ease-in-out"
                    >
                      <a href={candidateInfo?.cv_url} target="_blank">
                        Xem CV
                      </a>
                    </Text>
                  )}
                </Flex>
                <Input
                  type={"file"}
                  color={textColor}
                  name="cv_url"
                  onChange={(e) => handleCVChange(e)}
                />
              </FormControl>

              <FormControl>
                <FormLabel>Thông tin liên lạc khác</FormLabel>
                <Input
                  color={textColor}
                  {...register("otherContact")}
                  name="otherContact"
                />
              </FormControl>
            </Flex>
          </form>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="red" mr={3} onClick={onClose}>
            Đóng
          </Button>
          <Button
            colorScheme="blue"
            onClick={() => handleSubmit(onEditCandidateInfomation)()}
          >
            Sửa
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditInfomationForm;
