import CvTable from "./components/cvTable/CvTable";
import DashboardLayout from "./layout";

export default function UserReports() {
  return (
    <DashboardLayout>
      <CvTable />
    </DashboardLayout>
  );
}
