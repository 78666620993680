import { useContext, useEffect, useRef, useState } from "react";
import {
  AlertDialog,
  AlertDialogOverlay,
  Box,
  Button,
  Flex,
  Heading,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  useDisclosure,
} from "@chakra-ui/react";
import { MdArrowForwardIos } from "react-icons/md";
import { HTTP_CODE } from "common/constants";
import useOpenNotification from "hook/useOpenNotification";
import { evaluationCriteriaServices } from "services/EvaluationCriteria/EvaluationCriteriaServices";
import { EvaluationCriteriaContext } from "..";
import ModalEditCompetence from "./ModalEditCompetence";
import { IEvaluationCriteria } from "types/interface";

interface CompetenceItemProps {
  criteria?: IEvaluationCriteria;
}

const CompetenceItem = (props: CompetenceItemProps) => {
  const { criteria } = props;

  const {
    isOpen: isOpenConfirmDelete,
    onOpen: onOpenConfirmDelete,
    onClose: onCloseConfirmDelete,
  } = useDisclosure();

  const [isOpenEditModal, setIsOpenEditModal] = useState<boolean>(false);
  const [isHoverElement, setIsHoverElement] = useState<boolean>(false);

  const evaluationCriteriaContext = useContext(EvaluationCriteriaContext);
  const { getEvaluationCriteriaData } = evaluationCriteriaContext;

  const openNotification = useOpenNotification();
  const cancelRef = useRef();

  useEffect(() => {
    // Clean up function to avoid memory leak
    return () => {
      setIsOpenEditModal(false);
      setIsHoverElement(false);
    };
  }, []);

  const onDeleteCompetences = async () => {
    try {
      const deleteEvaluationCriteriaRes =
        await evaluationCriteriaServices.deleteEvaluationCriteria(
          { id: criteria?.id },
          {}
        );

      if (deleteEvaluationCriteriaRes?.status === HTTP_CODE.SUCCESS) {
        openNotification("Xoá tiêu chí đánh giá thành công");
        await getEvaluationCriteriaData();
        onCloseConfirmDelete();
      }
    } catch (error) {
      onCloseConfirmDelete();
    }
  };

  return (
    <Flex
      border="1px solid #e4dfdf"
      onMouseEnter={() => setIsHoverElement(true)}
      onMouseLeave={() => setIsHoverElement(false)}
      justifyContent="space-between"
      alignItems="center"
      h="40px"
      p="25px 0"
      _hover={{ cursor: "pointer", color: "brandScheme.200" }}
    >
      <Box d="flex" alignItems="center" ml="5px" p="20px 0">
        <MdArrowForwardIos color="#8F9BBA" fontSize="13px" />
        <Heading size="sm" fontWeight={500} ml="5px">
          {criteria.name}
        </Heading>
      </Box>

      {isHoverElement && (
        <Box>
          <Button colorScheme="red" size="sm" onClick={onOpenConfirmDelete}>
            Xoá
          </Button>
          <Button
            colorScheme="yellow"
            size="sm"
            onClick={() => setIsOpenEditModal(true)}
            ml="5px"
          >
            Chỉnh sửa
          </Button>
        </Box>
      )}

      <AlertDialog
        isOpen={isOpenConfirmDelete}
        leastDestructiveRef={cancelRef}
        onClose={onCloseConfirmDelete}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Xoá tiêu chí đánh giá
            </AlertDialogHeader>

            <AlertDialogBody>
              Bạn có muốn xoá tiêu chí đánh giá này không? Hành động này không
              thể hoàn tác.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onCloseConfirmDelete}>
                Huỷ
              </Button>
              <Button colorScheme="red" onClick={onDeleteCompetences} ml={3}>
                Xoá
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      {isOpenEditModal && (
        <ModalEditCompetence
          isOpen={isOpenEditModal}
          onClose={() => setIsOpenEditModal(false)}
          criteria={criteria}
        />
      )}
    </Flex>
  );
};

export default CompetenceItem;
