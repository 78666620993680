import {
  Box,
  Button,
  Flex,
  FormControl,
  Grid,
  Icon,
  Input,
  Select,
  SimpleGrid,
  Text,
  Tooltip,
  useColorModeValue,
} from "@chakra-ui/react";
import { createContext, useContext, useEffect, useRef, useState } from "react";
import { MdPersonAddAlt1 } from "react-icons/md";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import { usePagination } from "@ajna/pagination";
import { useHistory, useLocation } from "react-router-dom";
import { SearchIcon } from "@chakra-ui/icons";
import { MdArrowRightAlt } from "react-icons/md";
import { useForm } from "react-hook-form";
import Card from "components/card/Card";
import "react-toastify/dist/ReactToastify.css";
import { cvTableServices } from "../cvTable/CvTableService";
import { CvColumn } from "./CvColumnTable";
import { cvManageServices } from "./CvManageServices";
import { ThemeContextDraft } from "./cvDraft";
import SendEmailCandidateModal from "../ManageEmail/Modal/SendEmailCandidateModal";
import useOpenNotification from "hook/useOpenNotification";
import { getListStudio } from "services/utils";
import { IStudio } from "types/interface";
import ErrorMessage from "components/common/ErrorFormMessage";
import {
  HTTP_CODE,
  statusColumnConstants,
  STATUS_NAME,
} from "common/constants";
import { IoSync } from "react-icons/io5";
import { candidateService } from "services/Candidate/CandidateServices";
import ReasonCandidateFailModal from "views/admin/default/components/cvManage/Modal/ReasonCandidateFailModal";
import "./CvColumn.css";
import { UnFilterIcon } from "components/icons/Icons";
import { layoutSelector } from "redux/features/layoutSlice";
import { useSelector } from "react-redux";

export const ThemeContext = createContext(null);

interface FormData {
  searchCandidateName?: string;
  selectedJob?: string;
  studio?: number;
  startDate?: string;
  endDate?: string;
}
const LIST_STATUS_NOT_SEND_MAIL = [STATUS_NAME.CREATE, STATUS_NAME.REVIEW];

export default function CvManage() {
  const openNotification = useOpenNotification();

  const [theme, setTheme] = useState("");
  const [jobData, setJobData] = useState([]);
  const [listStatusFetchData, setListStatusFetchData] = useState([]);
  const [isOpenSendMailModal, setIsOpenSendMailModal] =
    useState<boolean>(false);
  const [isOpenReasonFailCandidate, setIsOpenReasonFailCandidate] =
    useState<boolean>(false);
  const [idCandidateChangeStatus, setIdCandidateChangeStatus] = useState<
    number | null
  >(null);
  const [statusChange, setStatusChange] = useState<string | null>(null);
  const [listStudio, setListStudio] = useState<IStudio[]>([]);
  const [isSyncProgress, setIsSyncProgress] = useState<boolean>(false);
  const [querySelectedJobId, setQuerySelectedJobId] = useState<number>();

  const layoutSelectorState = useSelector(layoutSelector);
  const { isCollapsedSidebar } = layoutSelectorState;

  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const queryJobId = searchParams.get("job_ids");

  const bgColor = useColorModeValue("#F4F5F7", "navy.800");
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const changeColor = useColorModeValue("white", "secondaryGray.900");
  const focusBorderColor = useColorModeValue("secondaryGray.500", "white");

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
    reset: resetFormValue,
  } = useForm<FormData>();

  const createNewCandidate = () => {
    history.push("/admin/cvmanage_create");
  };

  const getValueTheme = (value: any) => {
    setTheme(value);
  };

  const getJobInfo = async () => {
    async function getData() {
      const header = {
        page: 0,
        size: 1000,
        field: "jobName",
        order: "ASC",
      };
      try {
        const response = await cvTableServices.getJobData("", header);
        if (response?.data?.data) {
          setJobData(response.data.data);
        } else {
          setJobData([]);
        }
      } catch (error) {
        openNotification("Network error", "error");
      }
    }

    await getData();
  };

  const getListStudioApi = async () => {
    const listStudioRes = await getListStudio();

    setListStudio(listStudioRes);
  };

  const onDragEnd = async (result: DropResult) => {
    if (!result?.destination) return;

    if (result.destination.droppableId === result.source.droppableId) return;
    const cvId: string = result?.draggableId;
    const status: string = result?.destination?.droppableId;

    if (cvId && status) {
      const listPageNeedFetchData: string[] = [
        result.destination.droppableId,
        result.source.droppableId,
      ];

      // if status default not send mail, change status without send mail
      if (LIST_STATUS_NOT_SEND_MAIL.includes(status)) {
        const res: { status: number; message: string } | null =
          await handleChangeCvStatus(status, cvId);

        if (res?.status === HTTP_CODE.SUCCESS) {
          openNotification("Sửa thông tin thành công");
          setListStatusFetchData(listPageNeedFetchData);
        } else {
          openNotification("Sửa thông tin thất bại", "error");
        }

        // is need send mail before change candidate status
      } else {
        setIdCandidateChangeStatus(result?.draggableId as unknown as number);
        setStatusChange(status);

        if (status === STATUS_NAME.FAIL) {
          setIsOpenReasonFailCandidate(true);
        } else {
          setIsOpenSendMailModal(true);
        }
      }
    } else {
      openNotification("Có lỗi xảy ra", "error");
    }
  };

  const [total, setTotal] = useState<number | undefined>(undefined);
  const { currentPage, setCurrentPage, pageSize } = usePagination({
    total: total,
    initialState: {
      pageSize: 20,
      currentPage: 1,
    },
  });

  const themeDraft = useContext(ThemeContextDraft);

  const handleChangeCvStatus = async (key: string, id: string) => {
    let responseData;

    const keyParams = statusColumnConstants.find(
      (column) => column.columnName === key
    ).status;

    async function getData() {
      const header = {
        page: currentPage - 1,
        size: pageSize,
      };
      const body = {
        status: keyParams?.toLowerCase(),
      };
      const param = {
        id: id,
      };

      try {
        const response = await cvManageServices.changeCvStatus(
          body,
          param,
          header
        );

        responseData = response?.data;

        return response;
      } catch (error) {
        openNotification("Network error", "error");
      }
    }
    await getData();
    return responseData;
  };

  const onClearFilter = () => {
    resetFormValue();

    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    params.delete("job_ids");
    params.delete("start_date");
    params.delete("end_date");
    params.delete("team_id");
    params.delete("name");
    history.replace({
      search: params.toString(),
    });
  };

  const onSearchCandidate = async (data: FormData) => {
    const { selectedJob, startDate, endDate, studio, searchCandidateName } =
      data;

    let queryParams: any = {};

    if (selectedJob) queryParams.job_ids = selectedJob;
    if (startDate) queryParams.start_date = startDate;
    if (endDate) queryParams.end_date = endDate;
    if (studio) queryParams.team_id = studio;
    if (searchCandidateName) queryParams.name = searchCandidateName;

    const queryString = new URLSearchParams(queryParams).toString();

    history.push("?" + queryString);
  };

  const validateStartDateAndEndDate = () => {
    const startDate = getValues("startDate");
    const endDate = getValues("endDate");

    if ((!startDate && endDate) || (startDate && !endDate)) {
      return "Ngày bắt đầu và kết thúc cần được điền!";
    }

    if (startDate && endDate && !(endDate >= startDate)) {
      return "Ngày kết thúc phải lớn hơn ngày bắt đầu!";
    }

    return true;
  };

  const onSyncCvData = async () => {
    setIsSyncProgress(true);
    try {
      const syncCVRes = await candidateService.syncCvData();

      if (syncCVRes?.data?.status === HTTP_CODE.SUCCESS) {
        openNotification("Đồng bộ dữ liệu thành công");

        const allStatus = statusColumnConstants.map(
          (status) => status.columnName
        );
        setListStatusFetchData(allStatus);
      }
    } catch (error) {
      throw error;
    } finally {
      setIsSyncProgress(false);
    }
  };

  useEffect(() => {
    getListStudioApi();
    getJobInfo();
  }, []);

  useEffect(() => {
    setQuerySelectedJobId(+queryJobId);
  }, [queryJobId]);

  return (
    <ThemeContext.Provider
      value={{
        getValueTheme: getValueTheme,
        listStudio: listStudio,
        setListStatusFetchData,
      }}
    >
      <Box
        overflowX="auto"
        position="relative"
        height="calc(100vh - 110px)"
        bgColor={bgColor}
        w="100%"
      >
        <Box top={0} left={0} position="sticky" zIndex={20}>
          <Box>
            <form
              onSubmit={handleSubmit(onSearchCandidate)}
              style={{
                width: "100%",
                display: "flex",
                marginBottom: "5px",
                justifyContent: "space-between",
              }}
            >
              <Flex gap="15px">
                <FormControl>
                  <Select
                    color={textColor}
                    backgroundColor={changeColor}
                    w="220px"
                    {...register("selectedJob")}
                    name="selectedJob"
                    value={querySelectedJobId}
                    onChange={(e) => setQuerySelectedJobId(+e.target.value)}
                  >
                    <option value="">Tất cả công việc</option>
                    {jobData.map((item: any, index: any) => (
                      <option key={item.id} value={item.id}>
                        {item.jobname}
                      </option>
                    ))}
                  </Select>
                </FormControl>

                <FormControl position="relative">
                  <Input
                    color={textColor}
                    type="date"
                    w="170px"
                    {...register("startDate")}
                    name="startDate"
                  />
                </FormControl>
                <Flex alignItems="center">
                  <MdArrowRightAlt />
                </Flex>

                <FormControl position="relative">
                  <Input
                    color={textColor}
                    type="date"
                    w="170px"
                    {...register("endDate", {
                      validate: validateStartDateAndEndDate,
                    })}
                    name="endDate"
                  />
                  <Box position="absolute" top="100%" zIndex="999" w="300px">
                    <ErrorMessage error={errors.endDate} />
                  </Box>
                </FormControl>

                <FormControl>
                  <Select
                    color={textColor}
                    backgroundColor={changeColor}
                    w="220px"
                    {...register("studio")}
                    name="studio"
                    defaultValue=""
                  >
                    <option value="" key="all">
                      Tất cả studio
                    </option>
                    {listStudio.map((item: any) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </Select>
                </FormControl>

                <FormControl>
                  <Input
                    placeholder="Tên ứng viên ..."
                    _placeholder={{ opacity: 1, color: textColor }}
                    color={textColor}
                    {...register("searchCandidateName")}
                    _focus={{ borderColor: focusBorderColor }}
                    w="200px"
                    onKeyDown={() => handleSubmit(onSearchCandidate)}
                  />
                </FormControl>

                <Flex alignItems="center" pr={5}>
                  <Tooltip label="Tìm kiếm">
                    <Button
                      p={0}
                      borderRadius={"50%"}
                      type="submit"
                      colorScheme="orange"
                      mr={3}
                    >
                      <SearchIcon />
                    </Button>
                  </Tooltip>

                  <Tooltip label="Xóa filter" placement="top">
                    <Box
                      cursor="pointer"
                      _hover={{ color: "#C53030" }}
                      onClick={onClearFilter}
                    >
                      <UnFilterIcon fontSize="24px" />
                    </Box>
                  </Tooltip>
                </Flex>
              </Flex>

              <Box ml="auto">
                <Button
                  rightIcon={
                    <IoSync className={isSyncProgress && "sync-icon"} />
                  }
                  colorScheme="blue"
                  onClick={onSyncCvData}
                  px="4"
                >
                  Đồng bộ
                </Button>
              </Box>
            </form>
          </Box>
        </Box>

        <Box position="sticky" top="50px" zIndex={20} mt={4} left={0}>
          <Flex
            px="25px"
            mb="8px"
            align="center"
            maxWidth="300px"
            justifyContent="space-between"
            left={0}
          >
            <Text
              paddingRight="1rem"
              color={textColor}
              fontSize="22px"
              fontWeight="700"
              lineHeight="100%"
            >
              Thông tin ứng viên
            </Text>
            <Icon
              cursor="pointer"
              onClick={createNewCandidate}
              as={MdPersonAddAlt1}
              h="1.5rem"
              w="1.5rem"
              me="8px"
            />
          </Flex>
        </Box>

        <Box
          className="hidden-layer"
          width={`calc(100% - ${isCollapsedSidebar ? "150px" : "315px"})`}
        ></Box>

        <Card
          bgColor={bgColor}
          flexDirection="column"
          w="160rem"
          px={0}
          pb={3}
          height="100%"
        >
          <DragDropContext onDragEnd={onDragEnd}>
            <Grid
              templateColumns="repeat(7, 1fr)"
              gap={18}
              px="42px"
              minHeight="calc(100% - 100px)"
            >
              {statusColumnConstants.map((column, index) => (
                <Flex flexDirection="column">
                  <CvColumn
                    columnName={column.columnName}
                    status={column.status}
                    listStatusFetchData={listStatusFetchData}
                    key={column.columnName}
                  />
                </Flex>
              ))}
            </Grid>
          </DragDropContext>
        </Card>
      </Box>

      {isOpenReasonFailCandidate && (
        <ReasonCandidateFailModal
          isOpen={isOpenReasonFailCandidate}
          onClose={() => setIsOpenReasonFailCandidate(false)}
          idCandidateChangeStatus={idCandidateChangeStatus}
          setIsOpenSendMailModal={setIsOpenSendMailModal}
        />
      )}

      {isOpenSendMailModal && (
        <SendEmailCandidateModal
          isOpen={isOpenSendMailModal}
          setIsOpen={setIsOpenSendMailModal}
          idCandidateChangeStatus={idCandidateChangeStatus}
          statusChange={statusChange}
          handleChangeCvStatus={handleChangeCvStatus}
          setListStatusFetchData={setListStatusFetchData}
        />
      )}
    </ThemeContext.Provider>
  );
}
