import { useEffect, useRef, useState } from "react";
import { usePagination } from "@ajna/pagination";
import {
  Box,
  Button,
  Flex,
  FormControl,
  Input,
  Spinner,
  Text,
  Tooltip,
  useColorModeValue,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { SearchIcon } from "@chakra-ui/icons";
import { IMailHistory, OptionType } from "types/interface";
import { mailHistoryService } from "services/MailHistory/MailHistoryServices";
import EmailHistoryTable from "../../EmailHistory/MailHistoryTable";
import Card from "components/card/Card";
import CustomPagination from "components/common/Pagination";
import { DEFAULT_PAGE_SIZE, HTTP_CODE } from "common/constants";
import { UnFilterIcon } from "components/icons/Icons";
import DashboardLayout from "../../../layout";
import { MdSearch } from "react-icons/md";
interface IMailHistoryParams {
  job_ids?: string;
  status?: number;
  candidate_name?: string;
  mail_sent_by_users_id?: string;
}

const EmailHistory = () => {
  const [listMailHistory, setListMailHistory] = useState<IMailHistory[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const selectedFilterMailHistory = useRef<any>({
    filterJob: [],
    filterStatus: [],
    searchCandidateName: "",
    filterUser: [],
  });
  const queryMailHistory = useRef<any>();

  const { register, handleSubmit, reset: resetFormValue } = useForm();

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const focusBorderColor = useColorModeValue("secondaryGray.500", "white");

  // pagination table
  const [totalData, setTotalData] = useState<number | undefined>(undefined);
  const outerLimit = 2;
  const innerLimit = 2;

  const {
    pages,
    pagesCount,
    currentPage,
    setCurrentPage,
    pageSize,
    setPageSize,
  } = usePagination({
    total: totalData,
    initialState: {
      pageSize: DEFAULT_PAGE_SIZE,
      currentPage: 1,
    },
    limits: {
      outer: outerLimit,
      inner: innerLimit,
    },
  });

  const handlePageChange = (nextPage: number): void => {
    setCurrentPage(nextPage);
  };

  const onChangePageSize = (pageSize: number) => {
    setPageSize(pageSize);
    setCurrentPage(1);
  };

  // call api to get list mail history
  const getListMailHistory = async (params?: IMailHistoryParams) => {
    try {
      setIsLoading(true);
      const header = {
        page: currentPage - 1,
        size: pageSize,
      };

      const mailHistoryRes = await mailHistoryService.getMailHistory(
        params,
        header
      );

      if (mailHistoryRes?.status === HTTP_CODE.SUCCESS) {
        setListMailHistory(mailHistoryRes?.data?.data);
        setTotalData(mailHistoryRes?.data?.totalItems);
        setIsLoading(false);
        return;
      }

      setTotalData(0);
    } catch (error) {
      setListMailHistory([]);
      setTotalData(0);
    } finally {
      setIsLoading(false);
    }
  };

  const onSearchCandidateByFilter = () => {
    const { filterJob, filterStatus, searchCandidateName, filterUser } =
      selectedFilterMailHistory?.current ?? {
        filterJob: [],
        filterStatus: [],
        searchCandidateName: "",
        filterUser: [],
      };

    const queryParams = {
      job_ids: filterJob?.map((item: OptionType) => item.value).join(","),
      mail_sent_by_users_id: filterUser
        ?.map((item: OptionType) => item.value)
        .join(","),
      status: filterStatus?.map((item: OptionType) => item.value).join(","),
      candidate_name: searchCandidateName || undefined,
    };

    queryMailHistory.current = queryParams;

    getListMailHistory(queryParams);
  };

  const onClearFilter = () => {
    resetFormValue();
    selectedFilterMailHistory.current = {};
    getListMailHistory();
  };

  const onChangeFilterCandidateName = (e: any) => {
    selectedFilterMailHistory.current = {
      ...selectedFilterMailHistory.current,
      searchCandidateName: e.target.value,
    };
  };

  useEffect(() => {
    getListMailHistory(queryMailHistory.current);
  }, [pageSize, currentPage]);

  return (
    <>
      <DashboardLayout>
        <Flex flexDirection="column" gap={1} w="full" minHeight="80vh">
          <Flex
            alignItems="center"
            justifyContent={"space-between"}
            gap={3}
            px={4}
            pb={2}
            borderBottom={"1px solid #f5932a"}
            w="full"
            h={"7%"}
          >
            <Flex justifyContent={"space-between"}>
              <Text fontSize="xl" fontWeight="bold">
                Mail History
              </Text>
            </Flex>
            <Flex>
              <form>
                <Flex gap="10px" marginLeft="auto" alignItems="center">
                  <FormControl>
                    <Input
                      placeholder="Tên ứng viên..."
                      size="md"
                      _placeholder={{ opacity: 1, color: textColor }}
                      color={textColor}
                      {...register("searchCandidate")}
                      _focus={{ borderColor: focusBorderColor }}
                      w="200px"
                      onChange={(e) => onChangeFilterCandidateName(e)}
                    />
                  </FormControl>

                  <FormControl>
                    <Tooltip label="Tìm kiếm" placement="top">
                      <Button
                        p={0}
                        borderRadius={"50%"}
                        colorScheme="blue"
                        ml="auto"
                        onClick={handleSubmit(onSearchCandidateByFilter)}
                        type="submit"
                      >
                        <SearchIcon />
                      </Button>
                    </Tooltip>
                  </FormControl>
                  <FormControl>
                    <Tooltip label="Xóa filter" placement="top">
                      <Box
                        cursor="pointer"
                        _hover={{ color: "#C53030" }}
                        onClick={onClearFilter}
                      >
                        <UnFilterIcon fontSize="24px" />
                      </Box>
                    </Tooltip>
                  </FormControl>
                </Flex>
              </form>
            </Flex>
          </Flex>
          {isLoading ? (
            <Flex
              w={"100%"}
              h="400px"
              alignItems="center"
              justifyContent="center"
            >
              <Spinner size="lg" />
            </Flex>
          ) : (
            <>
              <EmailHistoryTable
                isLoading={isLoading}
                listMailHistory={listMailHistory}
                selectedFilterMailHistory={selectedFilterMailHistory}
              />
            </>
          )}

          {!isLoading && listMailHistory?.length > 0 && (
            <CustomPagination
              pages={pages}
              pagesCount={pagesCount}
              currentPage={currentPage}
              onPageChange={handlePageChange}
              pageSize={pageSize}
              onPageSizeChange={onChangePageSize}
              optionPageSize={[10, 20, 50]}
            />
          )}
        </Flex>
      </DashboardLayout>
    </>
  );
};

export default EmailHistory;
