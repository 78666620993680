import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "redux/store";

interface ILayoutState {
  isCollapsedSidebar: boolean;
}

const initialState: ILayoutState = {
  isCollapsedSidebar: false,
};

export const layoutSlice = createSlice({
  name: "Layout",
  initialState,
  reducers: {
    actToggleSidebar: (state: ILayoutState) => {
      state.isCollapsedSidebar = !state.isCollapsedSidebar;
    },
  },
});

export const { actToggleSidebar } = layoutSlice.actions;
export default layoutSlice.reducer;
export const layoutSelector = (state: RootState) => state.Layout;
