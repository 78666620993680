import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  Flex,
  Icon,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  useDisclosure,
  Tooltip,
  Box,
  Select,
  FormLabel,
  Checkbox,
} from "@chakra-ui/react";
import {
  CV_STATUS,
  CV_URL_STATUS,
  HTTP_CODE,
  UNLIMITED_PAGE_SIZE,
} from "common/constants";
import {
  compareArrays,
  formatDateWithFormatType,
  truncateString,
} from "common/utils";
import ErrorMessage from "components/common/ErrorFormMessage";
import useOpenNotification from "hook/useOpenNotification";
import { useEffect, useRef, useState } from "react";
import { MdOutlineCopyAll, MdOutlineDeleteOutline } from "react-icons/md";
import { ICandidate, IJob, OptionType } from "types/interface";
import { cvManageServices } from "views/admin/default/components/cvManage/CvManageServices";
import { cvTableServices } from "views/admin/default/components/cvTable/CvTableService";
import _ from "lodash";
import CustomFilter from "components/common/CustomFilter";

interface DraftCVTableProps {
  listDraftCv: ICandidate[];
  isLoading: boolean;
  setComponentShouleBeRefresh: () => void;
  searchQueryDraftCV: any;
  setSearchQueryDraftCV: (value: string | number) => void;
}

const filterCvOptions = [
  { value: CV_URL_STATUS.EMPTY, label: "Không có CV" },
  { value: CV_URL_STATUS.NOT_EMPTY, label: "Có CV" },
];

const DraftCVTable = (props: DraftCVTableProps) => {
  const {
    listDraftCv,
    isLoading,
    searchQueryDraftCV,
    setComponentShouleBeRefresh,
    setSearchQueryDraftCV,
  } = props;

  const [isDeleteCv, setIsDeleteCv] = useState<boolean>(false);
  const [cvIdChange, setCvIdChange] = useState<number | null>(null);
  const [jobId, setJobId] = useState<number>();
  const [listJob, setListJob] = useState<IJob[]>([]);
  const [selectJobErrorMessage, setSelectJobErrorMessage] =
    useState<string>("");

  const [listSelectedCv, setListSelectedCv] = useState<number[]>([]);

  const openNotification = useOpenNotification();

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const bgColor = useColorModeValue("white", "navy.800");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

  const {
    isOpen: isOpenDialog,
    onOpen: onOpenDialog,
    onClose: onCloseDialog,
  } = useDisclosure();
  const cancelRef = useRef();

  const cellStyles = {
    borderColor: borderColor,
    fontSize: { sm: "14px" },
    minW: { sm: "150px", md: "200px", lg: "auto" },
    textColor: textColor,
    padding: "16px",
  };

  const tableHeaderStyle = {
    borderColor: borderColor,
    cursor: "pointer",
    p: "16px",
  };

  const handleCopyText = (url: string) => {
    navigator.clipboard.writeText(url);
    url?.length > 0
      ? openNotification("Sao chép thành công")
      : openNotification("Đường dẫn trống", "error");
  };

  const onCloseConfirmButton = () => {
    onCloseDialog();
    setCvIdChange(null);
    setJobId(0);
  };

  const handleChangeCvStatus = (id: number, isDeleteCvStatus: boolean) => {
    setIsDeleteCv(isDeleteCvStatus);
    setCvIdChange(id);

    if (isDeleteCvStatus && listSelectedCv.length === 0) {
      openNotification("Vui lòng chọn CV cần xoá", "error");

      return;
    }

    onOpenDialog();
  };

  const handleJobChange = (event: any) => {
    const changedJobId = Number(event?.target?.value);
    setJobId(changedJobId);
    validateSelectJob(changedJobId);
  };

  const validateSelectJob = (changedJobId: number) => {
    if (!changedJobId) {
      setSelectJobErrorMessage("Vui lòng chọn công việc");
    } else {
      setSelectJobErrorMessage("");
    }

    return !!changedJobId;
  };

  const onConfirmButton = async () => {
    // change cv status -> create
    if (!isDeleteCv) {
      const validJob = validateSelectJob(jobId);

      if (!validJob) {
        return;
      }

      const params = {
        id: cvIdChange,
      };

      const body = {
        status: CV_STATUS.CREATE,
        job_id: jobId,
      };

      try {
        const changeCvRes = await cvManageServices.changeCvStatus(
          body,
          params,
          {}
        );

        if (changeCvRes?.status === HTTP_CODE.SUCCESS) {
          openNotification("Thành công");
          setComponentShouleBeRefresh();
          onCloseDialog();
        }
      } catch (error) {
        throw error;
      }
    } else {
      // handle delete CV
      await deleteMultipleCv();
    }
  };

  const getJobInfo = async () => {
    setListSelectedCv([]);
    const header = {
      page: 0,
      size: UNLIMITED_PAGE_SIZE,
    };
    try {
      const jobDataRes = await cvTableServices.getJobData("", header);
      if (jobDataRes?.status === HTTP_CODE.SUCCESS) {
        setListJob(jobDataRes?.data?.data);
      }
    } catch (error) {
      setListJob([]);
      throw error;
    }
  };

  const handleCheckedAll = (e: any) => {
    const checked = e.target.checked;

    if (checked) {
      setListSelectedCv(listDraftCv.map((item) => item.id));
    } else {
      setListSelectedCv([]);
    }
  };

  const handleCheckedDraftCv = (e: any, id: number) => {
    const checked = e.target.checked;

    checked
      ? setListSelectedCv([...listSelectedCv, id])
      : setListSelectedCv(listSelectedCv.filter((item) => item !== id));
  };

  const deleteMultipleCv = async () => {
    const params = {
      ids: listSelectedCv.join(","),
    };

    try {
      const deleteCvRes = await cvManageServices.deleteMultipleCv(params);

      if (deleteCvRes?.status === HTTP_CODE.SUCCESS) {
        openNotification("Thành công");
        setComponentShouleBeRefresh();
        onCloseDialog();
      }
    } catch (error) {
      onCloseDialog();
      throw error;
    }
  };

  useEffect(() => {
    getJobInfo();
  }, []);

  return (
    <>
      <Flex justifyContent="flex-end">
        <Box
          _hover={{ color: "red.600", cursor: "pointer" }}
          fontSize="28px"
          cursor="pointer"
          color="red.500"
          onClick={() => handleChangeCvStatus(0, true)}
        >
          <MdOutlineDeleteOutline />
        </Box>
      </Flex>

      <Table variant="simple" color="gray.500" mb="24px" mt="12px">
        <Thead position="sticky" top={0} bgColor={bgColor}>
          <Tr>
            <Tooltip
              label={
                listSelectedCv.length === listDraftCv.length
                  ? "Bỏ chọn tất cả"
                  : "Chọn tất cả"
              }
              placement="top"
            >
              <Th {...tableHeaderStyle} width="5%">
                <Checkbox
                  onChange={handleCheckedAll}
                  isChecked={compareArrays(
                    listSelectedCv,
                    listDraftCv.map((cv) => cv.id)
                  )}
                ></Checkbox>
              </Th>
            </Tooltip>

            <Th {...tableHeaderStyle} width="15%">
              Tên ứng viên
            </Th>
            <Th {...tableHeaderStyle} width="25%">
              Email title
            </Th>
            <Th {...tableHeaderStyle} width="10%">
              Email
            </Th>
            <Th {...tableHeaderStyle} width="15%">
              <Flex>
                <span>CV url</span>

                <CustomFilter
                  options={filterCvOptions}
                  onChange={(option: OptionType) => {
                    setSearchQueryDraftCV({
                      ...searchQueryDraftCV,
                      cv_url_status: option.value,
                    });
                  }}
                  isCloseMenuAfterChange={true}
                  isClear={true}
                />
              </Flex>
            </Th>
            <Th {...tableHeaderStyle} width="15%">
              Tạo lúc
            </Th>
            <Th {...tableHeaderStyle} width="15%">
              Action
            </Th>
          </Tr>
        </Thead>

        {!isLoading && (
          <>
            <Tbody bg={bgColor}>
              {listDraftCv.map((candidateInfo: ICandidate) => (
                <Tr key={candidateInfo.id}>
                  <Td {...cellStyles}>
                    <Checkbox
                      isChecked={listSelectedCv.includes(candidateInfo.id)}
                      onChange={(e) =>
                        handleCheckedDraftCv(e, candidateInfo.id)
                      }
                    ></Checkbox>
                  </Td>
                  <Td {...cellStyles}>
                    <Tooltip
                      label={candidateInfo?.candidate_name}
                      placement="top"
                    >
                      {truncateString(candidateInfo?.candidate_name, 20)}
                    </Tooltip>
                  </Td>
                  <Td {...cellStyles}>
                    <Tooltip label={candidateInfo?.title} placement="top">
                      {truncateString(candidateInfo?.title, 30)}
                    </Tooltip>
                  </Td>
                  <Td {...cellStyles}>
                    <Tooltip
                      label={
                        candidateInfo?.email.length > 28 && candidateInfo?.email
                      }
                      placement="top"
                    >
                      {truncateString(candidateInfo?.email, 28)}
                    </Tooltip>
                  </Td>
                  <Td {...cellStyles}>
                    {candidateInfo?.cv_url?.length > 0 ? (
                      <Flex alignItems="center">
                        {truncateString(candidateInfo?.cv_url[0], 25)}

                        <Icon
                          cursor="pointer"
                          onClick={() =>
                            handleCopyText(candidateInfo?.cv_url[0])
                          }
                          as={MdOutlineCopyAll}
                          h="1.2rem"
                          w="1.2rem"
                          ml="8px"
                        />
                      </Flex>
                    ) : (
                      <Text
                        color={candidateInfo?.cv_url?.length === 0 && "red"}
                      >
                        Trống
                      </Text>
                    )}
                  </Td>
                  <Td {...cellStyles}>
                    {formatDateWithFormatType(
                      candidateInfo?.created_at,
                      "MM-DD HH:mm"
                    )}
                  </Td>
                  <Td {...cellStyles}>
                    <Flex gap="15px">
                      <Button
                        size="sm"
                        colorScheme="blue"
                        onClick={() =>
                          handleChangeCvStatus(candidateInfo?.id, false)
                        }
                      >
                        Nhận hồ sơ
                      </Button>
                    </Flex>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </>
        )}
      </Table>

      <AlertDialog
        isOpen={isOpenDialog}
        leastDestructiveRef={cancelRef}
        onClose={onCloseConfirmButton}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {isDeleteCv ? "Xoá CV" : "Chuyển trạng thái CV"}
            </AlertDialogHeader>

            <AlertDialogBody>
              Bạn có muốn {isDeleteCv ? "xoá" : "chuyển"} CV? Hành động này
              không thể hoàn tác.
            </AlertDialogBody>

            {!isDeleteCv && (
              <Box px="24px">
                <FormLabel>
                  Chọn job <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Select
                  onChange={handleJobChange}
                  value={jobId}
                  placeholder="Chọn công việc"
                  w="100%"
                  mt="10px"
                >
                  {listJob.map((item: any, index: any) => (
                    <option key={item.id + index} value={item.id}>
                      {item.jobname}
                    </option>
                  ))}
                </Select>

                <ErrorMessage error={{ message: selectJobErrorMessage }} />
              </Box>
            )}

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onCloseConfirmButton}>
                Huỷ
              </Button>
              <Button
                colorScheme={isDeleteCv ? "red" : "blue"}
                ml={3}
                onClick={onConfirmButton}
              >
                {isDeleteCv ? "Xoá" : "Chuyển"}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default DraftCVTable;
