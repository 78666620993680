import { useContext, useEffect } from "react";
import {
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalCloseButton,
  Flex,
  FormControl,
  FormLabel,
  Input,
  ModalFooter,
  Button,
  useColorModeValue,
  Modal,
  Select,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import ErrorMessage from "components/common/ErrorFormMessage";
import {
  IEvaluationCriteria,
  IEvaluationCriteriaData,
  OptionType,
} from "types/interface";
import { evaluationCriteriaServices } from "services/EvaluationCriteria/EvaluationCriteriaServices";
import useOpenNotification from "hook/useOpenNotification";
import { CONVERTED_MESSAGE, HTTP_CODE } from "common/constants";
import { EvaluationCriteriaContext } from "..";

interface ModalEditCompetenceProps {
  isOpen: boolean;
  onClose: () => void;
  criteria: IEvaluationCriteria;
}

const ModalEditCompetence = (props: ModalEditCompetenceProps) => {
  const { isOpen, onClose, criteria } = props;

  const {
    register,
    formState: { errors },
    getValues,
    setValue: setFormValue,
    handleSubmit,
  } = useForm();

  const openNotification = useOpenNotification();
  const textColor = useColorModeValue("secondaryGray.900", "white");

  const evaluationCriteriaContext = useContext(EvaluationCriteriaContext);
  const { getEvaluationCriteriaData, evaluationCriteriaData } =
    evaluationCriteriaContext;

  const listCriteriaGroup = evaluationCriteriaData?.map(
    (group: IEvaluationCriteriaData) => ({
      value: group?.criteriaGroupId,
      label: group?.name,
    })
  );

  const onEditCompetence = async () => {
    const { compentencesName, groupCompetences } = getValues();

    try {
      const updateBody = {
        name: compentencesName,
        criteria_group_id: parseInt(groupCompetences),
      };
      const params = {
        id: criteria?.id,
      };

      const updatedEvaluationCriteriaRes =
        await evaluationCriteriaServices.updateEvaluationCriteria(
          updateBody,
          params,
          {}
        );

      if (updatedEvaluationCriteriaRes?.data?.code === HTTP_CODE.SUCCESS) {
        openNotification("Cập nhật thành công", "success");
        await getEvaluationCriteriaData();
      } else {
        openNotification(
          CONVERTED_MESSAGE[updatedEvaluationCriteriaRes?.data?.message],
          "error"
        );
      }

      onClose();
    } catch (error) {
      onClose();
    }
  };

  useEffect(() => {
    setFormValue("compentencesName", criteria?.name);
  }, [criteria]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Sửa thông tin</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form
            style={{
              width: "100%",
              display: "flex",
              marginBottom: "5px",
            }}
          >
            <Flex flexDirection="column" gap="10px" w="100%">
              <FormControl>
                <FormLabel>Tiêu chí</FormLabel>
                <Input
                  color={textColor}
                  {...register("compentencesName", {
                    required: "Tiêu chí không được để trống",
                  })}
                  name="compentencesName"
                />
                <ErrorMessage error={errors.compentencesName} />
              </FormControl>

              <FormControl>
                <FormLabel>Nhóm tiêu chí</FormLabel>
                <Select
                  {...register("groupCompetences", {
                    required: "Vui lòng chọn group",
                  })}
                  placeholder="Chọn nhóm tiêu chí"
                  name="groupCompetences"
                  defaultValue={criteria?.criteriaGroupId}
                >
                  {listCriteriaGroup.map((criteria: OptionType) => (
                    <option key={criteria?.value} value={criteria?.value}>
                      {criteria?.label}
                    </option>
                  ))}
                </Select>
                <ErrorMessage error={errors.groupCompetences} />
              </FormControl>
            </Flex>
          </form>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="red" mr={3} onClick={onClose}>
            Đóng
          </Button>
          <Button
            colorScheme="blue"
            type="submit"
            onClick={handleSubmit(onEditCompetence)}
          >
            Sửa
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalEditCompetence;
