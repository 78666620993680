import { ApiServices } from "services/ApiServices";
import { API_URL } from "config/environment";
import { API_URL_PATH } from "common/constants";

const base_url = API_URL + API_URL_PATH.MAIL_HISTORY;

export class MailHistoryServices extends ApiServices {
  public async getMailHistory(params: any, headers: any) {
    return await this.getRequest(base_url, params, headers);
  }
}

export const mailHistoryService = new MailHistoryServices();
