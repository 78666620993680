import { Box, Skeleton, Tbody, Td, Tr } from "@chakra-ui/react";

interface SkeletonColProps {
    col: number;
}

const SkeletonCol = ({col}:SkeletonColProps ) => {
  return (
    <Tbody pt={5}>
      <Tr>
        {new Array(col).fill(0).map((_, index) => (
          <Td key={index}>
            <Skeleton speed={0.5}>
              <Box w={"100%"} h={"20px"}></Box>
            </Skeleton>
          </Td>
        ))}
      </Tr>
    </Tbody>
  );
};

export default SkeletonCol;
