import { ChakraProvider } from "@chakra-ui/react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { GOOGLE_CLIENT_ID } from "config/environment";
import React from "react";
import ReactDOM from "react-dom";
import { HashRouter, Redirect, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import RouteAuthenticated from "route/AuthenticatedRoute.component";
import RouteUnauthenticated from "route/UnauthenticatedRoute.component";
import { isAuthenticated } from "services/AuthServices";
import "./assets/css/App.css";
import AdminLayout from "./layouts/admin";
import AuthLayout from "./layouts/auth";
import theme from "./theme/theme";
import "react-toastify/dist/ReactToastify.css";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { ClickToComponent } from "click-to-react-component";
import { Provider } from "react-redux";
import { persistor, store } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
dayjs.extend(utc);

const fallbackUri = `${isAuthenticated() ? "/admin" : "/auth"}`;

ReactDOM.render(
  <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
    <ChakraProvider theme={theme}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ClickToComponent editor="vscode-insiders" />
          <HashRouter>
            <Switch>
              <RouteUnauthenticated path={`/auth`} component={AuthLayout} />
              <RouteAuthenticated path={`/admin`} component={AdminLayout} />
              <Redirect to={fallbackUri} />
            </Switch>
          </HashRouter>
        </PersistGate>
      </Provider>
      <ToastContainer />
    </ChakraProvider>
  </GoogleOAuthProvider>,
  document.getElementById("root")
);
