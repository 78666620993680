import { useEffect, useState } from "react";
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  useColorModeValue,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalCloseButton,
  Select,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import { useForm } from "react-hook-form";
import _ from "lodash";
import { cvManageServices } from "../../cvManage/CvManageServices";
import TextEditor from "../EmailEditor/TextEditor";
import ErrorMessage from "components/common/ErrorFormMessage";
import { emailService } from "services/Email/EmailServices";
import {
  EMAIL_TEMPLATES_ACTIVE_STATUS,
  HTTP_CODE,
  statusColumnConstants,
  UNLIMITED_PAGE_SIZE,
} from "common/constants";
import useOpenNotification from "hook/useOpenNotification";
import { emailTemplateServices } from "services/EmailTemplate/EmailTemplateServices";
import { IEmailTemplate, ITextEditor } from "types/interface";
import {
  getStatusCVByColumnName,
  getColumnNameByStatus,
  convertReactQuillTextToSimpleHtml,
} from "common/utils";

export const EMPTY_TEXT_EDITOR_VALUE = "<p><br></p>";

const convertStatusToKey = (status: string): string | undefined => {
  return statusColumnConstants.find((item) => item.columnName === status)
    ?.status;
};

interface SendEmailCandidateModalProps {
  isOpen?: boolean;
  setIsOpen?: (value: boolean) => void;
  idCandidateChangeStatus?: number;
  statusChange?: string;
  oldStatus?: string;
  handleChangeCvStatus: (
    key: string,
    id: string
  ) => Promise<{ status: number; message: string }>;
  setListStatusFetchData: any;
}

const SendEmailCandidateModal = (props: SendEmailCandidateModalProps) => {
  const {
    isOpen,
    setIsOpen,
    idCandidateChangeStatus,
    statusChange,
    handleChangeCvStatus,
    setListStatusFetchData,
  } = props;

  const openNotification = useOpenNotification();

  const [isPreviewEmailOpen, setIsPreviewEmailOpen] = useState<boolean>(false);
  const [textValue, setTextValue] = useState<any>({ value: null });
  const [isLoadingButton, setIsLoadingButton] = useState<boolean>(false);
  const [textEditorError, setTextEditorError] = useState<{
    message: string | null;
  }>({ message: null });
  const [previewEmail, setPreviewEmail] = useState<ITextEditor>({
    value: EMPTY_TEXT_EDITOR_VALUE,
  });
  const [oldStatus, setOldStatus] = useState<string | null>(null);

  const [listEmailTemplate, setListEmailTemplate] = useState([]);

  const {
    register,
    formState: { errors },
    getValues,
    handleSubmit,
    reset,
    setValue: setFormValue,
  } = useForm();

  const resetEmailForm = () => {
    reset();
    setTextValue({ value: null });
    setIsOpen(false);
    setIsLoadingButton(false);
  };

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const labelColor = useColorModeValue("secondaryGray.900", "white");

  const onCloseModal = () => {
    setIsOpen(false);
  };

  const getCandidateInfoById = async () => {
    if (!idCandidateChangeStatus) return;

    const header = {
      page: 0,
      size: UNLIMITED_PAGE_SIZE,
    };
    const param = {
      id: idCandidateChangeStatus,
    };

    const candidateInfoRes = await cvManageServices.getCvById(param, header);
    return candidateInfoRes;
  };

  const getListEmailTemplate = async () => {
    try {
      const header = {
        page: 0,
        size: UNLIMITED_PAGE_SIZE,
      };
      const emailTemplateRes = await emailTemplateServices.getEmailTemplate(
        {
          active: EMAIL_TEMPLATES_ACTIVE_STATUS.ACTIVE,
        },
        header
      );

      if (emailTemplateRes?.status === HTTP_CODE.SUCCESS) {
        return emailTemplateRes?.data?.data;
      }

      return [];
    } catch (error) {
      throw error;
      return [];
    }
  };

  const fetchData = async () => {
    const listEmailTemplate = await getListEmailTemplate();
    setListEmailTemplate(listEmailTemplate);

    if (isOpen) {
      const candidateInfoRes = await getCandidateInfoById();

      // depend on status to get content of email template
      if (candidateInfoRes.status === HTTP_CODE.SUCCESS) {
        const { data } = candidateInfoRes;
        setOldStatus(getColumnNameByStatus(data?.status));

        // find email template by status
        const emailTemplateFoundByStatus = listEmailTemplate?.find(
          (emailTemplate: IEmailTemplate) =>
            emailTemplate?.status === getStatusCVByColumnName(statusChange)
        );

        if (emailTemplateFoundByStatus) {
          setFormValue("titleEmail", emailTemplateFoundByStatus?.title);
          setTextValue({ value: emailTemplateFoundByStatus?.content });
          setFormValue("emailTemplate", emailTemplateFoundByStatus?.id);
        }

        setFormValue("candidateEmail", data.email);
      }
    }
  };

  const onChangeEmailTemplate = (e: any) => {
    const emailTemplateId = e?.target?.value;

    const emailTemplate = listEmailTemplate.find(
      (emailTemplate) => emailTemplate.id === Number(emailTemplateId)
    );

    if (emailTemplate) {
      setFormValue("titleEmail", emailTemplate.title);
      setTextValue({ value: emailTemplate.content });
    }
  };

  const sendMailForCandidate = async () => {
    if (!textValue.value || textValue.value === EMPTY_TEXT_EDITOR_VALUE) {
      setTextEditorError({ message: "Vui lòng nhập nội dung email" });
      return;
    } else {
      setTextEditorError({ message: null });
    }

    setIsLoadingButton(true);
    const { candidateEmail, titleEmail, attachFiles } = getValues();
    const formData = new FormData();

    _.forEach(attachFiles, (file, key) => {
      formData.append("file", file);
    });

    formData.append("to", candidateEmail.split(" - ")[0]);
    formData.append("subject", titleEmail);
    formData.append(
      "content",
      convertReactQuillTextToSimpleHtml(textValue.value)
    );
    formData.append("to", candidateEmail);
    formData.append("candidate", idCandidateChangeStatus.toString());
    formData.append("status", convertStatusToKey(statusChange) || "");

    try {
      const sendEmailRes = await emailService.sendEmail(formData, "", {});

      if (sendEmailRes?.status === HTTP_CODE.CREATED) {
        openNotification("Gửi email thành công");

        const changeCvRes = await handleChangeCvStatus(
          statusChange,
          idCandidateChangeStatus.toString()
        );

        if (changeCvRes?.status === HTTP_CODE.SUCCESS) {
          setListStatusFetchData([oldStatus, statusChange]);
        }
      } else {
        openNotification("Gửi email thất bại", "error");
      }

      resetEmailForm();
    } catch (err) {
      openNotification("Network error", "error");
      resetEmailForm();
    }
  };

  const getPreviewEmailContent = async () => {
    try {
      const previewEmailRes = await emailService.previewEmail(
        {
          content: textValue.value,
          candidateId: idCandidateChangeStatus,
        },
        {}
      );

      if (previewEmailRes?.status === HTTP_CODE.SUCCESS) {
        setPreviewEmail(
          previewEmailRes?.data?.length > 0
            ? previewEmailRes?.data
            : " <p><strong>Nội dung email trống</strong></p>"
        );
        return;
      }

      setPreviewEmail(null);
    } catch (error) {
      throw error;
    }
  };

  const handleOpenPreviewEmail = async () => {
    await getPreviewEmailContent();
    setIsPreviewEmailOpen(true);
  };

  const onClosePreviewEmail = () => {
    setIsPreviewEmailOpen(false);
  };

  useEffect(() => {
    fetchData();
  }, [isOpen, idCandidateChangeStatus]);

  return (
    <>
      <Modal isOpen={isOpen} onClose={onCloseModal} size="2xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader> Gửi email cho ứng viên</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Card p="0" m="20px 0">
              <form>
                <Flex
                  p="0 5px"
                  justifyContent="space-between"
                  flexDirection="column"
                  gap="10px"
                >
                  <FormControl>
                    <FormLabel>Tới</FormLabel>
                    <Input
                      placeholder="Email ứng viên"
                      size="sm"
                      _placeholder={{ opacity: 1, color: labelColor }}
                      _hover={{ borderColor: "none" }}
                      style={{ cursor: "pointer" }}
                      color={labelColor}
                      borderRadius="5px"
                      {...register("candidateEmail", {
                        required: "Vui lòng nhập email của ứng viên",
                      })}
                      name="candidateEmail"
                    />
                    <ErrorMessage error={errors.candidateEmail} />
                  </FormControl>

                  <FormControl>
                    <FormLabel>Chọn mẫu email</FormLabel>

                    <Select
                      {...register("emailTemplate")}
                      placeholder="Chọn mẫu email"
                      name="emailTemplate"
                      w="50%"
                      onChange={(e) => onChangeEmailTemplate(e)}
                      size="sm"
                    >
                      {listEmailTemplate.map(
                        (emailTemplate: IEmailTemplate) => (
                          <option
                            value={emailTemplate?.id}
                            key={emailTemplate?.id}
                          >
                            {emailTemplate?.name}
                          </option>
                        )
                      )}
                    </Select>
                  </FormControl>

                  <FormControl>
                    <FormLabel>Tiêu đề</FormLabel>
                    <Input
                      placeholder="Tiêu đề của email"
                      size="sm"
                      _placeholder={{ opacity: 1, color: labelColor }}
                      _hover={{ borderColor: "none" }}
                      style={{ cursor: "pointer" }}
                      color={textColor}
                      borderRadius="5px"
                      {...register("titleEmail", {
                        required: "Vui lòng nhập title của email",
                      })}
                      name="titleEmail"
                    />

                    <ErrorMessage error={errors.titleEmail} />
                  </FormControl>

                  <FormControl>
                    <FormLabel>Nội dung</FormLabel>
                    {!isPreviewEmailOpen && (
                      <TextEditor
                        textValue={textValue}
                        onChange={setTextValue}
                      />
                    )}
                    <ErrorMessage error={textEditorError} />
                  </FormControl>

                  <FormControl>
                    <FormLabel>File đính kèm</FormLabel>
                    <Input
                      multiple={true}
                      type="file"
                      w="50%"
                      {...register("attachFiles")}
                      name="attachFiles"
                    />
                  </FormControl>
                </Flex>
              </form>

              <Flex ml="auto" mt="10px" gap="10px">
                <Button border="1px solid #a09c9c" onClick={onCloseModal}>
                  Huỷ
                </Button>

                <Button
                  border="1px solid #a09c9c"
                  onClick={handleOpenPreviewEmail}
                >
                  Xem trước
                </Button>
                <Button
                  colorScheme="orange"
                  borderRadius="8px"
                  width="100px"
                  onClick={handleSubmit(sendMailForCandidate)}
                  isLoading={isLoadingButton}
                >
                  Gửi
                </Button>
              </Flex>
            </Card>
          </ModalBody>
        </ModalContent>
      </Modal>

      {isPreviewEmailOpen && (
        <Modal
          isOpen={isPreviewEmailOpen}
          onClose={onClosePreviewEmail}
          size="2xl"
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Xem trước nội dung email</ModalHeader>
            <ModalBody p="10px 15px">
              <Card>
                <TextEditor
                  textValue={{ value: previewEmail }}
                  disable={true}
                  onChange={() => {}}
                />

                <Flex mt="10px">
                  <Button
                    border="1px solid #a09c9c"
                    onClick={onClosePreviewEmail}
                    ml="auto"
                  >
                    Đóng
                  </Button>
                </Flex>
              </Card>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default SendEmailCandidateModal;
