import { Flex, Text, Tooltip } from "@chakra-ui/react";
import {
  CV_STATUS,
  MASTER_DATA_TYPE,
  statusColumnConstants,
} from "common/constants";
import { formatDateWithFormatType, truncateString } from "common/utils";
import Card from "components/card/Card";
import {
  ICandidate,
  IMasterData,
  IStatusColumnConstants,
} from "types/interface";

const candidateApplyInfomationMapping = {
  jobname: "Vị trí ứng tuyển",
  title: "Tiêu đề",
  created_at: "Ngày ứng tuyển",
  status: "Trạng thái",
  test_at: "Ngày làm bài test",
  user_assigned_name: "Người phỏng vấn",
  interview_time: "Ngày phỏng vấn",
  team_name: "Studio",
  created_by_user: "Tạo bởi",
  updated_at: "Cập nhật lần cuối",
  updated_by_user: "Cập nhật bởi",
  reason_fail: "Lí do loại",
};

interface CandidateApplyInfoProps {
  candidateInfo: ICandidate;
  masterData: IMasterData[];
}

const CandidateApplyInfo = (props: CandidateApplyInfoProps) => {
  const { candidateInfo, masterData } = props;

  const infoStyle = {
    alignItems: "center",
    gap: "5px",
  };

  const findStatusNameByStatus = (status: string) => {
    return statusColumnConstants.find(
      (item: IStatusColumnConstants) => item.status === status
    )?.columnName;
  };

  const renderInfomationRow = () => {
    return Object.entries(candidateApplyInfomationMapping).map(
      ([key, value]: [string, any]) => {
        if (key === "status") {
          return (
            <Flex key={key} {...infoStyle}>
              <Text fontWeight="bold">{value}:</Text>
              <Text>{findStatusNameByStatus(candidateInfo[key])}</Text>
            </Flex>
          );
        }

        if (key === "reason_fail") {
          const listReasonFail = masterData.filter(
            (data: IMasterData) => data.type === MASTER_DATA_TYPE.REASON_FAIL
          );

          return (
            <>
              {candidateInfo?.status === CV_STATUS.FAIL && (
                <Flex color="red" key={key} {...infoStyle}>
                  <Text fontWeight="bold">{value}: </Text>
                  <Text>
                    {listReasonFail
                      .filter((reason: IMasterData) =>
                        candidateInfo[key]
                          ?.split(",")
                          .includes(reason.id.toString())
                      )
                      ?.map((reasonFail) => reasonFail?.name)
                      ?.join(", ")}
                  </Text>
                </Flex>
              )}
            </>
          );
        }

        if (key === "title") {
          return (
            <>
              <Tooltip label={candidateInfo[key]} placement="top" key={key}>
                <Flex {...infoStyle}>
                  <Text fontWeight="bold">{value}:</Text>
                  <Text>{truncateString(candidateInfo[key], 70)}</Text>
                </Flex>
              </Tooltip>
            </>
          );
        }

        if (["created_at", "test_at", "updated_at"].includes(key)) {
          return (
            <Flex key={key} {...infoStyle}>
              <Text fontWeight="bold">{value}:</Text>
              <Text>
                {candidateInfo?.[key as keyof ICandidate] &&
                  formatDateWithFormatType(
                    candidateInfo?.[key as keyof ICandidate].toString(),
                    "HH:mm:ss DD/MM/YYYY"
                  )}
              </Text>
            </Flex>
          );
        }

        return (
          <Flex key={key} {...infoStyle}>
            <Text fontWeight="bold">{value}:</Text>
            <Text>{(candidateInfo as any)[key]}</Text>
          </Flex>
        );
      }
    );
  };

  return (
    <>
      <Card d="flex" gap="10px" p="20px 0">
        {renderInfomationRow()}
      </Card>
    </>
  );
};

export default CandidateApplyInfo;
