import { Box, Flex, Spinner, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { reportService } from "services/Report/ReportService";
import { STATUS_NAME } from "common/constants";
import { convertHoursToDaysAndHours } from "./DataFilterFunction";
import { toast } from "react-toastify";

type Props = {
  filterData: any;
};

const AverageTimePerRoundChart = ({ filterData }: Props) => {
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  const getData = async () => {
    try {
      setLoading(true);
      const headers = {};
      const params = {
        fromDate: "",
        toDate: "",
      };
      if (filterData.fromDate) params["fromDate"] = filterData.fromDate;
      if (filterData.toDate) params["toDate"] = filterData.toDate;

      const response = await reportService.getCandidateHistory(params, headers);
      if (response.data) {
        const listStatus = Object.keys(STATUS_NAME);
        const removeStatuses = ["reject", "draft", "deleted"];

        const statusOrderMap = listStatus.reduce(
          (map: any, status: any, index: any) => {
            map[status.toLowerCase()] = index;
            return map;
          },
          {}
        );
        const sortedData = response.data.sort((a: any, b: any) => {
          return statusOrderMap[a.status] - statusOrderMap[b.status];
        });
        const filteredData = sortedData.filter(
          (item: any) => !removeStatuses.includes(item.status)
        );
        setData(filteredData);
      }
    } catch (error) {
      toast.error("Lỗi khi lấy dữ liệu Average Time Per Round");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (filterData.fromDate && filterData.toDate) {
      getData();
    }
  }, [filterData]);
  return (
    <>
      {loading && <Spinner />}
      {!loading && data.length === 0 && (
        <Box className="chart_empty" minH={"5vh"}>
          Không có dữ liệu
        </Box>
      )}
      {!loading && data.length > 0 && (
        <Flex
          flexDirection={"column"}
          px={5}
          mt={{ sm: 0, md: 1 }}
          gap={1}
          minH="10vh"
          width={"40vw"}
          alignItems="center"
        >
          {data.map((item: any, index: any) => (
            <Flex
              key={index}
              width={"50%"}
              justifyContent="space-between"
              borderBottom={"1px solid orange"}
              pb={2}
              px={4}
              fontSize="sm"
            >
              <Text>
                {item.status &&
                  STATUS_NAME[
                    item.status.toUpperCase() as keyof typeof STATUS_NAME
                  ]}
              </Text>
              <Text>{convertHoursToDaysAndHours(item.time / item.count)}</Text>
            </Flex>
          ))}
        </Flex>
      )}
    </>
  );
};

export default AverageTimePerRoundChart;
