import {
  Box,
  Button,
  Flex,
  Link,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Tooltip,
} from "@chakra-ui/react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SkeletonCol from "../roleAccessPage/components/SkeletonCol";

import { cvTableServices } from "./CvTableService";

import { usePagination } from "@ajna/pagination";

import CustomPagination from "components/common/Pagination";
import { useEffect, useState } from "react";
import { HiPlus } from "react-icons/hi";
import { useHistory } from "react-router-dom";
import UpdateJobInfo from "../jobManage/UpdateJobInfo";
import CvIcon from "./Icon";

export default function CvTable() {
  const history = useHistory();
  const outerLimit = 2;
  const innerLimit = 2;
  let field = "";
  let order = "";
  const [showUpdateJobModal, setShowUpdateJobModal] = useState(false);
  const [jobData, setJobData] = useState([]);
  const [isShowData, setIsShowData] = useState(false);
  const [isProgress, setIsProgress] = useState(true);
  const [numberJobClick, setNumberJobClick] = useState(0);
  const [jobClick, setJobClick] = useState(false);
  const [numberStatusClick, setNumberStatusClick] = useState(0);
  const [statusClick, setStatusClick] = useState(false);
  const [numberCvClick, setNumberCvClick] = useState(0);
  const [cvClick, setCvClick] = useState(false);
  const [total, setTotal] = useState<number | undefined>(undefined);
  const {
    pages,
    pagesCount,
    currentPage,
    setCurrentPage,
    pageSize,
    setPageSize,
  } = usePagination({
    total: total,
    limits: {
      outer: outerLimit,
      inner: innerLimit,
    },
    initialState: {
      pageSize: 10,
      currentPage: 1,
    },
  });

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textHover = useColorModeValue(
    { color: "secondaryGray.600", bg: "unset" },
    { color: "secondaryGray.900", bg: "unset" }
  );
  const bgColor = useColorModeValue("white", "navy.800");

  const handleJobClick = (itemId: any) => {
    history.push({
      pathname: "/admin/jobmanageinfo_detail",
      search: `?id=${itemId.id}`,
      state: itemId,
    });
  };

  const handlePageChange = (nextPage: number): void => {
    setCurrentPage(nextPage);
  };

  const handleHeaderStatusClick = (): void => {
    setNumberJobClick(numberStatusClick + 1);
    if ((numberStatusClick + 1) % 3 === 0) {
      field = "";
      order = "";
    }
    if ((numberStatusClick + 1) % 3 === 1) {
      field = "status";
      order = "asc";
    }
    if ((numberStatusClick + 1) % 3 === 2) {
      field = "status";
      order = "desc";
    }
    setNumberCvClick(0);
    setNumberJobClick(0);
    setJobClick(false);
    setCvClick(false);
    setStatusClick(true);
    getJobInfo();
  };

  const handleHeaderJobClick = (): void => {
    setNumberJobClick(numberJobClick + 1);
    if ((numberJobClick + 1) % 3 === 0) {
      field = "";
      order = "";
    }
    if ((numberJobClick + 1) % 3 === 1) {
      field = "jobname";
      order = "asc";
    }
    if ((numberJobClick + 1) % 3 === 2) {
      field = "jobname";
      order = "desc";
    }
    setNumberCvClick(0);
    setNumberStatusClick(0);
    setJobClick(true);
    setCvClick(false);
    setStatusClick(false);
    getJobInfo();
  };

  const handleHeaderCvClick = (): void => {
    setNumberCvClick(numberCvClick + 1);
    if ((numberCvClick + 1) % 3 === 0) {
      field = "";
      order = "";
    }
    if ((numberCvClick + 1) % 3 === 1) {
      field = "total_apply_candidate";
      order = "asc";
    }
    if ((numberCvClick + 1) % 3 === 2) {
      field = "total_apply_candidate";
      order = "desc";
    }
    setNumberJobClick(0);
    setNumberStatusClick(0);
    setJobClick(false);
    setCvClick(true);
    setStatusClick(false);
    getJobInfo();
  };

  const getJobInfo = () => {
    setIsProgress(true);
    setIsShowData(false);
    async function getData() {
      const header = {
        page: currentPage - 1,
        size: pageSize,
        field: field || "jobName",
        order: order || "ASC",
      };

      try {
        const response = await cvTableServices.getJobData("", header);

        if (response?.data?.data) {
          setIsShowData(true);
          setJobData(response.data.data);
          setTotal(response.data.totalItems);
        } else {
          setIsShowData(false);
          setJobData([]);
          setTotal(0);
        }
      } catch (error) {
        toast.error("Network error");
      } finally {
        setIsProgress(false);
        setIsShowData(true);
      }
    }
    getData();
  };

  useEffect(() => {
    getJobInfo();
  }, [currentPage, pageSize]);

  return (
    <Flex flexDirection="column" gap={1} w="full" h="full">
      <Flex
        px={4}
        pb={2}
        justifyContent="space-between"
        align="center"
        borderBottom={"1px solid #f5932a"}
        gap={2}
      >
        <Text
          color={textColor}
          fontSize="xl"
          fontWeight="700"
          lineHeight="100%"
        >
          Recruitment Table
        </Text>
        <Tooltip label="Tạo công việc mới" placement="top">
          <Button
            colorScheme="orange"
            px={0}
            borderRadius={"50%"}
            color="white"
            onClick={() => {
              setShowUpdateJobModal(true);
            }}
          >
            <HiPlus size={20} />
          </Button>
        </Tooltip>
      </Flex>

      <Box overflowY="auto" width={"100%"} h={"85%"} mb={0}>
        <Table variant="simple" size={"lg"} mb="24px" colorScheme="orange">
          <Thead position="sticky" top={0} bgColor={bgColor} zIndex="docked">
            <Tr>
              <Th
                onClick={handleHeaderJobClick}
                cursor="pointer"
                textColor={textColor}
              >
                <Flex
                  align="center"
                  fontSize={{ sm: "10px", lg: "14px" }}
                  fontWeight={"700"}
                >
                  Tên công việc
                  <CvIcon clickNumber={numberJobClick} isShow={jobClick} />
                </Flex>
              </Th>
              <Th onClick={handleHeaderCvClick} cursor="pointer">
                <Flex
                  align="center"
                  fontSize={{ sm: "10px", lg: "14px" }}
                  fontWeight={"700"}
                >
                  Số lượng cv
                  <CvIcon clickNumber={numberCvClick} isShow={cvClick} />
                </Flex>
              </Th>
              <Th onClick={handleHeaderStatusClick} cursor="pointer">
                <Flex
                  align="center"
                  fontSize={{ sm: "10px", lg: "14px" }}
                  fontWeight={"700"}
                >
                  Trạng thái
                  <CvIcon
                    clickNumber={numberStatusClick}
                    isShow={statusClick}
                  />
                </Flex>
              </Th>
            </Tr>
          </Thead>
          {isShowData && !isProgress ? (
            <Tbody>
              {jobData.map((item: any, index: any) => (
                <Tr key={index}>
                  <Td
                    _hover={textHover}
                    key={index + item.jobname}
                    fontSize={{ sm: "14px" }}
                    minW={{ sm: "150px", md: "200px", lg: "auto" }}
                    textColor={textColor}
                    onClick={() => handleJobClick(item)}
                  >
                    <Link>{item.jobname}</Link>
                  </Td>
                  <Td
                    key={index + item.total_apply_candidate}
                    fontSize={{ sm: "14px" }}
                    minW={{ sm: "150px", md: "200px", lg: "auto" }}
                    textColor={textColor}
                  >
                    {item.total_apply_candidate}
                  </Td>
                  <Td
                    key={index + item.status}
                    fontSize={{ sm: "14px" }}
                    minW={{ sm: "150px", md: "200px", lg: "auto" }}
                    textColor={textColor}
                  >
                    {item.status}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          ) : (
            <SkeletonCol col={3} />
          )}
        </Table>
      </Box>

      <Box pt={2}>
        {!isProgress && jobData.length > 0 && (
          <CustomPagination
            pages={pages}
            pagesCount={pagesCount}
            currentPage={currentPage}
            onPageChange={handlePageChange}
            pageSize={pageSize}
            onPageSizeChange={setPageSize}
            optionPageSize={[10, 20, 50]}
          />
        )}
      </Box>

      {isShowData && !isProgress && jobData.length === 0 && (
        <Flex height="300px" justify="center" align="center">
          <Text
            color={textColor}
            fontFamily="Roboto Slab"
            fontSize="22px"
            fontWeight="700"
            lineHeight="100%"
          >
            Không có dữ liệu
          </Text>
        </Flex>
      )}

      <UpdateJobInfo
        show={showUpdateJobModal}
        setShow={setShowUpdateJobModal}
        getJobInfo={getJobInfo}
      />
    </Flex>
  );
}
