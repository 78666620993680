// import { storage_name } from "@/common/constants";
import axios from "axios";
import { API_URL } from "config/environment";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { TokenStorageService } from "./TokenServices";
const instance = axios.create();
const token = localStorage.getItem("access_token");
export class ApiServices {
  base_url = API_URL;
  sign_in_url = this.base_url + "/auth/login";
  google_sign_in_url = this.base_url + "/auth/social-login";
  sign_out_url = this.base_url + "/auth/logout";

  constructor() {
    instance.interceptors.request.use(
      (config) => {
        if (token) {
          config.headers["Authorization"] = "Bearer " + token;
        }
        config.headers["Content-Type"] = "application/json";
        // config.headers['Content-Type'] = 'application/json';
        return config;
      },
      (error) => {
        Promise.reject(error);
      }
    );
    instance.interceptors.response.use(
      (response) => {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data

        return response;
      },
      (error) => {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        switch (error.response?.status) {
          case 401:
            this.clearLocalStorage();
            window.location.reload();
            break;
          case 500:
            // Handle 500 here
            // redirect
            break;
          // and so on..
        }
        return Promise.reject(error);
      }
    );
    instance.interceptors.response.use(
      (res) => res,
      (err) => {
        throw new Error(err);
      }
    );
  }

  private customHeader(
    page: number = 0,
    size: number = 10,
    field: string = "",
    order: string = ""
  ) {
    let headers: any = {};
    headers.page = page;
    headers.size = size;
    headers.field = field;
    headers.order = order;
    return { ...headers };
  }

  public async getRequest(url: string, params: any, headers?: any) {
    try {
      return await instance.get(url, {
        params,
        headers: this.customHeader(
          headers.page,
          headers.size,
          headers.field,
          headers.order
        ),
      });
    } catch (error) {
      console.log(error);
      toast.error("Network error");
    }
  }

  public async postRequest(url: string, params: any, body: any, headers?: any) {
    try {
      return await instance.post(url, body, {
        params,
        headers: this.customHeader(
          headers?.page,
          headers?.size,
          headers?.field,
          headers?.order
        ),
      });
    } catch (error) {
      console.log(error);
      toast.error("Network error");
    }
  }

  public async postFileRequest(
    url: string,
    params: any,
    body: any,
    headers?: any
  ) {
    try {
      let bodyFormData = new FormData();
      for (let i = 0; i < body.length; i++) {
        bodyFormData.append("files", body[i]);
      }
      return await axios.post(url, bodyFormData, {
        params,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + token,
        },
      });
    } catch (error) {
      console.log(error);
      toast.error("Network error");
    }
  }

  public async postFileRequestWithFile(
    url: string,
    params: any,
    body: any,
    header?: any
  ) {
    try {
      return await axios.post(url, body, {
        params,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + token,
        },
      });
    } catch (error) {
      console.log(error);
      toast.error("Network error");
    }
  }

  public async putRequest(url: string, params: any, body: any, headers?: any) {
    try {
      return await instance.put(url, body, {
        params,
        headers: this.customHeader(
          headers.page,
          headers.size,
          headers.field,
          headers.order
        ),
      });
    } catch (error) {
      console.log(error);
      toast.error("Network error");
    }
  }

  public async patchRequest(
    url: string,
    params: any,
    body: any,
    headers?: any
  ) {
    try {
      return await instance.patch(url, body, {
        params,
        headers: this.customHeader(
          headers.page,
          headers.size,
          headers.field,
          headers.order
        ),
      });
    } catch (error) {
      console.log(error);
      toast.error("Network error");
    }
  }

  public async deleteRequest(url: string, params: any, headers?: any) {
    try {
      return await instance.delete(url, {
        params,
        headers: this.customHeader(
          headers.page,
          headers.size,
          headers.field,
          headers.order
        ),
      });
    } catch (error) {
      console.log(error);
      toast.error("Network error");
    }
  }

  public async signInRequest(body: any) {
    try {
      const response = await instance.post(this.sign_in_url, body);
      if (response) {
        if (response.data) {
          this.setLocalStorage(response.data);
        }
        return true;
      }
      return false;
    } catch (error) {
      console.log(error);
      toast.error("Network error");
    }
  }

  public async googleSignInRequest(body: any) {
    try {
      const response = await instance.post(this.google_sign_in_url, body);
      if (response) {
        if (response.data) {
          this.setLocalStorage(response.data);
        }
        return true;
      }
      return false;
    } catch (error) {
      console.log(error);
      toast.error("Network error");
    }
  }

  public async signOutRequest() {
    try {
      let res = await instance.post(
        this.sign_out_url,
        {},
        {
          headers: this.customHeader(),
        }
      );
      if (res) {
        return true;
      }
      return false;
    } catch (error) {
      console.log(error);
      toast.error("Network error");
    }
  }

  public setLocalStorage(data: any) {
    let { access_token, role, roleId } = data;
    const _token = new TokenStorageService();
    localStorage.setItem("roleId", roleId);
    localStorage.setItem("access_token", access_token);
    localStorage.setItem("roles", JSON.stringify(role));
    localStorage.setItem("roles", _token.encode(localStorage.getItem("roles")));
  }

  public clearLocalStorage() {
    localStorage.removeItem("roleId");
    localStorage.removeItem("access_token");
    localStorage.removeItem("roles");
  }
}
export default ApiServices;
