import {
  Box,
  Button,
  Flex,
  Progress,
  SimpleGrid,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { statusColumnConstants } from "common/constants";
import Card from "components/card/Card";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { jobManageService } from "./JobManageService";
import UpdateJobInfo from "./UpdateJobInfo";

export interface JobInfo {
  jobname: string;
  start_date: string;
  end_date: string;
  total_apply_candidate: number;
  description: string;
  detail: [any];
  created_at: string;
  status: string;
}

export default function JobDetail(data: any) {
  const id = data.location.search.split("=")[1];
  const history = useHistory();
  const [jobinfo, setJobInfo] = useState<JobInfo>();
  const [isProgress, setIsProgress] = useState(true);

  const [showUpdateJobModal, setShowUpdateJobModal] = useState(false);
  const [dataUpdate, setDataUpdate] = useState({});

  const textColor = useColorModeValue("secondaryGray.900", "white");

  const getJobInfo = () => {
    setIsProgress(true);
    async function getData() {
      const header = {
        page: 0,
        size: 1000,
      };
      const param = {
        id: id,
      };

      try {
        const response = await jobManageService.getJobById(param, header);

        if (response?.data) {
          const { data } = response;

          setJobInfo(data);
        } else {
          setJobInfo(null);
        }
      } catch (error) {
        toast.error("Network error");
      } finally {
        setIsProgress(false);
      }
    }
    getData();
  };

  const handleUpdateJobInfo = () => {
    setShowUpdateJobModal(true);
    setDataUpdate(jobinfo);
  };
  const resetData = () => {
    setDataUpdate({});
  };

  const handleViewJobInfoEachStatus = (jobinfo: any) => {
    history.push({
      pathname: "/admin/cvmanage",
      search: `?job_ids=${jobinfo.id}`,
      state: jobinfo,
    });
  };

  useEffect(() => {
    getJobInfo();
  }, []);

  return (
    <Box overflowX="auto">
      <SimpleGrid>
        {!isProgress && (
          <Flex gap={"100px"}>
            <Card flexDirection="column" w="full" pb={3}>
              <Flex
                px="25px"
                mb="1rem "
                justifyContent="space-between"
                align="center"
              >
                <Text
                  color={textColor}
                  fontSize="1.5rem"
                  fontWeight="300"
                  lineHeight="100%"
                >
                  Tên công việc :{" "}
                  <span style={{ fontWeight: "700" }}>{jobinfo?.jobname}</span>
                </Text>
              </Flex>
              <Flex
                px="25px"
                mb="1rem "
                justifyContent="space-between"
                align="center"
              >
                <Text
                  color={textColor}
                  fontSize="1.3rem"
                  fontWeight="300"
                  lineHeight="100%"
                >
                  Số lượng CV:{" "}
                  <span style={{ fontWeight: "700" }}>
                    {jobinfo?.total_apply_candidate}
                  </span>
                </Text>
              </Flex>
              <Flex
                px="25px"
                mb="1rem "
                justifyContent="space-between"
                align="center"
              >
                <Text
                  color={textColor}
                  fontSize="1rem"
                  fontWeight="300"
                  lineHeight="100%"
                >
                  Ngày bắt đầu:{" "}
                  <span style={{ fontWeight: "700" }}>
                    {jobinfo?.start_date}
                  </span>
                </Text>
              </Flex>
              <Flex
                px="25px"
                mb="1rem "
                justifyContent="space-between"
                align="center"
              >
                <Text
                  color={textColor}
                  fontSize="1rem"
                  fontWeight="300"
                  lineHeight="100%"
                >
                  Ngày kết thúc:{" "}
                  <span style={{ fontWeight: "700" }}>{jobinfo?.end_date}</span>
                </Text>
              </Flex>
              <Flex
                px="25px"
                mb="1rem "
                justifyContent="space-between"
                align="center"
              >
                <Text
                  color={textColor}
                  fontSize="1rem"
                  fontWeight="300"
                  lineHeight="100%"
                >
                  Trạng thái:{" "}
                  <span style={{ fontWeight: "700" }}>{jobinfo?.status}</span>
                </Text>
              </Flex>
              <Flex
                px="25px"
                mb="1rem "
                justifyContent="space-between"
                align="center"
              >
                <Text
                  color={textColor}
                  fontSize="1rem"
                  fontWeight="300"
                  lineHeight="100%"
                  whiteSpace="break-spaces"
                >
                  Mô tả công việc: <br />
                  {jobinfo?.description}
                </Text>
              </Flex>
            </Card>
            <Card flexDirection="column" w="full" pb={3}>
              <Flex
                px="25px"
                mb="1rem "
                justifyContent="space-between"
                align="center"
              >
                <Text
                  color={textColor}
                  fontSize="1.5rem"
                  fontWeight="300"
                  lineHeight="100%"
                >
                  Số lượng CV theo từng trạng thái:
                </Text>
              </Flex>

              {statusColumnConstants.map((status, index) => (
                <>
                  <Flex
                    key={`cv-status-${index}`}
                    px="25px"
                    mb="1rem "
                    align="center"
                    fontWeight="500"
                    gap="5px"
                  >
                    <Text color={textColor} fontSize="1rem" lineHeight="100%">
                      {status.columnName} :{" "}
                    </Text>

                    <Text>
                      {jobinfo.detail.find(
                        (item: any) => item.status === status.status
                      )
                        ? jobinfo.detail.find(
                            (item: any) => item.status === status.status
                          ).total
                        : 0}
                    </Text>
                  </Flex>
                </>
              ))}
            </Card>
          </Flex>
        )}

        {isProgress && (
          <Flex height="710px" justify="center">
            <Progress size="xs" w="100%" mx={5} isIndeterminate />
          </Flex>
        )}
      </SimpleGrid>

      <Button
        margin="20px"
        padding="25px"
        _hover={{ backgroundColor: "#C0C0C0" }}
        colorScheme="pink"
        variant="outline"
        onClick={() => handleUpdateJobInfo()}
      >
        Chỉnh sửa
      </Button>

      <Button
        margin="20px"
        padding="25px"
        _hover={{ backgroundColor: "#C0C0C0" }}
        colorScheme="pink"
        variant="outline"
        onClick={() => handleViewJobInfoEachStatus(jobinfo)}
      >
        Xem chi tiết
      </Button>
      <UpdateJobInfo
        show={showUpdateJobModal}
        setShow={setShowUpdateJobModal}
        dataUpdate={dataUpdate}
        resetData={resetData}
        getJobInfo={getJobInfo}
      />
    </Box>
  );
}
