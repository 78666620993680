import { usePagination } from "@ajna/pagination";
import { SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  FormControl,
  Input,
  InputGroup,
  InputRightAddon,
  InputRightElement,
  Spinner,
  Text,
  Tooltip,
  useColorModeValue,
} from "@chakra-ui/react";
import { CV_STATUS, DEFAULT_PAGE_SIZE, HTTP_CODE } from "common/constants";
import Card from "components/card/Card";
import CustomPagination from "components/common/Pagination";
import { CircleAlertIcon, UnFilterIcon } from "components/icons/Icons";
import { useToggle } from "hook/useToggle";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { cvManageServices } from "views/admin/default/components/cvManage/CvManageServices";
import DraftCVTable from "views/admin/default/components/DraftCV/DraftCVTable";

const DraftCV = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [listDraftCv, setListDraftCv] = useState<any[]>([]);
  const [searchQueryDraftCV, setSearchQueryDraftCV] = useState<any>({});

  const [componentShouleBeRefresh, setComponentShouleBeRefresh] =
    useToggle(false);

  // pagination table
  const [totalData, setTotalData] = useState<number | undefined>(undefined);
  const outerLimit = 2;
  const innerLimit = 2;

  const {
    pages,
    pagesCount,
    currentPage,
    setCurrentPage,
    pageSize,
    setPageSize,
  } = usePagination({
    total: totalData,
    initialState: {
      pageSize: DEFAULT_PAGE_SIZE,
      currentPage: 1,
    },
    limits: {
      outer: outerLimit,
      inner: innerLimit,
    },
  });

  const { register, handleSubmit, reset: resetFormValue } = useForm();

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const focusBorderColor = useColorModeValue("secondaryGray.500", "white");

  const handlePageChange = (nextPage: number): void => {
    setCurrentPage(nextPage);
  };

  const onChangePageSize = (pageSize: number) => {
    setPageSize(pageSize);
    setCurrentPage(1);
  };

  const getDraftCvData = async () => {
    try {
      const header = {
        page: currentPage - 1,
        size: pageSize,
      };

      const params = {
        status: CV_STATUS.DRAFT,
        multipleQuery: searchQueryDraftCV?.multipleQuery,
        cv_url_status: searchQueryDraftCV?.cv_url_status,
      };

      const draftCvRes = await cvManageServices.getCvData(params, header);

      if (draftCvRes?.status === HTTP_CODE.SUCCESS) {
        setListDraftCv(draftCvRes?.data?.data);
        setTotalData(draftCvRes?.data?.totalItems);
      }
    } catch (error) {
      setListDraftCv([]);
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  const onChangeFiltermultipleQuery = (e: any) => {
    setSearchQueryDraftCV({
      ...searchQueryDraftCV,
      multipleQuery: e.target.value,
    });
  };

  const onFilterDraftCv = () => {
    setCurrentPage(1);
    setComponentShouleBeRefresh();
  };

  const onClearFilter = () => {
    setSearchQueryDraftCV({});
    resetFormValue();
    setComponentShouleBeRefresh();
  };

  useEffect(() => {
    getDraftCvData();
  }, [currentPage, pageSize, componentShouleBeRefresh]);

  return (
    <Box>
      <Card
        flexDirection="column"
        w="100%"
        overflowX={{ sm: "scroll", lg: "hidden" }}
        minHeight="80vh"
      >
        <Flex mb="8px" justifyContent="space-between" align="center">
          <Text
            color={textColor}
            fontSize="22px"
            fontWeight="700"
            lineHeight="100%"
          >
            Draft CV
          </Text>

          <form>
            <Flex
              gap="10px"
              marginLeft="auto"
              maxHeight="32px"
              alignItems="center"
            >
              <FormControl>
                <InputGroup>
                  <Input
                    placeholder="Search ..."
                    size="sm"
                    _placeholder={{ opacity: 1, color: textColor }}
                    color={textColor}
                    {...register("searchCandidate")}
                    _focus={{ borderColor: focusBorderColor }}
                    w="170px"
                    onChange={(e) => onChangeFiltermultipleQuery(e)}
                  />

                  <InputRightElement
                    maxHeight="32px"
                    _hover={{ cursor: "text" }}
                  >
                    <Tooltip
                      label="Tìm kiếm theo tên ứng viên, title hoặc email"
                      placement="top"
                    >
                      <CircleAlertIcon fontSize="30px" />
                    </Tooltip>
                  </InputRightElement>
                </InputGroup>
              </FormControl>

              <FormControl>
                <Button
                  colorScheme="blue"
                  p={0}
                  borderRadius={"50%"}
                  ml="auto"
                  onClick={handleSubmit(onFilterDraftCv)}
                  type="submit"
                >
                  <SearchIcon />
                </Button>
              </FormControl>

              <FormControl>
                <Tooltip label="Xóa filter" placement="top">
                  <Box
                    cursor="pointer"
                    _hover={{ color: "#C53030" }}
                    onClick={onClearFilter}
                  >
                    <UnFilterIcon fontSize="24px" />
                  </Box>
                </Tooltip>
              </FormControl>
            </Flex>
          </form>
        </Flex>

        <DraftCVTable
          listDraftCv={listDraftCv}
          isLoading={isLoading}
          setComponentShouleBeRefresh={setComponentShouleBeRefresh}
          searchQueryDraftCV={searchQueryDraftCV}
          setSearchQueryDraftCV={setSearchQueryDraftCV}
        />

        {isLoading ? (
          <Flex mt="40px" alignItems="center" justifyContent="center" h="400px">
            <Spinner size="lg" />
          </Flex>
        ) : (
          <>
            {listDraftCv?.length === 0 ? (
              <Flex
                alignItems="center"
                justifyContent="center"
                minWidth="100%"
                mt="15px"
                height="300px"
              >
                <Text
                  fontStyle="italic"
                  textAlign="center"
                  fontSize="22px"
                  fontWeight="bold"
                >
                  Không có dữ liệu
                </Text>
              </Flex>
            ) : (
              <>
                <Flex justifyContent="flex-end" p="0 10px">
                  <Text fontSize="14px" fontWeight="500" fontStyle="italic">
                    Có {totalData} dữ liệu
                  </Text>
                </Flex>
                <CustomPagination
                  pages={pages}
                  pagesCount={pagesCount}
                  currentPage={currentPage}
                  onPageChange={handlePageChange}
                  pageSize={pageSize}
                  onPageSizeChange={onChangePageSize}
                  optionPageSize={[10, 20, 50]}
                />
              </>
            )}
          </>
        )}
      </Card>
    </Box>
  );
};

export default DraftCV;
