import { useEffect, useState } from "react";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  Heading,
  Text,
} from "@chakra-ui/react";
import _ from "lodash";
import CompetenceItem from "./CompetencesItem";
import { IEvaluationCriteria, IEvaluationCriteriaData } from "types/interface";
import { ChevronUpIcon } from "@chakra-ui/icons";

interface ListCompetenceProps {
  evaluationCriteriaData?: IEvaluationCriteriaData[];
}

const ListCompetence = (props: ListCompetenceProps) => {
  const { evaluationCriteriaData } = props;

  const [expandedIndex, setExpandedIndex] = useState([]);

  const togglePanel = (index: number[]) => {
    setExpandedIndex(index);
  };

  useEffect(() => {
    // get list index of accordion to expand all
    const expandedIndex = _.range(evaluationCriteriaData.length);
    setExpandedIndex(expandedIndex);
  }, [evaluationCriteriaData]);

  const ExpandAllAccordion = () => {
    const isCurrentExpand = expandedIndex?.length > 0;

    const toggleExpandAccordion = () => {
      if (isCurrentExpand) {
        setExpandedIndex([]);
      } else {
        const expandedIndex = _.range(evaluationCriteriaData.length);
        setExpandedIndex(expandedIndex);
      }
    };

    return (
      <Button
        colorScheme="blue"
        size="sm"
        onClick={toggleExpandAccordion}
        w="140px"
      >
        <Text>{isCurrentExpand ? "Đóng tất cả" : "Mở tất cả"}</Text>
        <ChevronUpIcon
          fontSize="20px"
          transform={` rotate(${isCurrentExpand ? "0" : "180deg"})`}
          transition="transform 0.2s "
        />
      </Button>
    );
  };

  return (
    <Box>
      <Flex justifyContent="flex-end">
        <ExpandAllAccordion />
      </Flex>

      <Accordion
        allowMultiple
        _focus={{ border: "none", boxShadow: "none" }}
        mt="10px"
        border="1px solid #E9EDF7"
        index={expandedIndex}
        onChange={togglePanel}
      >
        {evaluationCriteriaData.map((item: IEvaluationCriteriaData) => (
          <AccordionItem
            _focus={{ border: "none !important", boxShadow: "none !important" }}
            my="10px"
            key={item?.criteriaGroupId}
          >
            <AccordionButton
              _focus={{
                border: "none !important",
                boxShadow: "none !important",
              }}
              height="60px"
              d="flex"
              justifyContent="space-between"
              bg="secondaryGray.300"
            >
              <Heading size="md">{item?.name}</Heading>
              <AccordionIcon />
            </AccordionButton>

            <AccordionPanel p="0">
              {item?.data?.length === 0 ? (
                <Text
                  textAlign="center"
                  fontStyle="italic"
                  py="10px"
                  fontWeight="500"
                >
                  Chưa có tiêu chí nào
                </Text>
              ) : (
                item?.data?.map((criteria: IEvaluationCriteria) => (
                  <CompetenceItem criteria={criteria} key={criteria?.id} />
                ))
              )}
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </Box>
  );
};

export default ListCompetence;
