import { ApiServices } from "services/ApiServices";
import { API_URL } from "config/environment";
import { API_URL_PATH } from "common/constants";

const base_url = API_URL + API_URL_PATH.CANDIDATE_NOTE;

export class CandidateNoteServices extends ApiServices {
  public async getCandidateNote(params: any, headers: any) {
    return await this.getRequest(base_url, params, headers);
  }

  public async createCandidateNote(body: any, headers: any) {
    return await this.postRequest(base_url, {}, body, headers);
  }

  public async updateCandidateNote(body: any, params: any, headers: any) {
    return await this.putRequest(base_url + `/${params.id}`, {}, body, headers);
  }
}

export const candidateNoteServices = new CandidateNoteServices();
