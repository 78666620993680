import ApiServices from "services/ApiServices";
import { API_URL } from "config/environment";

const base_url = API_URL + "/job-info";

export class CvTableServices extends ApiServices {
  public async getJobData(parasm: any, headers: any) {
    return await this.getRequest(base_url, parasm, headers);
  }
}

export const cvTableServices = new CvTableServices();
