import {
  Flex,
  Heading,
  SkeletonText,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import { useEffect, useState } from "react";
import VariableItem from "./VariableItem";
import { HTTP_CODE, UNLIMITED_PAGE_SIZE } from "common/constants";
import { IEmailTemplateVariable } from "types/interface";
import { emailTemplateServices } from "services/EmailTemplate/EmailTemplateServices";
import { EMAIL_TEMPLATES_ACTIVE_STATUS } from "common/constants";

const VariableList = () => {
  const bgColor = useColorModeValue("#f5ecec", "#082c50");
  const [listVariable, setListVariable] = useState<IEmailTemplateVariable[]>(
    []
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const getListVariableData = async () => {
    const header = {
      page: 0,
      size: UNLIMITED_PAGE_SIZE,
    };

    const params = {
      active: EMAIL_TEMPLATES_ACTIVE_STATUS.ACTIVE,
    };

    try {
      const getListVariableRes =
        await emailTemplateServices.getEmailTemplateVariables(params, header);
      if (getListVariableRes?.status === HTTP_CODE.SUCCESS) {
        setListVariable(getListVariableRes?.data?.data);
      }
    } catch (error) {
      setListVariable([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getListVariableData();
  }, []);

  return (
    <Card flex={0.25} borderRadius="none" bg={bgColor}>
      <Heading size="md">TEMPLATE VARIABLES</Heading>

      <Text color="green" mb="20px">
        Bạn có thể sử dụng biến trong Tiêu đề và Nội dung email, hệ thống sẽ tự
        động thay thế bằng thông tin tương ứng
      </Text>

      <Flex
        flexDirection="column"
        overflow="hidden"
        overflowY="auto"
        sx={{
          "&::-webkit-scrollbar": {
            width: "0px",
          },
        }}
      >
        {listVariable.map((variable: IEmailTemplateVariable) => (
          <VariableItem key={variable.id} variable={variable} bg={bgColor} />
        ))}

        {listVariable.length === 0 && !isLoading && (
          <Text
            textAlign="center"
            fontSize="20px"
            fontWeight="700"
            fontStyle="italic"
          >
            Chưa có variable
          </Text>
        )}

        {isLoading && (
          <SkeletonText mt="4" noOfLines={20} spacing="4" skeletonHeight="2" />
        )}
      </Flex>
    </Card>
  );
};

export default VariableList;
