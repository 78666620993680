import { ApiServices } from "services/ApiServices";
import { API_URL } from "config/environment";
import { API_URL_PATH } from "common/constants";

const base_url = API_URL + API_URL_PATH.USER;
const assign_interviewer_url = API_URL + API_URL_PATH.USER_ASSIGN;
const change_password_url = base_url + API_URL_PATH.CHANGE_PASSWORD;

export class UserServices extends ApiServices {
  public async getAllUser(params: any, headers: any) {
    return await this.getRequest(base_url, params, headers);
  }

  public async getUserById(params: any, headers: any) {
    return await this.getRequest(base_url + `/${params?.userId}`, {}, headers);
  }

  public async assignInterviewerToCandidate(
    body: any,
    params: any,
    headers: any
  ) {
    return await this.postRequest(
      assign_interviewer_url,
      params,
      body,
      headers
    );
  }

  public async getUserInterviewByCandidateId(params: any, headers: any) {
    return await this.getRequest(assign_interviewer_url, params, headers);
  }

  public async changeUserPassword(body: any) {
    return await this.putRequest(change_password_url, {}, body, {});
  }
}

export const userServices = new UserServices();
