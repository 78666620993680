import { Box, Flex, Text, Tooltip, useColorModeValue } from "@chakra-ui/react";
import { NavLink, useLocation } from "react-router-dom";
import { IRoute } from "types/interface";

interface SidebarItemProps {
  route: IRoute;
  isCollapsedSidebar: boolean;
  activePath: string;
}

const SidebarItem = (props: SidebarItemProps) => {
  const { route, isCollapsedSidebar, activePath } = props;

  const isActiveRoute = activePath === route.layout + route.path;

  const activeIconColor = useColorModeValue("brand.500", "white");
  const activeTextColor = useColorModeValue("gray.700", "white");
  const inActiveTextColor = useColorModeValue("secondaryGray.500", "white");

  return (
    <NavLink
      to={route.layout + route.path}
      style={{ cursor: "pointer", width: "100%", transition: "all 0.3s ease" }}
    >
      {!isCollapsedSidebar ? (
        <Box>
          <Flex
            w="100%"
            alignItems="center"
            justifyContent="center"
            background={isActiveRoute ? "brand.100" : ""}
            p="5px 10px"
          >
            <Box
              color={isActiveRoute ? activeIconColor : inActiveTextColor}
              me="18px"
              display="flex"
              alignItems="center"
            >
              {route.icon}
            </Box>

            <Text
              me="auto"
              color={isActiveRoute ? activeTextColor : inActiveTextColor}
              fontWeight={isActiveRoute ? "bold" : "normal"}
            >
              {route.name}
            </Text>
          </Flex>
        </Box>
      ) : (
        <Tooltip label={route.name} placement="right">
          <Flex
            color={isActiveRoute ? activeIconColor : inActiveTextColor}
            alignItems="center"
            justifyContent="center"
            w="100%"
            background={isActiveRoute ? "brand.100" : ""}
            p="6px 8px"
            borderRadius={8}
            _hover={{ color: "brand.200" }}
          >
            {route.icon}
          </Flex>
        </Tooltip>
      )}
    </NavLink>
  );
};

export default SidebarItem;
