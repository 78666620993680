import { usePagination } from "@ajna/pagination";
import {
  Box,
  Button,
  Flex,
  FormControl,
  Input,
  Select,
  Text,
  Tooltip,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  DEFAULT_PAGE_SIZE,
  EMAIL_TEMPLATES_ACTIVE_STATUS,
  HTTP_CODE,
  INTERVIEW_STATUS,
  UNLIMITED_PAGE_SIZE,
} from "common/constants";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { userAssignServices } from "services/UserAssign/UserAssignServices";
import { IInterviewSchedule } from "types/interface";
import DashboardLayout from "../../layout";
import { useToggle } from "hook/useToggle";
import { UnFilterIcon } from "components/icons/Icons";
import { MdSearch } from "react-icons/md";
import InterviewCalendar from "./InterviewCalendar";
import dayjs from "dayjs";
import { getUsernameFromEmail } from "common/utils";
import { SearchIcon } from "@chakra-ui/icons";

interface IInterviewScheduleCalendarParams {
  candidate_name?: string;
  status?: number;
}
const InterviewScheduleCalendarPage = () => {
  const [listInterviewCalendar, setListInterviewCalendar] = useState<
    IInterviewSchedule[]
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const queryFilter = useRef<any>({});

  const {
    register,
    getValues,
    handleSubmit,
    reset: resetFormValue,
  } = useForm();

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const focusBorderColor = useColorModeValue("secondaryGray.500", "white");

  // pagination table
  const [totalData, setTotalData] = useState<number | undefined>(undefined);
  const [componentShouldBeRefresh, setComponentShouldBeRefresh] =
    useToggle(false);
  const outerLimit = 2;
  const innerLimit = 2;

  const { currentPage, setCurrentPage, pageSize } = usePagination({
    total: totalData,
    initialState: {
      pageSize: DEFAULT_PAGE_SIZE,
      currentPage: 1,
    },
    limits: {
      outer: outerLimit,
      inner: innerLimit,
    },
  });

  // call api to get list interview calendar
  const getInterviewScheduleCalendar = async (
    params?: IInterviewScheduleCalendarParams
  ) => {
    setIsLoading(true);

    const header = {
      page: currentPage - 1,
      size: UNLIMITED_PAGE_SIZE,
    };

    const interviewCalendarRes = await userAssignServices.getInterviewSchedule(
      params,
      header
    );

    if (interviewCalendarRes?.status === HTTP_CODE.SUCCESS) {
      const stateData = interviewCalendarRes?.data?.data.map(
        (item: IInterviewSchedule) => ({
          title: `${item.candidate_name} - ${
            item.job_applied_name
          }  - HR: ${getUsernameFromEmail(
            item.hr_name
          )} - Interviewer: ${getUsernameFromEmail(item.interviewer_name)}`,
          start: dayjs(item.start_time).utc().format("YYYY-MM-DD HH:mm:ss"),
          end: dayjs(item.end_time).utc().format("YYYY-MM-DD HH:mm:ss"),
          backgroundColor:
            item.status === EMAIL_TEMPLATES_ACTIVE_STATUS.ACTIVE
              ? "green"
              : "#2a9df5",
        })
      );

      setListInterviewCalendar(stateData);
      setTotalData(interviewCalendarRes?.data?.totalItems);
      setIsLoading(false);
      return;
    }

    setIsLoading(false);
    setListInterviewCalendar([]);
    setTotalData(0);
  };

  const onSearchCandidateByFilter = () => {
    const { searchCandidate, filterStatus } = getValues();

    if (searchCandidate) queryFilter.current.candidate_name = searchCandidate;
    if (filterStatus) queryFilter.current.status = parseInt(filterStatus);

    setCurrentPage(1);
    setComponentShouldBeRefresh();
  };

  const onClearFilter = () => {
    resetFormValue();
    getInterviewScheduleCalendar();
  };

  useEffect(() => {
    getInterviewScheduleCalendar(queryFilter.current);
  }, [pageSize, currentPage, componentShouldBeRefresh]);

  return (
    <DashboardLayout>
      <Flex
        flexDirection="column"
        w="full"
        gap={1}
        minHeight="80vh"
      >
        <Flex
          alignItems="center"
          justifyContent={"space-between"}
          gap={3}
          px={4}
          pb={2}
          borderBottom={"1px solid #f5932a"}
          w="full"
          h={"7%"}
        >
          <Flex>
            <Text fontSize="xl" fontWeight="bold">
              Interview Calendar
            </Text>
          </Flex>
          <Flex>
            <form>
              <Flex gap="10px" marginLeft="auto" alignItems="center">
                <FormControl>
                  <Input
                    placeholder="Tên ứng viên..."
                    _placeholder={{ opacity: 1, color: textColor }}
                    color={textColor}
                    {...register("searchCandidate")}
                    _focus={{ borderColor: focusBorderColor }}
                    w="200px"
                  />
                </FormControl>

                <FormControl>
                  <Select minWidth="200px" {...register("filterStatus")}>
                    <option value="">Tất cả trạng thái</option>
                    {INTERVIEW_STATUS.map((item, index) => (
                      <option value={item.value} key={index}>
                        {item.label}
                      </option>
                    ))}
                  </Select>
                </FormControl>

                <FormControl>
                  <Tooltip label="Tìm kiếm" placement="top">
                    <Button
                      p={0}
                      borderRadius={"50%"}
                      colorScheme="blue"
                      ml={"auto"}
                      onClick={handleSubmit(onSearchCandidateByFilter)}
                      type="submit"
                    >
                      <SearchIcon />
                    </Button>
                  </Tooltip>
                </FormControl>

                <FormControl>
                  <Tooltip label="Xóa filter" placement="top">
                    <Box
                      cursor="pointer"
                      _hover={{ color: "#C53030" }}
                      onClick={onClearFilter}
                    >
                      <UnFilterIcon fontSize="24px" />
                    </Box>
                  </Tooltip>
                </FormControl>
              </Flex>
            </form>
          </Flex>
        </Flex>

        <Flex gap="10px" w="100%" justifyContent="flex-end">
          <Flex alignItems="center" gap="1">
            <Box bgColor="#2a9df5" h="10px" w="20px"></Box>
            <Text>Chưa phỏng vấn</Text>
          </Flex>
          <Flex alignItems="center" gap="1">
            <Box bgColor="green" h="10px" w="20px"></Box>
            <Text>Đã phỏng vấn</Text>
          </Flex>
        </Flex>

        <Box mt={2} boxShadow={"sm"}>
          {!isLoading && (
            <InterviewCalendar listInterviewCalendar={listInterviewCalendar} />
          )}
        </Box>
      </Flex>
    </DashboardLayout>
  );
};

export default InterviewScheduleCalendarPage;
