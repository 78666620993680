import { ApiServices } from "services/ApiServices";
import { API_URL } from "config/environment";
import { API_URL_PATH } from "common/constants";

const base_url = API_URL + API_URL_PATH.MASTER_DATA;

export class MasterDataServices extends ApiServices {
  public async getMasterData(parasm: any, headers: any) {
    return await this.getRequest(base_url, parasm, headers);
  }

  public async createMasterData(body: any, headers: any) {
    return await this.postRequest(base_url, {}, body, headers);
  }

  public async updateMasterData(body: any, params: any, headers: any) {
    return await this.putRequest(
      base_url + `/${params.id}`,
      params,
      body,
      headers
    );
  }

  public async deleteMasterData(params: any) {
    return await this.deleteRequest(base_url + `/${params.id}`, params, {});
  }
}

export const masterDataService = new MasterDataServices();
