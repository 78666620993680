import { useContext } from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  Spinner,
  Text,
  Tooltip,
  useColorModeValue,
} from "@chakra-ui/react";
import { MdAddCircleOutline } from "react-icons/md";
import { useForm } from "react-hook-form";
import ListCompetence from "./ListCompetence";
import ErrorMessage from "components/common/ErrorFormMessage";
import Card from "components/card/Card";
import { evaluationCriteriaServices } from "services/EvaluationCriteria/EvaluationCriteriaServices";
import useOpenNotification from "hook/useOpenNotification";
import { CONVERTED_MESSAGE, HTTP_CODE } from "common/constants";
import { EvaluationCriteriaContext } from "..";
import { IEvaluationCriteriaData } from "types/interface";
import { HiPlus } from "react-icons/hi";

const EvaluationCriteriaList = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset: resetFormValue,
    getValues,
  } = useForm();

  const evaluationCriteriaContext = useContext(EvaluationCriteriaContext);
  const { isLoading, getEvaluationCriteriaData, evaluationCriteriaData } =
    evaluationCriteriaContext;

  const openNotification = useOpenNotification();

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const labelColor = useColorModeValue("secondaryGray.500", "white");

  const handleAddCompetence = async () => {
    const { competence, groupCompetences } = getValues();

    try {
      const createdCriteriaRes =
        await evaluationCriteriaServices.createEvaluationCriteria({
          name: competence,
          criteria_group_id: parseInt(groupCompetences),
        });

      if (createdCriteriaRes?.data?.code === HTTP_CODE.CREATED) {
        openNotification("Thêm tiêu chí đánh giá thành công");
        await getEvaluationCriteriaData();
        resetFormValue();
      } else {
        openNotification(
          CONVERTED_MESSAGE[createdCriteriaRes?.data?.message],
          "error"
        );
      }
    } catch (error) {
      resetFormValue();
    }
  };

  return (
    <Flex direction={"column"}>
      <Card p="0" mb="20px" mt="5px" minHeight="70vh">
        <form
          onSubmit={handleSubmit(handleAddCompetence)}
          style={{
            width: "100%",
            display: "flex",
            marginBottom: "5px",
          }}
        >
          <Flex p="0 5px" w="100%" justifyContent="space-between">
            <FormControl flex="0.6">
              <InputGroup h="40px">
                <InputLeftElement>
                  <MdAddCircleOutline fontSize="20px" color="#8F9BBA" />
                </InputLeftElement>
                <Input
                  placeholder="Thêm tiêu chí"
                  size="sm"
                  _placeholder={{ opacity: 1, color: labelColor }}
                  _hover={{ borderColor: "none" }}
                  style={{ cursor: "pointer" }}
                  color={textColor}
                  h="100%"
                  borderRadius="5px"
                  {...register("competence", {
                    required: "Vui lòng nhập tiêu chí đánh giá",
                  })}
                  name="competence"
                />
              </InputGroup>
              <ErrorMessage error={errors.competence} />
            </FormControl>
 
            <FormControl display={"flex"} gap="1" flex="0.25" flexDir={"row"}>
              <Select
                ml={3}
                {...register("groupCompetences", {
                  required: "Vui lòng chọn group",
                })}
                placeholder="Chọn group"
                name="groupCompetences"
              >
                {evaluationCriteriaData.map(
                  (criteria: IEvaluationCriteriaData) => (
                    <option
                      key={criteria.criteriaGroupId}
                      value={criteria?.criteriaGroupId}
                    >
                      {criteria?.name}
                    </option>
                  )
                )}
              </Select>
              <ErrorMessage error={errors.groupCompetences} />
              <Tooltip label="Thêm tiêu chí" placement="top">
                <Button
                  colorScheme="orange"
                  borderRadius={"50%"}
                  p={0}
                  type="submit"
                >
                  <HiPlus />
                </Button>
              </Tooltip>
            </FormControl>
          </Flex>
        </form>

        {isLoading && (
          <Flex h="300px" justifyContent="center" alignItems="center">
            <Spinner />
          </Flex>
        )}

        {!isLoading && evaluationCriteriaData.length === 0 ? (
          <Flex
            alignItems="center"
            justifyContent="center"
            minWidth="100%"
            mt="15px"
            position="absolute"
            transform="translate(-50%, -50%)"
            top="50%"
            left="50%"
          >
            <Text
              fontStyle="italic"
              textAlign="center"
              fontSize="22px"
              fontWeight="bold"
            >
              Không có dữ liệu
            </Text>
          </Flex>
        ) : (
          <Box mt="10px">
            <ListCompetence evaluationCriteriaData={evaluationCriteriaData} />
          </Box>
        )}
      </Card>
    </Flex>
  );
};

export default EvaluationCriteriaList;
