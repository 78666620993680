import { Box, Flex, SimpleGrid, useColorModeValue } from "@chakra-ui/react";
import Card from "components/card/Card";
import { createContext, useState } from "react";

export const ThemeContextDraft = createContext(null);

export default function CvDraft() {
  const bgColor = useColorModeValue("#F4F5F7", "navy.800");

  return (
    <Box>
      <SimpleGrid columns={1} overflowY="hidden">
        <Card bgColor={bgColor} flexDirection="column" w="100%" px="0px" pb={3}>
          <Flex paddingLeft={8}></Flex>
        </Card>
      </SimpleGrid>
    </Box>
  );
}
