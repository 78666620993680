import { useCallback } from "react";
import ReactQuill from "react-quill";
import EditorToolbar, { modules, formats } from "./EditorToolbar";
import "react-quill/dist/quill.snow.css";

interface TextEditorProps {
  textValue: any;
  onChange?: any;
  disable?: boolean;
  height?: number;
}

export const TextEditor = (props: TextEditorProps) => {
  const { textValue, onChange, disable = false, height = 400 } = props;

  const handleChange = useCallback(
    (editorValue: any) => {
      onChange({ value: editorValue });
    },
    [onChange]
  );

  return (
    <div className="text-editor">
      <EditorToolbar />
      <div>
        <ReactQuill
          readOnly={disable}
          theme="snow"
          value={textValue.value}
          onChange={handleChange}
          placeholder={
            "Nội dung chi tiết email sẽ được gửi đi (Lưu ý sử dụng template variables theo hướng dẫn bên phải)"
          }
          modules={modules}
          formats={formats}
          style={{ height: `${height}px`, color: "white !important" }}
        />
      </div>
    </div>
  );
};

export default TextEditor;
