import { usePagination } from "@ajna/pagination";
import {
  Avatar,
  Box,
  Button,
  Flex,
  Icon,
  Input,
  Select,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import { DEFAULT_PAGE_SIZE, LIST_ROLE } from "common/constants";
import { debounce } from "common/utils";
import CustomPagination from "components/common/Pagination";
import Base64 from "crypto-js/enc-base64";
import dayjs from "dayjs";
import { capitalize } from "lodash";
import { Lock, PenLine } from "lucide-react";
import { FC, useEffect, useState } from "react";
import { IoMdClose } from "react-icons/io";
import { MdFilterList, MdSearch } from "react-icons/md";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { roleService } from "services/Role/RoleServices";
import { studioService } from "services/StudioServirces";
import { userServices } from "services/UserServices";
import { IUser } from "types/interface";
import { constant } from "variables/constant";
import ModalUserCommon from "./components/ModalUserCommon";
import SkeletonCol from "./components/SkeletonCol";
import CryptoJS from "crypto-js";

type modalType = "add" | "edit" | "delete" | "none";
type Props = {
  openAddModal: boolean;
  setOpenAddModal: (value: boolean) => void;
};

const ListMemberPage: FC<Props> = ({ openAddModal, setOpenAddModal }) => {
  const roles = {
    [constant.ROLE_ADMIN]: "Admin",
    [constant.ROLE_MANAGER]: "Manager",
    [constant.ROLE_USER]: "User",
    [constant.ROLE_SUPER_ADMIN]: "Super admin",
  };

  const { search } = useLocation();

  const [userInfo, setUserInfo] = useState<IUser>(null);
  const [studioDatas, setStudioDatas] = useState([]);
  const [roleDatas, setRoleDatas] = useState([]);

  const searchParams = new URLSearchParams(search);
  const studioId = searchParams.get("id");
  const [total, setTotal] = useState<number | undefined>(undefined);
  const [totalPage, setTotalPage] = useState<number | undefined>(undefined);
  const {
    pages,
    pagesCount,
    currentPage,
    setCurrentPage,
    pageSize,
    setPageSize,
  } = usePagination({
    total: total,
    pagesCount: totalPage,
    initialState: {
      pageSize: DEFAULT_PAGE_SIZE,
      currentPage: 1,
    },
  });

  const [showFilter, setShowFilter] = useState(false);
  const [filterData, setFilterData] = useState<any>({
    team_id: studioId,
    username: "",
    status: "",
  });

  const [users, setUsers] = useState<any[]>([]);
  const [isProgress, setIsProgress] = useState<boolean>(false);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [modalType, setModalType] = useState<modalType>();
  const [modalData, setModalData] = useState<any>({
    username: "",
    password: "",
    roles: 3,
  });

  const handlePageChange = (nextPage: number): void => {
    setCurrentPage(nextPage);
  };

  const getUserLoggedInfo = () => {
    setIsProgress(true);
    const getData = async () => {
      const roleId = localStorage.getItem("roleId");
      if (roleId) {
        setUserInfo({
          role_id: roleId,
        });
      }
    };
    getData();
  };

  const getStudioInfo = () => {
    const getData = async () => {
      const header = {
        page: 0,
        size: 20,
      };
      const params = {
        type: "team",
      };
      try {
        const res = await studioService.getStudioDatas(params, header);
        if (res?.data?.data) {
          setStudioDatas(res.data.data);
        } else {
          setStudioDatas([]);
        }
      } catch (error) {
        toast.error("Get studios info failed");
      }
    };
    getData();
  };

  const getUsersInfo = () => {
    setIsProgress(true);
    const getData = async () => {
      try {
        const header = {
          page: currentPage - 1,
          size: pageSize,
        };
        let param = {};
        if (filterData.username) {
          param = { ...param, username: filterData.username };
        }
        if (filterData.status) {
          param = { ...param, status: +filterData.status };
        }
        if (filterData.role_id) {
          param = { ...param, role_id: +filterData.role_id };
        }
        if (userInfo?.role_id === constant.ROLE_MANAGER) {
          param = { ...param, team_id: +userInfo?.team_id };
        }
        if (userInfo?.role_id === constant.ROLE_ADMIN) {
          if (filterData.team_id) {
            param = { ...param, team_id: +filterData.team_id };
          }
        }
        const res = await userServices.getUserDatas(param, header);
        if (res?.data.data) {
          setUsers(res?.data.data);
          setTotal(res?.data.total);
          setTotalPage(res?.data.totalPages);
        }
      } catch (error) {
        toast.error("Get user info failed");
      } finally {
        setIsProgress(false);
      }
    };
    getData();
  };

  const getRolesInfo = () => {
    setIsProgress(true);
    const getData = async () => {
      try {
        const headers = {
          page: 0,
          size: 20,
        };
        const params = {};
        const res = await roleService.getAllRole(params, headers);
        if (res?.data) {
          setRoleDatas(res?.data);
        }
      } catch (error) {
        toast.error("Get user info failed");
      } finally {
        setIsProgress(false);
      }
    };
    getData();
  };

  const handleSubmitAddUserModal = (e: any) => {
    e.preventDefault();

    let passwordHash = Base64.stringify(
      CryptoJS.enc.Utf8.parse(modalData?.password)
    );

    let pushData = {
      ...modalData,
      password: passwordHash,
      roles: [+modalData.roles || 3],
      teamId: +modalData?.team_id,
    };

    const addUserAction = async () => {
      try {
        const header = {};
        const params = {};
        const res = await userServices.addUser(params, pushData, header);
        if (res?.data) {
          toast.success("Tạo user thành công");
          getUsersInfo();
        }
      } catch (error) {
        toast.error("Tạo user thất bại");
      } finally {
        setModalType("none");
        setModalData({});
        onClose();
      }
    };
    addUserAction();
  };

  const handleSubmitEditUserModal = (e: any) => {
    e.preventDefault();
    let pushData = {
      status: +modalData?.status,
      teamId: +modalData?.team_id,
      roles: [+modalData.roles || +modalData.role_id],
    };
    const actionUpdateUser = async () => {
      try {
        const header = {};
        const params = {};
        const res = await userServices.updateUser(
          modalData.id,
          params,
          pushData,
          header
        );
        if (res?.data) {
          toast.success("Cập nhật user thành công");
          getUsersInfo();
        }
      } catch (error) {
        toast.error("Cập nhật user thất bại");
      } finally {
        setModalType("none");
        setModalData({});
        onClose();
      }
    };
    actionUpdateUser();
  };

  const handleSubmitDeleteUser = (e: any) => {
    e.preventDefault();
    const actionDeleteUser = async () => {
      try {
        const header = {};
        const params = {};
        const res = await userServices.deleteUser(modalData.id, params, header);
        if (res) {
          toast.success("Khoá user thành công");
          getUsersInfo();
        }
      } catch (error) {
        toast.error("Khoá user thất bại");
      } finally {
        setModalType("none");
        setModalData({});
        onClose();
      }
    };
    actionDeleteUser();
  };

  const handleFilterSearchNameChange = debounce((e: any) => {
    setFilterData((prev: any) => ({ ...prev, username: e.target.value }));
  }, 300);

  const handleFilterStatusChange = (e: any) => {
    setFilterData((prev: any) => ({ ...prev, status: e.target.value }));
  };

  const handleFilterTeamChange = (e: any) => {
    setFilterData((prev: any) => ({ ...prev, team_id: e.target.value }));
  };

  const handleFilterRoleChange = (e: any) => {
    setFilterData((prev: any) => ({ ...prev, role_id: e.target.value }));
  };

  useEffect(() => {
    getUsersInfo();
    getRolesInfo();
    if (
      [LIST_ROLE.SUPER_ADMIN, LIST_ROLE.ADMIN].includes(
        Number(userInfo?.role_id)
      )
    ) {
      getStudioInfo();
    }
  }, [currentPage, filterData, userInfo, pageSize]);

  useEffect(() => {
    if (openAddModal) {
      setModalType("add");
      onOpen();
    }
  }, [openAddModal]);

  useEffect(() => {
    getUserLoggedInfo();
  }, []);

  return (
    <Flex flexDirection="column" gap={1} w="full" h="full">
      {/* btn filter */}
      {!showFilter ? (
        <Tooltip label="Hiển thị filter" placement="top">
          <Button
            size={"sm"}
            onClick={() => {
              setShowFilter(!showFilter);
            }}
            borderRadius={"10px"}
            colorScheme="orange"
            px={5}
            color="white"
            w={"fit-content"}
          >
            <Icon
              as={MdFilterList}
              display="block"
              width="20px"
              height="20px"
            />
            <Text px={2}>Filter</Text>
          </Button>
        </Tooltip>
      ) : (
        <Flex gap={2} alignItems={"center"} w={"70%"}>
          <Tooltip label="Đóng filter" placement="top">
            <Box>
              <Icon
                as={IoMdClose}
                cursor={"pointer"}
                onClick={() => {
                  setShowFilter(!showFilter);
                }}
              />
            </Box>
          </Tooltip>
          <Input
            size={"sm"}
            type="text"
            placeholder="Search name"
            onChange={handleFilterSearchNameChange}
          />
          {userInfo?.role_id === constant.ROLE_ADMIN && (
            <Select size={"sm"} w={"50%"} onChange={handleFilterTeamChange}>
              <option value="">All Team</option>
              {studioDatas.map((studio: any) => (
                <option key={studio.id} value={studio.id}>
                  {studio.name}
                </option>
              ))}
            </Select>
          )}
          <Select size={"sm"} w={"50%"} onChange={handleFilterRoleChange}>
            <option value="">All Role </option>
            {roleDatas.map((role: any) => (
              <option key={role.id} value={role.id}>
                {capitalize(role.name.toLowerCase(role.name))}
              </option>
            ))}
          </Select>
          <Select size={"sm"} w={"50%"} onChange={handleFilterStatusChange}>
            <option value="">All Status</option>
            <option value="1">Active</option>
            <option value="0">InActive</option>
          </Select>

          <Tooltip label="Search" placement="top">
            <Button colorScheme={"orange"} borderRadius={"50%"}>
              <Icon color={"white"} as={MdSearch} />
            </Button>
          </Tooltip>
        </Flex>
      )}

      {users.length > 0 ? (
        <TableContainer width={"100%"} h={"85%"} overflowY={"scroll"}>
          <Table size={"md"} variant="simple" colorScheme="orange">
            <Thead
              position="sticky"
              top={0}
              zIndex="docked"
              background={"white"}
            >
              <Tr>
                <Th>Name</Th>
                <Th>Team</Th>
                <Th>Role</Th>
                <Th>Status</Th>
                <Th>Tạo lúc</Th>
                <Th>Action</Th>
              </Tr>
            </Thead>
            {!isProgress ? (
              <Tbody>
                {users.map((user) => {
                  return (
                    <Tr key={user.id}>
                      <Td>
                        <Flex alignItems={"center"} gap={3}>
                          <Avatar borderRadius={"50%"} width={30} height={30} />
                          <Text>{user?.username}</Text>
                        </Flex>
                      </Td>
                      <Td>{user?.team_name || "No team"}</Td>
                      <Td>{roles[user.role_id] || "No role"}</Td>
                      <Td>
                        <Text
                          w={"fit-content"}
                          px={3}
                          py={1}
                          textColor={"white"}
                          backgroundColor={
                            user.status === 1 ? "green.300" : "red.300"
                          }
                          borderRadius={"30px"}
                        >
                          {user.status === 1 ? "Active" : "In-active"}
                        </Text>
                      </Td>
                      <Td>{dayjs(user.created_at).format("DD/MM/YYYY")}</Td>
                      <Td>
                        <Flex gap={4}>
                          <Tooltip label="Sửa user" placement="top">
                            <Button
                              size={"sm"}
                              colorScheme={"orange"}
                              onClick={() => {
                                setModalType("edit");
                                setModalData(user);
                                onOpen();
                              }}
                            >
                              <PenLine size="16" />
                            </Button>
                          </Tooltip>

                          <Tooltip label="Khoá user" placement="top">
                            <Button
                              size={"sm"}
                              colorScheme={"red"}
                              onClick={() => {
                                setModalType("delete");
                                setModalData(user);
                                onOpen();
                              }}
                            >
                              <Lock size="16" />
                            </Button>
                          </Tooltip>
                        </Flex>
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            ) : (
              <SkeletonCol col={6} />
            )}
          </Table>
        </TableContainer>
      ) : (
        <Flex justifyContent={"center"} alignItems={"center"} h={"60vh"}>
          <Text fontSize={"2xl"} fontWeight="bold">
            Không có dữ liệu
          </Text>
        </Flex>
      )}

      <Box pt={2}>
        {!isProgress && users?.length > 0 && (
          <CustomPagination
            pages={pages}
            pagesCount={pagesCount}
            currentPage={currentPage}
            onPageChange={handlePageChange}
            pageSize={pageSize}
            onPageSizeChange={setPageSize}
            optionPageSize={[10, 20, 50]}
          />
        )}
      </Box>
      <ModalUserCommon
        isOpen={isOpen}
        onClose={onClose}
        modalData={modalData}
        setModalData={setModalData}
        onSubmitModal={
          modalType === constant.ADD_MODAL
            ? handleSubmitAddUserModal
            : modalType === constant.EDIT_MODAL
            ? handleSubmitEditUserModal
            : modalType === constant.DELETE_MODAL
            ? handleSubmitDeleteUser
            : () => {}
        }
        modalType={modalType}
        userInfo={userInfo}
        studioDatas={studioDatas}
        setOpenAddModal={setOpenAddModal}
      />
    </Flex>
  );
};

export default ListMemberPage;
