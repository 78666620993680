import { Button, Flex, Tab, TabList, Tabs, Tooltip } from "@chakra-ui/react";
import { useState } from "react";
import { HiPlus } from "react-icons/hi";
import DashboardLayout from "../../layout";
import ListDepartmentPage from "./ListDepartmentPage";
import ListMemberPage from "./ListMemberPage";

type modalType = "add" | "edit" | "delete" | "none";
type Props = {};

const RoleAccessPage = (props: Props) => {
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [openAddModal, setOpenAddModal] = useState<boolean>(false);

  const handleTabChange = (selectedTabIndex: number) => {
    setSelectedTab(selectedTabIndex);
    setOpenAddModal(false);
  };

  return (
    <DashboardLayout>
      <Flex flexDirection="column" gap={1} w="full" h="full">
        <Flex
          flexDirection={"row"}
          alignItems="center"
          justifyContent={"space-between"}
          gap={3}
          px={4}
          pb={2}
          // borderBottom={"1px solid #f5932a"}
          w="full"
        >
          <Flex>
            <Tabs
              width="100%"
              align="start"
              onChange={handleTabChange}
              defaultIndex={selectedTab}
            >
              <TabList>
                <Tab>User Management</Tab>
                <Tab>Studio Management</Tab>
              </TabList>
            </Tabs>
          </Flex>
          <Flex gap={3} alignItems={"center"}>
            {/* <Text fontSize="xl" fontWeight="bold">
              Quản lý và phân quyền
            </Text> */}
            <Tooltip
              label={`${selectedTab === 0 ? "Thêm Người dùng" : "Thêm Studio"}`}
              placement="left"
            >
              <Button
                colorScheme="orange"
                px={0}
                borderRadius={"50%"}
                color="white"
                onClick={() => {
                  setOpenAddModal(!openAddModal);
                }}
              >
                <HiPlus size={20} />
              </Button>
            </Tooltip>
          </Flex>
        </Flex>
        {selectedTab === 1 ? (
          <ListDepartmentPage
            openAddModal={openAddModal}
            setOpenAddModal={setOpenAddModal}
          />
        ) : (
          <ListMemberPage
            openAddModal={openAddModal}
            setOpenAddModal={setOpenAddModal}
          />
        )}
      </Flex>
    </DashboardLayout>
  );
};

export default RoleAccessPage;
