import {
  Button,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Textarea,
  useColorModeValue,
} from "@chakra-ui/react";
import _ from "lodash";
import { useEffect, useState } from "react";
import { jobManageService } from "./JobManageService";

export default function UpdateJobInfo(props: any) {
  const { show, setShow, dataUpdate } = props;
  const jobStatus = ["Open", "Close", "Pending"];

  const [id, setId] = useState(0);
  const [jobname, setJobname] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [totalCV, setTotalCV] = useState(0);
  const [description, setDescription] = useState("");
  const [status, setStatus] = useState("");

  const [showAlertValidateDate, setShowAlertValidateDate] = useState(false);
  const [showAlertJobname, setShowAlertJobname] = useState(false);
  const [showAlertStartDate, setShowAlertStartDate] = useState(false);
  const [showAlertEndate, setShowAlertEndate] = useState(false);
  const [showAlertDescription, setShowAlertDescription] = useState(false);
  const [showAlertStatus, setShowAlertStatus] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);

  const textColor = useColorModeValue("secondaryGray.900", "white");

  const handleClose = () => {
    setShow(false);
    setId(0);
    setJobname("");
    setStartDate("");
    setEndDate("");
    setDescription("");
    setStatus("");
    setShowAlertValidateDate(false);
    setShowAlertJobname(false);
    setShowAlertStartDate(false);
    setShowAlertEndate(false);
    setShowAlertDescription(false);
    setShowAlertStatus(false);
    if (isUpdate) {
      props.resetData();
    }
  };

  useEffect(() => {
    if (!_.isEmpty(dataUpdate)) {
      setId(dataUpdate.id);
      setJobname(dataUpdate.jobname);
      setStartDate(dataUpdate.start_date);
      setEndDate(dataUpdate.end_date);
      setTotalCV(dataUpdate.total_apply_candidate);
      setDescription(dataUpdate.description);
      setStatus(dataUpdate.status);
      setIsUpdate(true);
    }
  }, [dataUpdate]);

  const validate = () => {
    const boolenArray = [
      showAlertValidateDate,
      showAlertJobname,
      showAlertStartDate,
      showAlertEndate,
      showAlertDescription,
      showAlertStatus,
    ];
    const isNotValid = boolenArray.find((item: boolean) => item === true);
    return isNotValid ? true : false;
  };

  useEffect(() => {
    setStartDate(startDate);
    validateDate();
  }, [startDate]);

  useEffect(() => {
    setEndDate(endDate);
    validateDate();
  }, [endDate]);

  useEffect(() => {
    setDescription(description);
    validateText();
  }, [description]);

  useEffect(() => {
    setJobname(jobname);
    validateText();
  }, [jobname]);

  useEffect(() => {
    setStatus(status);
    validateText();
  }, [status]);

  const validateDate = () => {
    if (_.isEmpty(startDate)) {
      setShowAlertStartDate(true);
    } else {
      setShowAlertStartDate(false);
    }

    if (_.isEmpty(endDate)) {
      setShowAlertEndate(true);
    } else {
      setShowAlertEndate(false);
    }

    if (!_.isEmpty(startDate) && !_.isEmpty(endDate)) {
      let start_date = new Date(startDate).getTime();
      let end_date = new Date(endDate).getTime();

      if (start_date > end_date) {
        setShowAlertValidateDate(true);
      } else {
        setShowAlertValidateDate(false);
      }
    }
  };

  const validateText = () => {
    if (_.isEmpty(description)) {
      setShowAlertDescription(true);
    } else {
      setShowAlertDescription(false);
    }

    if (_.isEmpty(jobname)) {
      setShowAlertJobname(true);
    } else {
      setShowAlertJobname(false);
    }
    if (_.isEmpty(status)) {
      setShowAlertStatus(true);
    } else {
      setShowAlertStatus(false);
    }
  };

  const handleSave = async () => {
    if (!validate()) {
      async function updateJobInfo() {
        const param = {
          id: Number(id),
        };
        const putData = {
          jobname: jobname,
          start_date: startDate,
          end_date: endDate,
          description: description,
          status: status,
        };
        const result = await jobManageService.update(putData, param, "");
        if (result.status === 200) {
          props.getJobInfo();
          handleClose();
        }
      }
      updateJobInfo();
    }
  };

  const handleCreate = async () => {
    if (!validate()) {
      async function createJobInfo() {
        const putData = {
          jobname: jobname,
          start_date: startDate,
          end_date: endDate,
          description: description,
          status: status,
        };
        const result = await jobManageService.create(putData, "", "");
        if (result.status === 201) {
          props.getJobInfo();
          handleClose();
        }
      }
      createJobInfo();
    }
  };

  return (
    <>
      <Modal isOpen={show} onClose={handleClose} size="6xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize="4xl">{jobname}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl style={{ display: "flex", flexWrap: "wrap" }}>
              <Flex flexDirection="column" pb={5} w="50%">
                <FormLabel fontSize="1.2rem" fontWeight="300" lineHeight="100%">
                  Tên công việc
                </FormLabel>
                <Input
                  color={textColor}
                  placeholder="Nhập tên công việc"
                  onChange={(event) => setJobname(event?.target?.value)}
                  value={jobname}
                  w="95%"
                />
                {showAlertJobname && (
                  <FormHelperText textColor="red">
                    Vui lòng nhập tên công việc
                  </FormHelperText>
                )}
              </Flex>
              <Flex flexDirection="column" pb={5} w="50%">
                <FormLabel
                  fontSize="1.2rem"
                  fontWeight="300"
                  lineHeight="100%"
                  marginLeft="10px">
                  Số lượng CV
                </FormLabel>
                <Input
                  disabled={true}
                  color={textColor}
                  marginLeft="10px"
                  onChange={() => null}
                  value={totalCV}
                  type="number"
                  w="95%"
                />
              </Flex>
              <Flex flexDirection="column" pb={5} w="50%">
                <FormLabel fontSize="1.2rem" fontWeight="300" lineHeight="150%">
                  Ngày bắt đầu
                </FormLabel>
                <Input
                  color={textColor}
                  onChange={(event) => setStartDate(event?.target?.value)}
                  value={startDate}
                  type="date"
                  w="95%"
                />
                {showAlertStartDate && (
                  <FormHelperText textColor="red">
                    Vui lòng nhập thời gian bắt đầu
                  </FormHelperText>
                )}
                {showAlertValidateDate && (
                  <FormHelperText textColor="red">
                    Thời gian bắt đầu phải lớn hơn thời gian kết thúc
                  </FormHelperText>
                )}
              </Flex>
              <Flex flexDirection="column" pb={5} w="50%">
                <FormLabel
                  fontSize="1.2rem"
                  fontWeight="300"
                  lineHeight="150%"
                  marginLeft="10px">
                  Ngày kết thúc
                </FormLabel>
                <Input
                  color={textColor}
                  marginLeft="10px"
                  onChange={(event) => setEndDate(event?.target?.value)}
                  value={endDate}
                  type="date"
                  w="95%"
                />
                {showAlertEndate && (
                  <FormHelperText textColor="red">
                    Vui lòng nhập thời gian kết thúc
                  </FormHelperText>
                )}
              </Flex>
              <Flex flexDirection="column" pb={5} w="50%">
                <FormLabel fontSize="1.2rem" fontWeight="300" lineHeight="100%">
                  Trạng thái
                </FormLabel>
                <Select
                  color={textColor}
                  value={status}
                  onChange={(event) => setStatus(event?.target?.value)}
                  w="40%">
                  <option value="" disabled={!status}>
                    Chọn công việc
                  </option>
                  {jobStatus.map((item: any) => (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  ))}
                </Select>
                {showAlertStatus && (
                  <FormHelperText textColor="red">
                    Vui lòng chọn trạng thái
                  </FormHelperText>
                )}
              </Flex>
              <Flex flexDirection="column" pb={5} w="100%">
                <FormLabel fontSize="1.2rem" fontWeight="300" lineHeight="200%">
                  Mô tả công việc
                </FormLabel>
                <Textarea
                  color={textColor}
                  onChange={(event) => setDescription(event?.target?.value)}
                  value={description}
                  w="100%"
                  h="200px"
                />
                {showAlertDescription && (
                  <FormHelperText textColor="red">
                    Vui lòng nhập mô tả công việc
                  </FormHelperText>
                )}
              </Flex>
            </FormControl>
          </ModalBody>

          <ModalFooter>
            {isUpdate && (
              <Button colorScheme="blue" mr={3} onClick={() => handleSave()}>
                Lưu
              </Button>
            )}
            {!isUpdate && (
              <Button colorScheme="blue" mr={3} onClick={() => handleCreate()}>
                Tạo
              </Button>
            )}
            <Button variant="ghost" onClick={handleClose}>
              Đóng
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
