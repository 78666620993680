export const constant = {
  PAGE_SIZE: 7,
  CURRENT_PAGE: 1,
  OUTER_LIMIT: 2,
  INNER_LIMIT: 2,
  ADD_MODAL: "add",
  EDIT_MODAL: "edit",
  DELETE_MODAL: "delete",
  CLOSE_MODAL: "none",
  ROLE_ADMIN: "1",
  ROLE_MANAGER: "2",
  ROLE_USER: "3",
  ROLE_SUPER_ADMIN: "4",
};
