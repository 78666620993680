import { Redirect, Route, RouteProps } from "react-router-dom";
import { isAuthenticated } from "services/AuthServices";

const RouteAuthenticated = ({ component: Component, path }: RouteProps) => {
  if (!isAuthenticated()) {
    return <Redirect to="/auth/sign-in" />;
  }

  return <Route component={Component} path={path} />;
};

export default RouteAuthenticated;
