import { API_URL_PATH } from "common/constants";
import { API_URL } from "config/environment";
import ApiServices from "../ApiServices";

const api_replace = API_URL.replace("/api", "");
const base_url = api_replace + API_URL_PATH.ROLES;

export class RoleServices extends ApiServices {
  public async getAllRole(params: any, headers: any) {
    return await this.getRequest(base_url, params, headers);
  }
}

export const roleService = new RoleServices();
