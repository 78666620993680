import {
  Box,
  Button,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Icon,
  Input,
  Link,
  Progress,
  Select,
  SimpleGrid,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Tooltip,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import * as React from "react";
import { MdFullscreen } from "react-icons/md";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { cvTableServices } from "../cvTable/CvTableService";
import { cvManageServices } from "./CvManageServices";

export default function CvCreate() {
  const history = useHistory();
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const iconColor = useColorModeValue("brand.500", "white");

  const [jobData, setJobData] = React.useState([]);
  const [selectedJobId, setSelectedJobId] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [candidateName, setCandidateName] = React.useState("");
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [CvURL, setCvURL] = React.useState([""]);
  const [showCvLoading, setShowCvLoading] = React.useState(false);
  const [showAlert, setShowAlert] = React.useState(false);
  const [showAlertJobId, setShowAlertJobId] = React.useState(false);
  const [showAlertEmail, setShowAlertEmail] = React.useState(false);
  const [showAlertCV, setShowAlertCV] = React.useState(false);
  const [showAlertPhoneNumber, setShowAlertPhoneNumber] = React.useState(false);
  const [showAlertcandidateName, setShowAlertCandidateName] =
    React.useState(false);

  const getJobInfo = () => {
    async function getData() {
      const header = {
        page: 0,
        size: 1000,
      };
      try {
        const response = await cvTableServices.getJobData("", header);
        if (response?.data?.data) {
          setJobData(response.data.data);
        } else {
          setJobData([]);
        }
      } catch (error) {
        toast.error("Network error");
      }
    }
    getData();
  };

  const handleJobChange = (event: any) => {
    setShowAlert(false);
    setSelectedJobId(event.target.value);
  };

  const validateEmail = () => {
    if (
      !String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    )
      setShowAlertEmail(true);
    else setShowAlertEmail(false);
  };

  const validateJobId = () => {
    if (!selectedJobId) setShowAlertJobId(true);
    else setShowAlertJobId(false);
  };

  const validateCandidateName = () => {
    if (candidateName.length > 0) setShowAlertCandidateName(false);
    else setShowAlertCandidateName(true);
  };

  const validateCV = (data: any) => {
    if ((!data || !validateURL(data)) && !showCvLoading) {
      setShowAlertCV(true);
    } else {
      setShowAlertCV(false);
    }
  };

  const validateURL = (data: any) => {
    let isValid = true;
    if (data.length > 0) {
      data.forEach((item: any) => {
        if (!item || item === "") isValid = false;
      });
    }
    if (data.length === 0) isValid = false;
    return isValid;
  };

  const validatePhoneNumber = () => {
    const regexPhoneNumber = /(84|0[3|5|7|8|9])+([0-9]{8})\b/g;

    phoneNumber.match(regexPhoneNumber)
      ? setShowAlertPhoneNumber(false)
      : setShowAlertPhoneNumber(true);
  };

  const handleEmailChange = (event: any) => {
    setShowAlert(false);
    setEmail(event.target.value);
  };

  const handleCandidateNameChange = (event: any) => {
    setShowAlert(false);
    setCandidateName(event.target.value);
  };

  const handlePhoneNumberChange = (event: any) => {
    setShowAlert(false);
    setPhoneNumber(event.target.value);
  };

  const handleCVChange = async (event: any) => {
    setShowAlert(false);
    setShowCvLoading(true);
    setCvURL([]);
    let CV = event.target.files;

    const header = {
      page: 0,
      size: 1000,
    };
    try {
      const res = await cvManageServices.uploadCv(CV, "", header);
      if (res?.data) {
        setCvURL(res.data.urls);
        validateCV(res.data.urls);
      } else {
        setCvURL([]);
      }
    } catch (error) {
      toast.error("Network error");
    } finally {
      setShowCvLoading(false);
    }
  };

  const handleCreateClick = () => {
    setShowAlert(true);
    if (!validate()) {
      async function uploadCV() {
        const header = {
          page: 0,
          size: 1000,
        };
        const postData = {
          job_id: Number(selectedJobId),
          email: email,
          candidate_name: candidateName,
          phone_number: phoneNumber,
          cv_url: CvURL,
        };
        const result = await cvManageServices.createCv(postData, "", header);
        if (result?.status === 201) {
          history.push("/admin/cvmanage");
        }
      }
      uploadCV();
    }
  };

  const validate = () => {
    const boolenArray = [
      showAlertCV,
      showAlertEmail,
      showAlertPhoneNumber,
      showAlertcandidateName,
      showAlertJobId,
    ];
    const isNotValid = boolenArray.find((item: boolean) => item === true);
    return isNotValid ? true : false;
  };

  React.useEffect(() => {
    getJobInfo();
  }, []);

  React.useEffect(() => {
    setEmail(email);
    validateEmail();
  }, [email]);

  React.useEffect(() => {
    setCandidateName(candidateName);
    validateCandidateName();
  }, [candidateName]);

  React.useEffect(() => {
    setSelectedJobId(selectedJobId);
    validateJobId();
  }, [selectedJobId]);

  React.useEffect(() => {
    setPhoneNumber(phoneNumber);
    validatePhoneNumber();
  }, [phoneNumber]);

  return (
    <Box overflowX="auto">
      <SimpleGrid columns={1}>
        <Card flexDirection="column" w="full" pb={3}>
          <Flex mb="2rem" align="center">
            <Text
              paddingRight="1rem"
              color={textColor}
              fontSize="22px"
              fontWeight="700"
              lineHeight="100%"
            >
              Tạo CV mới
            </Text>
          </Flex>
          <Box height="700px" mb={0}>
            <FormControl display="flex" flexDirection="row">
              <Flex display="flex" flexDirection="column" w="50%" h="100%">
                <Flex flexDirection="column" pb={5}>
                  <FormLabel>Tên công việc</FormLabel>
                  <Select
                    onChange={handleJobChange}
                    placeholder="Chọn công việc"
                    w="50%"
                  >
                    {jobData.map((item: any, index: any) => (
                      <option key={item.id + index} value={item.id}>
                        {item.jobname}
                      </option>
                    ))}
                  </Select>
                  {showAlertJobId && showAlert && (
                    <FormHelperText textColor="red">
                      Vui lòng chọn job
                    </FormHelperText>
                  )}
                </Flex>
                <Flex flexDirection="column" pb={5}>
                  <FormLabel>Email</FormLabel>
                  <Input
                    placeholder="Nhập địa chỉ mail"
                    onChange={handleEmailChange}
                    type="email"
                    w="50%"
                  />
                  {showAlertEmail && showAlert && (
                    <FormHelperText textColor="red">
                      Vui lòng nhập đúng địa chỉ email
                    </FormHelperText>
                  )}
                </Flex>
                <Flex flexDirection="column" pb={5}>
                  <FormLabel>Tên ứng viên</FormLabel>
                  <Input
                    placeholder="Nhập tên ứng viên"
                    onChange={handleCandidateNameChange}
                    type="text"
                    w="50%"
                  />
                  {showAlertcandidateName && showAlert && (
                    <FormHelperText textColor="red">
                      Vui lòng nhập tên ứng viên
                    </FormHelperText>
                  )}
                </Flex>
                <Flex flexDirection="column" pb={5}>
                  <FormLabel>Số điện thoại</FormLabel>
                  <Input
                    placeholder="Nhập số điện thoại ứng viên"
                    onChange={handlePhoneNumberChange}
                    type="text"
                    w="50%"
                  />
                  {showAlertPhoneNumber && showAlert && (
                    <FormHelperText textColor="red">
                      Vui lòng nhập đúng số điện thoại
                    </FormHelperText>
                  )}
                </Flex>
                <Flex flexDirection="column" pb={5}>
                  <FormLabel>CV</FormLabel>
                  <Input
                    multiple={true}
                    type="file"
                    onChange={handleCVChange}
                    w="50%"
                  />
                  {showCvLoading && (
                    <Progress size="xs" w="25%" mx={5} isIndeterminate />
                  )}
                  {showAlertCV && showAlert && (
                    <FormHelperText textColor="red">
                      Vui lòng chọn CV
                    </FormHelperText>
                  )}
                </Flex>
              </Flex>

              <Flex flexDirection="column" pb={5} w="50%" h="100%">
                <FormLabel fontSize="1rem" fontWeight="300" lineHeight="200%">
                  Xem trước CV tải lên
                </FormLabel>
                <Tabs>
                  <TabList>
                    {CvURL &&
                      CvURL.length > 0 &&
                      CvURL[0] !== "" &&
                      CvURL.map((item: any, index: number) => (
                        <Tab key={index}>
                          <Text
                            overflow="hidden"
                            noOfLines={1}
                            color={textColor}
                            fontSize="1rem"
                            fontWeight="500"
                            lineHeight="100%"
                          >
                            {item?.split("/files/")[1]?.split(".")[0]}
                          </Text>
                        </Tab>
                      ))}
                  </TabList>

                  <TabPanels>
                    {CvURL &&
                      CvURL.length > 0 &&
                      CvURL[0] !== "" &&
                      CvURL.map((item: any, index: number) => (
                        <TabPanel key={index}>
                          <Flex px="25px">
                            <Tooltip label="Fullscreen">
                              <Link href={item}>
                                <Icon
                                  as={MdFullscreen}
                                  color={iconColor}
                                  w="2rem"
                                  h="2rem"
                                />
                              </Link>
                            </Tooltip>
                          </Flex>
                          <Flex
                            px="25px"
                            mb="1rem"
                            justifyContent="space-between"
                            align="center"
                          >
                            <object
                              data={item}
                              type="application/pdf"
                              width="100%"
                              height="300px"
                            ></object>
                          </Flex>
                        </TabPanel>
                      ))}
                  </TabPanels>
                </Tabs>
              </Flex>
            </FormControl>
            <Flex flexDirection="column" pb={5}>
              <Button
                w="25%"
                fontSize="sm"
                variant="brand"
                fontWeight="500"
                h="50"
                mt="24px"
                onClick={handleCreateClick}
              >
                Tạo thông tin ứng viên mới
              </Button>
            </Flex>
          </Box>
        </Card>
      </SimpleGrid>
    </Box>
  );
}
