import { HTTP_CODE, UNLIMITED_PAGE_SIZE } from "common/constants";
import { cvTableServices } from "views/admin/default/components/cvTable/CvTableService";
import { masterDataService } from "./MasterData/MasterDataServices";
import { userServices } from "./User/UserServices";

const getListStudio = async () => {
  const query = {
    type: "team",
  };

  const header = {
    page: 0,
    size: UNLIMITED_PAGE_SIZE,
  };

  try {
    const response = await masterDataService.getMasterData(query, header);

    return response?.status === HTTP_CODE.SUCCESS ? response?.data?.data : [];
  } catch (error) {
    return [];
  }
};

const getListJobName = async () => {
  const header = {
    page: 0,
    size: 1000,
    field: "jobName",
    order: "ASC",
  };

  try {
    const response = await cvTableServices.getJobData("", header);
    return response?.status === HTTP_CODE.SUCCESS ? response?.data?.data : [];
  } catch (error) {
    return [];
  }
};

const getUserById = async (params: any, headers: any) => {
  try {
    const userRes = await userServices.getUserById(params, headers);
    return userRes?.status === HTTP_CODE.SUCCESS ? userRes?.data : null;
  } catch (error) {
    return null;
  }
};

const getListUser = async (params: any, headers: any) => {
  try {
    const listUserRes = await userServices.getAllUser(params, headers);
    return listUserRes?.status === HTTP_CODE.SUCCESS ? listUserRes?.data : null;
  } catch (error) {
    return [];
  }
};

export { getListStudio, getListJobName, getUserById, getListUser };
