import { Box, Flex, Spinner } from "@chakra-ui/react";
import { ResponsiveFunnel } from "@nivo/funnel";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { reportService } from "services/Report/ReportService";
import { allValuesNull, dataFilterConversation } from "./DataFilterFunction";

type Props = {
  filterData: any;
};

const ConversationRateChart = ({ filterData }: Props) => {
  const CV_STATUS = {
    create: "Create",
    review: "Review",
    test: "Test",
    pv: "PV",
    offer: "Offer",
  };

  const [chartData, setChartData] = useState([]);
  const [loading, setLoading] = useState(false);

  const getDatas = async () => {
    try {
      setLoading(true);
      const headers = {};
      const params = {
        fromDate: "",
        toDate: "",
      };
      if (filterData.fromDate) params["fromDate"] = filterData.fromDate;
      if (filterData.toDate) params["toDate"] = filterData.toDate;

      const { data } = await reportService.getConversationRatePerRound(
        params,
        headers
      );
      const statusOrder = Object.keys(CV_STATUS);
      const sortedData = dataFilterConversation(data.statusObject, statusOrder);

      setChartData(sortedData.reverse());
    } catch (error) {
      toast.error("Lỗi khi lấy dữ liệu Conversation Rate");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (filterData.fromDate && filterData.toDate) {
      getDatas();
    }
  }, [filterData]);

  return (
    <Flex width="40vw" height="40vh">
      {loading && (
        <Box
          mt={{ sm: 0, md: 5 }}
          minH={"25vh"}
          width="100%"
          textAlign={"center"}
        >
          <Spinner />{" "}
        </Box>
      )}
      {!loading && allValuesNull(chartData) && (
        <Box
          className="chart_empty"
          minH={"25vh"}
          width="100%"
          textAlign={"center"}
        >
          No data
        </Box>
      )}
      {!loading && !allValuesNull(chartData) && (
        <ResponsiveFunnel data={chartData} direction="horizontal" />
      )}
    </Flex>
  );
};

export default ConversationRateChart;
