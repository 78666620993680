import React, { useEffect } from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { HSeparator } from "components/separator/Separator";
import DefaultAuth from "layouts/auth/Default";
import illustration from "assets/img/auth/auth.png";
import { FcGoogle } from "react-icons/fc";
import { Alert, AlertIcon, AlertTitle } from "@chakra-ui/react";
import { useGoogleLogin } from "@react-oauth/google";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import ApiServices from "services/ApiServices";
import Base64 from "crypto-js/enc-base64";
import CryptoJS from "crypto-js";
import { ROCKET_EMAIL_PATTERN } from "common/constants";
import ErrorMessage from "components/common/ErrorFormMessage";

function SignIn() {
  // Chakra color mode
  const apiServices = new ApiServices();
  const [showAlert, setShowAlert] = React.useState(false);
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const googleBg = useColorModeValue("secondaryGray.400", "whiteAlpha.200");
  const googleText = useColorModeValue("navy.700", "white");
  const googleHover = useColorModeValue(
    { bg: "gray.200" },
    { bg: "whiteAlpha.300" }
  );
  const googleActive = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.200" }
  );
  const [show, setShow] = React.useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm();

  // const validateEmail = (email: string) => {
  //   if (!email) {
  //     return "Email is required";
  //   }
  //   if (!ROCKET_EMAIL_PATTERN.test(email)) {
  //     return "Please enter your Rocket Studio email";
  //   }
  //   return true;
  // };

  const handleClick = () => setShow(!show);

  const handleSingInClick = async () => {
    const { email, password } = getValues();

    let base64Pass = Base64.stringify(CryptoJS.enc.Utf8.parse(password));
    const postData = {
      username: email,
      password: base64Pass,
    };
    const res = await apiServices.signInRequest(postData);
    if (res) {
      localStorage.setItem("user_id", JSON.stringify(res));
      // history.push("/admin/default");
      window.location.reload();
    } else {
      setShowAlert(true);
    }
  };

  const login = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const postData = {
        idToken: tokenResponse.access_token,
      };
      const res = await apiServices.googleSignInRequest(postData);
      if (res) {
        // history.push("/admin/default");
        window.location.reload();
      } else {
        setShowAlert(true);
      }
    },
  });

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w="100%"
        mx={{ base: "auto", lg: "0px" }}
        me="auto"
        h="100%"
        alignItems="start"
        justifyContent="center"
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection="column"
      >
        <>
          {showAlert && (
            <Alert status="error">
              <AlertIcon />
              <AlertTitle>Something when wrong when login</AlertTitle>
            </Alert>
          )}
        </>
        <Box me="auto">
          <Heading color={textColor} fontSize="36px" mb="10px">
            Sign In
          </Heading>
          <Text
            mb="36px"
            ms="4px"
            color={textColorSecondary}
            fontWeight="400"
            fontSize="md"
          >
            Enter your email and password to sign in!
          </Text>
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: "100%", md: "420px" }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: "auto", lg: "unset" }}
          me="auto"
          mb={{ base: "20px", md: "auto" }}
        >
          <Button
            onClick={() => login()}
            fontSize="sm"
            me="0px"
            mb="26px"
            py="15px"
            h="50px"
            borderRadius="16px"
            bg={googleBg}
            color={googleText}
            fontWeight="500"
            _hover={googleHover}
            _active={googleActive}
            _focus={googleActive}
          >
            <Icon as={FcGoogle} w="20px" h="20px" me="10px" />
            Sign in with Google
          </Button>
          <Flex align="center" mb="25px">
            <HSeparator />
            <Text color="gray.400" mx="14px">
              or
            </Text>
            <HSeparator />
          </Flex>

          <form
            onSubmit={handleSubmit(handleSingInClick)}
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "15px",
            }}
          >
            <FormControl>
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                Email
              </FormLabel>
              <Input
                variant="auth"
                fontSize="sm"
                ms={{ base: "0px", md: "0px" }}
                placeholder="Enter your email address"
                fontWeight="500"
                size="lg"
                {...register(
                  "email"
                  // { validate: validateEmail }
                )}
              />

              <ErrorMessage error={errors.email} />
            </FormControl>

            <FormControl>
              <FormLabel
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                display="flex"
              >
                Password
              </FormLabel>
              <InputGroup size="md">
                <Input
                  fontSize="sm"
                  placeholder="Enter your password"
                  size="lg"
                  type={show ? "text" : "password"}
                  variant="auth"
                  {...register("password", {
                    required: "Please enter your password",
                    // minLength: {
                    //   value: 8,
                    //   message: "Password must be at least 8 characters",
                    // },
                  })}
                />
                <InputRightElement display="flex" alignItems="center" mt="4px">
                  <Icon
                    color={textColorSecondary}
                    _hover={{ cursor: "pointer" }}
                    as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                    onClick={handleClick}
                  />
                </InputRightElement>
              </InputGroup>

              <ErrorMessage error={errors.password} />
            </FormControl>

            <Button
              fontSize="sm"
              variant="brand"
              fontWeight="500"
              w="100%"
              h="50"
              mt="24px"
              type="submit"
            >
              Sign In
            </Button>
          </form>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default SignIn;
