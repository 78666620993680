import { Box, VStack, Flex } from "@chakra-ui/react";
import returnRoute from "routes";
import { IRoute } from "types/interface";
import { ChevronsLeft, ChevronsRight } from "lucide-react";
import SidebarBrand from "components/Sidebar/SidebarBrand";
import { useSelector } from "react-redux";
import { actToggleSidebar, layoutSelector } from "redux/features/layoutSlice";
import { useDispatch } from "react-redux";
import SidebarItem from "components/Sidebar/SidebarItem";
import { useLocation } from "react-router-dom";

const Sidebar = () => {
  const dispatch = useDispatch();

  const layoutSelectorState = useSelector(layoutSelector);
  const { isCollapsedSidebar } = layoutSelectorState;

  const toggleSidebar = () => {
    dispatch(actToggleSidebar());
  };

  const location = useLocation();
  const showRoute = returnRoute.filter((route: IRoute) => route.show);

  const getActivePath = () => {
    const activeRoute = returnRoute.find((route: IRoute) =>
      location.pathname.includes(route.layout + route.path)
    );

    if (activeRoute) {
      return activeRoute.show
        ? activeRoute.layout + activeRoute.path
        : activeRoute.layout + activeRoute.parentPath;
    }

    return "";
  };

  return (
    <Box display="flex" height="100vh" position="sticky" top="0">
      <Flex
        width={isCollapsedSidebar ? "80px" : "250px"}
        transition="all 0.3s"
        backgroundColor="white"
        color="white"
        overflow="hidden"
        flexDirection="column"
        justifyContent="space-between"
      >
        <VStack align="start" p={isCollapsedSidebar ? 2 : 4} spacing={4}>
          <Flex w="100%" h="100%" justifyContent="center">
            <SidebarBrand />
          </Flex>

          <Flex flexDirection="column" gap="8px" w="100%">
            {showRoute.map((route: IRoute, index: number) => (
              <SidebarItem
                key={route?.apiEndPoint + route?.path}
                route={route}
                isCollapsedSidebar={isCollapsedSidebar}
                activePath={getActivePath()}
              />
            ))}
          </Flex>
        </VStack>

        <Flex
          justifyContent="center"
          w="100%"
          mt="auto"
          onClick={toggleSidebar}
          cursor="pointer"
          _hover={{ bg: "brand.100" }}
        >
          <Box borderRadius="10px" p="5px" color="brand.300">
            {isCollapsedSidebar ? <ChevronsRight /> : <ChevronsLeft />}
          </Box>
        </Flex>
      </Flex>
    </Box>
  );
};

export default Sidebar;
