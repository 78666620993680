import { constant } from "variables/constant";
import {
  Box,
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
} from "@chakra-ui/react";
import { LIST_ROLE } from "common/constants";
import { getUsernameFromEmail } from "common/utils";

interface ModalUserCommonProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmitModal?: (e: any) => void;
  modalData?: any;
  setModalData?: any;
  modalType?: string;
  userInfo: any;
  studioDatas: any;
  setOpenAddModal?: (value: boolean) => void;
}

const ModalUserCommon = ({
  isOpen,
  onClose,
  onSubmitModal,
  modalData,
  setModalData,
  modalType,
  userInfo,
  studioDatas,
  setOpenAddModal,
}: ModalUserCommonProps) => {
  const closeModal = () => {
    onClose();
    setModalData({});
    setOpenAddModal(false);
  };
  if (modalType !== "delete") {
    return (
      <Modal isOpen={isOpen} onClose={closeModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {modalType === "edit"
              ? `Sửa user ${getUsernameFromEmail(modalData.username)}`
              : "Tạo user"}
          </ModalHeader>
          <ModalCloseButton onClick={closeModal} />
          <form onSubmit={onSubmitModal}>
            <ModalBody display={"flex"} flexDir={"column"} gap={5}>
              {modalType === "add" && (
                <>
                  <Box>
                    <label htmlFor="user_name">Email:</label>
                    <Input
                      id="user_name"
                      name="user_name"
                      type="email"
                      required
                      placeholder={modalData?.username || "Hãy nhập email"}
                      onChange={(e) => {
                        setModalData({
                          ...modalData,
                          username: e.target.value,
                        });
                      }}
                    />
                  </Box>
                  <Box>
                    <label htmlFor="password">Mật khẩu:</label>
                    <Input
                      id="password"
                      name="password"
                      type="password"
                      minLength={8}
                      required
                      placeholder={modalData?.password || "Hãy nhập password"}
                      onChange={(e) => {
                        setModalData({
                          ...modalData,
                          password: e.target.value || "123456",
                        });
                      }}
                    />
                  </Box>
                </>
              )}
              {modalType === "edit" && (
                <Box>
                  <label htmlFor="">Status:</label>
                  <Select
                    name="status"
                    defaultValue={modalData?.status}
                    onChange={(e) => {
                      setModalData({
                        ...modalData,
                        status: e.target.value,
                      });
                    }}
                  >
                    <option value="0">In-Active</option>
                    <option value="1">Active</option>
                  </Select>
                </Box>
              )}
              {[LIST_ROLE.SUPER_ADMIN, LIST_ROLE.ADMIN].includes(
                Number(userInfo?.role_id)
              ) && (
                <Box>
                  <label htmlFor="">Team:</label>
                  <Select
                    name="team"
                    defaultValue={modalData?.team_id}
                    required
                    onChange={(e) => {
                      setModalData({
                        ...modalData,
                        team_id: e.target.value || studioDatas[0]?.id,
                      });
                    }}
                  >
                    <option value={""}>Chọn Team</option>
                    {studioDatas.map((studio: any) => (
                      <option key={studio.id} value={studio.id}>
                        {studio.name}
                      </option>
                    ))}
                  </Select>
                </Box>
              )}
              <Box>
                <label htmlFor="">Role:</label>
                <Select
                  name="user_role"
                  defaultValue={modalData?.role_id || "3"}
                  onChange={(e) => {
                    setModalData({
                      ...modalData,
                      roles: e.target.value || modalData?.role_id,
                    });
                  }}
                >
                  <option value="1">Admin</option>
                  <option value="2">Manager</option>
                  <option value="3">User</option>
                </Select>
              </Box>
            </ModalBody>
            <ModalFooter justifyContent={"right"} gap={3} mt={3}>
              <Button onClick={closeModal}>Huỷ</Button>
              <Button colorScheme={"blue"} type="submit">
                {modalType === constant.ADD_MODAL ? "Tạo" : "Sửa"}
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    );
  }
  return (
    <Modal isOpen={isOpen} onClose={closeModal}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Khoá user</ModalHeader>
        <ModalCloseButton />
        <ModalBody textAlign={"center"}>
          <Text fontSize={"md"} py={3}>
            Bạn có chắc chắn muốn khoá tài khoản{" "}
            {getUsernameFromEmail(modalData?.username)} ?
          </Text>
          <Text fontSize={"sm"} fontStyle={"italic"} color={"red.400"}>
            *Khoá tài khoản sẽ không thể đăng nhập vào hệ thống
          </Text>
        </ModalBody>
        <ModalFooter justifyContent={"right"} gap={3}>
          <Button onClick={closeModal}>Huỷ</Button>{" "}
          <Button colorScheme={"red"} onClick={onSubmitModal}>
            Đồng ý
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalUserCommon;
