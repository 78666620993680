import { Box } from "@chakra-ui/react";
import Card from "components/card/Card";
import React from "react";

interface RoleAccessLayoutProps {
  children: React.ReactNode;
}

const DashboardLayout = ({ children }: RoleAccessLayoutProps) => {
  return (
    <Box overflowX="auto">
      <Card
        flexDirection="column"
        w="100%"
        minH={{ sm: "50vh", md: "85vh" }}
        overflowX={{ sm: "scroll", lg: "hidden" }}
      >
        {children}
      </Card>
    </Box>
  );
};

export default DashboardLayout;
