import { useCallback } from "react";
import { useToast } from "@chakra-ui/react";

type NotificationStatus = "success" | "error" | "warning" | "info";

const useOpenNotification = () => {
  const toast = useToast();

  const openNotification = useCallback(
    (
      title: string,
      status: NotificationStatus = "success",
      duration: number = 1000
    ) => {
      toast({
        title: title,
        status: status,
        duration: duration,
        position: "top-right",
        isClosable: true,
      });
    },
    [toast]
  );

  return openNotification;
};

export default useOpenNotification;
