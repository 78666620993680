import { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Select,
  Spinner,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import TextEditor from "../EmailEditor/TextEditor";
import Card from "components/card/Card";
import ErrorMessage from "components/common/ErrorFormMessage";
import { EmailTemplateContext } from "..";
import { ArrowBackIcon } from "@chakra-ui/icons";
import {
  HTTP_CODE,
  CONVERTED_MESSAGE,
  STATUS_EMAIL_TEMPLATE_NAME,
} from "common/constants";
import useOpenNotification from "hook/useOpenNotification";
import { emailTemplateServices } from "services/EmailTemplate/EmailTemplateServices";
import { sleep } from "common/utils";

const EDIT_EMAIL_TEMPLATE_PATH = "edit-template-email";

const EmailTemplateForm = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isCreateEmailTemplate, setIsCreateEmailTemplate] =
    useState<boolean>(true);

  const emailTemplateContext = useContext(EmailTemplateContext);
  const { textEditorValue, setTextEditorValue } = emailTemplateContext;

  const openNotification = useOpenNotification();
  const history = useHistory();
  const location = history.location.pathname;

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    reset: resetFormValue,
    setValue: setFormValue,
  } = useForm();

  const resetEmailtemplateForm = () => {
    setIsLoading(false);
    resetFormValue();
    setTextEditorValue({ value: "" });
  };

  const saveEmailTemplate = async () => {
    setIsLoading(true);
    if (!textEditorValue.value) {
      setTextEditorError({ message: "Nội dung email không được để trống" });
      return;
    }
    try {
      let bodyData: {
        name: string;
        title: string;
        content: string;
        status?: string;
      } = {
        name: getValues("templateEmailName"),
        title: getValues("titleEmail"),
        content: textEditorValue.value,
      };

      if (getValues("status")) {
        bodyData.status = getValues("status");
      }

      if (isCreateEmailTemplate) {
        const createdEmailTemplateRes =
          await emailTemplateServices.createEmailTemplate(bodyData, {});

        if (createdEmailTemplateRes?.data?.code === HTTP_CODE.CREATED) {
          openNotification("Tạo mẫu email thành công");
          resetEmailtemplateForm();
          history.push("/admin/manage-email");
        } else {
          setIsLoading(false);
          openNotification(
            CONVERTED_MESSAGE[createdEmailTemplateRes?.data?.message],
            "error"
          );
        }
      } else {
        const updatedEmailTemplateRes =
          await emailTemplateServices.updateEmailTemplate(
            bodyData,
            { id: location.split("/").pop() },
            {}
          );

        if (updatedEmailTemplateRes?.data?.code === HTTP_CODE.SUCCESS) {
          openNotification("Sửa mẫu email thành công");
          history.push("/admin/manage-email");
          resetEmailtemplateForm();
        } else {
          setIsLoading(false);
          openNotification(
            CONVERTED_MESSAGE[updatedEmailTemplateRes?.data?.message],
            "error"
          );
        }
      }
      await sleep(300);
    } catch (error) {
      history.push("/admin/manage-email");
      resetEmailtemplateForm();
      throw error;
    }
  };

  const [textEditorError, setTextEditorError] = useState<any>("");

  const labelColor = useColorModeValue("secondaryGray.500", "white");
  const textColor = useColorModeValue("secondaryGray.900", "white");

  const getEditEmailTemplate = async () => {
    try {
      const emailTemplateRes = await emailTemplateServices.getOneEmailTemplate({
        id: location.split("/").pop(),
      });

      if (emailTemplateRes?.status === HTTP_CODE.SUCCESS) {
        if (_.isEmpty(emailTemplateRes?.data)) {
          history.push("/admin/manage-email");
        } else {
          const emailTemplateData = emailTemplateRes?.data;

          // set value for edit email template
          setFormValue("templateEmailName", emailTemplateData?.name);
          setFormValue("titleEmail", emailTemplateData?.title);
          setFormValue("status", emailTemplateData?.status);
          setTextEditorValue({ value: emailTemplateData?.content });
        }
      }
    } catch (error) {
      history.push("/admin/manage-email");
      throw error;
    }
  };

  useEffect(() => {
    // is edit email template
    if (location.includes(EDIT_EMAIL_TEMPLATE_PATH)) {
      setIsCreateEmailTemplate(false);
      getEditEmailTemplate();
    } else {
      setIsCreateEmailTemplate(true);
    }
  }, [location]);

  return (
    <Card flex={0.75} borderRadius="none" pt="0">
      {!isLoading ? (
        <>
          <Box
            onClick={() => history.push("/admin/manage-email")}
            d="flex"
            alignItems="center"
            color="brand.500"
            fontSize="20px"
            _hover={{
              cursor: "pointer",
              color: "brand.400",
              transform: "translateX(-2px)",
              textDecoration: "underline",
            }}
            width="fit-content"
          >
            <ArrowBackIcon />
            <Text> Xem mẫu email</Text>
          </Box>

          <Heading size="md" mt="20px">
            {isCreateEmailTemplate ? "Thêm" : "Sửa"} mẫu email mới
          </Heading>
          <Flex>
            <form
              onSubmit={handleSubmit(saveEmailTemplate)}
              style={{
                width: "100%",
                display: "flex",
                marginBottom: "5px",
              }}
            >
              <Flex w="100%" flexDirection="column" gap="30px" mt="30px">
                <FormControl>
                  <FormLabel color={labelColor} fontSize="12px">
                    TÊN MẪU EMAIL
                  </FormLabel>
                  <Input
                    color={textColor}
                    w="100%"
                    {...register("templateEmailName", {
                      required: "Tên mẫu email không được để trống",
                    })}
                    name="templateEmailName"
                    border="none"
                    borderBottom="2px solid #a09c9c"
                  />
                  <ErrorMessage error={errors.templateEmailName} />
                </FormControl>

                <FormControl>
                  <FormLabel color={labelColor} fontSize="12px">
                    TIÊU ĐỀ EMAIL
                  </FormLabel>
                  <Input
                    color={textColor}
                    w="100%"
                    {...register("titleEmail", {
                      required: "Tiêu đề email không được để trống",
                    })}
                    name="titleEmail"
                    borderBottom="2px solid #a09c9c" // Se
                  />
                  <ErrorMessage error={errors.titleEmail} />
                </FormControl>

                <FormControl>
                  <Select
                    {...register("status")}
                    placeholder="Chọn trạng thái"
                    name="status"
                    w="30%"
                  >
                    {STATUS_EMAIL_TEMPLATE_NAME.map((status) => (
                      <option key={status?.value} value={status?.value}>
                        {status?.label}
                      </option>
                    ))}
                  </Select>
                </FormControl>

                <Box mt="20px">
                  <FormLabel color={labelColor} fontSize="12px">
                    NỘI DUNG EMAIL
                  </FormLabel>
                  <TextEditor
                    textValue={textEditorValue}
                    onChange={setTextEditorValue}
                  />
                  <ErrorMessage error={textEditorError} />
                </Box>

                <Button
                  type="submit"
                  w="100%"
                  color={textColor}
                  colorScheme="blue"
                >
                  {isCreateEmailTemplate ? "TẠO" : "SỬA"}
                </Button>
              </Flex>
            </form>
          </Flex>
        </>
      ) : (
        <>
          <Flex h="70vh" justifyContent="center" alignItems="center">
            <Spinner />
          </Flex>
        </>
      )}
    </Card>
  );
};

export default EmailTemplateForm;
