import {
  Box,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  IMailHistory,
  IStatusColumnConstants,
  IUser,
  OptionType,
} from "types/interface";
import PreviewMailHistoryModal from "./PreviewMailHistoryModal";
import { useEffect, useState } from "react";
import CustomFilter from "components/common/CustomFilter";
import { cvTableServices } from "../cvTable/CvTableService";
import { HTTP_CODE, LIST_ROLE, statusColumnConstants } from "common/constants";
import useOpenNotification from "hook/useOpenNotification";
import { userServices } from "services/User/UserServices";
import { getUsernameFromEmail, TruncatedHtmlContent } from "common/utils";
import dayjs from "common/dayjsConfig";

const convertStatus = (status: string) => {
  return statusColumnConstants.find(
    (item: IStatusColumnConstants) => item.status === status
  )?.columnName;
};

const statusFilterOptions = statusColumnConstants.map(
  (item: IStatusColumnConstants) => ({
    value: item.status,
    label: item.columnName,
  })
);

interface MailHistoryTableProps {
  listMailHistory: IMailHistory[];
  isLoading: boolean;
  selectedFilterMailHistory?: any;
}

const EmailHistoryTable = (props: MailHistoryTableProps) => {
  const { listMailHistory, isLoading, selectedFilterMailHistory } = props;

  const [isOpenPreviewMailHistoryModal, setIsOpenPreviewMailHistoryModal] =
    useState<boolean>(false);
  const [previewMailHistoryInfo, setPreviewMailHistoryInfo] =
    useState<IMailHistory | null>(null);
  const [filterJobOption, setFilterJobOption] = useState<OptionType[]>([]);
  const [filterEmailSendByUserOption, setFilterEmailSendByUserOption] =
    useState<OptionType[]>([]);

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const bgColor = useColorModeValue("white", "navy.800");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

  const openNotification = useOpenNotification();

  const cellStyles = {
    borderColor: borderColor,
    fontSize: { sm: "14px" },
    minW: { sm: "150px", md: "200px", lg: "auto" },
    textColor: textColor,
    padding: "16px",
  };

  const handlePreviewMailHistory = (mailHistoryInfo: IMailHistory) => {
    setIsOpenPreviewMailHistoryModal(true);
    setPreviewMailHistoryInfo(mailHistoryInfo);
  };

  const onClosePreviewMailHistoryModal = () => {
    setIsOpenPreviewMailHistoryModal(false);
    setPreviewMailHistoryInfo(null);
  };

  const tableHeaderStyle = {
    borderColor: borderColor,
    cursor: "pointer",
    p: "16px",
  };

  const getDataFilterJob = async () => {
    const header = {
      page: 0,
      size: 1000,
      field: "jobName",
      order: "ASC",
    };

    try {
      const response = await cvTableServices.getJobData("", header);
      if (response?.data?.data) {
        const jobData = response.data.data.map((job: any) => {
          return {
            value: job?.id,
            label: job?.jobname,
          };
        });

        setFilterJobOption(jobData);
      } else {
        setFilterJobOption([]);
      }
    } catch (error) {
      openNotification("Network error", "error");
      setFilterJobOption([]);
    }
  };

  const getFilterEmailSendByUserOption = async () => {
    try {
      // only ADMIN can send mail so we only get user with role ADMIN
      const params = {
        role_id: LIST_ROLE.ADMIN,
      };

      const getListUserRes = await userServices.getAllUser(params, {});

      if (getListUserRes?.status === HTTP_CODE.SUCCESS) {
        const data = getListUserRes?.data?.data.map((item: IUser) => {
          return {
            value: item?.id,
            label: getUsernameFromEmail(item?.username),
          };
        });
        setFilterEmailSendByUserOption(data);
      } else {
        setFilterEmailSendByUserOption([]);
      }
    } catch (error) {
      setFilterEmailSendByUserOption([]);
    }
  };

  useEffect(() => {
    getDataFilterJob();
    getFilterEmailSendByUserOption();
  }, []);

  return (
    <TableContainer width={"100%"} minH="400px">
      <Table size={"lg"} variant="simple" colorScheme="orange">
        <Thead position="sticky" top={0} zIndex="docked" background={"white"}>
          <Tr>
            <Th {...tableHeaderStyle}>Tên ứng viên</Th>
            <Th {...tableHeaderStyle}>
              <Flex>
                <span>Vị trí ứng tuyển</span>
                <CustomFilter
                  options={filterJobOption}
                  isMulti={true}
                  onChange={(value: OptionType) => {
                    selectedFilterMailHistory.current = {
                      ...selectedFilterMailHistory.current,
                      filterJob: value,
                    };
                  }}
                  value={selectedFilterMailHistory?.current?.filterJob}
                />
              </Flex>
            </Th>
            <Th {...tableHeaderStyle}>Nội dung</Th>
            <Th {...tableHeaderStyle}>
              <Flex>
                <span>Trạng thái</span>
                <CustomFilter
                  options={statusFilterOptions}
                  isMulti={true}
                  onChange={(value: OptionType) => {
                    selectedFilterMailHistory.current = {
                      ...selectedFilterMailHistory.current,
                      filterStatus: value,
                    };
                  }}
                  value={selectedFilterMailHistory?.current?.filterStatus}
                />
              </Flex>
            </Th>
            <Th {...tableHeaderStyle}>Thời gian</Th>
            <Th {...tableHeaderStyle}>
              <Flex>
                <span>Người gửi</span>
                <CustomFilter
                  options={filterEmailSendByUserOption}
                  isMulti={true}
                  onChange={(value: OptionType) => {
                    selectedFilterMailHistory.current = {
                      ...selectedFilterMailHistory.current,
                      filterUser: value,
                    };
                  }}
                  value={selectedFilterMailHistory?.current?.filterUser}
                />
              </Flex>
            </Th>
            <Th {...tableHeaderStyle}>Action</Th>
          </Tr>
        </Thead>

        {!isLoading && (
          <>
            <Tbody bg={bgColor}>
              {listMailHistory.map((mailHistoryInfo) => (
                <Tr key={mailHistoryInfo.id} p="16px">
                  <Td {...cellStyles}>{mailHistoryInfo?.candidate_name}</Td>
                  <Td {...cellStyles}>{mailHistoryInfo?.job_name}</Td>
                  <Td {...cellStyles}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: TruncatedHtmlContent(
                          mailHistoryInfo?.content,
                          20
                        ),
                      }}
                    />
                  </Td>
                  <Td {...cellStyles}>
                    {convertStatus(mailHistoryInfo?.status)}
                  </Td>
                  <Td {...cellStyles}>
                    {dayjs
                      .utc(mailHistoryInfo?.created_at)
                      .format("HH:mm DD/MM")}
                  </Td>

                  <Td {...cellStyles}>
                    {getUsernameFromEmail(mailHistoryInfo?.mail_send_by)}
                  </Td>

                  <Td>
                    <Box
                      fontSize="0.875rem"
                      bg="#3965FF"
                      color="#ffffff"
                      borderRadius="16px"
                      p="0 8px"
                      h="32px"
                      fontWeight="600"
                      d="flex"
                      justifyContent="center"
                      alignItems="center"
                      cursor="pointer"
                      _hover={{ bg: "#3652ba" }}
                      onClick={() => handlePreviewMailHistory(mailHistoryInfo)}
                    >
                      <span>Chi tiết</span>
                    </Box>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </>
        )}
      </Table>

      {listMailHistory?.length === 0 && (
        <Flex
          alignItems="center"
          justifyContent="center"
          minWidth="100%"
          mt="15px"
          position="absolute"
          transform="translate(-50%, -50%)"
          top="50%"
          left="50%"
        >
          <Text
            fontStyle="italic"
            textAlign="center"
            fontSize="22px"
            fontWeight="bold"
          >
            Không có dữ liệu
          </Text>
        </Flex>
      )}

      <PreviewMailHistoryModal
        isOpen={isOpenPreviewMailHistoryModal}
        onClose={onClosePreviewMailHistoryModal}
        mailHistoryInfo={previewMailHistoryInfo}
      />
    </TableContainer>
  );
};

export default EmailHistoryTable;
