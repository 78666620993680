import ApiServices from "./ApiServices";
import { API_URL } from "config/environment";

const base_url = API_URL + "/user";

class UserServices extends ApiServices {
  public async getUserDatas(params: any, headers: any) {
    return await this.getRequest(base_url, params, headers);
  }
  public async addUser(params: any, data: any, header: any) {
    return await this.postRequest(base_url, params, data, header);
  }
  public async updateUser(id: any, params: any, data: any, header: any) {
    return await this.putRequest(`${base_url}/${id}`, params, data, header);
  }
  public async deleteUser(id: any, params: any, header: any) {
    return await this.deleteRequest(`${base_url}/${id}`, params, header);
  }
}

export const userServices = new UserServices();
