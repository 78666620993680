import { useEffect } from "react";
import {
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalBody,
  Flex,
  FormControl,
  FormLabel,
  useColorModeValue,
  Input,
  ModalFooter,
  Button,
} from "@chakra-ui/react";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import ErrorMessage from "components/common/ErrorFormMessage";
import {
  actSetEditMatrixData,
  actSetOpenMatrixModal,
} from "redux/features/superAdminSlice";
import { RootState } from "redux/store";
import { HTTP_CODE, LIST_ROLE, METHOD } from "common/constants";
import { matrixServices } from "services/Matrix/MatrixServices";
import useOpenNotification from "hook/useOpenNotification";
import { OptionType } from "types/interface";

export const methodOption = Object.values(METHOD).map((method) => ({
  value: method,
  label: method,
}));

export const roleOption = Object.entries(LIST_ROLE).map(
  ([roleName, roleValue]) => ({
    label: roleName,
    value: roleValue,
  })
);

interface AddMatrixModalProps {
  setComponentShouldBeRefresh: () => void;
}

const AddMatrixModal = (props: AddMatrixModalProps) => {
  const { setComponentShouldBeRefresh } = props;

  const dispatch = useDispatch();
  const superAdminState = useSelector((state: RootState) => state.SuperAdmin);
  const { isOpenMatrixModal, editMatrixData } = superAdminState;

  const isEditMatrix = !!editMatrixData;

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const openNotification = useOpenNotification();

  const {
    register,
    setValue: setFormValue,
    formState: { errors },
    getValues,
    handleSubmit,
    control,
    reset: resetFormValue,
  } = useForm();

  const onCloseMatrixModal = () => {
    dispatch(actSetOpenMatrixModal(false));
    dispatch(actSetEditMatrixData(null));
    resetFormValue();
  };

  const onCreateMatrix = async () => {
    const matrixBody = {
      ...getValues(),
      roles: getValues("role").map((role: OptionType) => role.value),
      method: getValues("method").value,
    };

    try {
      if (isEditMatrix) {
        const updateMatrixRes = await matrixServices.updateMatrixData(
          matrixBody,
          { id: editMatrixData?.id }
        );

        if (updateMatrixRes?.status === HTTP_CODE.SUCCESS) {
          openNotification("Cập nhật matrix thành công");
          setComponentShouldBeRefresh();
        }
      } else {
        const createMatrixRes = await matrixServices.createMatrixData(
          matrixBody
        );

        if (createMatrixRes?.status === HTTP_CODE.CREATED) {
          openNotification("Tạo matrix thành công");
          setComponentShouldBeRefresh();
        }
      }

      onCloseMatrixModal();
    } catch (error) {
      onCloseMatrixModal();
      throw error;
    }
  };

  useEffect(() => {
    if (isEditMatrix) {
      setFormValue("endpoint", editMatrixData?.endpoint);
      setFormValue("page", editMatrixData?.page);
      setFormValue(
        "method",
        methodOption.find((method) => method.value === editMatrixData?.method)
      );
      setFormValue(
        "role",
        roleOption.filter((role) =>
          editMatrixData?.roleIds?.split(",")?.includes(role.value.toString())
        )
      );
    }
  }, [isOpenMatrixModal]);

  return (
    <Modal isOpen={isOpenMatrixModal} onClose={onCloseMatrixModal}>
      <ModalOverlay />

      <ModalContent>
        <ModalHeader>{isEditMatrix ? "Sửa matrix" : "Thêm matrix"}</ModalHeader>
        <ModalCloseButton />

        <ModalBody>
          <form
            style={{
              width: "100%",
              display: "flex",
              marginBottom: "5px",
            }}
          >
            <Flex flexDirection="column" gap="10px" w="100%">
              <FormControl>
                <FormLabel>Endpoint</FormLabel>
                <Input
                  color={textColor}
                  {...register("endpoint", {
                    required: "Hãy nhập endpoint",
                  })}
                  name="endpoint"
                />
                <ErrorMessage error={errors.endpoint} />
              </FormControl>

              <FormControl>
                <FormLabel>Method</FormLabel>

                <Controller
                  name="method"
                  {...register("method", {
                    required: "Hãy chọn method",
                  })}
                  control={control}
                  defaultValue={[]}
                  render={({ field }) => (
                    <Select
                      value={field.value}
                      options={methodOption}
                      onChange={(selectedOption) =>
                        field.onChange(selectedOption)
                      }
                    />
                  )}
                />
                <ErrorMessage error={errors.method} />
              </FormControl>

              <FormControl>
                <FormLabel>Page</FormLabel>
                <Input
                  color={textColor}
                  {...register("page", {
                    required: "Hãy nhập page",
                  })}
                  name="page"
                />
                <ErrorMessage error={errors.page} />
              </FormControl>

              <FormControl>
                <FormLabel>Method</FormLabel>

                <Controller
                  name="role"
                  {...register("role", {
                    required: "Hãy chọn role",
                  })}
                  control={control}
                  defaultValue={[]}
                  render={({ field }) => (
                    <Select
                      closeMenuOnSelect={false}
                      isMulti
                      value={field.value}
                      options={roleOption}
                      onChange={(selectedOption) =>
                        field.onChange(selectedOption)
                      }
                    />
                  )}
                />
                <ErrorMessage error={errors.role} />
              </FormControl>
            </Flex>
          </form>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="red" mr={3} onClick={onCloseMatrixModal}>
            Đóng
          </Button>
          <Button
            colorScheme="blue"
            onClick={handleSubmit(onCreateMatrix)}
            type="submit"
          >
            {isEditMatrix ? "Cập nhật" : "Tạo mới"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddMatrixModal;
