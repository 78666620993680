import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  useColorModeValue,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  useDisclosure,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import { CV_STATUS, HTTP_CODE } from "common/constants";
import useOpenNotification from "hook/useOpenNotification";
import { useContext, useRef, useState } from "react";
import { RxDotsVertical } from "react-icons/rx";
import { ThemeContext } from "views/admin/default/components/cvManage/CvManage";
import { cvManageServices } from "views/admin/default/components/cvManage/CvManageServices";
import AssignInterviewModal from "views/admin/default/components/cvManage/Modal/AssignInterviewModal";
import { getColumnNameByStatus } from "common/utils";
import { ICandidate } from "types/interface";

const STEP_STATUS_INDEX = {
  NEW: 1,
  REVIEW: 2,
  TEST: 3,
  INTERVIEW: 4,
  OFFER: 5,
  REJECT: 6,
};

interface CvMenuProps {
  currentStatusIndex: number;
  data: ICandidate;
}

const CvMenu = (props: CvMenuProps) => {
  const { data, currentStatusIndex } = props;
  const { status } = data;

  const cvManageContext = useContext(ThemeContext);
  const { setListStatusFetchData } = cvManageContext;

  const {
    isOpen: isOpenDialog,
    onOpen: onOpenDialog,
    onClose: onCloseDialog,
  } = useDisclosure();
  const cancelRef = useRef();

  const openNotification = useOpenNotification();

  const [isOpenAssignInterviewModal, setIsOpenAssignInterviewModal] =
    useState<boolean>(false);
  const [isOpenChangeStudioModal, setIsOpenChangeStudioModal] =
    useState<boolean>(false);

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const iconColor = useColorModeValue("#1B2559", "white");

  const handleDeleteCv = async (id: number) => {
    const params = { id };
    const body = {
      status: CV_STATUS.DELETED,
    };
    try {
      const changeCvRes = await cvManageServices.changeCvStatus(
        body,
        params,
        {}
      );

      if (changeCvRes?.status === HTTP_CODE.SUCCESS) {
        openNotification("Thành công");
        const columnStatusName = getColumnNameByStatus(status);
        setListStatusFetchData([columnStatusName]);
      }
    } catch (error) {
      throw error;
    } finally {
      onCloseDialog();
    }
  };

  const onCloseConfirmButton = () => {
    onCloseDialog();
  };

  return (
    <>
      <Menu>
        <MenuButton _hover={{ color: "red" }}>
          <RxDotsVertical color={iconColor} />
        </MenuButton>
        <MenuList color={textColor}>
          <MenuItem color={textColor} onClick={onOpenDialog}>
            Xoá CV
          </MenuItem>

          <MenuItem
            color={textColor}
            onClick={() => setIsOpenAssignInterviewModal(true)}
          >
            Assign phỏng vấn
          </MenuItem>

          {isOpenAssignInterviewModal && (
            <AssignInterviewModal
              isOpen={isOpenAssignInterviewModal}
              onClose={() => setIsOpenAssignInterviewModal(false)}
              candidateInfo={data}
              columnStatus={status}
            />
          )}
        </MenuList>
      </Menu>

      <AlertDialog
        isOpen={isOpenDialog}
        leastDestructiveRef={cancelRef}
        onClose={onCloseConfirmButton}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Chuyển trạng thái CV
            </AlertDialogHeader>

            <AlertDialogBody>
              Bạn có muốn xoá CV? Hành động này không thể hoàn tác.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onCloseConfirmButton}>
                Huỷ
              </Button>
              <Button
                colorScheme="red"
                ml={3}
                onClick={() => handleDeleteCv(data?.id)}
              >
                Xoá
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default CvMenu;
