import { ApiServices } from "services/ApiServices";
import { API_URL } from "config/environment";
import { API_URL_PATH } from "common/constants";

const base_url = API_URL + API_URL_PATH.MATRIX;

export class MatrixServices extends ApiServices {
  public async getAllMatrix(params: any, headers: any) {
    return await this.getRequest(base_url, params, headers);
  }

  public async createMatrixData(body: any) {
    return await this.postRequest(base_url, {}, body);
  }

  public async updateMatrixData(body: any, params: any) {
    return await this.putRequest(base_url + `/${params.id}`, params, body, {});
  }

  public async deleteMatrix(params: any) {
    return await this.deleteRequest(base_url + `/${params.id}`, params, {});
  }
}

export const matrixServices = new MatrixServices();
