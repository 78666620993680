import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { UnFilterIcon } from "components/icons/Icons";
import { IoAddCircleOutline } from "react-icons/io5";
import Card from "components/card/Card";
import {
  Box,
  Button,
  Flex,
  FormControl,
  Input,
  Text,
  Tooltip,
  useColorModeValue,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import { masterDataService } from "services/MasterData/MasterDataServices";
import { IMasterData, OptionType } from "types/interface";
import CustomPagination from "components/common/Pagination";
import { useToggle } from "hook/useToggle";
import { usePagination } from "@ajna/pagination";
import { DEFAULT_PAGE_SIZE, HTTP_CODE } from "common/constants";

import { actSetOpenMasterDataModal } from "redux/features/superAdminSlice";
import AddMasterDataModal from "views/SuperAdmin/ManageRoleMatrix/ManageMasterData/AddMasterDataModal";
import ManageMasterDataTable from "views/SuperAdmin/ManageRoleMatrix/ManageMasterData/ManageMasterDataTable";

const EMPTY_QUERY: {
  queryName: string;
  queryType: OptionType[];
} = {
  queryName: "",
  queryType: [],
};

export type SearchQueryMasterData = {
  queryName?: string;
  queryType?: OptionType[];
};

const ManageMasterData = () => {
  const [listMasterData, setListMasterData] = useState<IMasterData[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [searchQueryMasterData, setSearchQueryMasterData] =
    useState<SearchQueryMasterData>(EMPTY_QUERY);

  const { register, handleSubmit } = useForm();

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const focusBorderColor = useColorModeValue("secondaryGray.500", "white");

  const dispatch = useDispatch();

  // pagination table
  const [totalData, setTotalData] = useState<number | undefined>(undefined);
  const [componentShouldBeRefresh, setComponentShouldBeRefresh] =
    useToggle(false);
  const outerLimit = 2;
  const innerLimit = 2;

  const {
    pages,
    pagesCount,
    currentPage,
    setCurrentPage,
    pageSize,
    setPageSize,
  } = usePagination({
    total: totalData,
    initialState: {
      pageSize: DEFAULT_PAGE_SIZE,
      currentPage: 1,
    },
    limits: {
      outer: outerLimit,
      inner: innerLimit,
    },
  });

  const getMasterDataData = async () => {
    setIsLoading(true);

    const header = {
      page: currentPage - 1,
      size: pageSize,
    };

    // get search query master data
    let queryParamsMasterData: any = {};
    const { queryName, queryType } = searchQueryMasterData || {};

    if (queryName) {
      queryParamsMasterData.name = queryName;
    }

    if (queryType) {
      queryParamsMasterData.type = queryType
        ?.map((option) => option.value)
        ?.join(",");
    }

    try {
      const maxtrixRes = await masterDataService.getMasterData(
        queryParamsMasterData,
        header
      );

      if (maxtrixRes?.status === HTTP_CODE.SUCCESS) {
        setListMasterData(maxtrixRes?.data?.data);
        setTotalData(maxtrixRes?.data?.totalItems);
      }
    } catch (error) {
      setListMasterData([]);
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  const handlePageChange = (nextPage: number): void => {
    setCurrentPage(nextPage);
  };

  const onChangePageSize = (pageSize: number) => {
    setPageSize(pageSize);
    setCurrentPage(1);
  };

  const handleAddNewMasterData = () => {
    dispatch(actSetOpenMasterDataModal(true));
  };

  const onSearchMasterData = () => {
    setCurrentPage(1);
    setComponentShouldBeRefresh();
  };

  const onClearFilter = () => {
    setSearchQueryMasterData(EMPTY_QUERY);
    setComponentShouldBeRefresh();
  };

  useEffect(() => {
    getMasterDataData();
  }, [currentPage, pageSize, componentShouldBeRefresh]);

  return (
    <Box w="full">
      <Card
        flexDirection="column"
        w="100%"
        overflowX={{ sm: "scroll", lg: "hidden" }}
        minHeight={{ sm: "50vh", md: "85vh" }}
      >
        <Flex px="25px" mb="8px" justifyContent="space-between" align="center">
          <Flex gap="5px">
            <Text
              color={textColor}
              fontSize="22px"
              fontWeight="700"
              lineHeight="100%"
            >
              Manage master data
            </Text>

            <Tooltip label="Thêm master data" placement="top">
              <Box
                fontSize="24px"
                cursor="pointer"
                _hover={{ color: "#422AFB" }}
                onClick={handleAddNewMasterData}
              >
                <IoAddCircleOutline />
              </Box>
            </Tooltip>
          </Flex>

          <form>
            <Flex
              gap="10px"
              marginLeft="auto"
              maxHeight="32px"
              alignItems="center"
            >
              <FormControl>
                <Input
                  placeholder="Name ..."
                  size="sm"
                  _placeholder={{ opacity: 1, color: textColor }}
                  color={textColor}
                  {...register("searchMasterData")}
                  _focus={{ borderColor: focusBorderColor }}
                  w="130px"
                  onChange={(e) =>
                    setSearchQueryMasterData({
                      ...searchQueryMasterData,
                      queryName: e.target.value,
                    })
                  }
                  value={searchQueryMasterData?.queryName}
                />
              </FormControl>

              <FormControl>
                <Button
                  colorScheme="blue"
                  ml="auto"
                  onClick={handleSubmit(onSearchMasterData)}
                  size="sm"
                  type="submit"
                >
                  <SearchIcon />
                  <Text ml="3px">Tìm kiếm</Text>
                </Button>
              </FormControl>

              <FormControl>
                <Tooltip label="Xóa filter" placement="top">
                  <Box
                    cursor="pointer"
                    _hover={{ color: "#C53030" }}
                    onClick={onClearFilter}
                  >
                    <UnFilterIcon fontSize="24px" />
                  </Box>
                </Tooltip>
              </FormControl>
            </Flex>
          </form>
        </Flex>

        <Flex
          flexDirection="column"
          minHeight="70vh"
          justifyContent="space-between"
        >
          <ManageMasterDataTable
            listMasterData={listMasterData}
            setComponentShouldBeRefresh={setComponentShouldBeRefresh}
            searchQueryMasterData={searchQueryMasterData}
            setSearchQueryMasterData={setSearchQueryMasterData}
            isLoading={isLoading}
          />

          {listMasterData?.length === 0 && !isLoading && (
            <Flex
              alignItems="center"
              justifyContent="center"
              minWidth="100%"
              mt="15px"
              position="absolute"
              transform="translate(-50%, -50%)"
              top="50%"
              left="50%"
            >
              <Text
                fontStyle="italic"
                textAlign="center"
                fontSize="24px"
                fontWeight="bold"
              >
                Không có dữ liệu
              </Text>
            </Flex>
          )}

          {listMasterData?.length > 0 && !isLoading && (
            <>
              <CustomPagination
                pages={pages}
                pagesCount={pagesCount}
                currentPage={currentPage}
                onPageChange={handlePageChange}
                pageSize={pageSize}
                onPageSizeChange={onChangePageSize}
                optionPageSize={[10, 20, 50]}
              />
            </>
          )}
        </Flex>

        <AddMasterDataModal
          setComponentShouldBeRefresh={setComponentShouldBeRefresh}
        />
      </Card>
    </Box>
  );
};

export default ManageMasterData;
