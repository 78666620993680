import { useContext, useEffect, useState } from "react";
import {
  Flex,
  Table,
  TableContainer,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import _ from "lodash";
import CandidateRowItem from "./CandidateRowItem";
import CustomFilter from "components/common/CustomFilter";
import { getListJobName } from "services/utils";
import { ICandidate, IJob, OptionType } from "types/interface";
import { CandidateInfomationContext } from "..";

interface ListCandidateTableProps {
  listCandidate: ICandidate[];
}

const ListCandidateTable = (props: ListCandidateTableProps) => {
  const { listCandidate } = props;

  const [listJobOption, setListJobOption] = useState<OptionType[]>([]);

  const candidateInfomationContext = useContext(CandidateInfomationContext);
  const { selectedFilterJob, setSelectedFilterJob } =
    candidateInfomationContext;

  const textColor = useColorModeValue("secondaryGray.900", "white");

  const getListJobData = async () => {
    const jobDataRes = await getListJobName();

    if (!_.isEmpty(jobDataRes)) {
      const jobData = jobDataRes.map((job: IJob) => {
        return {
          value: job?.id,
          label: job?.jobname,
        };
      });

      setListJobOption(jobData);
    } else {
      setListJobOption([]);
    }
  };

  useEffect(() => {
    getListJobData();
  }, []);

  return (
    <TableContainer width={"100%"} minHeight="400px">
      <Table size={"lg"} variant="simple" colorScheme="orange">
        <Thead position="sticky" top={0} zIndex="docked" background={"white"}>
          <Tr>
            <Th cursor="pointer" textColor={textColor}>
              Họ và tên
            </Th>
            <Th cursor="pointer" textColor={textColor}>
              Thông tin liên hệ
            </Th>

            <Th cursor="pointer">
              <Flex align="center" fontSize={{ sm: "10px", lg: "12px" }}>
                <span>Vị trí ứng tuyển</span>
                <CustomFilter
                  options={listJobOption}
                  defaultValue={selectedFilterJob?.current}
                  isMulti={true}
                  onChange={(value: OptionType) => setSelectedFilterJob(value)}
                  value={selectedFilterJob?.current}
                />
              </Flex>
            </Th>
            <Th cursor="pointer">Nguồn</Th>
            <Th cursor="pointer">Ngày ứng tuyển</Th>
          </Tr>
        </Thead>

        <Tbody>
          {listCandidate.map((candidate: any) => (
            <Tr key={candidate?.id}>
              <CandidateRowItem candidate={candidate} />
            </Tr>
          ))}
        </Tbody>
      </Table>

      {listCandidate.length === 0 && (
        <Flex
          alignItems="center"
          justifyContent="center"
          minWidth="100%"
          mt="15px"
          position="absolute"
          transform="translate(-50%, -50%)"
          top="50%"
          left="50%"
        >
          <Text
            fontStyle="italic"
            textAlign="center"
            fontSize="22px"
            fontWeight="bold"
          >
            Không có dữ liệu
          </Text>
        </Flex>
      )}
    </TableContainer>
  );
};

export default ListCandidateTable;
