import { useContext, useState } from "react";
import {
  Button,
  Flex,
  Heading,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import { IEvaluationCriteriaData } from "types/interface";
import { EvaluationCriteriaContext } from "..";
import CreateEvaluationCriteriaGroupModal from "./CreateEvaluationCriteriaGroupModal";

const EvaluationCriteriaGroup = () => {
  const [editCriteriaGroupData, setEditCriteriaGroupData] =
    useState<IEvaluationCriteriaData>(null);
  const [isOpenModalEdit, setIsOpenModalEdit] = useState<boolean>(false);

  const evaluationCriteriaContext = useContext(EvaluationCriteriaContext);
  const { evaluationCriteriaData, getEvaluationCriteriaData } =
    evaluationCriteriaContext;

  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const tableHeaderStyle = {
    borderColor: borderColor,
    textColor: textColor,
  };

  const handleEditCriteriaGroup = (criteriaGroup: IEvaluationCriteriaData) => {
    setIsOpenModalEdit(true);
    setEditCriteriaGroupData(criteriaGroup);
  };

  const handleAddCriteriaGroup = () => {
    setEditCriteriaGroupData(null);
    setIsOpenModalEdit(true);
  };

  return (
    <Card mt="5px">
      <Flex justifyContent="space-between" alignItems="center">
        <Heading as="h4" size="md" mb="10px">
          Danh sách nhóm tiêu chí đánh giá
        </Heading>

        <Button colorScheme="blue" size="sm" onClick={handleAddCriteriaGroup}>
          Thêm nhóm tiêu chí
        </Button>
      </Flex>

      <Table variant="simple" color="gray.500">
        <Thead>
          <Tr>
            <Th {...tableHeaderStyle}>Nhóm tiêu chí</Th>
            <Th {...tableHeaderStyle}>Số lượng tiêu chí</Th>
            <Th {...tableHeaderStyle}>Action</Th>
          </Tr>
        </Thead>

        <Tbody>
          {evaluationCriteriaData?.map(
            (criteriaGroup: IEvaluationCriteriaData) => (
              <Tr key={criteriaGroup?.criteriaGroupId} color={textColor}>
                <Td>{criteriaGroup?.name}</Td>
                <Td>{criteriaGroup?.data?.length || 0}</Td>
                <Td>
                  <Button
                    colorScheme="orange"
                    size="sm"
                    onClick={() => handleEditCriteriaGroup(criteriaGroup)}
                  >
                    Sửa
                  </Button>
                </Td>
              </Tr>
            )
          )}
        </Tbody>
      </Table>

      <CreateEvaluationCriteriaGroupModal
        isOpen={isOpenModalEdit}
        onClose={() => setIsOpenModalEdit(false)}
        editCriteriaGroupData={editCriteriaGroupData}
        getEvaluationCriteriaData={getEvaluationCriteriaData}
      />
    </Card>
  );
};

export default EvaluationCriteriaGroup;
