import { useEffect } from "react";
import {
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalBody,
  Flex,
  FormControl,
  FormLabel,
  useColorModeValue,
  Input,
  ModalFooter,
  Button,
  Textarea,
} from "@chakra-ui/react";
import _ from "lodash";
import { useForm } from "react-hook-form";
import ErrorMessage from "components/common/ErrorFormMessage";
import { Campaign } from "..";
import { validateEndDate } from "common/utils";

interface CreateCampaignModalProps {
  isOpen: boolean;
  onClose: () => void;
  campaignData?: Campaign;
}

const CreateCampaignModal = (props: CreateCampaignModalProps) => {
  const { isOpen, onClose, campaignData } = props;

  const {
    register,
    reset,
    setValue: setFormValue,
    formState: { errors },
    getValues,
    trigger,
  } = useForm();

  const textColor = useColorModeValue("secondaryGray.900", "white");

  const onCreateCampaign = async () => {
    // trigger to validate
    await trigger();
  };

  const isCreateCampaign = _.isEmpty(campaignData);

  useEffect(() => {
    if (!isCreateCampaign) {
      setFormValue("campaignName", campaignData?.name);
      setFormValue("campaignDescription", campaignData?.description);
      // need refactor later
      setFormValue("startDate", campaignData?.startDate);
      setFormValue("endDate", campaignData?.endDate);
    } else {
      reset();
    }
  }, [isOpen, campaignData]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="3xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {isCreateCampaign ? "Tạo campaign" : "Sửa campaign"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form
            style={{
              width: "100%",
              display: "flex",
              marginBottom: "5px",
            }}
          >
            <Flex flexDirection="column" gap="10px" w="100%">
              <FormControl>
                <FormLabel>Tên campaign</FormLabel>
                <Input
                  placeholder="Nhập tên campaign"
                  color={textColor}
                  {...register("campaignName", {
                    required: "Hãy nhập tên của campaign",
                  })}
                  name="campaignName"
                />
                <ErrorMessage error={errors.campaignName} />
              </FormControl>

              <FormControl>
                <FormLabel>Mô tả</FormLabel>
                <Textarea
                  placeholder="Nhập mô tả của campaign"
                  color={textColor}
                  {...register("campaignDescription")}
                  name="campaignDescription"
                />
              </FormControl>

              <Flex gap="50px">
                <FormControl>
                  <FormLabel>Ngày bắt đầu</FormLabel>
                  <Input
                    type="date"
                    {...register("startDate", {
                      required: "Hãy chọn ngày bắt đầu",
                    })}
                    name="startDate"
                  />
                  <ErrorMessage error={errors.startDate} />
                </FormControl>

                <FormControl>
                  <FormLabel>Ngày kết thúc</FormLabel>
                  <Input
                    type="date"
                    {...register("endDate", {
                      required: "Hãy chọn ngày kết thúc",
                      validate: (value) =>
                        validateEndDate(value, getValues("startDate")),
                    })}
                    name="endDate"
                  />
                  <ErrorMessage error={errors.endDate} />
                </FormControl>
              </Flex>
            </Flex>
          </form>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="red" mr={3} onClick={onClose}>
            Đóng
          </Button>
          <Button colorScheme="blue" onClick={onCreateCampaign}>
            {isCreateCampaign ? "Tạo" : "Sửa"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CreateCampaignModal;
