import { useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  FormControl,
  FormLabel,
  useColorModeValue,
} from "@chakra-ui/react";
import { CRITERIA_TYPE, HTTP_CODE } from "common/constants";
import ErrorMessage from "components/common/ErrorFormMessage";
import useOpenNotification from "hook/useOpenNotification";
import { useForm } from "react-hook-form";
import { masterDataService } from "services/MasterData/MasterDataServices";

interface CreateEvaluationCriteriaGroupModalProps {
  isOpen: boolean;
  onClose: () => void;
  editCriteriaGroupData: any;
  getEvaluationCriteriaData: () => void;
}

const CreateEvaluationCriteriaGroupModal = (
  props: CreateEvaluationCriteriaGroupModalProps
) => {
  const { isOpen, onClose, editCriteriaGroupData, getEvaluationCriteriaData } =
    props;

  const {
    formState: { errors },
    getValues,
    setValue: setFormValue,
    handleSubmit,
    register,
  } = useForm();

  const isCreateCriteriaGroup = !editCriteriaGroupData;
  const openNotification = useOpenNotification();

  const onCreateEvaluationCriteriaGroup = async () => {
    try {
      const { criteriaGroupName } = getValues();

      const bodyData = {
        name: criteriaGroupName,
        code: criteriaGroupName,
        type: CRITERIA_TYPE,
      };

      // is create new criteria group
      if (isCreateCriteriaGroup) {
        const createdCriteriaGroupRes =
          await masterDataService.createMasterData(bodyData, {});

        if (createdCriteriaGroupRes?.status === HTTP_CODE.CREATED) {
          openNotification("Thêm nhóm tiêu chí thành công");
          getEvaluationCriteriaData();
        }

        onClose();
        return;
      }

      // edit criteria group
      const params = {
        id: editCriteriaGroupData?.criteriaGroupId,
      };

      const updatedCriteriaGroupRes = await masterDataService.updateMasterData(
        bodyData,
        params,
        {}
      );

      if (updatedCriteriaGroupRes?.status === HTTP_CODE.SUCCESS) {
        openNotification("Sửa nhóm tiêu chí thành công");
        getEvaluationCriteriaData();
        onClose();
      }
    } catch (error) {
      onClose();
    }
  };

  const textColor = useColorModeValue("secondaryGray.900", "white");

  useEffect(() => {
    if (!isCreateCriteriaGroup) {
      setFormValue("criteriaGroupName", editCriteriaGroupData?.name);
    } else {
      setFormValue("criteriaGroupName", "");
    }
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {isCreateCriteriaGroup ? "Thêm nhóm tiêu chí" : "Sửa nhóm tiêu chí"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form
            style={{
              width: "100%",
              display: "flex",
              marginBottom: "5px",
            }}
          >
            <FormControl>
              <FormLabel>Tên nhóm tiêu chí</FormLabel>

              <Input
                placeholder="Nhập tên nhóm tiêu chí"
                color={textColor}
                {...register("criteriaGroupName", {
                  required: "Hãy nhập tên của campaign",
                })}
                name="criteriaGroupName"
              />
              <ErrorMessage error={errors.criteriaGroupName} />
            </FormControl>
          </form>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="red" mr={3} onClick={onClose}>
            Đóng
          </Button>
          <Button
            colorScheme="blue"
            type="submit"
            onClick={handleSubmit(onCreateEvaluationCriteriaGroup)}
          >
            {isCreateCriteriaGroup ? "Tạo" : "Sửa"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CreateEvaluationCriteriaGroupModal;
