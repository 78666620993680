import { Flex, Input, Text } from "@chakra-ui/react";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import DashboardLayout from "../../layout";
import AverageTimePerRoundChart from "./components/AverageTimePerRoundChart";
import ConversationRateChart from "./components/ConversationRateChart";
import CountCVByTeam from "./components/CountCVByTeam";
import CountCVSourceComponent from "./components/CountCVSourceComponent";
import CountCVStatusComponent from "./components/CountCVStatusComponent";

const ReportPage = () => {
  const [filterData, setFilterData] = useState<any>({
    fromDate: "",
    toDate: "",
  });

  const setInitialDate = () => {
    const formatedDate = dayjs().subtract(1, "month").format("YYYY-MM-DD");
    const formatedDateTo = dayjs().format("YYYY-MM-DD");
    setFilterData((prev: any) => ({
      ...prev,
      fromDate: formatedDate,
      toDate: formatedDateTo,
    }));
  };

  useEffect(() => {
    setInitialDate();
  }, []);

  return (
    <DashboardLayout>
      {/* header */}
      <Flex
        flexDirection={"column"}
        alignItems={"flex-start"}
        justifyContent={"flex-start"}
        px={4}
        pb={2}
        borderBottom={"1px solid #f5932a"}
        w="full"
      >
        <Text fontSize="2xl" fontWeight={"bold"}>
          Báo cáo tổng hợp
        </Text>
      </Flex>
      {/* filter */}
      <Flex gap={3} w={"full"} justifyContent="center" my={2}>
        <Flex alignItems={"center"} gap={2} w={"20%"}>
          <label htmlFor="fromDate">from:</label>
          <Input
            size={"sm"}
            id="fromDate"
            type={"date"}
            value={filterData.fromDate}
            onChange={(e) => {
              setFilterData((prev: any) => ({
                ...prev,
                fromDate: e.target.value,
              }));
            }}
          />
        </Flex>
        <Flex alignItems={"center"} gap={2} w={"20%"}>
          <label htmlFor="toDate">to:</label>
          <Input
            size={"sm"}
            id="toDate"
            type={"date"}
            value={filterData.toDate}
            onChange={(e) => {
              setFilterData((prev: any) => ({
                ...prev,
                toDate: e.target.value,
              }));
            }}
          />
        </Flex>
        {/* <Flex alignItems={"center"} gap={2}>
          <Select>
            <option value="">Select all department</option>
            <option value="skynet">Skynet</option>
            <option value="galaxy">Galaxy</option>
            <option value="unicorn">Unicorn</option>
          </Select>
        </Flex>
        <Flex alignItems={"center"} gap={2} w={"40%"}>
          <Select>
            <option value="">Select all status campaign</option>
            <option value="skynet">Skynet</option>
            <option value="galaxy">Galaxy</option>
            <option value="unicorn">Unicorn</option>
          </Select>
        </Flex> */}
      </Flex>
      {/* body */}
      <Flex flexDirection={{ sm: "column", lg: "row" }}>
        {/* left */}
        <Flex
          w={{ sm: "100%", lg: "40%" }}
          h={"100%"}
          flexDirection={"column"}
          borderRight="1px solid orange"
        >
          <Flex flexDirection={"column"} alignItems={"center"}>
            <Text fontWeight={"bold"} fontSize="xl">
              Số lượng CV theo từng nguồn
            </Text>
            <CountCVSourceComponent filterData={filterData} />
          </Flex>
          <Flex flexDirection={"column"} alignItems={"center"} mt={2}>
            <Text fontWeight={"bold"} fontSize="xl">
              Thời gian trung bình từng giai đoạn
            </Text>
            <AverageTimePerRoundChart filterData={filterData} />
          </Flex>
          <Flex
            mt={2}
            flexDirection={"column"}
            alignItems={"center"}
            width="100%"
            height="90%"
          >
            <Text fontWeight={"bold"} fontSize="xl">
              Số lượng CV theo từng giai đoạn
            </Text>
            <CountCVStatusComponent filterData={filterData} />
          </Flex>
        </Flex>
        {/* right */}
        <Flex
          w={{ sm: "100%", lg: "60%" }}
          flexDirection={"column"}
          alignItems={"center"}
        >
          <Flex flexDirection={"column"} alignItems={"center"}>
            <Text fontWeight={"bold"} fontSize="xl">
              Số lượng CV theo từng studio
            </Text>
            <CountCVByTeam filterData={filterData} />
          </Flex>
          <Flex flexDirection={"column"} alignItems={"center"}>
            <Text fontWeight={"bold"} fontSize="xl">
              Tỉ lệ chuyển đổi CV
            </Text>
            <ConversationRateChart filterData={filterData} />
          </Flex>
        </Flex>
      </Flex>
    </DashboardLayout>
  );
};

export default ReportPage;
