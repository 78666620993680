import { usePagination } from "@ajna/pagination";
import {
  Box,
  Button,
  Flex,
  Icon,
  Input,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import { DEFAULT_PAGE_SIZE } from "common/constants";
import { debounce } from "common/utils";
import CustomPagination from "components/common/Pagination";
import { PenLine } from "lucide-react";
import { FC, useEffect, useState } from "react";
import { IoMdClose } from "react-icons/io";
import { MdFilterList, MdSearch } from "react-icons/md";
import { toast } from "react-toastify";
import { studioService } from "services/StudioServirces";
import { constant } from "variables/constant";
import ModalDepartmentCommon from "./components/ModalDepartmentCommon";
import SkeletonCol from "./components/SkeletonCol";

type Props = {
  openAddModal: boolean;
  setOpenAddModal: (value: boolean) => void;
};

const ListDepartmentPage: FC<Props> = ({ openAddModal, setOpenAddModal }) => {
  const [showFilter, setShowFilter] = useState(false);

  const [total, setTotal] = useState<number | undefined>(undefined);
  const [totalPage, setTotalPage] = useState<number | undefined>(undefined);
  const {
    pages,
    pagesCount,
    currentPage,
    setCurrentPage,
    pageSize,
    setPageSize,
  } = usePagination({
    total: total,
    pagesCount: totalPage,
    initialState: {
      pageSize: DEFAULT_PAGE_SIZE,
      currentPage: 1,
    },
  });

  const [studioDatas, setStudioDatas] = useState([]);
  const [modalData, setModalData] = useState({} as any);
  const [isProgress, setIsProgress] = useState(false);

  const [filterData, setFilterData] = useState<any>({
    name: "",
  });

  const [modalType, setModalType] = useState<"add" | "edit">(undefined);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handlePageChange = (nextPage: number): void => {
    setCurrentPage(nextPage);
  };

  const handleFilterSearchNameChange = debounce((e: any) => {
    setFilterData((prev: any) => ({ ...prev, name: e.target.value }));
  }, 300);

  const onSubmitEditModal = (e: any) => {
    setIsProgress(true);
    e.preventDefault();
    const putData = {
      code: modalData.name.toLowerCase(),
      name: modalData.name,
      type: "team",
    };
    const header = {
      page: currentPage - 1,
      size: pageSize,
    };
    const params = {};
    const putAction = async () => {
      try {
        const res = await studioService.updateStudioData(
          modalData.id,
          params,
          putData,
          header
        );
        if (res) {
          toast.success("Sửa studio thành công");
          getStudioInfo();
        }
      } catch (error) {
        toast.error("Sửa studio thất bại");
      } finally {
        setIsProgress(false);
        setModalData({});
        onClose();
      }
    };
    putAction();
  };

  const onSubmitAddModal = (e: any) => {
    e.preventDefault();
    setIsProgress(true);
    const pushData = {
      code: modalData.name.toLowerCase(),
      name: modalData.name,
      type: "team",
    };
    const header = {
      page: currentPage - 1,
      size: pageSize,
    };
    const pushAction = async () => {
      try {
        const res = await studioService.addStudioData({}, pushData, header);
        if (res) {
          toast.success("Tạo studio thành công");
          getStudioInfo();
        }
      } catch (error) {
        toast.success("Tạo studio thất bại");
      } finally {
        setIsProgress(false);
        setModalData({});
        onClose();
      }
    };
    pushAction();
  };

  const getStudioInfo = () => {
    setIsProgress(true);
    const getData = async () => {
      const header = {
        page: currentPage - 1,
        size: pageSize,
      };
      const params: any = {};

      params.type = "team";

      if (filterData.name) {
        params.name = filterData.name;
      }
      try {
        const res = await studioService.getStudioDatas(params, header);
        if (res?.data?.data) {
          setTotal(res.data.totalItems);
          setTotalPage(res.data.totalPages);
          setStudioDatas(res.data.data);
        } else {
          setTotal(0);
          setStudioDatas([]);
        }
      } catch (error) {
        toast.error("Get studios info failed");
      } finally {
        setIsProgress(false);
      }
    };
    getData();
  };

  useEffect(() => {
    getStudioInfo();
  }, [currentPage, pageSize, filterData]);

  useEffect(() => {
    if (openAddModal) {
      setModalType("add");
      onOpen();
    }
  }, [openAddModal]);

  return (
    <>
      {/* btn filter */}
      {!showFilter ? (
        <Tooltip label="Hiển thị filter" placement="top">
          <Button
            size={"sm"}
            onClick={() => {
              setShowFilter(!showFilter);
            }}
            borderRadius={"10px"}
            colorScheme="orange"
            px={5}
            color="white"
            w={"fit-content"}
          >
            <Icon
              as={MdFilterList}
              display="block"
              width="20px"
              height="20px"
            />
            <Text px={2}>Filter</Text>
          </Button>
        </Tooltip>
      ) : (
        <Flex gap={2} alignItems={"center"} w={"20%"}>
          <Tooltip label="Đóng filter" placement="top">
            <Box>
              <Icon
                as={IoMdClose}
                cursor={"pointer"}
                onClick={() => {
                  setShowFilter(!showFilter);
                }}
              />
            </Box>
          </Tooltip>
          <Input
            size={"sm"}
            type="text"
            placeholder="Search name"
            onChange={handleFilterSearchNameChange}
          />

          <Tooltip label="Search" placement="top">
            <Button size={"sm"} colorScheme={"orange"} borderRadius={"50%"}>
              <Icon color={"white"} as={MdSearch} />
            </Button>
          </Tooltip>
        </Flex>
      )}
      {/* table */}
      {studioDatas.length > 0 ? (
        <TableContainer mt={2} h={"85%"}>
          <Table w={"100%"} size={"sm"} variant="simple" colorScheme="orange">
            <Thead
              background={"white"}
              position="sticky"
              top={0}
              zIndex="docked"
              w="100%"
            >
              <Tr fontWeight={"bold"} fontSize={"30px"}>
                <Th>Name</Th>
                <Th>Action</Th>
              </Tr>
            </Thead>
            {!isProgress ? (
              <Tbody pt={5}>
                {studioDatas.map((studio, index) => (
                  <Tr
                    key={studio.id}
                    _hover={{
                      background: "orange.100",
                      transition: "ease-in-out 0.2s",
                    }}
                  >
                    <Td>
                      <Box display={"flex"} alignItems={"center"} gap={3}>
                        <Text>{studio.name}</Text>
                      </Box>
                    </Td>
                    <Td w={"10%"}>
                      <Tooltip label="Sửa studio" placement="right-start">
                        <Button
                          size={"sm"}
                          colorScheme={"orange"}
                          onClick={() => {
                            setModalData(studio);
                            setModalType("edit");
                            onOpen();
                          }}
                        >
                          <PenLine size="16" />
                        </Button>
                      </Tooltip>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            ) : (
              <SkeletonCol col={4} />
            )}
          </Table>
        </TableContainer>
      ) : (
        <Flex justifyContent={"center"} alignItems={"center"} h={"60vh"}>
          <Text fontSize={"2xl"} fontWeight="bold">
            Không có dữ liệu
          </Text>
        </Flex>
      )}

      <Box pt={2}>
        <CustomPagination
          pages={pages}
          pagesCount={pagesCount}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          pageSize={pageSize}
          onPageSizeChange={setPageSize}
          optionPageSize={[10, 20, 50]}
          setCurrentPage={setCurrentPage}
        />
      </Box>
      {/* Modal */}

      <ModalDepartmentCommon
        setOpenAddModal={setOpenAddModal}
        onClose={onClose}
        isOpen={isOpen}
        modalData={modalData}
        setModalData={setModalData}
        onSubmit={
          modalType === constant.ADD_MODAL
            ? onSubmitAddModal
            : onSubmitEditModal
        }
      />
    </>
  );
};

export default ListDepartmentPage;
