import { Flex, Tabs, TabList, Tab, Text } from "@chakra-ui/react";
import { HTTP_CODE } from "common/constants";
import _ from "lodash";
import { createContext, useEffect, useState } from "react";
import { evaluationCriteriaServices } from "services/EvaluationCriteria/EvaluationCriteriaServices";
import { ICriteriaGroup, IEvaluationCriteriaData } from "types/interface";
import DashboardLayout from "../../../layout";
import EvaluationCriteriaList from "./EvaluationCriteria";
import EvaluationCriteriaGroup from "./EvaluationCriteriaGroup";
import { BsExclamationOctagonFill } from "react-icons/bs";

const tabPanelTitle = ["Tiêu chí đánh giá", "Nhóm tiêu chí đánh giá"];
const TAB_PANEL_INDEX = {
  EVALUATION_CRITERIA: 0,
  EVALUATION_CRITERIA_GROUP: 1,
};

export const EvaluationCriteriaContext = createContext(null);

const EvaluationCriteria = () => {
  const [selectedTab, setSelectedTab] = useState<number>(
    TAB_PANEL_INDEX.EVALUATION_CRITERIA
  );
  const [evaluationCriteriaData, setEvaluationCriteriaData] = useState<
    IEvaluationCriteriaData[]
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const getEvaluationCriteriaData = async () => {
    setIsLoading(true);
    const headers = {
      page: 0,
      size: 1000,
    };

    try {
      const evaluationCompetencesDataRes =
        await evaluationCriteriaServices.getEvaluationCriteria({}, headers);
      const criteriaGroupRes =
        await evaluationCriteriaServices.getListEvaluationCriteriaGroup(
          headers
        );

      // Check if both requests are successful
      if (
        evaluationCompetencesDataRes?.status === HTTP_CODE.SUCCESS &&
        criteriaGroupRes?.status === HTTP_CODE.SUCCESS
      ) {
        const evaluationCompetencesData =
          evaluationCompetencesDataRes?.data?.data;
        const criteriaGroupData = criteriaGroupRes?.data?.data;

        // Group evaluation competences data by criteriaGroupId
        const groupedData = _.groupBy(
          evaluationCompetencesData,
          "criteriaGroupId"
        );

        // Refactor grouped data
        const refactorCriteria = _.map(
          groupedData,
          (data, criteriaGroupId) => ({
            criteriaGroupId: parseInt(criteriaGroupId),
            data,
            name: data[0].criteriaGroupName,
          })
        );

        // Merge criteria group data with refactor criteria to make sure all criteria group has data
        const finalCriteriaData = criteriaGroupData.map(
          (group: ICriteriaGroup) => {
            const data = refactorCriteria.find(
              (item) => item.criteriaGroupId === group.masterdata_id
            );
            return data
              ? data
              : {
                  criteriaGroupId: group.masterdata_id,
                  data: [],
                  name: group.masterdata_name,
                };
          }
        );

        setIsLoading(false);
        setEvaluationCriteriaData(finalCriteriaData);
      }
    } catch (error) {
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getEvaluationCriteriaData();
  }, []);

  const handleTabChange = (selectedTabIndex: number) => {
    setSelectedTab(selectedTabIndex);
  };

  return (
    <EvaluationCriteriaContext.Provider
      value={{
        isLoading,
        evaluationCriteriaData,
        getEvaluationCriteriaData,
      }}
    >
      <DashboardLayout>
        <Flex flexDirection="column" gap={1} w="full" h="full">
          <Flex
            alignItems="center"
            justifyContent={"space-between"}
            gap={3}
            px={4}
            pb={2}
            borderBottom={"1px solid #f5932a"}
            w="full"
            h={"8%"}
          >
            <Flex w={"40%"}>
              <Tabs
                align="start"
                mt="15px"
                onChange={handleTabChange}
                defaultIndex={selectedTab}
              >
                <TabList>
                  {tabPanelTitle.map((title, index) => (
                    <Tab key={index}>{title}</Tab>
                  ))}
                </TabList>
              </Tabs>
            </Flex>
            <Flex maxW={"60%"} gap="3px" px={3} bgColor="red.100" alignItems="center">
              <Text
                py="5px"
                px="10px"
                color="secondaryGray.900"
                fontSize={"md"}
                display="flex"
                alignItems={"center"}
              >
                Tiêu chí đánh giá (competences) giúp bạn xây dựng tiêu chí đánh
                giá ứng viên chính xác.
              </Text>
              <BsExclamationOctagonFill size={25} color="orange"/>
            </Flex>
          </Flex>
          {selectedTab === TAB_PANEL_INDEX.EVALUATION_CRITERIA && (
            <EvaluationCriteriaList />
          )}
          {selectedTab === TAB_PANEL_INDEX.EVALUATION_CRITERIA_GROUP && (
            <EvaluationCriteriaGroup />
          )}
        </Flex>
      </DashboardLayout>
    </EvaluationCriteriaContext.Provider>
  );
};

export default EvaluationCriteria;
