import { API_URL } from "config/environment";
import ApiServices from "services/ApiServices";

const base_url = API_URL + "/job-info";

export class JobManageServices extends ApiServices {
  public async getJobById(parasm: any, headers: any) {
    return await this.getRequest(base_url + `/${parasm.id}`, "", headers);
  }

  public async update(body: any, parasm: any, headers: any) {
    return await this.putRequest(
      base_url + `/${parasm.id}`,
      parasm,
      body,
      headers
    );
  }

  public async create(body: any, parasm: any, headers: any) {
    return await this.postRequest(base_url, parasm, body, headers);
  }
}

export const jobManageService = new JobManageServices();
