import { useEffect, useState } from "react";
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  useColorModeValue,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalCloseButton,
  Text,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import { useForm } from "react-hook-form";
import _ from "lodash";
import ErrorMessage from "components/common/ErrorFormMessage";
import TextEditor from "../ManageEmail/EmailEditor/TextEditor";
import { IMailHistory } from "types/interface";
import { getUsernameFromEmail } from "common/utils";

interface PreviewMailHistoryModalProps {
  isOpen: boolean;
  onClose: () => void;
  mailHistoryInfo?: IMailHistory;
}

const PreviewMailHistoryModal = (props: PreviewMailHistoryModalProps) => {
  const { isOpen, onClose, mailHistoryInfo } = props;

  const [textValue, setTextValue] = useState<any>({ value: null });
  const [textEditorError, setTextEditorError] = useState<{
    message: string | null;
  }>({ message: null });

  const {
    register,
    formState: { errors },
    setValue: setFormValue,
  } = useForm();

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const labelColor = useColorModeValue("secondaryGray.900", "white");

  useEffect(() => {
    setFormValue("candidateEmail", mailHistoryInfo?.candidate_email);
    setFormValue("titleEmail", mailHistoryInfo?.subject);
    setTextValue({ value: mailHistoryInfo?.content });
  }, [isOpen]);

  const inputStyle = {
    _placeholder: { opacity: 1, color: labelColor },
    _hover: { borderColor: "none" },
    style: { cursor: "not-allowed" },
    color: textColor,
    disabled: true,
    borderRadius: "5px",
    opacity: "0.8 !important",
    size: "sm",
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Lịch sử gửi email</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Card p="0" m="20px 0">
            <form>
              <Flex
                p="0 5px"
                justifyContent="space-between"
                flexDirection="column"
                gap="10px"
              >
                <FormControl>
                  <FormLabel>Tới</FormLabel>
                  <Input
                    placeholder="Email ứng viên"
                    {...inputStyle}
                    {...register("candidateEmail")}
                    name="candidateEmail"
                  />
                  <ErrorMessage error={errors.candidateEmail} />
                </FormControl>

                <FormControl>
                  <FormLabel>Tiêu đề</FormLabel>
                  <Input
                    placeholder="Tiêu đề của email"
                    {...inputStyle}
                    {...register("titleEmail")}
                    name="titleEmail"
                  />

                  <ErrorMessage error={errors.titleEmail} />
                </FormControl>

                <FormControl>
                  <FormLabel>Nội dung</FormLabel>
                  <TextEditor
                    textValue={textValue}
                    onChange={setTextValue}
                    disable={true}
                  />
                  <ErrorMessage error={textEditorError} />
                </FormControl>
              </Flex>
            </form>

            <Flex mt="10px" fontStyle="italic">
              <Text>Gửi bởi:</Text>&nbsp;
              <Text fontWeight="600">
                {getUsernameFromEmail(mailHistoryInfo?.mail_send_by)}
              </Text>
              &nbsp; vào &nbsp;
              <Text fontWeight="600">{mailHistoryInfo?.created_at}</Text>
            </Flex>

            <Flex ml="auto" mt="10px" gap="10px">
              <Button border="1px solid #a09c9c" onClick={onClose}>
                Đóng
              </Button>
            </Flex>
          </Card>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default PreviewMailHistoryModal;
