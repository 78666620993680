import { usePagination } from "@ajna/pagination";
import { SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  FormControl,
  Input,
  Spinner,
  Text,
  Tooltip,
  useColorModeValue,
} from "@chakra-ui/react";
import { DEFAULT_PAGE_SIZE, HTTP_CODE } from "common/constants";
import { removeUndefinedKeyFromObject } from "common/utils";
import ErrorMessage from "components/common/ErrorFormMessage";
import CustomPagination from "components/common/Pagination";
import { UnFilterIcon } from "components/icons/Icons";
import useOpenNotification from "hook/useOpenNotification";
import {
  createContext,
  KeyboardEvent,
  useEffect,
  useRef,
  useState,
} from "react";
import { useForm } from "react-hook-form";
import { MdArrowRightAlt } from "react-icons/md";
import { candidateService } from "services/Candidate/CandidateServices";
import { OptionType } from "types/interface";
import DashboardLayout from "../../layout";
import ListCandidateTable from "./Table/ListCandidateTable";

export const CandidateInfomationContext = createContext(null);

const CandidateManage = () => {
  const {
    register,
    getValues,
    formState: { errors },
    handleSubmit,
    reset: resetFormValue,
  } = useForm();

  const [listCandidate, setListCandidate] = useState([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const selectedFilterJob = useRef<OptionType[]>([]);
  const selectParams = useRef<any>({});

  const setSelectedFilterJob = (value: OptionType[]) => {
    setCurrentPage(1);
    selectedFilterJob.current = value;
  };

  const openNotification = useOpenNotification();

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const focusBorderColor = useColorModeValue("secondaryGray.500", "white");

  const [totalData, setTotalData] = useState<number>(0);

  const {
    pages,
    pagesCount,
    currentPage,
    setCurrentPage,
    pageSize,
    setPageSize,
  } = usePagination({
    total: totalData,
    initialState: {
      pageSize: DEFAULT_PAGE_SIZE,
      currentPage: 1,
    },
  });

  const getCandidateData = async (params: any) => {
    setIsLoading(true);
    const headers = {
      page: currentPage - 1,
      size: pageSize,
    };

    try {
      const candidateRes = await candidateService.getCandidate(params, headers);

      if (candidateRes?.status === HTTP_CODE.SUCCESS) {
        setIsLoading(false);
        setTotalData(candidateRes?.data?.totalItems);
        setListCandidate(candidateRes?.data?.data);
      }
    } catch (err) {
      openNotification("Network error", "error");
      setIsLoading(false);
      setListCandidate([]);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePageChange = (nextPage: number): void => {
    setCurrentPage(nextPage);
  };

  const validateStartDateAndEndDate = () => {
    const startDate = getValues("startDate");
    const endDate = getValues("endDate");

    if ((!startDate && endDate) || (startDate && !endDate)) {
      return "Ngày bắt đầu và kết thúc cần được điền!";
    }

    if (startDate && endDate && !(endDate >= startDate)) {
      return "Ngày kết thúc phải lớn hơn ngày bắt đầu!";
    }

    return true;
  };

  const onSearchCandidateByFilter = () => {
    const { startDate, endDate, searchCandidate } = getValues();

    const params = {
      job_ids: selectedFilterJob.current.map((job) => job?.value)?.join(", "),
      start_date: startDate,
      end_date: endDate,
      name: searchCandidate,
    };

    const newParams = removeUndefinedKeyFromObject(params);
    setCurrentPage(1);
    selectParams.current = newParams;
  };

  const onClearFilter = () => {
    resetFormValue();
    setCurrentPage(1);
    selectedFilterJob.current = [];
    selectParams.current = {};
    onChangePageSize(DEFAULT_PAGE_SIZE);
  };

  const onChangePageSize = (pageSize: number) => {
    setPageSize(pageSize);
    setCurrentPage(1);
  };

  const handleKeyDownSearch = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      getCandidateData(selectParams.current);
    }
  };

  useEffect(() => {
    getCandidateData(selectParams.current);
  }, [pageSize, currentPage, selectParams.current]);

  return (
    <CandidateInfomationContext.Provider
      value={{ selectedFilterJob, setSelectedFilterJob }}
    >
      <DashboardLayout>
        <Flex
          flexDirection="column"
          gap={1}
          minHeight={{ sm: "50vh", md: "80vh" }}
        >
          <Flex
            alignItems="center"
            justifyContent={"space-between"}
            gap={3}
            px={4}
            pb={2}
            borderBottom={"1px solid #f5932a"}
            w="full"
          >
            <Flex justifyContent={"space-between"}>
              <Text fontSize="xl" fontWeight="bold">
                Danh sách ứng viên
              </Text>
            </Flex>
            <Flex>
              <form>
                <Flex gap="10px" marginLeft="auto" alignItems="center">
                  <FormControl>
                    <Input
                      color={textColor}
                      type="date"
                      w="200px"
                      {...register("startDate")}
                      name="startDate"
                      // maxHeight="32px"
                    />
                  </FormControl>
                  <Flex alignItems="center">
                    <MdArrowRightAlt />
                  </Flex>

                  <FormControl>
                    <Input
                      color={textColor}
                      type="date"
                      w="200px"
                      {...register("endDate", {
                        validate: validateStartDateAndEndDate,
                      })}
                      name="endDate"
                      // maxHeight="32px"
                    />
                    <Box position="absolute" top="100%" zIndex="999" w="300px">
                      <ErrorMessage error={errors.endDate} />
                    </Box>
                  </FormControl>

                  <FormControl>
                    <Input
                      placeholder="Tên ứng viên..."
                      _placeholder={{ opacity: 1, color: textColor }}
                      color={textColor}
                      {...register("searchCandidate")}
                      _focus={{ borderColor: focusBorderColor }}
                      w="200px"
                      onKeyDown={handleKeyDownSearch}
                    />
                  </FormControl>
                  <FormControl>
                    <Tooltip label="Tìm kiếm" placement="top">
                      <Button
                        p={0}
                        borderRadius={"50%"}
                        colorScheme="blue"
                        ml="auto"
                        onClick={handleSubmit(onSearchCandidateByFilter)}
                        type="submit"
                      >
                        <SearchIcon />
                      </Button>
                    </Tooltip>
                  </FormControl>
                  <FormControl>
                    <Tooltip label="Xóa filter" placement="top">
                      <Box
                        cursor="pointer"
                        _hover={{ color: "#C53030" }}
                        onClick={onClearFilter}
                      >
                        <UnFilterIcon fontSize="24px" />
                      </Box>
                    </Tooltip>
                  </FormControl>
                </Flex>
              </form>
            </Flex>
          </Flex>

          {isLoading ? (
            <Flex
              w={"100%"}
              h="400px"
              justifyContent="center"
              alignItems="center"
            >
              <Spinner />
            </Flex>
          ) : (
            <Flex
              flexDirection="column"
              minHeight="70vh"
              justifyContent="space-between"
            >
              <ListCandidateTable listCandidate={listCandidate} />

              {listCandidate.length > 0 && (
                <CustomPagination
                  pages={pages}
                  pagesCount={pagesCount}
                  currentPage={currentPage}
                  onPageChange={handlePageChange}
                  pageSize={pageSize}
                  onPageSizeChange={onChangePageSize}
                  optionPageSize={[10, 20, 50]}
                />
              )}
            </Flex>
          )}
        </Flex>
      </DashboardLayout>
    </CandidateInfomationContext.Provider>
  );
};

export default CandidateManage;
