import { useEffect } from "react";
import {
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalBody,
  Flex,
  FormControl,
  FormLabel,
  useColorModeValue,
  Input,
  ModalFooter,
  Button,
  Select,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import { useForm } from "react-hook-form";
import ErrorMessage from "components/common/ErrorFormMessage";
import { HTTP_CODE, TYPE_MASTER_DATA } from "common/constants";
import { useDispatch } from "react-redux";
import {
  actSetEditMasterData,
  actSetOpenMasterDataModal,
} from "redux/features/superAdminSlice";
import { masterDataService } from "services/MasterData/MasterDataServices";
import useOpenNotification from "hook/useOpenNotification";

interface AddMasterDataModalProps {
  setComponentShouldBeRefresh: () => void;
}

const AddMasterDataModal = (props: AddMasterDataModalProps) => {
  const { setComponentShouldBeRefresh } = props;

  const superAdminState = useSelector((state: RootState) => state.SuperAdmin);
  const { isOpenMasterDataModal, editMasterData } = superAdminState;
  const isEditMasterData = !!editMasterData;

  const dispatch = useDispatch();
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const openNotification = useOpenNotification();

  const {
    register,
    setValue: setFormValue,
    formState: { errors },
    getValues,
    handleSubmit,
    reset: resetFormValue,
  } = useForm();

  const onCloseMasterDataModal = () => {
    dispatch(actSetOpenMasterDataModal(false));
    dispatch(actSetEditMasterData(null));
    resetFormValue();
  };

  const onCreateMasterData = async () => {
    const masterDataBody = { ...getValues() };

    try {
      if (isEditMasterData) {
        const updatedMasterData = await masterDataService.updateMasterData(
          masterDataBody,
          { id: editMasterData?.id },
          {}
        );

        if (updatedMasterData?.status === HTTP_CODE.SUCCESS) {
          openNotification("Cập nhật master data thành công");
          setComponentShouldBeRefresh();
        }
      } else {
        const createMasterDataRes = await masterDataService.createMasterData(
          masterDataBody,
          {}
        );

        if (createMasterDataRes?.status === HTTP_CODE.CREATED) {
          openNotification("Tạo master data thành công");
          setComponentShouldBeRefresh();
        }
      }

      onCloseMasterDataModal();
    } catch (error) {
      onCloseMasterDataModal();
      throw error;
    }
  };

  useEffect(() => {
    if (isEditMasterData) {
      setFormValue("code", editMasterData?.code);
      setFormValue("name", editMasterData?.name);
      setFormValue("type", editMasterData?.type);
    }
  }, [isOpenMasterDataModal]);

  return (
    <Modal isOpen={isOpenMasterDataModal} onClose={onCloseMasterDataModal}>
      <ModalOverlay />

      <ModalContent>
        <ModalHeader>
          {isEditMasterData ? "Sửa master data" : "Thêm master data"}
        </ModalHeader>
        <ModalCloseButton />

        <ModalBody>
          <form
            style={{
              width: "100%",
              display: "flex",
              marginBottom: "5px",
            }}
          >
            <Flex flexDirection="column" gap="10px" w="100%">
              <FormControl>
                <FormLabel>Code</FormLabel>
                <Input
                  color={textColor}
                  {...register("code", {
                    required: "Hãy nhập code",
                  })}
                  name="code"
                />
                <ErrorMessage error={errors.code} />
              </FormControl>

              <FormControl>
                <FormLabel>Name</FormLabel>
                <Input
                  color={textColor}
                  {...register("name", {
                    required: "Hãy nhập name",
                  })}
                  name="name"
                />
                <ErrorMessage error={errors.name} />
              </FormControl>

              <FormControl>
                <FormLabel>Type</FormLabel>
                <Select
                  color={textColor}
                  {...register("type", {
                    required: "Hãy chọn type",
                  })}
                  name="type"
                  placeholder="Chọn type"
                >
                  {Object.entries(TYPE_MASTER_DATA).map(([value, label]) => (
                    <option key={value} value={value}>
                      {label}
                    </option>
                  ))}
                </Select>
                <ErrorMessage error={errors.type} />
              </FormControl>
            </Flex>
          </form>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="red" mr={3} onClick={onCloseMasterDataModal}>
            Đóng
          </Button>
          <Button
            colorScheme="blue"
            onClick={handleSubmit(onCreateMasterData)}
            type="submit"
          >
            {isEditMasterData ? "Cập nhật" : "Tạo mới"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddMasterDataModal;
