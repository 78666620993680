import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "redux/store";
import { IMasterData, IMatrix } from "types/interface";

interface ISuperAdminState {
  isOpenMatrixModal: boolean;
  isOpenMasterDataModal: boolean;
  editMatrixData: IMatrix | null;
  editMasterData: IMasterData | null;
}

const initialState: ISuperAdminState = {
  isOpenMatrixModal: false,
  isOpenMasterDataModal: false,
  editMatrixData: null,
  editMasterData: null,
};

export const superAdminSlice = createSlice({
  name: "SuperAdmin",
  initialState,
  reducers: {
    actSetOpenMatrixModal: (
      state: ISuperAdminState,
      action: PayloadAction<any>
    ) => {
      const { payload } = action;
      state.isOpenMatrixModal = payload;
    },

    actSetOpenMasterDataModal: (
      state: ISuperAdminState,
      action: PayloadAction<any>
    ) => {
      const { payload } = action;
      state.isOpenMasterDataModal = payload;
    },

    actSetEditMatrixData: (
      state: ISuperAdminState,
      action: PayloadAction<any>
    ) => {
      const { payload } = action;
      state.editMatrixData = payload;
    },

    actSetEditMasterData: (
      state: ISuperAdminState,
      action: PayloadAction<any>
    ) => {
      const { payload } = action;
      state.editMasterData = payload;
    },
  },
});

export const {
  actSetOpenMatrixModal,
  actSetEditMatrixData,
  actSetOpenMasterDataModal,
  actSetEditMasterData,
} = superAdminSlice.actions;
export const superAdminSelector = (state: RootState) => state.SuperAdmin;
export default superAdminSlice.reducer;
