import { CV_STATUS, STATUS_NAME } from "common/constants";

export function updateChartValues(chartData: any[], responseData: any): any {
  // Tạo một đối tượng map để lưu trữ count của mỗi status
  const statusCountMap: { [key: string]: number } = {};
  responseData.statusCount.forEach(
    (item: { status: string; count: string }) => {
      const status = item.status.toLowerCase();
      statusCountMap[status] = parseInt(item.count);
    }
  );
  // Cập nhật giá trị của chartData dựa trên count
  const newChartData = chartData.map((data: any) => {
    const status = data.data_id.toLowerCase();
    if (statusCountMap.hasOwnProperty(status)) {
      data.value = statusCountMap[status];
      return data;
    }
    return data;
  });
  return newChartData;
}

export function updateAverageStateData(
  averageData: any[],
  responseData: any
): any {
  const cloneAverageData = JSON.parse(JSON.stringify(averageData));
  responseData.forEach(
    (item: { status: string; count: number; time: number }) => {
      cloneAverageData.findIndex((data: any) => {
        if (data.status_id === item.status) {
          data.time = item.time / item.count;
        }
      });
    }
  );
  return cloneAverageData;
}

export function convertHoursToDaysAndHours(hours: number) {
  const days = Math.floor(hours / 24);
  const remainingHours = Math.ceil(hours % 24);
  return `${days} ngày, ${remainingHours} giờ`;
}

export function sortDataByStatus(statusCount: any[]) {
  const status = Object.keys(STATUS_NAME).map((key) => key.toLowerCase());

  const sortOrderIndex: { [key: string]: number } = {};
  status.forEach((status, index) => {
    sortOrderIndex[status] = index;
  });

  statusCount.sort((a: any, b: any) => {
    return sortOrderIndex[a.status] - sortOrderIndex[b.status];
  });
  return statusCount;
}

export const allValuesNull = (chartData: any) => {
  return chartData.every((item: any) => item.value === null);
};

export const dataFilterConversation = (data: any, statusOrder: any) => {
  let previousRateValue = 0;

  return statusOrder.reverse().map((status: any, index: number) => {
    if (data[status] === undefined && index === 0) {
      previousRateValue = 0;
    }
    if (data[status] !== undefined) {
      previousRateValue = data[status] + previousRateValue;
    }

    return {
      id: `id_${status}`,
      label: status,
      value: previousRateValue,
    };
  });
};
