import { useState } from "react";
import { usePagination } from "@ajna/pagination";
import {
  Box,
  Button,
  Flex,
  Link,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import CustomFilter from "components/common/CustomFilter";
import CustomPagination from "components/common/Pagination";
import CreateCampaignModal from "./Modal/CreateCampaignModal";
import { DEFAULT_PAGE_SIZE } from "common/constants";

export type Campaign = {
  id?: number;
  name?: string;
  description?: string;
  source?: string;
  startDate?: string;
  endDate?: string;
  createdBy?: string;
};

const seedCampaignData: Campaign[] = [
  {
    id: 1,
    name: "Campaign 1",
    description: "Description",
    source: "TopCV",
    startDate: "06/01/2024",
    endDate: "06/02/2024",
    createdBy: "HR 1",
  },
  {
    id: 2,
    name: "Campaign 2",
    description: "Description 2",
    source: "Linked",
    startDate: "22/12/2024",
    endDate: "30/12/2024",
    createdBy: "HR 2",
  },
  {
    id: 3,
    name: "Campaign 3",
    description: "Description 3",
    source: "TopCV",
    startDate: "06/01/2024",
    endDate: "06/02/2024",
    createdBy: "HR 3",
  },
];

const options: { value: string; label: string }[] = [
  { value: "QA/QC", label: "QA/QC" },
  { value: "BA", label: "BA" },
  { value: "Tester", label: "Tester" },
];

const CampaignManage = () => {
  const [campaignData, setCampaignData] = useState<Campaign[] | null>(
    seedCampaignData
  );
  const [isOpenCampaignModal, setIsOpenCampaignModal] =
    useState<boolean>(false);
  const [editCampaignData, setEditCampaignData] = useState<Campaign>({});

  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const bgColor = useColorModeValue("white", "navy.800");
  const textColor = useColorModeValue("secondaryGray.900", "white");

  // pagination table
  const [totalData, setTotalData] = useState<number | undefined>(undefined);
  const outerLimit = 2;
  const innerLimit = 2;

  const {
    pages,
    pagesCount,
    currentPage,
    setCurrentPage,
    pageSize,
    setPageSize,
  } = usePagination({
    total: totalData,
    initialState: {
      pageSize: DEFAULT_PAGE_SIZE,
      currentPage: 1,
    },
    limits: {
      outer: outerLimit,
      inner: innerLimit,
    },
  });

  const handlePageChange = (nextPage: number): void => {
    setCurrentPage(nextPage);
    // fetch data from API later
  };

  const onChangePageSize = (pageSize: number) => {
    setPageSize(pageSize);
    setCurrentPage(1);
  };

  const handleSelectChange = (option: any) => {
    // fetch data from API later
  };

  const cellStyles = {
    borderColor: borderColor,
    fontSize: { sm: "14px" },
    minW: { sm: "150px", md: "200px", lg: "auto" },
    textColor: textColor,
  };

  const handleEditCampaign = (campaign: Campaign) => {
    setEditCampaignData(campaign);
    setIsOpenCampaignModal(true);
  };

  const handleAddCampaign = () => {
    setEditCampaignData({});
    setIsOpenCampaignModal(true);
  };

  return (
    <Box
      flexDirection="column"
      w="100%"
      px="0px"
      pb={3}
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      <Card>
        <Flex px="25px" mb="8px" justifyContent="space-between" align="center">
          <Text
            color={textColor}
            fontSize="22px"
            fontWeight="700"
            lineHeight="100%"
          >
            Campaign Table
          </Text>

          <Box>
            <Button onClick={handleAddCampaign} colorScheme="blue">
              Tạo
            </Button>
          </Box>
        </Flex>
        <Table variant="simple" color="gray.500" mb="24px" mt="12px">
          <Thead position="sticky" top={0} bgColor={bgColor}>
            <Tr>
              <Th
                borderColor={borderColor}
                cursor="pointer"
                textColor={textColor}
              >
                <Flex align="center" fontSize={{ sm: "10px", lg: "12px" }}>
                  Chiến dịch
                </Flex>
              </Th>
              <Th
                borderColor={borderColor}
                cursor="pointer"
                textColor={textColor}
              >
                <Flex align="center" fontSize={{ sm: "10px", lg: "12px" }}>
                  Mô tả
                </Flex>
              </Th>

              <Th borderColor={borderColor} cursor="pointer">
                <Flex align="center" fontSize={{ sm: "10px", lg: "12px" }}>
                  Start date
                </Flex>
              </Th>

              <Th borderColor={borderColor} cursor="pointer">
                <Flex align="center" fontSize={{ sm: "10px", lg: "12px" }}>
                  End date
                </Flex>
              </Th>

              <Th borderColor={borderColor} cursor="pointer">
                <Flex align="center" fontSize={{ sm: "10px", lg: "12px" }}>
                  Người tạo
                  <CustomFilter
                    options={options}
                    defaultValue={null}
                    isMulti={true}
                    onChange={handleSelectChange}
                  />
                </Flex>
              </Th>

              <Th borderColor={borderColor} cursor="pointer">
                <Flex align="center" fontSize={{ sm: "10px", lg: "12px" }}>
                  Action
                </Flex>
              </Th>
            </Tr>
          </Thead>

          <Tbody bg={bgColor}>
            {campaignData.map((campaign: Campaign, index) => {
              return (
                <Tr key={index}>
                  <Td key={index + campaign.name} {...cellStyles}>
                    <Link>{campaign.name}</Link>
                  </Td>
                  <Td key={index + campaign.description} {...cellStyles}>
                    {campaign.description}
                  </Td>

                  <Td key={index + campaign.startDate} {...cellStyles}>
                    {campaign.startDate}
                  </Td>
                  <Td key={index + campaign.endDate} {...cellStyles}>
                    {campaign.endDate}
                  </Td>
                  <Td key={index + campaign.createdBy} {...cellStyles}>
                    {campaign.createdBy}
                  </Td>

                  <Td {...cellStyles}>
                    <Button
                      size="sm"
                      colorScheme="orange"
                      onClick={() => handleEditCampaign(campaign)}
                    >
                      Sửa
                    </Button>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>

        <CreateCampaignModal
          isOpen={isOpenCampaignModal}
          onClose={() => setIsOpenCampaignModal(false)}
          campaignData={editCampaignData}
        />

        <Flex justifyContent="center">
          <CustomPagination
            pages={pages}
            pagesCount={pagesCount}
            currentPage={currentPage}
            onPageChange={handlePageChange}
            pageSize={pageSize}
            onPageSizeChange={onChangePageSize}
            optionPageSize={[10, 20, 50]}
          />
        </Flex>
      </Card>
    </Box>
  );
};

export default CampaignManage;
